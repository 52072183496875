import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TTabID from "../types/ChallengeViewTabsEnum";

const RedirectToSubmitCode = () => {
  const { challengeId, runNumber, userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(
      `/challenges/${challengeId}?tab=${TTabID.SubmitCode}&runNumber=${runNumber}&userId=${userId}`
    );
  }, [challengeId, runNumber, userId, navigate]);

  return null;
};

export default RedirectToSubmitCode;
