import API from "../../API";
import { DataLabels } from "../../types/DataLabels";
import axios from "axios";
import { Frequencies } from "../../types/Frequencies";
import ChallengeNew from "../../types/ChallengeNew";
import { Templates } from "../../types/Template";
import { DataFormat } from "../../types/DataFormat";
import { Projects } from "../../types/Projects";
import ProjectNew from "../../types/ProjectNew";
import { axiosTokened } from "../../utils/AxiosInstances";

const postSampleFile = async (selectedFile: string | Blob) => {
  const formData = new FormData();
  formData.append("file", selectedFile);
  const { data } = await axios.post<DataLabels>(API.postSampleFile, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

const getFrequencies = async () => {
  const { data } = await axios.get<Frequencies>(API.getFrequencies);
  return data;
};

const getProjects = async () => {
  const { data } = await axios.get<Projects>(API.getProjects);
  return data;
};

const getUserProjects = async () => {
  const { data } = await axiosTokened.get<Projects>(API.getUserProjects);
  return data;
}

const postChallengeNew = async (challengeNew: ChallengeNew) => {
  const { data } = await axios.post(API.postChallengeNew, challengeNew);
  return data;
};

const postProjectNew = async (projectNew: ProjectNew) => {
  const { data } = await axiosTokened.post(API.postProjectNew, projectNew);
  return data;
};

const getNamedTemplates = async () => {
  const { data } = await axios.get<Templates>(API.getNamedTemplates);
  return data;
};

const getTemplateDataFormat = async (templateId: string) => {
  const { data } = await axios.get<DataFormat>(
    API.genGetTemplateFormat(templateId)
  );
  return data;
};

export {
  postSampleFile,
  getFrequencies,
  postChallengeNew,
  getNamedTemplates,
  getTemplateDataFormat,
  getProjects,
  getUserProjects,
  postProjectNew,
};
