import ChapterTitle from "../../../components/ChapterTitle";
import FormatValidatorJson from "./FormatValidatorJson";
import FormatValidatorsCode from "./FormatValidatorsCode";

interface FormatValidatorsProps {
  templateId: string;
}

const FormatValidators = ({ templateId }: FormatValidatorsProps) => {
  return (
    <>
      <ChapterTitle header="JSON Schema validator" />
      <FormatValidatorJson templateId={templateId} />
      <ChapterTitle
        header="Custom Code validators"
        subHeader="python example for accessing data ---apartments = json.loads(sys.stdin.read())---. Also import sys and json"
      />
      <FormatValidatorsCode templateId={templateId} />
    </>
  );
};

export default FormatValidators;
