import { useContext, useState } from "react";
import { ProxyType } from "../../../types/ProxyType";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Confirm from "../../../components/Confirm";
import { GPTModel } from "../../../types/GPTModel";
import { SelectGPT } from "./SelectGPT";
import { GPTPrompts } from "../../../types/GPTPrompt";
import { PromptSettingsDialog } from "./PromptSettingsDialog";
import { LastCodeRefContext } from "../contexts/LastCodeRefContext";
import { UseLastCodeRefType } from "../hooks/UseLastCodeRef";

interface CodeGenerationSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (settings: CodeGenerationSettings) => void;
  isConfirmNeeded?: boolean;
}

const PROXY_TO_LABEL = {
  [ProxyType.NONE]: "No proxy",
  [ProxyType.REQUEST]: "Simple proxy",
  [ProxyType.RENDER]: "Render page for a few seconds",
};

const Row = ({ children }: { children: React.ReactNode }) => {
  if (Array.isArray(children) && children.length === 2) {
    return (
      <>
        <Grid item xs={5}>
          {children[0]}
        </Grid>
        <Grid item xs={7}>
          {children[1]}
        </Grid>
      </>
    );
  } else if (!Array.isArray(children)) {
    return (
      <Grid item xs={12}>
        {children}
      </Grid>
    );
  } else {
    // unsupported to give more than 2 nodes inside children
    return null;
  }
};

type CodeGenerationSettings = {
  proxyType: string;
  modelGPT: string;
  prompts: GPTPrompts;
  codeUserId?: string;
  codeRunId?: string;
  validationId?: string;
};

const getCodeFixTooltip = (
  codeRunRef: UseLastCodeRefType,
  isPromptCustom: boolean
) => {
  if (isPromptCustom) {
    return "This prompt template is applicable only to default prompt, not custom prompt.";
  }
  if (!codeRunRef.codeRun) {
    return "You need to open a code run firstly. There is no submitted/run code in your screen.";
  }
  if (codeRunRef.health.isCodeRunning) {
    return "Code is running. The code run has to be finished to use this feature.";
  }
  if (!codeRunRef.health.isCodeCrashed) {
    if (!codeRunRef.badValidationId) {
      return "Please wait for validations to finish."; // also snapshot creation
    }
    if (!codeRunRef.health.isTopValidationErrored) {
      return "Code run is good, no validation produced an error (warning is fine, internal errors are ignored). Thus there is no need to fix the code.";
    }
  }
  return "";
};

export const CodeGenerationSettingsDialog = ({
  open,
  onClose,
  onSubmit,
  isConfirmNeeded,
}: CodeGenerationSettingsDialogProps) => {
  const [proxyType, setProxyType] = useState<string>(ProxyType.NONE);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [model, setModel] = useState<GPTModel | null>(null);
  const [prompts, setPrompts] = useState<GPTPrompts>([]);
  const [isFixMyCode, setIsFixMyCode] = useState(true);

  const lastCodeRef = useContext(LastCodeRefContext);
  if (!lastCodeRef) {
    return null;
  }
  const tooltipFixMyCode = getCodeFixTooltip(lastCodeRef, prompts.length > 0);
  const isCheckedFixMyCode = !tooltipFixMyCode && isFixMyCode;

  const toReturn: CodeGenerationSettings = {
    proxyType,
    modelGPT: model?.name || "nomodel",
    prompts,
    codeUserId: isCheckedFixMyCode ? lastCodeRef.codeRun?.userId : undefined,
    codeRunId: isCheckedFixMyCode ? lastCodeRef.codeRun?.runId : undefined,
    validationId: isCheckedFixMyCode ? lastCodeRef.badValidationId : undefined,
  };

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>AI settings</DialogTitle>
      <DialogContent>
        <Grid container columns={12} columnSpacing={2}>
          <Row>
            <InputLabel style={{ alignContent: "center" }}>
              Proxy usage
            </InputLabel>
            <Select
              size="small"
              displayEmpty={false}
              value={proxyType}
              onChange={(e) => setProxyType(e.target.value || proxyType)}
            >
              {Object.entries(PROXY_TO_LABEL).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Row>
          <Row>
            <InputLabel style={{ alignContent: "center" }}>Model</InputLabel>
            <SelectGPT model={model} setModel={setModel} />
          </Row>
          <Row>
            <InputLabel>Input cost per 1M tokens</InputLabel>
            {!!model?.price_in_m ? "$" + model.price_in_m : "-"}
          </Row>
          <Row>
            <InputLabel>Output cost per 1M tokens</InputLabel>
            {!!model?.price_out_m ? "$" + model.price_out_m : "-"}
          </Row>
          <Row>
            <InputLabel>Context length in tokens</InputLabel>
            {!!model ? model.context_length_tokens : "-"}
          </Row>
          <Row>
            <InputLabel>Max tokens</InputLabel>
            {!!model ? model.max_tokens : "-"}
          </Row>
          <Row>
            <InputLabel>Fix my code run</InputLabel>
            <Tooltip title={tooltipFixMyCode} placement="right">
              <span>
                <Checkbox
                  checked={isCheckedFixMyCode}
                  onChange={(_, checked) => setIsFixMyCode(checked)}
                  disabled={!!tooltipFixMyCode}
                />
              </span>
            </Tooltip>
          </Row>
          <Row>
            <InputLabel>Prompts</InputLabel>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIsPromptOpen(true)}
            >
              {prompts.length < 1
                ? isCheckedFixMyCode
                  ? "default fix code"
                  : "default"
                : "custom"}
            </Button>
          </Row>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!model}
          onClick={() => {
            if (isConfirmNeeded) {
              setIsConfirmOpen(true);
            } else {
              onSubmit(toReturn);
            }
          }}
        >
          Generate
        </Button>
      </DialogActions>
      <PromptSettingsDialog
        onClose={(_prompts) => {
          setPrompts(_prompts);
          setIsPromptOpen(false);
        }}
        open={isPromptOpen}
        proxyType={proxyType}
        isUseFixMyCode={isCheckedFixMyCode}
      />
      <Confirm
        onCancel={() => setIsConfirmOpen(false)}
        onConfirm={() => {
          setIsConfirmOpen(false);
          onSubmit(toReturn);
        }}
        open={isConfirmOpen}
        text="You have unsaved code changes. Are you sure you want to generate code and lose changes?"
      />
    </Dialog>
  );
};
