import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { useMutation } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQueryUsersBasic } from "../hooks/UseQueryUsersBasic";
import { useInvalidateProjectUsers } from "../hooks/UseQueryProjectUsers";
import UserSelector from "./UserSelector";
import { ProjectUsers } from "../types/ProjectUsers";
import ProjectRoleEnum from "../types/ProjectRoleEnum";
import { BackendError } from "../types/BackendError";
import ErrorAlert from "./ErrorAlert";
import { postAddUserToProject } from "../pages/DashboardView/LocalOperations";
import { postSendInvitationEmailToProject } from "../pages/ChallengeViewSteps/LocalOperations";

interface AddUserToProjectModalProps {
  open: boolean;
  onClose: () => void;
  projectUsers: ProjectUsers;
  projectId: string;
  onUserAssignToProjectSuccess?: () => void;
}

const AddUserToProjectModal: React.FC<AddUserToProjectModalProps> = ({
  open,
  onClose,
  projectUsers,
  projectId,
  onUserAssignToProjectSuccess,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const query = useQueryUsersBasic();
  const [userRoles, setUserRoles] = useState<{
    [key: string]: ProjectRoleEnum;
  }>({});
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [userEmail, setUserEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleChangeSelectedUsers = (selectedIds: string[]) => {
    setSelectedUserIds(selectedIds);
    setUserRoles((prevRoles) => {
      const newRoles = { ...prevRoles };
      selectedIds.forEach((id) => {
        if (!newRoles[id]) {
          newRoles[id] = ProjectRoleEnum.Contributor;
        }
      });
      return newRoles;
    });
  };

  const handleRemoveUser = (userId: string) => {
    handleChangeSelectedUsers(selectedUserIds.filter((id) => id !== userId));
  };

  const invalidateProjectUsers = useInvalidateProjectUsers(projectId);

  const handleRoleChange = (userId: string, role: ProjectRoleEnum) => {
    setUserRoles((prevRoles) => ({
      ...prevRoles,
      [userId]: role,
    }));
  };

  const addUsersToProject = useMutation<
    void,
    BackendError,
    { projectId: string; users: { user_id: number; role: ProjectRoleEnum }[] }
  >(postAddUserToProject);

  const onAddUsersToProject = () => {
    if (selectedUserIds.length > 0) {
      const userObjects = selectedUserIds.map((userId) => ({
        user_id: Number(userId),
        role: userRoles[userId],
      }));

      addUsersToProject.mutate(
        { projectId, users: userObjects },
        {
          onSuccess: () => {
            onClose();
            onUserAssignToProjectSuccess?.();
            invalidateProjectUsers();
            setSelectedUserIds([]);
          },
        }
      );
    }
  };

  const qSendInvitationEmail = useMutation<void, BackendError, void>(
    () => postSendInvitationEmailToProject(projectId, userEmail),
    {
      onSuccess: () => {
        setUserEmail("");
        setSnackbarMessage(`Invitation to ${userEmail} sent successfully!`);
        setSnackbarOpen(true);
      },
      onError: (error: BackendError) => {
        console.error(error);
      },
    }
  );

  const handleSendInvite = () => {
    const userExistsInProject = projectUsers.some(
      (user) => user.email === userEmail
    );
  
    if (userExistsInProject) {
      setErrorMessage(`User ${userEmail} is already a member of the project`);
    } else {
      qSendInvitationEmail.mutate();
      setErrorMessage(null);
    }
  };
  
  const handleClose = () => {
    onClose();
    handleSnackbarClose();
    setSelectedUserIds([]);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" component="h2">
            Add user(s) to project
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Add existing user" />
          <Tab label="Add new user" />
        </Tabs>

        {activeTab === 0 && (
          <Box mt={2}>
            {query.isSuccess && (
              <>
                <UserSelector
                  selectedUserIds={selectedUserIds}
                  onChange={handleChangeSelectedUsers}
                  users={query.data}
                  label="Select user(s)"
                  projectUsers={projectUsers}
                />

                {selectedUserIds.length > 0 && (
                  <Box mt={2} sx={{ maxHeight: "55vh", overflowY: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>Role</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedUserIds.map((userId) => {
                          const user = query.data.find(
                            (user) => user.id === userId
                          );
                          const projectUser = projectUsers.find(
                            (pu) => pu.user_id === Number(userId)
                          );
                          const projectRole =
                            userRoles[userId] ||
                            projectUser?.project_role ||
                            ProjectRoleEnum.Contributor;

                          return (
                            <TableRow key={userId}>
                              <TableCell>{user?.email || "Unknown"}</TableCell>
                              <TableCell sx={{ paddingRight: 12 }}>
                                <Select
                                  value={projectRole}
                                  onChange={(e) =>
                                    handleRoleChange(
                                      userId,
                                      e.target.value as ProjectRoleEnum
                                    )
                                  }
                                  displayEmpty
                                  fullWidth
                                >
                                  {Object.values(ProjectRoleEnum)
                                    .filter(
                                      (role) => role !== ProjectRoleEnum.Owner
                                    )
                                    .map((role) => (
                                      <MenuItem key={role} value={role}>
                                        {role}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleRemoveUser(userId)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Box>
                )}
              </>
            )}

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={onAddUsersToProject}
                disabled={
                  addUsersToProject.isLoading || selectedUserIds.length === 0
                }
              >
                {addUsersToProject.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Add User(s) to Project"
                )}
              </Button>
            </Box>

            {addUsersToProject.isError && (
              <ErrorAlert
                value={addUsersToProject.error}
                sx={{ marginTop: 1 }}
              />
            )}
          </Box>
        )}

        {activeTab === 1 && (
          <Box mt={2}>
            <Typography variant="subtitle1" gutterBottom>
              Send invitation email to the user
            </Typography>
            <TextField
              fullWidth
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder="User's email"
              variant="outlined"
            />
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendInvite}
                disabled={!userEmail || qSendInvitationEmail.isLoading}
              >
                Send invitation
              </Button>
            </Box>

            {errorMessage && <ErrorAlert value={errorMessage} sx={{ marginTop: 1 }} />}

            {qSendInvitationEmail.isError && (
              <ErrorAlert
                value={qSendInvitationEmail.error}
                sx={{ marginTop: 1 }}
              />
            )}
          </Box>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default AddUserToProjectModal;
