import React from "react";
import {
  Box,
  Typography,
  Checkbox,
  Alert,
  CircularProgress,
  Grid,
  Snackbar,
  Paper,
} from "@mui/material";

interface Notification {
  key: string;
  name: string;
  platform: boolean;
  email: boolean;
  roles: string[];
}

interface NotificationsTabProps {
  notifications: Notification[];
  isLoading: boolean;
  errorMessage: string;
  handleCheckboxChange: (key: string, type: string, checked: boolean) => void;
  isSuccessSnackbarOpen: boolean;
  successMessage: string;
  closeSnackbar: () => void;
}

const NotificationsTab: React.FC<NotificationsTabProps> = ({
  notifications,
  isLoading,
  errorMessage,
  handleCheckboxChange,
  isSuccessSnackbarOpen,
  successMessage,
  closeSnackbar,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Paper elevation={0} sx={{ padding: 2, marginBottom: 3, border: "none" }}>
        <Typography variant="h5" sx={{ marginBottom: 3 }}>
          Manage global notifications
        </Typography>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Typography variant="body1" color="textSecondary">
              Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" color="textSecondary">
              Platform
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" color="textSecondary">
              Email
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <CircularProgress />
        ) : errorMessage ? (
          <Alert severity="error" sx={{ marginTop: 1 }}>
            {errorMessage}
          </Alert>
        ) : (
          notifications.map((notification) => (
            <Grid
              key={notification.key}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={2}>
                <Typography variant="body1" display="inline">
                  {notification.name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Checkbox checked={notification.platform} />
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  checked={notification.email}
                  onChange={(e) =>
                    handleCheckboxChange(
                      notification.key,
                      "email",
                      e.target.checked
                    )
                  }
                />
              </Grid>
            </Grid>
          ))
        )}
        <Box sx={{ m: 1, minWidth: 120 }}>
          <Snackbar
            open={isSuccessSnackbarOpen}
            autoHideDuration={3000}
            onClose={closeSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={closeSnackbar} severity="success">
              {successMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Paper>
    </Box>
  );
};

export default NotificationsTab;
