import { Container, CircularProgress } from "@mui/material";
import ChapterTitle from "../components/ChapterTitle";
import PageTitle from "../components/PageTitle";
import ErrorAlert from "../components/ErrorAlert";
import SnapshotValidations from "./SnapshotsViewSteps/components/SnapshotValidations";
import SnapshotDataPreview from "./SnapshotsViewSteps/components/SnapshotDataPreview";
import ExternalLink from "../components/ExternalLink";
import { localizeDatetimeUTC } from "../utils/Localize";
import { useQuerySnapshotHead } from "../hooks/UseQuerySnapshotHead";

type SnapshotsViewProps = {
  snapshotId: string;
};

const SnapshotsView = ({ snapshotId }: SnapshotsViewProps) => {
  const qSnapshot = useQuerySnapshotHead(snapshotId);

  const snapshot = qSnapshot.data;

  const associatedChallenge = qSnapshot.isSuccess
    ? `${snapshot?.challenge_name} (id: ${snapshot?.challenge_id})`
    : "Loading ...";
  const internalError = snapshot?.internal_error ?? "*No internal error*";

  return (
    <>
      <PageTitle
        header={`#. Snapshot (id: ${snapshotId}) details`}
        subHeader={`Associated challenge: ${associatedChallenge}`}
      />
      {qSnapshot.isLoading && <CircularProgress />}
      {qSnapshot.isError && (
        <ErrorAlert
          value={qSnapshot.error}
          prefix="Failed loading snapshot details. "
        />
      )}
      {qSnapshot.isSuccess && snapshot === undefined && (
        <ErrorAlert value="Oops, something went wrong: blank snapshot received." />
      )}
      {qSnapshot.isSuccess && snapshot && (
        <>
          <Container className="containerMain">
            <ChapterTitle
              header="Created at datetime"
              subHeader={localizeDatetimeUTC(snapshot.date_created)}
            />
            <ChapterTitle
              header="Verified message"
              subHeader={`Not implemented. ${snapshot.verified_message}`}
            />
            <ChapterTitle
              header="Internal error"
              subHeader={`${internalError}`}
            />
            <ChapterTitle
              header="Data"
              subHeader="Note that data is already parsed. If parsing failed, {} will be used as data."
            />
            <SnapshotDataPreview snapshotId={snapshotId} />
            {!!snapshot.json_data_file_url && (
              <ExternalLink
                href={snapshot.json_data_file_url}
                style={{ display: "block" }}
              >
                Download full snapshot
              </ExternalLink>
            )}
            <ChapterTitle
              header="Validations table"
              style={{ marginBottom: "-50px" }}
            />
          </Container>
          <SnapshotValidations snapshotId={snapshotId ?? "no-id"} />
        </>
      )}
    </>
  );
};

export default SnapshotsView;
