import axios from "axios";
import { TokenPair } from "../types/TokenPair";
import API from "../API";
import { queryClient } from "./QueryClient";
import { BackendError } from "../types/BackendError";

export const getAccessToken = () => window.localStorage.getItem("ACCESS_TOKEN");
export const setAccessToken = (value: string) => {
  try {
    window.localStorage.setItem("ACCESS_TOKEN", value);
  } catch {
    // user disabled local storage
  }
};

export const getRefreshToken = () =>
  window.localStorage.getItem("REFRESH_TOKEN");
export const setRefreshToken = (value: string) => {
  try {
    window.localStorage.setItem("REFRESH_TOKEN", value);
  } catch {
    // user disabled local storage
  }
};

export const setTokenPair = (tokens: TokenPair) => {
  setAccessToken(tokens.access_token);
  setRefreshToken(tokens.refresh_token);
};

export const deleteTokenPair = () => {
  window.localStorage.removeItem("ACCESS_TOKEN");
  window.localStorage.removeItem("REFRESH_TOKEN");
};

const postRefreshToken = async (refreshToken: string) => {
  const { data } = await axios.postForm<TokenPair>(
    API.postRefreshToken,
    {
      refresh_token: refreshToken,
    },
    {
      timeout: 5000, // 5 s
    }
  );
  return data;
};

/** raises on failure */
export const refreshTokens = async (refreshToken: string) => {
  // there is no method queryClient.mutate() because we are not using useMutation hook
  const data = await queryClient.fetchQuery<TokenPair, BackendError>(
    "refreshToken",
    () => postRefreshToken(refreshToken),
    {
      staleTime: 2 * 60 * 1000, // 2 mins
    }
  );
  setTokenPair(data);
};
