import { createTheme } from '@mui/material';

export const dashboardTheme = createTheme({
  palette: {
    primary: {
      main: "#398CBF",
    },
    error: {
      main: "#FC2727",
    },
    success: {
      main: "#39BF7F",
    },
    warning: {
      main: "#FCA727",
    },
  },
});
