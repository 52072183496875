import {
  Alert,
  Button,
  IconButton,
  IconButtonProps,
  Popover,
  Slide,
  Snackbar,
  Stack,
} from "@mui/material";
import { useRef, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CodeRunHead } from "../../../types/CodeRunHead";
import { getStatusClassname } from "../../../components/ColoredStatus";
import ClearIcon from "@mui/icons-material/Clear";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import Download from "@mui/icons-material/Download";
import { useMutation } from "react-query";
import { BackendError } from "../../../types/BackendError";
import {
  patchCancelRun,
  patchForceAutorun,
  patchForceRun,
  getXLSBlob,
} from "../LocalOperations";
import extractError from "../../../utils/ErrorExtract";
import { useQCodeRunHeadsRefetch } from "../hooks/UseQCodeRunHeadsRefetch";
import { useNavigate } from "react-router-dom";
import { usePerms } from "../../../hooks/UsePerms";
import { Perm } from "../../../Const";
import TTabID from "../../../types/ChallengeViewTabsEnum";

interface RunContextualMenuProps extends Omit<IconButtonProps, "ref"> {
  codeRunHead: CodeRunHead;
  queuedUserId: string;
  popoverStyle?: React.CSSProperties;
  onPopoverClose?: () => void;
}

const downloadFile = async (
  challengeId: string,
  challengeRunNumber: string,
  userId: string
) => {
  try {
    const response = await getXLSBlob(challengeId, challengeRunNumber, userId)
    const blob = new Blob([response.data], { 
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
    });
    const blobURL = window.URL.createObjectURL(blob);
    const newWindow = window.open();
    if (newWindow) {
      newWindow.location.href = blobURL;
    } else {
      // Handle case where the new window could not be opened
    }

  } catch (error) {
    // Handle the error appropriately
    console.error("Error downloading file:", error);
  }
};

const RunContextualMenu = ({
  codeRunHead,
  queuedUserId,
  style,
  onClick,
  popoverStyle,
  onPopoverClose,
  ...iconButtonProps
}: RunContextualMenuProps) => {
  const {
    challenge_id: challengeId,
    challenge_run_number: challengeRunNumber,
    user_id: userId,
  } = codeRunHead;

  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [errorText, setErrorText] = useState<undefined | string>(undefined);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const perms = usePerms();

  const closeSnackbar = () => {
    setErrorText(undefined);
    setIsSuccess(false);
  };
  const refetchCodeRunHeads = useQCodeRunHeadsRefetch(
    challengeId,
    queuedUserId
  );
  const queryOutcomeListener = {
    onError: (error: BackendError) =>
      setErrorText(extractError(error) || "unknown error"),
    onSuccess: () => {
      refetchCodeRunHeads();
      setTimeout(() => {
        // act after a pause to avoid flickering moving-snackbar
        setIsSuccess(true);
      }, 700 /* 0.7 s */);
    },
  };

  const qDoAutorun = useMutation<void, BackendError>(
    () => patchForceAutorun(challengeId, challengeRunNumber, userId),
    queryOutcomeListener
  );
  const qRunForcefully = useMutation<void, BackendError>(
    () => patchForceRun(challengeId, challengeRunNumber, userId),
    queryOutcomeListener
  );
  const qCancelRun = useMutation<void, BackendError>(
    () => patchCancelRun(challengeId, challengeRunNumber, userId),
    queryOutcomeListener
  );

  const statusClassname = getStatusClassname(codeRunHead.status);
  const isCancellable =
    !statusClassname && !codeRunHead.status.toLowerCase().includes("cancel");

  const buttonVarriant = "outlined";

  return (
    <>
      <IconButton
        style={{ padding: "0px", width: "100%", height: "100%", ...style }}
        ref={menuRef}
        onClick={(e) => {
          onClick?.(e);
          setIsMenuOpen(true);
        }}
        {...iconButtonProps}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        open={isMenuOpen}
        onClose={() => {
          onPopoverClose?.();
          setIsMenuOpen(false);
        }}
        anchorEl={menuRef.current}
        anchorOrigin={{
          // attach to where
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          // at that point, which popover corner shall reside?
          vertical: "top",
          horizontal: "right",
        }}
        style={popoverStyle}
      >
        <Stack direction="column">
          <Button
            startIcon={<ClearIcon />}
            variant={buttonVarriant}
            disabled={!isCancellable || qCancelRun.isLoading}
            onClick={() => qCancelRun.mutate()}
          >
            Cancel run
          </Button>
          <Button
            startIcon={<PlayArrowIcon />}
            variant={buttonVarriant}
            disabled={qRunForcefully.isLoading}
            onClick={() => qRunForcefully.mutate()}
          >
            Force run
          </Button>
          <Button
            startIcon={<EditIcon />}
            variant={buttonVarriant}
            onClick={() =>
              navigate(
                `/challenges/${challengeId}/?tab=${TTabID.SubmitCode}&runNumber=${challengeRunNumber}&userId=${userId}`
              )
            }
          >
            Use code
          </Button>
          {perms.has(Perm.DOWNLOAD_ANY_EXPORT) && (
            <Button
              startIcon={<Download />}
              variant={buttonVarriant}
              onClick={() => downloadFile(challengeId, challengeRunNumber, userId)}>
              Download as XLS
           </Button>
          )}
          {perms.has(Perm.SCHEDULE_AUTORUN) && (
            <Button
              startIcon={<UpdateIcon />}
              variant={buttonVarriant}
              disabled={qDoAutorun.isLoading}
              onClick={() => qDoAutorun.mutate()}
            >
              Run automatically
            </Button>
          )}
        </Stack>
      </Popover>
      <Snackbar
        open={!!errorText || isSuccess}
        onClose={closeSnackbar}
        TransitionComponent={(props) => <Slide {...props} direction="left" />}
        style={{
          marginBottom:
            "24px" /** do not collide with react query debug icon */,
        }}
        autoHideDuration={24000 /** 24 s */}
      >
        <Alert
          severity={isSuccess ? "success" : "error"}
          onClose={closeSnackbar}
        >
          {errorText || "Action performed successfully"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RunContextualMenu;
