import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { CodeRunHead } from "../types/CodeRunHead";
import { BackendError } from "../types/BackendError";
import { getCodeRunHead } from "../pages/ChallengeViewSteps/LocalOperations";

export const useQueryCodeRunHead = (
  challengeId: string | number,
  codeRunNumber: string | number,
  userId: string | number,
  options?: Omit<
    UseQueryOptions<CodeRunHead, BackendError, CodeRunHead, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _challengeId = String(challengeId);
  const _codeRunNumber = String(codeRunNumber);
  const _userId = String(userId);

  return useQuery<CodeRunHead, BackendError>(
    ["codeRunHead", _challengeId, _codeRunNumber, _userId],
    () => getCodeRunHead(_challengeId, _codeRunNumber, _userId),
    options
  );
};
