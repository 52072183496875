import {
  Box,
  Button,
  Drawer,
  ThemeProvider,
  Alert,
  Snackbar,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TuneIcon from "@mui/icons-material/Tune";
import ChallengesInTable from "./challengesListV2/components/ChallengesInTable";
import ChallengesListDisplayProvider from "./challengesListV2/contexts/ChallengesListDisplayProvider";
import { themeV2 } from "../misc/theme";
import ChallengesVerticalFilter from "./challengesListV2/components/ChallengesVerticalFilter";
import ChallengeFilteringProvider from "./challengesListV2/contexts/ChallengeFilteringProvider";
import ChallengeSearchField from "./challengesListV2/components/ChallengeSearchField";
import ChallengeDetailsInline from "./ChallengeViewSteps/ChallengeDetailsInline";
import { useQueryProjectDetails } from "./DashboardView/hooks/useQueryProjectDetails";

import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import ChallengeRewardInline from "./ChallengeViewSteps/ChallengeRewardInline";
import SelectedFiltersCounter from "./challengesListV2/components/SelectedFiltersCounter";

import {
  deleteChallenge,
  releaseChallenge,
} from "./ChallengeViewSteps/LocalOperations";

import { useState, useEffect } from "react";
import { usePerms } from "../hooks/UsePerms";
import { useMutation } from "react-query";
import { Perm } from "../Const";
import { BackendError } from "../types/BackendError";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import { default as DashboardTab } from "../types/DashboardTabsEnum";
interface SimpleBottomNavigationProps {
  onClose: () => void;
}

const SimpleBottomNavigation = (props: SimpleBottomNavigationProps) => {
  return (
    <Box sx={{ width: "100%", backgroundColor: "red" }}>
      <Paper sx={{ position: "fixed", bottom: 0, right: 0 }}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="Close"
            icon={<CloseIcon />}
            onClick={props.onClose}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

interface ChallengeBottomNavigationProps {
  onClose: () => void;
  onReserve: () => void;
  onRelease: () => void;
  onDelete: () => void;
  challengeId?: string;
}

// FIXME: check wether not already deleted (challenge.date_deleted)
const ChallengeBottomNavigation = (props: ChallengeBottomNavigationProps) => {
  const perms = usePerms();
  return (
    <Box sx={{ width: "100%", backgroundColor: "red" }}>
      <Paper sx={{ position: "fixed", bottom: 0, right: 0 }}>
        <BottomNavigation showLabels>
          {!!perms.has(Perm.DELETE_CHALLENGE) && (
            <BottomNavigationAction
              label="Release"
              icon={<RestoreIcon color="success" />}
              onClick={props.onRelease}
            />
          )}
          {!!perms.has(Perm.DELETE_CHALLENGE) && (
            <BottomNavigationAction
              label="Delete"
              icon={<DeleteIcon color="error" />}
              onClick={props.onDelete}
            />
          )}
          <BottomNavigationAction
            label="Reserve"
            icon={<ReplayIcon color="warning" />}
            onClick={props.onReserve}
          />

          <BottomNavigationAction
            label="Close"
            icon={<CloseIcon />}
            onClick={props.onClose}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

const ChallengesListInline = () => {
  const [isDrawerVisibe, setDrawerVisible] = useState(false);
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const [isDetailsConfirmMode, setDetailsConfirmMode] = useState(false);
  const [challengeName, setChallengeName] = useState<string>("");
  const [currentChallengeId, setCurrentChallengeId] = useState("");
  const location = useLocation();
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get("project_id");

  const qProject = useQueryProjectDetails(projectId || "noid", {
    enabled: !!projectId,
  });

  const project = qProject.data;

  var { mutate } = useMutation<void, BackendError, any>(releaseChallenge, {
    onSuccess: () => {
      navigate("/?is_up_for_grab=true");
    },
  });
  const releaseChallengeMutation = mutate;

  var { mutate } = useMutation<void, BackendError, any>(deleteChallenge, {
    onSuccess: () => {
      navigate("/?is_up_for_grab=true");
    },
  });
  const deleteChallengeMutation = mutate;

  useEffect(() => {
    if (location.state?.openChallengeDeleteSnackbar) {
      setChallengeName(location.state?.challengeName || "UNKNOWN");
      setSuccessMessage(`"${challengeName}" challenge is deleted.`);
      setIsSuccessSnackbarOpen(true);
    } else if (location.state?.openAccountCreateSnackbar) {
      setSuccessMessage("Account created successfully.");
      setIsSuccessSnackbarOpen(true);
    }
  }, [
    location.state?.openChallengeDeleteSnackbar,
    location.state?.challengeName,
    challengeName,
    location.state?.openAccountCreateSnackbar,
  ]);

  const handleSuccessSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={themeV2}>
      <ChallengeFilteringProvider>
        <Drawer
          anchor="right"
          open={isDrawerVisibe}
          onClose={() => setDrawerVisible(false)}
        >
          <ChallengesVerticalFilter
            style={{ minWidth: "18vw", marginTop: "40px" }}
          />
          <SimpleBottomNavigation onClose={() => setDrawerVisible(false)} />
        </Drawer>

        <Drawer anchor="right" open={isDetailsVisible}>
          {isDetailsConfirmMode ? (
            <>
              <ChallengeRewardInline challengeId={currentChallengeId} />
              <SimpleBottomNavigation
                onClose={() => {
                  setDetailsVisible(false);
                  setDetailsConfirmMode(false);
                }}
              />
            </>
          ) : (
            <>
              <ChallengeDetailsInline challengeId={currentChallengeId} />
              <ChallengeBottomNavigation
                onClose={() => setDetailsVisible(false)}
                onRelease={() => {
                  releaseChallengeMutation(currentChallengeId);
                  setDetailsVisible(false);
                }}
                onDelete={() => {
                  deleteChallengeMutation(currentChallengeId);
                  setDetailsVisible(false);
                }}
                onReserve={() => setDetailsConfirmMode(true)}
              />
            </>
          )}
        </Drawer>
        {project && (
          <>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Typography variant="h3" marginBottom={2}>
                {project.name}
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: "normal" }}>
                {project.description}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              marginTop={3}
              marginBottom={1}
            >
              <IconButton
                component={Link}
                to={`/projects/${projectId}?tab=${DashboardTab.Dashboard}`}
                style={{ padding: 0, marginRight: "20px" }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h5"
                sx={{ fontWeight: "normal" }}
                flexGrow={1}
              >
                Back to project dashboard
              </Typography>
            </Box>
          </>
        )}
        <ChallengesListDisplayProvider>
          <Box style={{ width: "100%", padding: "20px" }}>
            <Stack direction="row">
              <ChallengeSearchField />
              <Button
                size="large"
                sx={{ marginLeft: "20px" }}
                onClick={() => setDrawerVisible(!isDrawerVisibe)}
                variant="contained"
                startIcon={<TuneIcon />}
              >
                FILTERS
                <SelectedFiltersCounter />
              </Button>
            </Stack>
          </Box>
          <ChallengesInTable
            onShowChallengeDetails={(challengeId) => {
              setCurrentChallengeId(challengeId);
              setDetailsVisible(true);
            }}
          />
          <Box sx={{ marginTop: "60px" }}>
            <Snackbar
              open={isSuccessSnackbarOpen}
              autoHideDuration={3000}
              onClose={handleSuccessSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleSuccessSnackbarClose} severity="success">
                {successMessage}
              </Alert>
            </Snackbar>
          </Box>
        </ChallengesListDisplayProvider>
      </ChallengeFilteringProvider>
    </ThemeProvider>
  );
};

export default ChallengesListInline;
