import { useQuery, UseQueryOptions, QueryKey } from "react-query";
import { getProjectSubscriptions } from "../LocalOperations";
import { Subscriptions } from "../../../types/Subscription";
import { BackendError } from "../../../types/BackendError";

export const useQueryProjectSubscriptions = (
  projectId: string | number,
  options?: Omit<
    UseQueryOptions<Subscriptions, BackendError, Subscriptions, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _projectId = String(projectId);
  return useQuery<Subscriptions, BackendError>(
    ["projectSubscriptions", _projectId],
    () => getProjectSubscriptions(_projectId),
    {
      staleTime: 300000,
      ...options,
    }
  );
};
