import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { ChallengeBrief } from "../../../types/ChallengesBrief";
import BannerCenterMonitor from "./BannerCenterMonitor";
import { useNavigate } from "react-router-dom";
import { formatChallengeName } from "../../../utils/FormatChallengeName";

export interface ChallengeBannerProps {
  challenge: ChallengeBrief;
  linkPrefix?: string;
  variant?: "sell" | "monitor";
}

interface ChallengeReserverProps {
  challenge: ChallengeBrief;
  linkPrefix?: string;
}

const ChallengeReserver = ({
  challenge,
  linkPrefix,
}: ChallengeReserverProps) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="contained"
      color="info"
      onClick={() => {
        navigate(`${linkPrefix}${challenge.id}/reward`);
      }}
    >
      Reserve challenge
    </Button>
  );
};

const ChallengeBanner = ({
  challenge,
  linkPrefix = "",
  variant = "sell",
}: ChallengeBannerProps) => {
  const centerElement =
    variant === "sell" ? (
      <div style={{ textAlign: "center" }}>
        <Typography>{20}€</Typography>
        <Typography variant="subtitle2">for creation</Typography>
      </div>
    ) : (
      <BannerCenterMonitor challenge={challenge} />
    );

  return (
    <Card>
      <Box
        sx={{
          backgroundColor: "NavajoWhite",
          "&:hover": {
            backgroundColor: "LightSalmon",
          },
        }}
      >
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Typography align="left">
              {formatChallengeName(challenge)}
            </Typography>
            {centerElement}
            <ChallengeReserver challenge={challenge} linkPrefix={linkPrefix} />
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
};

export default ChallengeBanner;
