import { Alert, Button, Card, Stack, Typography } from "@mui/material";
import {
  ColoredStatus,
  getStatusClassname,
} from "../../../components/ColoredStatus";
import ValidationErrorDialog from "./ValidationErrorDialog";
import ErrorAlert from "../../../components/ErrorAlert";
import { useQueryValidations } from "../../../hooks/UseQueryValidations";
import { getIsAnyValidationRunning } from "../../../utils/ValidationChecker";
import { useContext, useEffect, useState } from "react";
import { LastCodeRefContext } from "../contexts/LastCodeRefContext";

interface SubmissionValidationCardProps {
  style?: React.CSSProperties;
  runNumber: string;
  isCodeRunning: boolean;
  isCodeBad: boolean;
  snapshotId: string;
}

const _weightStatus = (status: string) => {
  const className = getStatusClassname(status);
  return [undefined, "success-tag", "warning-tag", "error-tag"].findIndex(
    (value) => value === className
  );
};

const SubmissionValidationCard = ({
  style,
  runNumber,
  isCodeRunning,
  isCodeBad,
  snapshotId,
}: SubmissionValidationCardProps) => {
  const qValidations = useQueryValidations(snapshotId, {
    enabled: !!snapshotId, // maybe not submitted, maybe code crashed, maybe code running, etc.
    refetchInterval(validations) {
      return getIsAnyValidationRunning(validations)
        ? 3 * 1000 // 3s
        : false; // stop
    },
  });

  const [shownValidationId, setShownValidationId] = useState<
    string | undefined
  >(undefined);
  const closeValidationDialog = () => setShownValidationId(undefined);

  const isSolutionDeclined =
    isCodeBad ||
    !!qValidations.data?.some(
      (validation) => getStatusClassname(validation.status) === "error-tag"
    );
  const validations = qValidations.data?.sort(
    (validation1, validation2) =>
      _weightStatus(validation2.status) - _weightStatus(validation1.status)
  );

  const lastCodeRef = useContext(LastCodeRefContext);
  useEffect(() => {
    if (!lastCodeRef || !runNumber) return; // outside context
    if (!runNumber) {
      // code is not submitted (tab "submit code")
      lastCodeRef.setSnapValidation();
      lastCodeRef.setHealth({ isCodeCrashed: false, isCodeRunning: false });
      return;
    }

    lastCodeRef.setHealth({
      isCodeCrashed: isCodeBad,
      isCodeRunning: isCodeRunning,
    });
    if (lastCodeRef.badValidationId && (isCodeBad || isCodeRunning)) {
      lastCodeRef.setSnapValidation(); // no snap, no validation
      lastCodeRef.setHealth({
        isCodeCrashed: isCodeBad,
        isCodeRunning: isCodeRunning,
      });
    } else if (!validations) {
      lastCodeRef.setSnapValidation(snapshotId); // no validations
    } else if (validations) {
      const top_validation = validations?.[0];
      lastCodeRef.setSnapValidation(snapshotId, top_validation?.id);
      lastCodeRef.setHealth({
        isTopValidationErrored: !!top_validation?.error,
      });
    }
  }, [
    runNumber,
    isCodeRunning,
    isCodeBad,
    lastCodeRef,
    snapshotId,
    validations,
  ]);

  return (
    <Card style={style}>
      {!runNumber ? (
        <>
          <Typography variant="h6">Solution</Typography>
          <Typography variant="body2" color="textSecondary">
            Submit your code to see solution submission
          </Typography>
        </>
      ) : (
        <Typography variant="h6">
          {isCodeRunning
            ? "Reading the solution..."
            : !!snapshotId
            ? getIsAnyValidationRunning(qValidations.data)
              ? "Validating solution..."
              : "Validation results"
            : "Broken solution"}
        </Typography>
      )}
      {validations?.map((validation) => (
        <Stack direction="row" padding="3px 0px" key={validation.id}>
          <Typography flex={7} textAlign="left">
            {validation.validator_custom_name || validation.validator_name}
          </Typography>

          <Button
            style={{
              flex: 2,
              textAlign: "center",
              textTransform: "none",
              padding: "0px",
            }}
            onClick={() => setShownValidationId(validation.id)}
          >
            <ColoredStatus status={validation.status} style={{ width: "100%" }}>
              {validation.status}
            </ColoredStatus>
          </Button>
          <ValidationErrorDialog
            open={shownValidationId === validation.id}
            onClose={closeValidationDialog}
            validation={validation}
          />
        </Stack>
      ))}
      {isSolutionDeclined && (
        <>
          <ErrorAlert
            style={{ marginTop: "4%" }}
            prefix="Your solution has critical errors - "
            value={`${
              isCodeBad ? "Bad code" : "Validation failed"
            }. Fix it and submit solution again.`}
            isClosable
          />
          {!isCodeBad && (
            <Typography variant="subtitle2" textAlign="right">
              Validation status can be clicked for details.
            </Typography>
          )}
        </>
      )}
      {!!validations?.length &&
        validations.every((validation) =>
          ["success-tag", "warning-tag"].includes(
            getStatusClassname(validation.status) ?? ""
          )
        ) && (
          <Alert severity="success">
            The solution passed the validations. Way to go!
          </Alert>
        )}
    </Card>
  );
};

export default SubmissionValidationCard;
