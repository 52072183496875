enum TTabID {
  ChallengeDescription = "challenge_description",
  DataFormat = "data_format",
  History = "history",
  CodeRuns = "code_runs",
  LatestCodeVersion = "latest_code_version",
  Messages = "messages",
  SubmitCode = "submit_code",
}

export default TTabID;