export enum ReasoningName {
  /** the optional attributes found in data labels, yet not found in snapshot */
  SOFT_MISSES = "soft_misses",
  /** the required attributes found in data labels, yet not found in snapshot */
  HARD_MISSES = "hard_misses",
  /** the attributes not found in data labels, yet exist in snapshot */
  EXTRAS = "extras",
  /** snapshot has null, data label is not null */
  CONFLICTING_NULLS = "local_nulls",
  /** snapshot has optional, data label is not optional */
  CONFLICTING_OPTIONALS = "local_opts",
  /** snapshot has no null, data label is null */
  UNUSED_NULLS = "unused_nulls",
  /** snapshot has no optional, data label is optional */
  UNUSED_OPTIONALS = "unused_opts",
  /** subsetting type. E.g. snapshot has type integer, data label has type number - integer is subset of number */
  SOFT_TYPES = "soft_types",
  /** snapshot has type x, data label has type y. Type x is not a subset of y */
  HARD_TYPES = "hard_types",
}

export type TemplatingImpactSummary = {
  failing_before: number[];
  failing_after: number[];
  unknowns: number[];
  soft_success: number[];
  complete_success: number[];
  error?: null | string;
  is_own_soft: boolean;
  is_own_hard: boolean;
  is_own_fail: boolean;
  is_own_unknown: boolean;
  challenge_id?: number;
  challenge_id_to_name?: null | Record<number, string>;
  challenge_id_to_warning?: null | Record<number, string>;
  reasoning?: null | Record<
    ReasoningName,
    undefined | null | Record<string, number[]>
  >;
  /** reasons why challenges got into unknowns set */
  reason_unknowns?: Record<number, string>;
};
