import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { BackendError } from "../types/BackendError";
import { SnapshotHead } from "../types/SnapshotHead";
import { getSnapshotHead } from "../pages/SnapshotsViewSteps/LocalOperations";

export const useQuerySnapshotHead = (
  snapshotId: string | number,
  options?: Omit<
    UseQueryOptions<SnapshotHead, BackendError, SnapshotHead, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _snapshotId = String(snapshotId);
  return useQuery<SnapshotHead, BackendError>(
    ["snapshot", _snapshotId],
    () => getSnapshotHead(_snapshotId),
    options
  );
};
