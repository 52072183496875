import { QueryKey, UseQueryOptions, useQuery, useQueryClient } from "react-query";
import { useCallback } from "react";
import { Anomaly } from "../../../types/Anomaly";
import { BackendError } from "../../../types/BackendError";
import { getChallengeNextAnomaly } from "../LocalOperations";

export const useQueryChallengeNextAnomaly = (
  challengeId: number | string,
  options?: Omit<
    UseQueryOptions<Anomaly, BackendError, Anomaly, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _challengeId = String(challengeId);
  return useQuery<Anomaly, BackendError>(
    ["anomaly", _challengeId],
    () => getChallengeNextAnomaly(_challengeId),
    options
  );
};

export const useInvalidateChallengeNextAnomaly = (challengeId: string | number) => {
  const queryClient = useQueryClient();
  const _challengeId = String(challengeId);
  return useCallback(
    () => queryClient.invalidateQueries(["anomaly", _challengeId]),
    [queryClient, _challengeId]
  );
}
