import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { BackendError } from "../types/BackendError";
import { Template } from "../types/Template";
import { getTemplate } from "../pages/ChallengeViewSteps/LocalOperations";

export const genTemplateKey = (templateId?: string) => [
  "template",
  String(templateId),
];

export const useQueryTemplate = (
  templateId: number | string,
  options?: Omit<
    UseQueryOptions<Template, BackendError, Template, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _templateId = String(templateId);
  return useQuery<Template, BackendError>(
    genTemplateKey(_templateId),
    () => getTemplate(_templateId),
    options
  );
};
