import React from "react";
import BannerTable from "../../components/BannerTable";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Subscription, Subscriptions } from "../../types/Subscription";

interface SubscriptionsTabProps {
  subscriptions: Subscriptions;
  handleActionClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    subscription: Subscription
  ) => void;
  handleCloseMenu: () => void;
  handleManageSubscription: (projectId: number) => void;
  handleUnsubscribe: (projectId: number, projectName: string) => void;
  handleViewProject: (projectId: number) => void;
  isSuccessSnackbarOpen: boolean;
  successMessage: string;
  closeSnackbar: () => void;
  anchorEl: HTMLElement | null;
  selectedSubscription: Subscription | null;
  errorMessage: string;
  isLoading: boolean;
}

const SubscriptionsTab: React.FC<SubscriptionsTabProps> = ({
  subscriptions,
  handleActionClick,
  handleCloseMenu,
  handleManageSubscription,
  handleUnsubscribe,
  handleViewProject,
  isSuccessSnackbarOpen,
  successMessage,
  closeSnackbar,
  anchorEl,
  selectedSubscription,
  errorMessage,
  isLoading,
}) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Manage project subscriptions
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : errorMessage ? (
        <Alert severity="error" sx={{ marginTop: 1 }}>
          {errorMessage}
        </Alert>
      ) : subscriptions.length === 0 ? (
        <Typography>You have not subscribed to any projects</Typography>
      ) : (
        <BannerTable>
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((subscription) => (
              <TableRow key={subscription.id}>
                <TableCell>
                  <Typography variant="body1">{`#${subscription.project_id} ${subscription.project_name}`}</Typography>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="more options"
                    onClick={(event) => handleActionClick(event, subscription)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id="subscription-menu"
                    anchorEl={anchorEl}
                    open={Boolean(
                      anchorEl && selectedSubscription?.id === subscription.id
                    )}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem
                     onClick={() =>
                      handleViewProject(subscription.project_id)
                    }
                    >
                      View project
                    </MenuItem>

                    <MenuItem
                     onClick={() =>
                      handleManageSubscription(subscription.project_id)
                    }
                    >
                      Manage subscription
                    </MenuItem>
                    <MenuItem
                     onClick={() =>
                      handleUnsubscribe(
                        subscription.project_id,
                        subscription.project_name || "unknown"
                      )
                    }
                    >
                      Unsubscribe
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </BannerTable>
      )}
      <Snackbar
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={closeSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SubscriptionsTab;
