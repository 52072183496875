import { useRef, useState, useEffect } from "react";
import { useMutation } from "react-query";
import { BackendError } from "../types/BackendError";
import ChallengeNew from "../types/ChallengeNew";
import { DataLabels } from "../types/DataLabels";
import { DataLabelsDetailed } from "../types/DataLabelsDetailed";
import CompleteQuery from "./ChallengesNewSteps/CompleteQuery";
import ConfirmDataFormat from "./ChallengesNewSteps/ConfirmDataFormat";
import DefineDataset from "./ChallengesNewSteps/DefineDataset";
import DefineQuality from "./ChallengesNewSteps/DefineQuality";
import DescribeDataSource from "./ChallengesNewSteps/DescribeDataSource";
import { postChallengeNew } from "./ChallengesNewSteps/LocalOperations";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import extractError from "../utils/ErrorExtract";
import { CircularProgress, Stepper, Step, StepLabel, Box } from "@mui/material";
import createDefaultChallengeNew from "../utils/CreateDefaultChallenge";
import DataLabelsContext from "./ChallengesNewSteps/contexts/DataLabelsContext";
import ChallengeCreationIntro from "./ChallengesNewSteps/ChallengeCreationIntro";
import { default as ChalCreationTTabID } from "../types/ChallengeCreationTabsEnum";
import { default as ChalViewTTabID } from "../types/ChallengeViewTabsEnum";

enum LocalState {
  introduction,
  defineDataset,
  confirmNewDataFormat, // from file
  confirmExistingDataFormat, // alternative: from drop-down list
  describeDataSource,
  defineQuality,
  completeQuery,
  finish,
}

const stepLabels = [
  "Data selection",
  "Data Format",
  "Source details",
  "Quality levels",
  "Finalize query",
];

const mapLocalStateToStep = (state: LocalState) => {
  switch (state) {
    case LocalState.introduction:
      return 0;
    case LocalState.defineDataset:
      return 0;
    case LocalState.confirmNewDataFormat:
    case LocalState.confirmExistingDataFormat:
      return 1;
    case LocalState.describeDataSource:
      return 2;
    case LocalState.defineQuality:
      return 3;
    case LocalState.completeQuery:
      return 4;
    case LocalState.finish:
      return 5;
    default:
      return 0;
  }
};

const ChallengesNew = () => {
  const [step, setStep] = useState<LocalState>(LocalState.introduction);

  const [didReadIntro, setDidReadIntro] = useState<boolean>(false);

  const [selectedDataset, setSelectedDataset] = useState<string>("");
  const [uploadedFileName, setUploadedFileName] = useState<string>("");
  const [uploadedFileData, setUploadedFileData] = useState<DataLabels>([]);

  const [url, setUrl] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [frequencyOption, setFrequencyOption] = useState<string>("daily");
  const [selectedFrequency, setSelectedFrequency] = useState<string>("day");
  const completeness = 100;

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [budget, setBudget] = useState<number>(12);
  const [budgetLength, setBudgetLength] = useState<number>(24);
  const [selectedProjectId, setSelectedProjectId] = useState<number>(0);

  const challengeNewRef = useRef<ChallengeNew | null>(null);

  const getChallenge = (): ChallengeNew => {
    if (challengeNewRef.current !== null) {
      return challengeNewRef.current;
    }
    challengeNewRef.current = createDefaultChallengeNew();
    return challengeNewRef.current;
  };

  const [dataLabels, setDataLabels] = useState<DataLabelsDetailed>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const projectId = location.state?.projectId;

  const postChallenge = useMutation<void, BackendError, ChallengeNew>(
    postChallengeNew
  );

  const navigateToStep = (step: string) => {
    navigate(`${location.pathname}?tab=${step}`);
  };

  useEffect(() => {
    const tab = searchParams.get("tab") as ChalCreationTTabID;

    if (projectId) {
      setSelectedProjectId(projectId);
    }

    if (tab) {
      switch (tab) {
        case ChalCreationTTabID.DataSelection:
          setStep(LocalState.defineDataset);
          break;
        case ChalCreationTTabID.NewDataFormat:
          setStep(LocalState.confirmNewDataFormat);
          break;
        case ChalCreationTTabID.ExistingDataFormat:
          setStep(LocalState.confirmExistingDataFormat);
          break;
        case ChalCreationTTabID.SourceDetails:
          setStep(LocalState.describeDataSource);
          break;
        case ChalCreationTTabID.QualityLevels:
          setStep(LocalState.defineQuality);
          break;
        case ChalCreationTTabID.FinalizeQuery:
          setStep(LocalState.completeQuery);
          break;
        case ChalCreationTTabID.Finish:
          setStep(LocalState.finish);
          break;
        default:
          setStep(LocalState.introduction);
          break;
      }
    } else {
      navigate(
        { search: `?tab=${ChalCreationTTabID.ChallengeCreationIntro}` },
        { replace: true }
      );
    }

    if (!didReadIntro) {
      setStep(LocalState.introduction);
      window.history.replaceState(null, "", `?tab=${ChalCreationTTabID.ChallengeCreationIntro}`); // replace typo url with challenge creation intro url
    }
  }, [searchParams, didReadIntro, navigate, projectId]);

  const onProceedSuccess = () => {
    setDidReadIntro(true);
    navigateToStep(ChalCreationTTabID.DataSelection);
  };

  const onDataLabelsSuccess = (labels: DataLabels, fileName: string) => {
    const dataLabels = labels.map((dLabel) => {
      return { ...dLabel, optional: false, nullable: false };
    });
    setDataLabels(() => {
      getChallenge().data_labels = dataLabels;
      return dataLabels;
    });
    setSelectedDataset("");
    setUploadedFileName(fileName);
    setUploadedFileData(labels);
    navigateToStep(ChalCreationTTabID.NewDataFormat);
  };

  const onChooseExistingFormat = (templateId: string) => {
    getChallenge().template_id = templateId;
    setSelectedDataset(templateId);
    setDataLabels([]);
    setUploadedFileName("");
    navigateToStep(ChalCreationTTabID.ExistingDataFormat);
  };

  const onDataLabelsConfirm = (labelsDetailed: DataLabelsDetailed) => {
    setDataLabels(() => {
      getChallenge().data_labels = labelsDetailed;
      return labelsDetailed;
    });
    navigateToStep(ChalCreationTTabID.SourceDetails);
  };

  const onDescribeSuccess = (url: string, description: string) => {
    setUrl(url);
    setDescription(description);
    getChallenge().target_url = url;
    getChallenge().data_description = description;
    navigateToStep(ChalCreationTTabID.QualityLevels);
  };

  const onDefineQualitySuccess = (
    frequency: string,
    frequencyOption: string,
  ) => {
    getChallenge().frequency_name = frequency;
    getChallenge().completeness = completeness;
    setSelectedFrequency(frequency);
    setFrequencyOption(frequencyOption);
    navigateToStep(ChalCreationTTabID.FinalizeQuery);
  };

  const onCompleteQuerySuccess = (
    email: string,
    name: string,
    budgetEurPerMonth: number,
    budgetLengthMonths: number,
    selectedProjectId: number
  ) => {
    getChallenge().email = email;
    getChallenge().name = name;
    getChallenge().budget_eur_per_month = budgetEurPerMonth;
    getChallenge().budget_length_months = budgetLengthMonths;
    getChallenge().project_id = selectedProjectId;
    navigateToStep(ChalCreationTTabID.Finish);
    // the query has started previously? It should not happen
    if (!postChallenge.isIdle) {
      setTimeout(() => {
        navigate("finish", {
          state: {
            error:
              "Corruption error. There is a slight chance that your challenge was submitted and may contain corruption.",
            data: null,
          },
        });
      }, 1000);
      return;
    }

    // submit the challenge and navigate to newly created challenge view page
    postChallenge.mutate(getChallenge(), {
      onSettled: (data: any, error: BackendError | null) => {
        if (error) {
          navigate("finish", {
            state: {
              error: extractError(error),
              data: null,
            },
          });
        } else {
          navigate(
            `/challenges/${data.id}/?tab=${ChalViewTTabID.ChallengeDescription}`,
            {
              state: { openSnackbar: true },
              replace: true,
            }
          );
        }
      },
    });
  };

  return (
    <>
      {step !== LocalState.introduction && (
        <Stepper activeStep={mapLocalStateToStep(step)} alternativeLabel>
          {stepLabels.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <Box marginTop={2}>
        {step === LocalState.introduction && (
          <ChallengeCreationIntro onProceedSuccess={onProceedSuccess} />
        )}
        {step === LocalState.defineDataset && (
          <DefineDataset
            onDataLabelsSuccess={onDataLabelsSuccess}
            onChooseExistingFormat={onChooseExistingFormat}
            setSelectedDataset={setSelectedDataset}
            setUploadedFileName={setUploadedFileName}
            setUploadedFileData={setUploadedFileData}
            selectedDataset={selectedDataset}
            uploadedFileName={uploadedFileName}
            uploadedFileData={uploadedFileData}
          />
        )}
        {(step === LocalState.confirmNewDataFormat ||
          step === LocalState.confirmExistingDataFormat) && (
          <DataLabelsContext.Provider
            value={{
              dataLabels,
              setDataLabels,
              onConfirmDataLabels: () => onDataLabelsConfirm(dataLabels),
            }}
          >
            {step === LocalState.confirmNewDataFormat ? (
              <ConfirmDataFormat />
            ) : (
              <ConfirmDataFormat
                templateId={getChallenge().template_id ?? ""}
              />
            )}
          </DataLabelsContext.Provider>
        )}
        {step === LocalState.describeDataSource && (
          <DescribeDataSource
            onDescribeSuccess={onDescribeSuccess}
            url={url}
            description={description}
            setUrl={setUrl}
            setDescription={setDescription}
          />
        )}
        {step === LocalState.defineQuality && (
          <DefineQuality
            onDefineQualitySuccess={onDefineQualitySuccess}
            frequency={selectedFrequency}
            frequencyOption={frequencyOption}
            setFrequencyOption={setFrequencyOption}
            setSelectedFrequency={setSelectedFrequency}
          />
        )}
        {step === LocalState.completeQuery && (
          <CompleteQuery
            email={email}
            name={name}
            budget={budget}
            budgetLength={budgetLength}
            selectedProjectId={selectedProjectId}
            setEmail={setEmail}
            setName={setName}
            setBudget={setBudget}
            setBudgetLength={setBudgetLength}
            setSelectedProjectId={setSelectedProjectId}
            onCompleteQuerySuccess={onCompleteQuerySuccess}
          />
        )}
        {step === LocalState.finish && (
          <>
            <span>Submitting ...</span>
            <CircularProgress />
          </>
        )}
      </Box>
    </>
  );
};

export default ChallengesNew;
