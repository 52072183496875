import { createContext } from "react";
import { DataLabelsDetailed } from "../../../types/DataLabelsDetailed";

interface DataLabelsContextItems {
  dataLabels: DataLabelsDetailed;
  setDataLabels: React.Dispatch<React.SetStateAction<DataLabelsDetailed>>;
  onConfirmDataLabels: () => void;
}

const DataLabelsContext = createContext<DataLabelsContextItems>({
  dataLabels: [],
  setDataLabels: () => {},
  onConfirmDataLabels: () => {},
});

export default DataLabelsContext;
export type { DataLabelsContextItems };
