import { AutoFixHigh } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { usePerms } from "../../../hooks/UsePerms";
import { Perm } from "../../../Const";
import { useMutation } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { useQueryChallengeDetails } from "../../../hooks/UseQueryChallengeDetails";
import { CodeGenerateProps, postGenerateCode } from "../LocalOperations";
import { useState } from "react";
import ErrorAlert from "../../../components/ErrorAlert";
import DoneIcon from "@mui/icons-material/Done";
import { AIBakedCode } from "../../../types/AIBakedCode";
import { useUserState } from "../../../hooks/UserStateProvider";
import { CodeGenerationSettingsDialog } from "./CodeGenerationSettingsDialog";
import { ChallengeContext } from "../../ChallengeViewSteps/contexts/ChallengeContext";

interface GenerateCodeProps {
  isCodeEdited: boolean;
  onCodeChange: (newCode: string) => void;
  challengeId?: string | null;
}

export const GenerateCode = ({
  isCodeEdited,
  onCodeChange,
  challengeId,
}: GenerateCodeProps) => {
  const perms = usePerms();
  const [user] = useUserState();
  const qChallenge = useQueryChallengeDetails(challengeId ?? "no-id");
  const qGenerateCode = useMutation<
    AIBakedCode,
    BackendError,
    CodeGenerateProps
  >((codeProps) => postGenerateCode(codeProps), {
    onSuccess: (data) => {
      onCodeChange(data.code);
    },
  });
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const isPermit = perms.has(Perm.IMPERSONATE);
  const tooltipForbidden =
    "You don't have permission to generate code. It is an experimental feature";
  const tooltipPre = "Generate code";
  const tooltipIn = "Generating...";
  const tooltipInvalid = "Invalid challenge id";
  const onClickButton = () => {
    if (qGenerateCode.isLoading || !challengeId || !qChallenge.isSuccess) {
      return;
    } else {
      setIsSettingsOpen(true);
    }
  };

  return (
    <Box style={{ backgroundColor: "white", width: "98.5%" }}>
      <Stack direction={"row-reverse"}>
        <Tooltip
          title={
            !isPermit
              ? tooltipForbidden
              : qGenerateCode.isLoading
              ? tooltipIn
              : !challengeId || !qChallenge.isSuccess
              ? tooltipInvalid
              : tooltipPre
          }
        >
          <Box>
            {/* Wrapped button so disabled button can have a tooltip */}
            <IconButton
              size="small"
              onClick={onClickButton}
              disabled={qGenerateCode.isLoading || !isPermit}
            >
              <AutoFixHigh />
            </IconButton>
          </Box>
        </Tooltip>
        {qGenerateCode.isLoading && (
          <CircularProgress size={20} sx={{ mt: 0.5 }} />
        )}
        {qGenerateCode.isSuccess && (
          <DoneIcon color="success" sx={{ mt: 0.5 }} />
        )}
      </Stack>
      <ChallengeContext.Provider value={qChallenge.data}>
        <CodeGenerationSettingsDialog
          open={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          onSubmit={(settings) => {
            qGenerateCode.mutate({
              challengeId: challengeId ?? "no-id",
              templateId: qChallenge.data?.template_id || "no-id",
              userEmail: user.email, // tracker-like
              proxyType: settings.proxyType,
              model: settings.modelGPT,
              prompts: settings.prompts,
              codeUserId: settings.codeUserId,
              codeRunId: settings.codeRunId,
              validationId: settings.validationId,
            });
            setIsSettingsOpen(false);
          }}
          isConfirmNeeded={isCodeEdited}
        />
      </ChallengeContext.Provider>

      {qGenerateCode.isError && (
        <ErrorAlert value={qGenerateCode.error} isClosable />
      )}
    </Box>
  );
};
