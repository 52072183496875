import { Typography, Stack, Card, CardContent } from "@mui/material";

interface TitledCardProps {
  title?: string;
  children?: any;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

const TitledCard = ({
  title,
  children,
  style,
  wrapperStyle,
}: TitledCardProps) => {
  return (
    <Stack style={wrapperStyle}>
      <Typography variant="h6" gutterBottom component="div">
        {title}
      </Typography>
      <Card>
        <CardContent style={{ textAlign: "left", ...style }}>
          {children}
        </CardContent>
      </Card>
    </Stack>
  );
};

export default TitledCard;
