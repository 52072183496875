import React from "react";
import { useUserState } from "../../hooks/UserStateProvider";
import DashboardView from "./components/DashboardView";
import { ProjectBase } from "../../types/Projects";
import { BackendError } from "../../types/BackendError";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useQueryProjectDetails } from "./hooks/useQueryProjectDetails";
import { getProjectOnboardingTemplateName } from "./LocalOperations";

const GeneralDashboardView: React.FC = () => {
  const [user] = useUserState();
  const { projectId } = useParams<{ projectId: string }>();

  const qProject = useQueryProjectDetails(projectId || "noid", {
    enabled: !!projectId, // let's not silly attack backend using invalid projectId or with placeholder
  });

  const isProject = !!projectId;

  const project = qProject.data;

  const qProjectTemplateOnboardingName = useQuery<ProjectBase, BackendError>(
    ["OnboardingTemplateName"],
    getProjectOnboardingTemplateName,
    { staleTime: 10 * 60 * 1000 }
  );
  const isOnboardingProjectTemplate =
    qProjectTemplateOnboardingName.data?.name === project?.name;

  return (
    <DashboardView
      title={isProject ? project?.name || "" : "All challenges dashboard"}
      user={user}
      projectId={projectId}
      isProject={isProject}
      isOnboardingProjectTemplate={isOnboardingProjectTemplate}
    />
  );
};

export default GeneralDashboardView;
