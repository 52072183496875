import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "react-query";
import { useCallback } from "react";
import { ProjectUsers } from "../types/ProjectUsers";
import { BackendError } from "../types/BackendError";
import { getProjectUsers } from "../pages/DashboardView/LocalOperations";

export const useQueryProjectUsers = (
  projectId: string | number,
  options?: Omit<
    UseQueryOptions<ProjectUsers, BackendError, ProjectUsers, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _projectId = String(projectId);
  return useQuery<ProjectUsers, BackendError>(
    ["projectUsers", _projectId],
    () => getProjectUsers(_projectId),
    options
  );
};

export const useInvalidateProjectUsers = (projectId: string | number) => {
  const queryClient = useQueryClient();
  const _projectId = String(projectId);
  return useCallback(
    () => queryClient.invalidateQueries(["projectUsers", _projectId]),
    [queryClient, _projectId]
  );
};
