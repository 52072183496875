import { TableRow, TableCell, IconButton, Box, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { SnapshotValidationSummary } from "../../../types/SnapshotValidationSummary";
import { getValidationsSummary } from "../../../utils/SharedOperations";
import {
  ColoredStatus,
  getStatusClassname,
} from "../../../components/ColoredStatus";
import { CodeRunHead } from "../../../types/CodeRunHead";
import CodeRunBodyCards from "./CodeRunBodyCards";
import { localizeDatetimeUTC } from "../../../utils/Localize";
import RunContextualMenu from "../../codeRunsV2/components/RunContextualMenu";
import { useUserIdMapEmail } from "../../../hooks/UseUserIdMapEmail";
import { usePerms } from "../../../hooks/UsePerms";
import { Perm } from "../../../Const";
import TTabID from "../../../types/ChallengeViewTabsEnum";

interface CodeRunRowProps {
  codeRunHead: CodeRunHead;
  olderCodeRunHead?: CodeRunHead;
  openByDefault?: boolean;
  linkToSingleView?: boolean;
  /** The id of user that was queued. Required, since user id "all" will not match codeRunHead.user_id.
   * Pseudo id "all" is allowed.  */
  queuedUserId: string;
  isUserEmailShown?: boolean;
}

const CodeRunTableRow = ({
  codeRunHead,
  olderCodeRunHead,
  openByDefault,
  linkToSingleView,
  queuedUserId,
  isUserEmailShown = false,
}: CodeRunRowProps) => {
  const {
    snapshot_id: snapshotId,
    status,
    date_run_start: dateRunStart,
    length_secs: executionLength,
    user_id: userId,
    challenge_run_number: challengeRunNumber,
    challenge_id: challengeId,
  } = codeRunHead;

  const [isOpen, setIsOpen] = useState(!!openByDefault);
  const perms = usePerms();
  const isModerator = perms.has(Perm.VIEW_ANY_CODE);

  const qValidationsSummary = useQuery<SnapshotValidationSummary, BackendError>(
    ["SnapshotValidationsSummary", snapshotId],
    () => getValidationsSummary(snapshotId ?? "cant-happen"),
    {
      enabled:
        snapshotId !== undefined && // no data known about code run
        snapshotId !== null, // code run has no snapshot
    }
  );

  const userIdMapEmail = useUserIdMapEmail({ isEnabled: isUserEmailShown });

  const singleViewLink = `/challenges/${challengeId}/?tab=${TTabID.SubmitCode}&runNumber=${challengeRunNumber}&userId=${userId}`;

  const snapshotValidationStatus = qValidationsSummary.isIdle
    ? undefined
    : qValidationsSummary.isSuccess
    ? qValidationsSummary.data?.main_status
    : "Loading ...";

  const linkedSnapshotValidationStatus = linkToSingleView ? (
    <Link to={singleViewLink} className={getStatusClassname(snapshotValidationStatus)}>
      {snapshotValidationStatus}
    </Link>
  ) : (
    <div className={getStatusClassname(snapshotValidationStatus)}>
      {snapshotValidationStatus}
    </div>
  );

  const linkedStatus = linkToSingleView ? (
    <Link to={singleViewLink} className={getStatusClassname(status)}>
      {status}
    </Link>
  ) : (
    <ColoredStatus status={status}>{status}</ColoredStatus>
  );

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setIsOpen((old: boolean) => !old)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {linkedStatus}
        </TableCell>
        <TableCell align="right">{localizeDatetimeUTC(dateRunStart)}</TableCell>
        <TableCell align="right">{linkedSnapshotValidationStatus}</TableCell>
        {isUserEmailShown && (
          <TableCell align="right">
            {userIdMapEmail.get(userId) || `unknown (id: ${userId})`}
          </TableCell>
        )}
        <TableCell align="right">{executionLength}</TableCell>
        <TableCell align="right">
          <RunContextualMenu
            codeRunHead={codeRunHead}
            queuedUserId={queuedUserId}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{ margin: 1 }}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                columnGap: "50px",
                rowGap: "30px",
              }}
            >
              <CodeRunBodyCards codeRunHead={codeRunHead} olderCodeRunHead={olderCodeRunHead}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default CodeRunTableRow;
