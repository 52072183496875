import ChallengeDetailsInline from "./pages/ChallengeViewSteps/ChallengeDetailsInline";
import { useParams, Link } from "react-router-dom";

import CodeRunReviewNotification from "../src/pages/solutionSubmission/components/CodeRunReviewNotification";
import { useUserState } from "./hooks/UserStateProvider";

type ChallengeDetailsParams = {
  challengeId: any;
};

const ChallengeDetailsWrapper = () => {
  const { challengeId } = useParams<ChallengeDetailsParams>();
  const [user] = useUserState();
  return (
    <>
      <CodeRunReviewNotification
        challengeId={challengeId}
        runNumber={""}
        userId={user.id}
      />
      <ChallengeDetailsInline challengeId={challengeId} />
    </>
  );
};

const PageChallengeDetails = () => {
  return (
    <>
      <ChallengeDetailsWrapper />
      <Link to="reward" style={{ justifyContent: "center", display: "flex" }}>
        View reward
      </Link>
    </>
  );
};

export default PageChallengeDetails;
