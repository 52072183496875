import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserState } from "../hooks/UserStateProvider";
import CreateTaskButton from "./ChallengesNewSteps/components/CreateTaskButton";

const HomePage: React.FC = () => {
const [user] = useUserState();
const navigate = useNavigate();

    useEffect(() => {
        if (user.email === "unknown") {
        navigate("/login");
        }
    }, [user, navigate]);

  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item>
        <CreateTaskButton isExpanded={true} buttonSize="large"/>
      </Grid>
    </Grid>
  );
};

export default HomePage;
