import { Button, CircularProgress, Container, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useUserState } from "../../hooks/UserStateProvider";
import { BackendError } from "../../types/BackendError";
import createFakeToken from "../../utils/CreateFakeToken";
import { patchChallengeReserve, ReserveParams } from "./LocalOperations";
import ErrorAlert from "../../components/ErrorAlert";
import TTabID from "../../types/ChallengeViewTabsEnum";
import Box from "@mui/material/Box";

interface ChallengeRewardInlineProps {
  challengeId: string;
}

const ChallengeRewardInline = (props: ChallengeRewardInlineProps) => {
  const navigate = useNavigate();
  const [user] = useUserState();


  const qReserveChallenge = useMutation<void, BackendError, ReserveParams>(
    patchChallengeReserve
  );

  const onClick = () => {
    qReserveChallenge.mutate(
      { challengeId: props.challengeId, userToken: createFakeToken(user) },
      {
        onSuccess: () => {
          setTimeout(
            () => navigate(`/challenges/${props.challengeId}/?tab=${TTabID.SubmitCode}`),
            2000
          );
        },
      }
    );
  };
  return (
    <>
      <Box sx={{ width: "100%", padding: "20px", marginTop: "80px" }}>
        <Typography
          style={{ margin: "auto", width: "200px", fontSize: "small" }}
        >
          <div style={{ marginBottom: "12px" }}>
            By reserving you will show your intent to attempt creating Python
            solution. During the reservation time of 72 hours challenge will be
            not shown to others.{" "}
          </div>

          <div>
            There is no penalty if you are not able to solve this challenged
            with the time given. After your reservation expires it will be shown
            again in the free task pool
          </div>
        </Typography>
      </Box>
      <Box sx={{ width: "100%", padding: "20px" }}>
        <Button
          variant="contained"
          style={{ margin: "auto", display: "flex" }}
          onClick={onClick}
          disabled={!qReserveChallenge.isIdle}
        >
          Reserve and solve this challenge
        </Button>
      </Box>
      <Container>
        {qReserveChallenge.isLoading && (
          <Typography>
            Reserving ... <CircularProgress />
          </Typography>
        )}
        {qReserveChallenge.isError && (
          <ErrorAlert
            prefix="An error has occurred while reserving. "
            value={qReserveChallenge.error}
          />
        )}
        {qReserveChallenge.isSuccess && (
          <Typography>
            Reservation successful <DoneIcon color="success" />. Redirecting to
            solution view... <CircularProgress color="success" />
          </Typography>
        )}
      </Container>
    </>
  );
};

export default ChallengeRewardInline;
