import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ChallengeFiltering } from "../contexts/ChallengeFiltering";
import { Autocomplete, TextField } from "@mui/material";
import { FrontendFilterType } from "../../ChallengeViewSteps/hooks/UseChallengeFilter";
import { useInfQueryChallenges } from "../hooks/UseInfQueryChallenges";
import {
  ChallengeBrief,
  ChallengesBrief,
} from "../../../types/ChallengesBrief";
import { CHALLENGES_PER_TEXT_SEARCH } from "../../../Const";
import { useQueryChallengeDetails } from "../../../hooks/UseQueryChallengeDetails";
import { extractIdFromName } from "../../../utils/MakeFilterChallenge";
import { ChallengeDetailed } from "../../../types/ChallengesDetailed";
import { formatChallengeName } from "../../../utils/FormatChallengeName";
import { useNavigate } from "react-router-dom";
import TTabID from "../../../types/ChallengeViewTabsEnum";

const ChallengeSearchField = () => {
  const filtering = useContext(ChallengeFiltering);
  const filter = filtering?.getFilter(FrontendFilterType.Name);
  const name: string = filter?.value ?? "";
  const chalId: string | null = extractIdFromName(name);

  const [autoChallenges, setAutoChallenges] = useState<ChallengesBrief>([]);
  const autoOptions = useMemo(
    () =>
      autoChallenges.map((challenge) => {
        return { label: formatChallengeName(challenge), id: challenge.id };
      }),
    [autoChallenges]
  );

  const mergeChallenges = useCallback(
    (challengeById?: ChallengeDetailed, challengePages?: ChallengesBrief[]) => {
      let challenges: ChallengesBrief = [];
      if (!!challengeById && String(challengeById?.id) === String(chalId)) {
        // would mismatch if search query changed, yet the challenge is being fetched
        challenges.push(challengeById);
      }
      const filterIdDuplicate =
        challenges.length === 1
          ? (c: ChallengeBrief) => String(c.id) !== String(chalId)
          : () => true;
      for (const challengesPage of challengePages || []) {
        challenges.push(
          ...challengesPage
            .filter(filterIdDuplicate)
            .filter(filter?.filterFunction ?? (() => true))
        );
        if (challenges.length > CHALLENGES_PER_TEXT_SEARCH) {
          challenges = challenges.slice(0, CHALLENGES_PER_TEXT_SEARCH);
          break;
        }
      }
      return challenges;
    },
    [chalId, filter?.filterFunction]
  );

  const infQuery = useInfQueryChallenges({
    isNextPageTriggered: autoChallenges.length < CHALLENGES_PER_TEXT_SEARCH,
    filters: filtering?.filters ?? [],
    options: {
      enabled: !filtering?.isLoading,
    },
  });

  const qChalById = useQueryChallengeDetails(chalId ?? "no-id", {
    enabled: !!chalId,
    retry: (count, error) => {
      return count < 3 && error.response.status !== 404;
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    setAutoChallenges(mergeChallenges(qChalById.data, infQuery.data?.pages));
  }, [qChalById.data, infQuery.data, name, mergeChallenges]);

  if (!filtering) {
    return null;
  }

  return (
    <Autocomplete
      id="search-challenge"
      value={null}
      onChange={(_e, item) => {
        const id = typeof item === "string" ? autoChallenges[0]?.id : item?.id;
        if (!!id) {
          navigate(`/challenges/${id}/?tab=${TTabID.ChallengeDescription}`);
        }
      }}
      inputValue={name}
      onInputChange={(_e, value) => {
        filtering.setFilter(FrontendFilterType.Name, value);
      }}
      options={autoOptions}
      sx={{ width: 300 }}
      filterOptions={(option) => option /** disables auto filter */}
      clearOnBlur={false /** no option chosen on exit --> persist text */}
      freeSolo
      autoHighlight
      renderInput={(params) => (
        <TextField {...params} label="Search challenges" />
      )}
      componentsProps={{
        popper: { sx: { zIndex: 1111 } }, // zindex lower than dialog, header (1201), popper default is 1301 or 1300
      }}
    />
  );
};

export default ChallengeSearchField;
