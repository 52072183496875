import { CircularProgress, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useQuery } from "react-query";
import ErrorAlert from "../../../components/ErrorAlert";
import { BackendError } from "../../../types/BackendError";
import { Validation, Validations } from "../../../types/Validations";
import { getFailingValidations } from "../LocalOperations";
import FailingValidation from "./FailingValidation";

interface FailingValidationsProps {
  userId?: string;
}

const FailingValidations = ({ userId }: FailingValidationsProps) => {
  const qFailings = useQuery<Validations, BackendError>(
    ["failingValidations", userId],
    () => getFailingValidations(userId),
    { staleTime: 2 * 60 * 1000 }
  );

  if (qFailings.isLoading) {
    return <CircularProgress />;
  }
  if (qFailings.isError) {
    return (
      <ErrorAlert
        prefix="Whoops, failed to get failing validations: "
        value={qFailings.error}
      />
    );
  }
  if (!qFailings.data) {
    return <ErrorAlert value="Unknown error" />;
  }

  if (qFailings.data.length < 1) {
    return (
      <Typography variant="subtitle1">No failing validation exists</Typography>
    );
  }

  return (
    <Container className="containerMain gapVertical">
      {qFailings.data.map((failingValidation: Validation) => (
        <FailingValidation
          failingValidation={failingValidation}
          key={failingValidation.id}
        />
      ))}
    </Container>
  );
};

export default FailingValidations;
