import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useUserState } from "../../hooks/UserStateProvider";
import PageTitle from "../../components/PageTitle";
import { BackendError } from "../../types/BackendError";
import createFakeToken from "../../utils/CreateFakeToken";
import { patchChallengeReserve, ReserveParams } from "./LocalOperations";
import ErrorAlert from "../../components/ErrorAlert";
import TTabID from "../../types/ChallengeViewTabsEnum";

type ChallengeRewardParams = {
  challengeId?: string;
};

/*
<RewardGrid>
{procTexts.map((procText: procTextType, i: number) => (
  <RewardLine
    key={i}
    fullBudgetEur={fullBudgetEur}
    percentage={procText.proc}
    text={procText.text}
  />
))}
</RewardGrid>
*/

const ChallengeReward = () => {
  const navigate = useNavigate();
  const { challengeId } = useParams<ChallengeRewardParams>();
  const [user] = useUserState();

  const qReserveChallenge = useMutation<void, BackendError, ReserveParams>(
    patchChallengeReserve
  );

  const onClick = () => {
    qReserveChallenge.mutate(
      { challengeId: challengeId, userToken: createFakeToken(user) },
      {
        onSuccess: () => {
          setTimeout(
            () =>
              navigate(`/challenges/${challengeId}/?tab=${TTabID.SubmitCode}`),
            2000
          );
        },
      }
    );
  };
  return (
    <>
      <PageTitle header="Reward structure & reservation" />

      <Box
        style={{
          margin: "auto",
          display: "flex",
          width: "600px",
          padding: "20px",
        }}
      >
        <Container>
          <p>
            By reserving you will show your intent to attempt creating Python
            solution. During the reservation time of 72 hours the challenge will
            be not open to others.
          </p>

          <p>
            There is no penalty if you are not able to solve this challenge with
            the time given. After your reservation expires it will be shown
            again in the free task pool.
          </p>
        </Container>
      </Box>
      <Button
        variant="contained"
        style={{ margin: "auto", display: "flex" }}
        onClick={onClick}
        disabled={!qReserveChallenge.isIdle}
      >
        Reserve and solve this challenge
      </Button>
      <Container>
        {qReserveChallenge.isLoading && (
          <Typography>
            Reserving ... <CircularProgress />
          </Typography>
        )}
        {qReserveChallenge.isError && (
          <ErrorAlert
            prefix="An error has occurred while reserving. "
            value={qReserveChallenge.error}
          />
        )}
        {qReserveChallenge.isSuccess && (
          <Typography>
            Reservation successful <DoneIcon color="success" />. Redirecting to
            solution view... <CircularProgress color="success" />
          </Typography>
        )}
      </Container>
    </>
  );
};

export default ChallengeReward;
