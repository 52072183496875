import { QueryKey, UseQueryOptions,  useQuery} from "react-query";
import { Project } from "../../../types/Projects";
import { BackendError } from "../../../types/BackendError";
import { getProject } from "../LocalOperations";


export const useQueryProjectDetails = (
  projectId?: number | string,
  options?: Omit<
  UseQueryOptions<Project, BackendError, Project, QueryKey>,
  "queryKey" | "queryFn">
) => {
  const _projectId = String(projectId || 0);
  return useQuery<Project, BackendError>(
    ["Project", _projectId],
    () => getProject(_projectId),
    options
  );
};
