import { QueryKey, UseQueryOptions, useQuery, useQueryClient } from "react-query";
import { useCallback } from "react";
import { BackendError } from "../types/BackendError";
import { TemplateListensAnomaly } from "../types/TemplateListensAnomaly";
import { getTemplateListensToAnomalyTypes } from "../pages/ChallengeViewSteps/LocalOperations";
const genTemplateKey = (templateId?: number | string) => {
return ["templateAnomalies", String(templateId)]
}
export const useQueryTemplateListensAnomaly = (
  templateId: number | string,
  options?: Omit<
    UseQueryOptions<TemplateListensAnomaly, BackendError, TemplateListensAnomaly, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _templateId = String(templateId);
  return useQuery<TemplateListensAnomaly, BackendError>(
    genTemplateKey(_templateId),
    () => getTemplateListensToAnomalyTypes(_templateId),
    options
  );
};

export const useInvalidateTemplateListensAnomaly = (templateId: number | string) => {
  const queryClient = useQueryClient();
  const _templateId = String(templateId);
  return useCallback(
    () => queryClient.invalidateQueries(genTemplateKey(_templateId)),
    [queryClient, _templateId]
  );
}
