import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import MultiSelectUser from "./../../components/MultiSelectUser";
import { ChallengeDetailed } from "../../types/ChallengesDetailed";
import { useMutation } from "react-query";
import { BackendError } from "../../types/BackendError";
import { postChallengeSendEmailv2 } from "../../pages/ChallengeViewSteps/LocalOperations";
import Autocomplete from "@mui/material/Autocomplete";

interface ChallengeEmailComposerProps {
  challenge: ChallengeDetailed;
}

const ChallengeEmailComposer = ({ challenge }: ChallengeEmailComposerProps) => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [text, setText] = useState("");
  const [selectedPreset, setSelectedPreset] = useState<TextPresets | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] =
    useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");

  const qSendEmail = useMutation<void, BackendError, void>(
    () => postChallengeSendEmailv2(challenge.id, selectedUserIds, text),
    {
      onSuccess: () => {
        setSelectedPreset(null);
        setText("");
        setErrorMessage("");
        setIsSending(false);
        setIsSuccessSnackbarOpen(true);
        setSuccessMessage("Message sent successfully");
      },
      onError: (error: BackendError) => {
        setErrorMessage("Failed to send email. Please try again.");
        setIsSending(false);
      },
    }
  );

  enum TextPresets {
    FIX_ERROR = "Fix error",
    NEW_TASK = "New task",
    TASK_UPDATES = "Task updates",
  }

  const TEXT_PRESETS: { [key in TextPresets]: string } = {
    [TextPresets.FIX_ERROR]: `Hey contributor,
Please review and fix the challenge called ${challenge.name}.`,
    [TextPresets.NEW_TASK]: `Hey contributor,
New challenge called ${challenge.name} has landed on the dataplatform.lt.
We invite you to review task details and reserve first.`,
    [TextPresets.TASK_UPDATES]: `Hey contributor,
The budget "${challenge.name}" challenge has been adjusted to ${challenge.budget_eur_per_month}€.`,
  };

  const handleSendEmail = () => {
    if (selectedUserIds.length === 0) {
      setErrorMessage("Please select at least one user.");
      return;
    }
    setIsSending(true);
    qSendEmail.mutate();
  };

  const handleSelectPreset = (preset: TextPresets) => {
    setSelectedPreset(preset);
    setText(TEXT_PRESETS[preset]);
  };

  const handleMessageUpdate = (text: string | null) => {
    setText(text ?? "");
    setSelectedPreset(null);
  };

  const handleDiscardClick = () => {
    setSelectedUserIds([]);
    setText("");
    setSelectedPreset(null);
  };

  return (
    <>
      <Box sx={{ m: 1 }}>
        <MultiSelectUser
          onChangeSelectedUsers={setSelectedUserIds}
          selectedUserIds={selectedUserIds}
          challengeId={challenge.id}
        />
        {errorMessage && (
          <Alert severity="error" sx={{ marginTop: 1 }}>
            {errorMessage}
          </Alert>
        )}
        <Autocomplete
          value={selectedPreset}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              handleSelectPreset(newValue);
            }
          }}
          options={Object.values(TextPresets)}
          renderInput={(params) => (
            <TextField {...params} label="Select email template" />
          )}
          fullWidth
          sx={{ mb: 1, marginTop: 1 }}
        />

        <Typography variant="body1" component="div">
          Compose message
        </Typography>
        <TextField
          id="standard-multiline-static"
          label="Email message"
          multiline
          rows={8}
          value={text}
          onChange={(e) => handleMessageUpdate(e.target.value)}
          variant="filled"
          style={{ width: "100%" }}
        />
      </Box>
      <Box sx={{ m: 1, minWidth: 120 }}>
        <Button
          variant="contained"
          size="medium"
          style={{ float: "right" }}
          onClick={handleSendEmail}
          disabled={selectedUserIds.length === 0 || !text || isSending}
        >
          Send
        </Button>
        <Button
          variant="outlined"
          size="medium"
          style={{ float: "right", marginRight: "10px" }}
          onClick={handleDiscardClick}
        >
          Discard
        </Button>
        {isSending && <CircularProgress size={24} />}
        <Snackbar
          open={isSuccessSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setIsSuccessSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Position at top center
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => setIsSuccessSnackbarOpen(false)}
            severity="success"
          >
            {successMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default ChallengeEmailComposer;
