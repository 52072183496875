import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "react-query";
import { ChallengeDetailed } from "../types/ChallengesDetailed";
import { BackendError } from "../types/BackendError";
import { getChallengeDetails } from "../pages/ChallengeViewSteps/LocalOperations";
import { useCallback } from "react";

export const genChallengeDetailsKey = (challengeId?: string) => [
  "challengeDetailed",
  String(challengeId),
];

export const useQueryChallengeDetails = (
  challengeId: string | number,
  options?: Omit<
    UseQueryOptions<
      ChallengeDetailed,
      BackendError,
      ChallengeDetailed,
      QueryKey
    >,
    "queryKey" | "queryFn"
  >
) => {
  const _challengeId = String(challengeId);
  return useQuery<ChallengeDetailed, BackendError>(
    genChallengeDetailsKey(_challengeId),
    () => getChallengeDetails(_challengeId),
    options
  );
};

export const useInvalidateChallengeDetails = (
  challengeId?: number | string
) => {
  const _challengeId = String(challengeId);
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries(["challengeDetailed", _challengeId]),
    [queryClient]
  );
};
