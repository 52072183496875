import React, { useState, useEffect } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Chip,
  Alert,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Paper,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { BackendError } from "../types/BackendError";
import { useMutation } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { useModal } from "react-hooks-use-modal";
import { provideTheme } from "../utils/ProvideTheme";
import { useQueryUserDetails } from "../hooks/UseQueryUserDetails";
import { updateUserStatus } from "./ChallengeViewSteps/LocalOperations";
import { formatDate } from "../utils/FormatDate";

const UserDetailsView = () => {
  const { userId } = useParams<{ userId: string }>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchParams] = useSearchParams();
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] =
    useState<boolean>(false);
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] =
    useState<boolean>(false);
  const {
    data: user,
    isLoading,
    isError,
    refetch,
  } = useQueryUserDetails(userId || "");

  const [Modal, openDeleteDialog, closeDeleteDialog, isDeleteDialogOpen] =
    useModal();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab === "permissions") {
      setSelectedTab(1);
    } else if (tab === "details") {
      setSelectedTab(0);
    } else {
      setSelectedTab(0);
    }
  }, [searchParams]);

  const qUpdateStatus = useMutation<
    void,
    BackendError,
    { userId: string; status: string }
  >(({ userId, status }) => updateUserStatus(userId, status), {
    onSuccess: () => {
      refetch();
      setSelectedStatus("");
      setAnchorEl(null);
      setIsSuccessSnackbarOpen(true);
    },
    onError: (error: BackendError) => {
      setSelectedStatus("");
      setAnchorEl(null);
      setIsErrorSnackbarOpen(true);
    },
  });

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleActivateButton = (userId: string) => {
    setSelectedStatus("active");
    qUpdateStatus.mutate({ userId, status: "active" });
  };

  const handleDeactivateButton = (userId: string) => {
    setSelectedStatus("inactive");
    qUpdateStatus.mutate({ userId, status: "inactive" });
  };

  const handleSuccessSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  const handleErrorSnackbarClose = () => {
    setIsErrorSnackbarOpen(false);
  };

  const handleDeleteButtonClick = (userId: string, username: string) => {
    openDeleteDialog();
  };

  const handleDeleteUser = () => {
    closeDeleteDialog();
  };

  const renderStatusChip = (status: string) => {
    const textColor =
      status === "active"
        ? provideTheme.palette.success.main
        : provideTheme.palette.secondary.main;
    const backgroundColor =
      status === "active"
        ? provideTheme.palette.success.light
        : provideTheme.palette.secondary.light;
    const chipLabel = status.charAt(0).toUpperCase() + status.slice(1);

    return (
      <Box display="flex" alignItems="center">
        <Chip
          label={chipLabel}
          style={{ color: textColor, backgroundColor: backgroundColor }}
        />
        <IconButton onClick={handleMenuOpen}>
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          id="status-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {user?.status === "active" ? (
            <MenuItem onClick={() => handleDeactivateButton(user?.id ?? "")}>
              Deactivate user
            </MenuItem>
          ) : (
            <MenuItem onClick={() => handleActivateButton(user?.id ?? "")}>
              Activate user
            </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              handleDeleteButtonClick(userId ?? "", user?.shown_name ?? "")
            }
          >
            Delete user
          </MenuItem>
        </Menu>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete user{" "}
            {user?.shown_name ?? user?.email}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting this user will permanently remove their account and all
              associated data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeDeleteDialog}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDeleteUser}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const UserDetailsTab = (
    <Box display="flex" flexDirection="column" gap={2} sx={{ width: "1088px" }}>
      <Typography
        sx={{
          fontFamily: "Open Sans",
          fontSize: 24,
        }}
      >
        User information
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Alert severity="error">Error fetching user</Alert>
      ) : (
        <>
          <Paper elevation={0} sx={{ marginBottom: 3, border: "none" }}>
            <Box display="flex" alignItems="center" paddingLeft={3}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
                sx={{ width: 180 }}
              >
                Display name
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={1}
                bgcolor="#FFFFFF"
                sx={{ width: 220 }}
              >
                <Typography variant="body1">
                  {user?.shown_name ? user.shown_name : "Not set yet"}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" paddingLeft={3}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
                sx={{ width: 180 }}
              >
                Email address
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={1}
                bgcolor="#FFFFFF"
                sx={{ width: 220 }}
              >
                <Typography variant="body1">{user?.email}</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" paddingLeft={3}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
                sx={{ width: 180 }}
              >
                User type
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={1}
                bgcolor="#FFFFFF"
                sx={{ width: 220 }}
              >
                <Typography variant="body1">{user?.role_name}</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" paddingLeft={3}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
                sx={{ width: 180 }}
              >
                User status
              </Typography>
              {renderStatusChip(user?.status || "")}
            </Box>
            <Box display="flex" alignItems="center" paddingLeft={3}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
                sx={{ width: 180 }}
              >
                Joined
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={1}
                bgcolor="#FFFFFF"
                sx={{ width: 220 }}
              >
                <Typography variant="body1">
                  {user?.created ? formatDate(user.created.toString()) : "N/A"}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" paddingLeft={3}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
                sx={{ width: 180 }}
              >
                Last active
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={1}
                bgcolor="#FFFFFF"
                sx={{ width: 220 }}
              >
                <Typography variant="body1">
                  {user?.last_logged_in
                    ? formatDate(user.last_logged_in.toString())
                    : "N/A"}
                </Typography>
              </Box>
              <Snackbar
                open={isSuccessSnackbarOpen}
                autoHideDuration={3000}
                onClose={handleSuccessSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Alert onClose={handleSuccessSnackbarClose} severity="success">
                  User status updated successfully!
                </Alert>
              </Snackbar>
              <Snackbar
                open={isErrorSnackbarOpen}
                autoHideDuration={3000}
                onClose={handleErrorSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Alert onClose={handleErrorSnackbarClose} severity="error">
                  Error updating user status.
                </Alert>
              </Snackbar>
            </Box>
          </Paper>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: 24,
              marginBottom: 3,
            }}
          >
            User activity
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                fontFamily: "Open Sans",
                fontSize: 16,
              }}
            >
              Action
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                fontFamily: "Open Sans",
                fontSize: 16,
              }}
            >
              Date
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );

  const ManagePermissionsTab = (
    <Box display="flex" flexDirection="column" gap={2}>
      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Alert severity="error">Error fetching user</Alert>
      ) : (
        <Typography>Manage permissions</Typography>
      )}
    </Box>
  );

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 3 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="user details tabs"
        >
          <Tab label="User Details" />
          <Tab label="Manage Permissions" />
        </Tabs>
      </Box>
      {selectedTab === 0 && UserDetailsTab}
      {selectedTab === 1 && ManagePermissionsTab}
    </>
  );
};

export default UserDetailsView;
