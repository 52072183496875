import Fuse from "fuse.js";
import { ChallengeBrief } from "../types/ChallengesBrief";
import unidecode from "unidecode";
import { formatChallengeName, idSymbol } from "./FormatChallengeName";

type _ChallengeFilterType = (challenge: ChallengeBrief) => boolean;

/** Finds id inside name. Can be in the middle of string. id symbol is optional. Returns without id symbol */
export const extractIdFromName = (name: string): string | null => {
  const regId = "\\s*" + idSymbol + "?\\s*(\\d+)"; // allow searching by raw id, without #prefix
  return name.match(regId)?.[1] || null; // [0] matched fragment; [1] matched group
};

export const makeFilterChallengeByName = (
  nameInSearch: string,
  /** default 0.6; 0.0 is exact match; 1.0 is any */
  thresholdMatch: number = 0.47
): _ChallengeFilterType => {
  const idInSearch = extractIdFromName(nameInSearch);
  const filterByName = (challenge: ChallengeBrief) => {
    if (!nameInSearch) {
      return true;
    }

    if (idInSearch && String(challenge.id).includes(idInSearch)) {
      return true;
    }

    const matches = new Fuse([unidecode(formatChallengeName(challenge))], {
      threshold: thresholdMatch, // default 0.6; 0.0 is exact match; 1.0 is any
      useExtendedSearch: true, // lets write "3 2 1" even when name is "1 2 3"
    }).search(unidecode(nameInSearch)); // utf8 -> ascii
    return matches.length === 1;
  };
  return filterByName;
};
