import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { deleteChallenge } from "../LocalOperations";
import ErrorAlert from "../../../components/ErrorAlert";
import { usePerms } from "../../../hooks/UsePerms";
import { Perm } from "../../../Const";
import { useNavigate } from "react-router-dom";
import { useModal } from "react-hooks-use-modal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

interface DeleteChallengeButtonProps {
  challengeId: string;
  challengeName?: string;
}

export const DeleteChallengeButton: React.FC<DeleteChallengeButtonProps> = ({
  challengeId,
  challengeName,
}) => {
  const navigate = useNavigate();
  const [Modal, openDeleteDialog, closeDeleteDialog, isDeleteDialogOpen] =
    useModal();
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);

  const { mutate, isError, isLoading, error } = useMutation<
    void,
    BackendError,
    any
  >(deleteChallenge, {
    onSuccess: () => {
      navigate(`/`, {
        state: { openChallengeDeleteSnackbar: true, challengeName: challengeName },
      });
      setIsSuccessSnackbarOpen(true);
    },
  });

  const perms = usePerms();

  if (!perms.has(Perm.DELETE_CHALLENGE)) {
    return null;
  }

  return (
    <>
      {isError && <ErrorAlert value={error} />}
      <Button
        startIcon={<DeleteOutlineOutlinedIcon />}
        color="error"
        disabled={isLoading}
        onClick={openDeleteDialog}
      >
        DELETE CHALLENGE
      </Button>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete "{challengeName}" challenge?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting this challenge will permanently remove all associated data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDeleteDialog}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={() => mutate(challengeId)}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={24} />}
          >
            {isLoading ? "Deleting..." : "DELETE"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
