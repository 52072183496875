export const idSymbol = "#";

export const formatChallengeName = (
  challenge: {
    name: string;
    id: string;
  },
  { idPrefix = idSymbol, idSuffix = "" } = {}
) => {
  return `${idPrefix}${challenge.id}${idSuffix} ${challenge.name}`;
};
