import { Button, ButtonProps, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { postCodeReview } from "../LocalOperations";
import ErrorAlert from "../../../components/ErrorAlert";
import { BackendError } from "../../../types/BackendError";
import { useRefetchQueryCodeReview } from "../hooks/UseQueryCodeReview";
import { useChallengeSLA } from "../../../hooks/UseChallengeSLA";

interface CodeRunReviewActionsProps {
  challengeId: string;
  codeRunNumber: string;
  userId: string;
}

const CodeRunReviewActions = ({
  challengeId,
  codeRunNumber,
  userId,
}: CodeRunReviewActionsProps) => {
  const [isRejecting, setIsRejecting] = useState(false);
  const [rejectionText, setRejectionText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { refetch: refetchSLA } = useChallengeSLA(challengeId);

  const refetchReview = useRefetchQueryCodeReview(
    challengeId,
    codeRunNumber,
    userId
  );

  const qReviewCode = useMutation<void, BackendError>(
    () => {
      setIsSubmitting(true);
      return postCodeReview(
        challengeId,
        codeRunNumber,
        userId,
        isRejecting ? rejectionText : undefined
      );
    },
    {
      onError: () => setIsSubmitting(false),
      onSuccess: () => {
        refetchSLA();
        refetchReview();
      },
    }
  );

  const _secondaryButtonProps: ButtonProps = {
    variant: "outlined",
    disabled: isSubmitting,
  };
  const _primaryButtonProps: ButtonProps = {
    variant: "contained",
    style: { marginLeft: "8px" },
    disabled: isSubmitting,
  };

  const titleReject = `The submitted code will be rejected on ${new Date().toLocaleString()}.`;
  const titleNeutral = "Approve or reject the submitted code.";

  return (
    <>
      <Typography>{isRejecting ? titleReject : titleNeutral}</Typography>
      {isRejecting ? (
        <>
          <TextField
            multiline
            minRows={2}
            maxRows={10}
            placeholder="Add your feedback or reason for rejection here."
            fullWidth
            style={{ paddingBottom: "19px" }}
            value={rejectionText}
            onChange={(e) => setRejectionText(e.target.value)}
            disabled={isSubmitting}
          />
          <Button
            {..._secondaryButtonProps}
            onClick={() => setIsRejecting(false)}
          >
            Cancel
          </Button>
          <Button
            {..._primaryButtonProps}
            disabled={isSubmitting || rejectionText.length < 5}
            onClick={() => qReviewCode.mutate()}
          >
            Save
          </Button>
        </>
      ) : (
        <div style={{ paddingTop: "15px" }}>
          <Button
            {..._secondaryButtonProps}
            onClick={() => setIsRejecting(true)}
          >
            Reject with feedback
          </Button>
          <Button {..._primaryButtonProps} onClick={() => qReviewCode.mutate()}>
            Approve & Autorun
          </Button>
        </div>
      )}
      {qReviewCode.error && <ErrorAlert value={qReviewCode.error} />}
    </>
  );
};

export default CodeRunReviewActions;
