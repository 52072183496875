import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQueryChallengeDetails } from "../../../hooks/UseQueryChallengeDetails";
import ErrorAlert from "../../../components/ErrorAlert";
import ExternalLink from "../../../components/ExternalLink";
import { useState } from "react";

interface ChallengeDetailsCardProps {
  style?: React.CSSProperties;
  challengeId: string;
}

const ChallengeDetailsCard = ({
  style,
  challengeId,
}: ChallengeDetailsCardProps) => {
  const qChallenge = useQueryChallengeDetails(challengeId);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const handleAccordionChange = (isExpanded: boolean) => {
    setIsAccordionExpanded(isExpanded);
  };

  if (qChallenge.isError) {
    return <ErrorAlert value={qChallenge.error} />;
  }

  if (!qChallenge.isSuccess) {
    return <CircularProgress />;
  }

  const challenge = qChallenge.data;

  return (
    <Box style={{ width: "100%" }} borderRadius={1}>
      <Accordion
        expanded={isAccordionExpanded}
        onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Other information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!!challenge.data_description && (
            <>
              <Box display="flex" alignItems="center" paddingBottom={1}>
                <Typography color="textSecondary" sx={{ width: 85 }}>
                  Description:
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="right"
                  paddingLeft={1}
                >
                  <Typography>{challenge.data_description}</Typography>
                </Box>
              </Box>
            </>
          )}
          {!!challenge.target_url && (
            <>
              <Box display="flex" alignItems="center" paddingBottom={1}>
                <Typography color="textSecondary" sx={{ width: 85 }}>
                  Target URL:
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="right"
                  paddingLeft={1}
                >
                  <ExternalLink href={challenge.target_url}>
                    {challenge.target_url}
                  </ExternalLink>
                </Box>
              </Box>
            </>
          )}
          {!!challenge.frequency_name && (
            <>
              <Box display="flex" alignItems="center">
                <Typography color="textSecondary" sx={{ width: 85 }}>
                  Frequency:
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="right"
                  paddingLeft={1}
                >
                  <Typography>(every) {challenge.frequency_name}</Typography>
                </Box>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ChallengeDetailsCard;
