import { CircularProgress, Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { ChallengeBrief, ChallengesBrief } from "../../../types/ChallengesBrief";
import extractError from "../../../utils/ErrorExtract";

import DatasetBanner from "../components/DatasetBanner"
import { useInView } from "react-intersection-observer";
import { makeFilterChallengeByName } from "../../../utils/MakeFilterChallenge";


import {BackendFilterType} from "../../ChallengeViewSteps/hooks/UseChallengeFilter"
import {useInfQueryChallenges, } from "../../challengesListV2/hooks/UseInfQueryChallenges"

interface ChallengesViewProps {
  viewReserved?: boolean;
  headless?: boolean;
  linkPrefix?: string;
  showFilters?: boolean;
}

// partially deprecated component
const DatasetsView = ({
  viewReserved,
  headless,
  linkPrefix,
  showFilters,
}: ChallengesViewProps) => {
  const [challengeParams, setChallengeParams] = useState([viewReserved]);
  const [filterByName, setFilterByName] = useState(() =>
    makeFilterChallengeByName("")
  );
  const resultsEndView = useInView();

  const infQuery = useInfQueryChallenges({
    isNextPageTriggered: resultsEndView.inView,
    filters: [
      {type: BackendFilterType.IsMyGrant, value: true}
    ],
  });


  // if this check gets simplified to
  //  infQuery.data?.pages ? infQuery.data.pages.length > 0 : false;
  // then TypeScript complains about possibly undefined infQuery.data
  // in infQuery.data.pages.map part
  const isHasData =
    infQuery.data && infQuery.data.pages && infQuery.data.pages.length > 0;

  return (
    <>
      {headless !== true && (
        <PageTitle
          header="#1 Choose your datasets"
          subHeader="Choose one of datasets"
        />
      )}
      <Grid container direction="column" className="gridMain gapVertical">
        {infQuery.isLoading && (
          <Typography>
            Loading data ...
            <CircularProgress />
          </Typography>
        )}
        {infQuery.isError && <span>{extractError(infQuery.error)}</span>}
        {isHasData && (
          <>
            {infQuery.data.pages.map((page: ChallengesBrief, i: number) => (
              <React.Fragment key={-i}>
                {page.filter(filterByName).map((challenge: ChallengeBrief) => (
                  <DatasetBanner
                    key={challenge.id}
                    challenge={challenge}
                    linkPrefix={linkPrefix}
                  />
                ))}
              </React.Fragment>
            ))}
            {infQuery.isFetchingNextPage ? (
              <Typography textAlign="center">
                Loading more ...
                <CircularProgress />
              </Typography>
            ) : (
              <Button
                onClick={() => infQuery.fetchNextPage()}
                disabled={!infQuery.hasNextPage}
                ref={resultsEndView.ref}
              >
                {infQuery.hasNextPage ? "Load more..." : "Nothing more to load"}
              </Button>
            )}
          </>
        )}
        {isHasData && infQuery.data.pages[0].length < 1 && (
          <Typography textAlign="center">
            Oops, there are no challenges.
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default DatasetsView;
