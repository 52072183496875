import { Button, CircularProgress, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { BackendError } from "../../../types/BackendError";
import { getCodeRunHeadsByChallengeUser } from "../LocalOperations";
import CodeRunTable from "./CodeRunTable";
import ErrorAlert from "../../../components/ErrorAlert";
import CodeRunTableRow from "./CodeRunTableRow";
import { CodeRunHead, CodeRunHeads } from "../../../types/CodeRunHead";
import { useState } from "react";
import { usePerms } from "../../../hooks/UsePerms";
import { Perm } from "../../../Const";

interface MultiCodeProps {
  userId: string;
  challengeId: string;
}


const MultiCodeRun = ({ userId, challengeId }: MultiCodeProps) => {
  const isIrrespective = userId === "all";
  const [isManualRunOnly, setIsManualRunOnly] = useState(true);
  const qCodeRunHeads = useQuery<CodeRunHeads, BackendError>(
    ["codeRunHeads", challengeId, userId],
    () => getCodeRunHeadsByChallengeUser(challengeId, userId),
    {
      refetchInterval: 2 * 60 * 1000,
      onSuccess: (data: CodeRunHeads) => {
        // in future there might be no need to check for "does this challenge has an autorun?"
        // currently it is done locally to prevent from showing "moderator will review" text
        // if moderator already reviewed (by enabling an autorun)
        const isThereAnyAutorun = data.some(
          (head: CodeRunHead) => head.is_verified
        );
        setIsManualRunOnly(!isThereAnyAutorun);
      },
    }
  );
  const [visibleRowsCount, setVisibleRowsCount] = useState(7);
  const perms = usePerms();

  const codeRunHeads = qCodeRunHeads.data;

  return (
    <>
      {qCodeRunHeads.isLoading && <CircularProgress />}
      {qCodeRunHeads.isError && (
        <ErrorAlert prefix="Error. " value={qCodeRunHeads.error} />
      )}
      {qCodeRunHeads.isSuccess && codeRunHeads && (
        <>
          <CodeRunTable isUserEmailShown={isIrrespective}>
            {codeRunHeads
              .slice(0, visibleRowsCount)
              .map((codeRunHead: CodeRunHead, index) => (
                <CodeRunTableRow
                  codeRunHead={codeRunHead}
                  olderCodeRunHead={codeRunHeads[index + 1]}
                  // when viewing irrespective code runs, challenge run number is no longer unique.
                  key={`${codeRunHead.challenge_run_number}-${codeRunHead.user_id}`}
                  queuedUserId={userId}
                  linkToSingleView
                  isUserEmailShown={isIrrespective}
                />
              ))}
          </CodeRunTable>
          {codeRunHeads.length > 0 ? (
            <>
              {codeRunHeads.length > visibleRowsCount && (
                <div style={{ textAlign: "center", marginTop: "-20px" }}>
                  <Button
                    onClick={() => setVisibleRowsCount((prev) => prev + 5)}
                  >
                    Show few more code runs
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <Typography textAlign="center">Nothing to show ...</Typography>
              {perms.has(Perm.SCHEDULE_AUTORUN) && (
                <Typography textAlign="center">
                  Therefore "autorun" section hidden ...
                </Typography>
              )}
            </>
          )}
          <div className="br-big" />
        </>
      )}
    </>
  );
};

export default MultiCodeRun;
