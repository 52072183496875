import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface LinkForButtonProps extends Omit<LinkProps, 'title'> {
  title?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const LinkForButton: React.FC<LinkForButtonProps> = ({ title, disabled, style, children, ...props }) => {
  if (disabled) {
    return (
      <div style={style} title={title}>
        {children}
      </div>
    );
  }

  return (
    <Link {...props} style={{ ...style, textDecoration: 'none', color: 'inherit' }} title={title}>
      {children}
    </Link>
  );
};
