import React from "react";
import {
  Box,
  BoxProps,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";

type BarItem = {
  value: number;
  name: string;
  color?: string;
  className?: string;
};

export type PercentableItem = BarItem & {
  percent: number;
};

type ExpandedBarItem = PercentableItem & {
  tooltip: React.ReactNode;
};

export interface MultiColorBarProps {
  items: BarItem[];
  className?: string;
}

const CustomBox: any = styled(Box, {
  name: "MultiColorBar",
  slot: "Root",
})<BoxProps>(() => ({}));

interface percentItemsParams {
  items: PercentableItem[];
  isOverwrite?: boolean;
}

export const percentItems = ({ items, isOverwrite }: percentItemsParams) => {
  if (!isOverwrite) {
    items = [...items];
  }
  if (items.length === 0) return items;
  const true_total = items.reduce((acc, item) => acc + item.value, 0);
  const total = true_total === 0 ? 100 : true_total;
  const percent = (value: number, total: number) =>
    Math.floor((value / total) * 1e5) / 1e3;

  // case 0: all values are > 0
  const handleOptimistic = (item: PercentableItem) =>
    (item.percent = percent(item.value, total));

  // case 1: total is 0
  const handleAllZero = (item: PercentableItem) =>
    (item.percent = percent(100 / items.length, total));

  const editor =
    items.filter((item) => item.value > 0).length === 0
      ? handleAllZero
      : handleOptimistic;

  items.forEach(editor);

  // case 2: percents add up to 99.99 or 100.01
  const anyWidthItem = items.find((item) => item.percent !== 0);
  if (anyWidthItem) {
    anyWidthItem.percent =
      100 -
      (items.reduce((acc, item) => acc + item.percent, 0) -
        anyWidthItem.percent);
  }
  // if overwrite is true, no need to listen for return - your supplied object is already mutated
  return items;
};

const tooltipItems = (items: ExpandedBarItem[]) => {
  if (items.length === 0) return;
  const tooltips = items.map(
    (item, index) => `${index === 0 ? "" : ", "}${item.name}: ${item.value}`
  );
  items.forEach(
    (item, index) =>
      (item.tooltip = (
        <Stack direction="row">
          {items.map((_item2, index2) => (
            <Typography
              key={index2}
              textTransform={index === index2 ? "uppercase" : "none"}
              variant={index === index2 ? "body1" : "subtitle2"}
              fontWeight={index === index2 ? "bold" : "normal"}
            >
              {tooltips[index2]}
            </Typography>
          ))}
        </Stack>
      ))
  );
};

export const MultiColorBar = ({ items, className }: MultiColorBarProps) => {
  const expandedItems: ExpandedBarItem[] = items.map((item) => ({
    ...item,
    percent: 0,
    tooltip: "?",
  }));
  percentItems({ items: expandedItems, isOverwrite: true });
  tooltipItems(expandedItems);
  return (
    <CustomBox
      display="flex"
      width="100%"
      height="13px"
      borderRadius="8px"
      boxShadow="0 3px 6px rgba(0, 0, 0, 0.2)"
      overflow="hidden"
      className={className}
    >
      {expandedItems.map((item, index) => (
        <Tooltip title={item.tooltip} arrow key={`${index}${item.name}`}>
          <CustomBox
            width={`${item.percent}%`}
            bgcolor={item.color}
            className={item.className}
          />
        </Tooltip>
      ))}
    </CustomBox>
  );
};

export default MultiColorBar;
