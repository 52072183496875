import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const NoPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/not-found");
  }, [navigate]);
  return <h1>404</h1>;
};

export default NoPage;
