import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useState } from "react";
import { TemplatingEditContext } from "../contexts/TemplatingEditContext";

interface AddLabelProps {
  onSuccess?: (name: string) => void;
}

export const AddLabel = ({ onSuccess }: AddLabelProps) => {
  const templating = useContext(TemplatingEditContext);
  const [name, setName] = useState("");
  if (!templating) {
    return null;
  }
  return (
    <Stack direction="row" style={{ padding: "11px 0" }}>
      <Autocomplete
        renderInput={(params) => <TextField {...params} label="Field name" />}
        options={templating.autocompleteSuggestions}
        freeSolo
        style={{ width: "10vw", maxWidth: "10rem", marginRight: "10px" }}
        inputValue={name}
        onInputChange={(_e, value) => setName(value)}
      />
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => {
          templating.addLabel(name);
          setName("");
          onSuccess?.(name);
        }}
        disabled={!name}
      >
        Add field to schema
      </Button>
    </Stack>
  );
};
