enum DataType {
  //backendType = displayed type on creation,view,templating
  int = "INT",
  str = "STR",
  float = "FLOAT",
  datetime = "DATE",
  bool = "BOOL",
}

export enum DataTypeAdvanced {
  //backendType = displayed type on view,templating
  array = "ARRAY",
  object = "OBJECT",
}

export const DataTypeAllVals: Record<
  keyof typeof DataType | keyof typeof DataTypeAdvanced,
  DataType | DataTypeAdvanced
> = {
  ...DataType,
  ...DataTypeAdvanced,
};

export type DataTypeAll = DataType | DataTypeAdvanced;
export type DataTypeBackend = keyof typeof DataTypeAllVals;

export const DataTypeToBackendArg = (
  Object.keys(DataTypeAllVals) as (keyof typeof DataTypeAllVals)[]
).reduce(
  (acc, key) => {
    return { ...acc, [DataTypeAllVals[key]]: key };
  },
  {}

  // type specified only in the end using AS TYPE to ignore TS error:
  // Type '{}' is missing the following properties from type 'Record<DataTypeAll, "object" | "int" | "str" | "float" | "datetime" | "bool" | "array">': INT, STR, FLOAT, DATE, and 3 more.ts(2740)
) as Record<DataTypeAll, DataTypeBackend>;

export default DataType;
