import { CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import ErrorAlert from "../../../components/ErrorAlert";
import JSONData from "../../../components/JSONData";
import { BackendError } from "../../../types/BackendError";
import { SnapshotPreview } from "../../../types/SnapshotPreview";
import { getSnapshotPreview } from "../LocalOperations";

interface SnapshotDataPreviewProps {
  snapshotId: string;
  isPreviewDisplayedInitially?: boolean;
  textFieldStyle?: React.CSSProperties;
}

const SnapshotDataPreview = ({
  snapshotId,
  isPreviewDisplayedInitially,
  textFieldStyle,
}: SnapshotDataPreviewProps) => {
  const qPreview = useQuery<SnapshotPreview, BackendError>(
    ["snapshotPreview", snapshotId],
    () => getSnapshotPreview(snapshotId)
  );

  if (qPreview.isLoading) {
    return <CircularProgress />;
  }
  if (qPreview.isError) {
    return <ErrorAlert value={qPreview.error} />;
  }
  if (!qPreview.isSuccess) {
    return (
      <ErrorAlert
        value={`Unexpected error for snapshot ${snapshotId} preview`}
      />
    );
  }

  return (
    <JSONData
      JSONObject={qPreview.data.json_data_preview}
      isInitiallyOpen={isPreviewDisplayedInitially}
      textFieldStyle={textFieldStyle}
    />
  );
};

export default SnapshotDataPreview;
