import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { Validations } from "../types/Validations";
import { BackendError } from "../types/BackendError";
import { getSnapshotValidations } from "../pages/SnapshotsViewSteps/LocalOperations";

export const useQueryValidations = (
  snapshotId: string | number,
  options?: Omit<
    UseQueryOptions<Validations, BackendError, Validations, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _snapshotId = String(snapshotId);
  return useQuery<Validations, BackendError>(
    ["snapshotValidations", _snapshotId],
    () => getSnapshotValidations(_snapshotId),
    options
  );
};
