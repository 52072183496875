import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useQuery } from "react-query";
import { BackendError } from "../../../types/BackendError";
import {
  ValidatorCustomCode,
  ValidatorsCustomCode,
} from "../../../types/ValidatorsCustomCode";
import createDefaultCustomCodeValidator from "../../../utils/CreateDefaultValidator";
import extractError from "../../../utils/ErrorExtract";
import { getCustomCodeValidators } from "../LocalOperations";

interface SelectValidatorCodeProps {
  templateId: string;
  idValue: string;
  onChange: (validator: ValidatorCustomCode) => void;
  error?: boolean;
  disabled?: boolean;
  autoSelectFirst?: boolean;
  required?: boolean;
}

const SelectValidatorCode = ({
  templateId,
  idValue,
  onChange: onValidatorChange,
  error,
  disabled,
  autoSelectFirst,
  required,
}: SelectValidatorCodeProps) => {
  const qCustomCodes = useQuery<ValidatorsCustomCode, BackendError>(
    ["templateCustomCodes", templateId],
    () => getCustomCodeValidators(templateId),
    {
      onSuccess: (data: ValidatorsCustomCode) => {
        if (autoSelectFirst && idValue === "" && data[0]) {
          onValidatorChange(data[0]);
        }
      },
    }
  );

  const onChange = (e: SelectChangeEvent) => {
    const matchedValidators = qCustomCodes.data?.filter(
      (validator: ValidatorCustomCode) => validator.id === e.target.value
    );
    const matched =
      matchedValidators?.[0] ?? createDefaultCustomCodeValidator();
    onValidatorChange(matched);
  };

  const loadingItems = <MenuItem value="loading">Loading ...</MenuItem>;
  const emptyItems = (
    <MenuItem value="">No custom code validators found</MenuItem>
  );
  const loadedItems =
    qCustomCodes.data && qCustomCodes.data.length > 0
      ? qCustomCodes.data.map((validator: ValidatorCustomCode) => (
          <MenuItem key={validator.id} value={validator.id}>
            {validator.id}. {validator.runtime}{" "}
            {validator.validator_custom_name
              ? `- ${validator.validator_custom_name}`
              : ""}
          </MenuItem>
        ))
      : emptyItems;

  const selectedValidatorId =
    idValue.length < 1 && qCustomCodes.isLoading ? "loading" : idValue;

  return (
    <FormControl
      required={required}
      sx={{ m: 1, minWidth: 120 }}
      error={error || qCustomCodes.isError}
    >
      <Select
        value={selectedValidatorId}
        onChange={onChange}
        disabled={disabled}
        autoWidth
      >
        {qCustomCodes.isLoading ? loadingItems : loadedItems}
      </Select>
      {error && (
        <FormHelperText>
          {qCustomCodes.isError ? extractError(qCustomCodes.error) : "Required"}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectValidatorCode;
