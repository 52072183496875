interface ColoredStatusProps {
  status?: string | null;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const getStatusClassname = (status?: string | null) => {
  if (status == null) {
    return undefined;
  }

  const lowerStatus = status.toLowerCase();
  if (
    lowerStatus.includes("error") ||
    lowerStatus.includes("fail") ||
    lowerStatus.includes("crash")
  ) {
    return "error-tag";
  }
  if (lowerStatus.includes("warning")) {
    return "warning-tag";
  }
  if (
    lowerStatus.includes("success") ||
    lowerStatus.includes("pass") ||
    lowerStatus.includes("done")
  ) {
    return "success-tag";
  } else {
    return undefined;
  }
};

const ColoredStatus = ({ status, children, style }: ColoredStatusProps) => {
  return (
    <div className={getStatusClassname(status)} style={style}>
      {children}
    </div>
  );
};

export { ColoredStatus, getStatusClassname };
