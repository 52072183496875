import extractError from "../../utils/ErrorExtract";
import FormatCardTable from "../solutionSubmission/components/FormatCardTable";
import ChallengeFixNotice from "./components/ChallengeFixNotice";
import { FrequencyEditorForChallenge } from "../ChallengesNewSteps/FrequencySelector";
import ErrorAlert from "../../components/ErrorAlert";
import ExternalLink from "../../components/ExternalLink";
import API from "../../API";
import ChallengeSolvings from "./components/ChallengeSolvings";
import ChallengeSolverDetails from "./components/ChallengeSolverDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MultiCodeRun from "./components/MultiCodeRun";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  Grid,
  Paper,
  Snackbar,
  Alert,
  IconButton,
  AlertTitle,
} from "@mui/material";
import {
  Link,
  useSearchParams,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";

import { Perm } from "../../Const";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { DeleteChallengeButton } from "./components/DeleteChallengeButton";
import { useState, useEffect, useRef, useCallback } from "react";
import { usePerms } from "../../hooks/UsePerms";
import { ChallengeDetailed } from "../../types/ChallengesDetailed";
import { SLA } from "../../types/SLA";
import { User } from "../../types/Users";
import TTabID from "../../types/ChallengeViewTabsEnum";
import { default as DashboardTab } from "../../types/DashboardTabsEnum";
import { useQueryChallengeDetails } from "../../hooks/UseQueryChallengeDetails";
import { useQueryCurrentUserDetails } from "../../hooks/UseQueryUserDetails";
import { useChallengeSLA } from "../../hooks/UseChallengeSLA";
import { useInvalidateChallengeNextAnomaly, useQueryChallengeNextAnomaly } from "./hooks/UseQueryChallengeNextAnomaly";

import {
  EditableChallengeBudget,
  EditableChallengeDescription,
  EditableChallengeName,
  EditableChallengeTargetURL,
  EditableChallengeRunTime,
} from "./components/EditableChallengeDetails";
import SolutionSubmissionExtended from "../solutionSubmission/components/SolutionSubmissionExtended";
import ChallengeEmailComposer from "./ChallengeEmailComposer";
import { formatChallengeName } from "../../utils/FormatChallengeName";
import { formatAnomalyDetectedDate } from "../../utils/FormatAnomalyDetectedDate";
import { UndeleteChallengeButton } from "./components/UndeleteChallengeButton";
import { CodeRunDetails } from "../../types/CodeRunDetails";
import { ProjectEditorForChallenge } from "../ChallengesNewSteps/ProjectSelector";
import { useQueryProjectDetails } from "../DashboardView/hooks/useQueryProjectDetails";
import { Project } from "../../types/Projects";
import { CustomTabPanel, a11yProps } from "./components/CustomTabPanel";
import { useMutation } from "react-query";
import { BackendError } from "../../types/BackendError";
import {
  patchChallengeSnoozeStatus,
  patchAnomalySnooze,
} from "./LocalOperations";
import { useInvalidateChallengeDetails } from "../../hooks/UseQueryChallengeDetails";
import SplitButtonDropdown from "../../components/SplitButtonDropdown";
import { Anomaly } from "../../types/Anomaly";

interface ChallengeDetailsInlineProps {
  challengeId: string | null;
}

interface BasicTabsProps {
  challenge: ChallengeDetailed;
}

interface UserTabsProps extends BasicTabsProps {
  userId: string;
}

interface CodeRunProps extends BasicTabsProps {
  codeRunId: string;
  codeRunUserId: string;
  code: string;
  isEdited: boolean;
  setCode: (code: string) => void;
  setIsEdited: (isEdited: boolean) => void;
  onSubmitCodeSuccess?: (codeRunDetailed: CodeRunDetails) => void;
}

const ChallengeTargetURL = ({ challenge }: BasicTabsProps) => {
  return (
    <Typography>
      {!!challenge.target_url ? (
        <ExternalLink href={challenge.target_url}>
          {challenge.target_url}
        </ExternalLink>
      ) : (
        "(no link provided)."
      )}
    </Typography>
  );
};

const ChallengeRunTimeLimit = ({ challenge }: BasicTabsProps) => {
  return (
    <Typography>
      {!!challenge.run_time_limit_s ? challenge.run_time_limit_s : "no limit"}
    </Typography>
  );
};

const TabDescription = ({
  challenge,
  SLA,
  project,
}: {
  challenge: ChallengeDetailed;
  SLA: SLA;
  project: Project;
}) => {
  const perms = usePerms();

  return (
    <Box sx={{ padding: 3 }}>
      <ChallengeFixNotice challenge={challenge} />
      <Grid item xs={12}>
        <Typography variant="h5" component="span">
          Details
        </Typography>
      </Grid>
      <Paper elevation={0} sx={{ padding: 2, marginBottom: 3, border: "none" }}>
        <Grid container sx={{ marginBottom: 2 }}>
          <Grid item xs={2}>
            <Typography variant="body1" component="span" color="textSecondary">
              Data:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" component="span">
              JSON
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: 2 }}>
          <Grid item xs={2}>
            <Typography variant="body1" component="span" color="textSecondary">
              Description:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {!!challenge.is_editable ? (
              <EditableChallengeDescription challengeId={challenge.id} />
            ) : (
              <Typography component="span">
                {challenge.data_description || "(no description provided)."}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: 2 }}>
          <Grid item xs={2}>
            <Typography variant="body1" component="span" color="textSecondary">
              Target:
            </Typography>
          </Grid>
          <Grid item xs={5}>
            {!!challenge.is_editable ? (
              <EditableChallengeName challengeId={challenge.id} />
            ) : (
              <Typography component="span">{challenge.name}</Typography>
            )}
            <Typography variant="body1" component="span">
              {!!challenge.is_editable ? (
                <EditableChallengeTargetURL
                  challengeId={challenge.id}
                  readMode={<ChallengeTargetURL challenge={challenge} />}
                />
              ) : (
                <ChallengeTargetURL challenge={challenge} />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: 2 }}>
          <Grid item xs={2}>
            <Typography variant="body1" component="span" color="textSecondary">
              Budget:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {!!challenge.is_editable ? (
              <EditableChallengeBudget challengeId={challenge.id} />
            ) : (
              <Typography component="span">
                {challenge.budget_eur_per_month}€ per month for{" "}
                {challenge.budget_length_months} month(s)
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: 2 }}>
          <Grid item xs={2}>
            <Typography variant="body1" color="textSecondary" component="span">
              Frequency:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {!!challenge.is_editable ? (
              <FrequencyEditorForChallenge challenge={challenge} />
            ) : (
              <Typography component="span">
                {challenge.frequency_name === "once"
                  ? "The requested data is created only once"
                  : `The requested data shall be updated every ${challenge.frequency_name}`}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: 2 }}>
          <Grid item xs={2}>
            <Typography variant="body1" color="textSecondary" component="span">
              Project:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {!!challenge.is_editable ? (
              <ProjectEditorForChallenge
                challenge={challenge}
                project={project}
              />
            ) : (
              <Typography component="span">
                {project.name || "(no project provided)"}
              </Typography>
            )}
          </Grid>
        </Grid>
        {!!challenge.is_editable && (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={2}>
                <Typography
                  variant="body1"
                  component="span"
                  color="textSecondary"
                >
                  Max run time (seconds):
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <EditableChallengeRunTime
                  challengeId={challenge.id}
                  readMode={<ChallengeRunTimeLimit challenge={challenge} />}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
      <Grid item xs={12}>
        <Typography variant="h5" component="span">
          Format
        </Typography>
      </Grid>
      <Paper elevation={0} sx={{ padding: 2, marginBottom: 3 }}>
        <FormatCardTable templateId={challenge.template_id} isTitled />
        {perms.has(Perm.VIEW_ANY_VALIDATOR) && (
          <Link
            to={`/challenges/${challenge.id}/format`}
            style={{
              justifyContent: "left",
              display: "flex",
              margin: "8px",
            }}
          >
            Edit format details
          </Link>
        )}
      </Paper>

      <Grid item xs={12} marginBottom={2} marginTop={2}>
        <Typography variant="h5" component="span">
          Solver
        </Typography>
      </Grid>

      <ChallengeSolverDetails challenge={challenge} sla={SLA} />
      <Grid item xs={12} textAlign={"right"}>
        {challenge.date_deleted ? (
          <UndeleteChallengeButton
            challengeId={challenge.id}
            challengeName={challenge.name}
          />
        ) : (
          <DeleteChallengeButton
            challengeId={challenge.id}
            challengeName={challenge.name}
          />
        )}
      </Grid>
    </Box>
  );
};

const TabFormat = (props: BasicTabsProps) => {
  const perms = usePerms();
  return (
    <>
      <FormatCardTable templateId={props.challenge.template_id} isTitled />
      {perms.has(Perm.VIEW_ANY_VALIDATOR) && (
        <Link
          to={`/challenges/${props.challenge.id}/format`}
          style={{
            justifyContent: "left",
            display: "flex",
            margin: "8px",
          }}
        >
          Edit format details
        </Link>
      )}
    </>
  );
};

const TabHistory = (props: BasicTabsProps) => {
  const perms = usePerms();
  return (
    <>
      {props.challenge.is_up_for_grab || perms.has(Perm.VIEW_ANY_CODE) ? (
        <ChallengeSolvings challengeId={props.challenge.id ?? ""} />
      ) : null}
      <Stack direction="row" display="flex" justifyContent="left">
        <ExternalLink href={API.genGetLastSnapshot(props.challenge.id)}>
          <Button>JSON</Button>
        </ExternalLink>
        <Button disabled>Excel</Button>
      </Stack>
    </>
  );
};

const TabMessages = ({ challenge }: BasicTabsProps) => {
  return <ChallengeEmailComposer challenge={challenge} />;
};

const TabCodeRuns = ({
  userId,
  challenge,
  sla,
}: UserTabsProps & { sla: SLA }) => {
  const perms = usePerms();

  const renderCodeRuns = () => {
    if (
      perms.has(Perm.VIEW_ANY_CODE) ||
      !challenge.is_reserved ||
      String(userId) === String(sla.solver_id)
    ) {
      return <MultiCodeRun userId="all" challengeId={challenge.id} />;
    } else {
      return <MultiCodeRun userId={userId} challengeId={challenge.id} />;
    }
  };

  return <div>{renderCodeRuns()}</div>;
};

const TabLatestCodeVersion = ({
  challenge,
  codeRunId,
  codeRunUserId,
  isEdited,
  setIsEdited,
  code,
  setCode,
  onSubmitCodeSuccess,
}: CodeRunProps) => {
  const fallbackDisplay: React.ReactNode = challenge.is_reserved ? (
    <Typography>
      No code submitted yet. Proceed to{" "}
      <Link to={`?tab=${TTabID.SubmitCode}`}>code submission</Link>.
    </Typography>
  ) : (
    <Typography>
      Code cannot be submitted to unreserved challenge.{" "}
      <Link to={`reward`}>Reserve challenge</Link>.
    </Typography>
  );
  return !!codeRunId && !!codeRunUserId ? (
    <SolutionSubmissionExtended
      challengeId={challenge.id}
      sourceRunNumber={codeRunId}
      userId={codeRunUserId}
      templateId={challenge.template_id}
      isEdited={isEdited}
      setIsEdited={setIsEdited}
      code={code}
      setCode={setCode}
      onSuccessfulCode={onSubmitCodeSuccess}
      subHeader={`For Challenge${formatChallengeName(challenge, {
        idSuffix: ":",
      })}`}
    />
  ) : !!codeRunId || !!codeRunUserId ? (
    <Typography>
      Error in the latest code version tab{" "}
      {`${challenge.id}_${codeRunId}_${codeRunUserId}`}
    </Typography>
  ) : (
    fallbackDisplay
  );
};

const TabSubmitCode = ({
  challenge,
  codeRunId,
  codeRunUserId,
  isEdited,
  setIsEdited,
  code,
  setCode,
  onSubmitCodeSuccess,
}: CodeRunProps) => {
  return (
    <SolutionSubmissionExtended
      challengeId={challenge.id}
      sourceRunNumber={codeRunId}
      userId={codeRunUserId}
      templateId={challenge.template_id}
      isEdited={isEdited}
      setIsEdited={setIsEdited}
      code={code}
      setCode={setCode}
      onSuccessfulCode={onSubmitCodeSuccess}
      subHeader={`For Challenge${formatChallengeName(challenge, {
        idSuffix: ":",
      })}`}
    />
  );
};

const TAB_LABELS: Record<TTabID, string> = {
  [TTabID.ChallengeDescription]: "Challenge Description",
  [TTabID.DataFormat]: "Data Format",
  [TTabID.History]: "History",
  [TTabID.CodeRuns]: "Code Runs",
  [TTabID.LatestCodeVersion]: "Latest Code Version",
  [TTabID.Messages]: "Messages",
  [TTabID.SubmitCode]: "Submit Code",
};

const BasicTabs = ({
  challenge,
  SLA,
  user,
  project,
  refetchSLA,
}: {
  challenge: ChallengeDetailed;
  SLA: SLA;
  user: User;
  project: Project;
  refetchSLA: () => void;
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(TTabID.ChallengeDescription);
  const [searchParams, setSearchParams] = useSearchParams();
  const submitHandler = useCallback(
    (codeRunDetailed: CodeRunDetails) => {
      setSearchParams(
        (prev) => {
          if (prev.has("last_run_id")) {
            prev.set("last_run_id", codeRunDetailed.challenge_run_number);
            prev.set("last_run_user_id", codeRunDetailed.user_id);
          } else {
            prev.set("userId", codeRunDetailed.user_id);
            prev.set("runNumber", codeRunDetailed.challenge_run_number);
          }
          return prev;
        },
        { replace: true }
      );
      refetchSLA();
    },
    [setSearchParams, refetchSLA]
  );

  const perms = usePerms();
  const userId = user.id || "";
  const lastRunId = searchParams.get("last_run_id") ?? SLA.last_run_id ?? "";
  const lastRunUserId =
    searchParams.get("last_run_user_id") ?? SLA.last_run_user_id ?? "";
  const [isEdited, setIsEdited] = useState(false);
  const tab = searchParams.get("tab") as TTabID;
  const presentCodeRunNumber = searchParams.get("runNumber") ?? "";
  const presentCodeRunUserId = searchParams.get("userId") ?? userId;
  const confirmationFn = useRef<() => void>(() => {});
  const [leavingPage, setLeavingPage] = useState(false);
  const [code, setCode] = useState("");
  const location = useLocation();
  const isModerator = perms.has(Perm.VIEW_ANY_CHALLENGE);
  const isSnoozed = challenge.snoozed_until !== null;
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const invalidateChallenge = useInvalidateChallengeDetails(challenge.id);
  const qChallengeNextAnomaly = useQueryChallengeNextAnomaly(challenge.id);
  const anomaly = qChallengeNextAnomaly.data || ({} as Anomaly)

  const invalidateChallengeNextAnomaly = useInvalidateChallengeNextAnomaly(challenge.id);

  // const isViewedBySolver = String(user.email) === String(SLA.solver);

  const TABS: TTabID[] = [
    TTabID.ChallengeDescription,
    TTabID.DataFormat,
    TTabID.History,
    TTabID.CodeRuns,
    TTabID.LatestCodeVersion,
    ...(perms.has(Perm.REVIEW_ANY_CODE) ? [TTabID.Messages] : []),
    TTabID.SubmitCode,
  ];

  useEffect(() => {
    if (tab && TAB_LABELS[tab]) {
      setValue(tab);
    } else if (!tab) {
      // old format - no tab in search params? default to Challenge Description
      setValue(TTabID.ChallengeDescription);
      searchParams.set("tab", TTabID.ChallengeDescription);
      navigate({ search: searchParams.toString() });
    } else {
      navigate("/not_found"); // Navigate to not_found page if there is a typo
    }
  }, [searchParams, navigate, tab]);

  //Prevent navigation on browser back and browser forward buttons
  const handlePopState = useCallback(
    (event: PopStateEvent) => {
      if (isEdited && code.length > 0) {
        const confirmation = window.confirm(
          "You have unsaved changes. Are you sure you want to leave?"
        );
        if (confirmation) {
          confirmationFn.current = () => {
            navigate(window.location.href);
          };
          setIsEdited(false);
        } else {
          window.history.pushState(null, document.title, window.location.href);
          navigate(location);
        }
      } else {
        window.history.pushState(
          document.location,
          document.title,
          window.location.search
        );
      }
    },
    [isEdited, code, navigate, location]
  );

  //Prevent navigation on page reload or navigate to another page, in different origin
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (isEdited && code.length > 0) {
        event.preventDefault();
      }
    },
    [isEdited, code]
  );

  //Prevent navigation when user clicks on navlinks (<Link>)
  const handleClick = useCallback(
    (event: MouseEvent) => {
      const target = (event.target as Element).closest(
        "a"
      ) as HTMLAnchorElement;
      const isExternal = target.origin !== window.location.origin;

      // Ignore external links (example: "http://blog.dataplatform.lt")
      if (!target.getAttribute("href")?.startsWith("/")) {
        return;
      }

      if (isEdited && code.length > 0) {
        event.preventDefault();

        confirmationFn.current = () => {
          if (isExternal) {
            window.location.href = target.href;
          } else {
            navigate(target.pathname + target.search);
          }
        };

        setLeavingPage(true);
      }
    },
    [isEdited, code, navigate]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    document.querySelectorAll("a").forEach((link) => {
      link.addEventListener("click", handleClick);
    });
    window.addEventListener("popstate", handlePopState);

    if (leavingPage) {
      const confirmation = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );

      if (confirmation && confirmationFn.current) {
        confirmationFn.current();
        setIsEdited(false);
      }
      setLeavingPage(false);
    }

    return () => {
      document.querySelectorAll("a").forEach((link) => {
        link.removeEventListener("click", handleClick);
      });
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEdited, leavingPage, handleBeforeUnload, handleClick, handlePopState]);

  const handleChange = (event: React.SyntheticEvent, newValue: TTabID) => {
    if (
      isEdited &&
      code.length > 0 &&
      (tab === TTabID.SubmitCode || tab === TTabID.LatestCodeVersion)
    ) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Are you sure you want to leave this page?"
      );
      if (!confirmLeave) {
        return;
      }
      setIsEdited(false);
    }

    setCode("");

    if (Object.values(TTabID).includes(newValue)) {
      setValue(newValue);

      const params: Record<string, string> = { tab: newValue };

      if (newValue === TTabID.LatestCodeVersion) {
        params.last_run_id = lastRunId;
        params.last_run_user_id = lastRunUserId;
      } else if (
        newValue === TTabID.SubmitCode &&
        presentCodeRunNumber &&
        presentCodeRunUserId &&
        presentCodeRunUserId !== userId
      ) {
        params.runNumber = presentCodeRunNumber;
        params.userId = presentCodeRunUserId;
      }

      navigate({
        search: `?${createSearchParams(params)}`,
      });
    } else {
      navigate("/not-found");
    }
  };

  const handleSuccessSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  const updateChallengeSnooze = useMutation<
    void,
    BackendError,
    { checked: boolean; challenge_id: string }
  >(
    ({ checked, challenge_id }) =>
      patchChallengeSnoozeStatus(checked, challenge_id),
    {
      onSuccess: () => {
        setIsSuccessSnackbarOpen(true);
        setSnackbarMessage("Challenge snooze status updated successfully!");
        invalidateChallenge();
      },
    }
  );

  const snoozeAnomaly = useMutation<
    void,
    BackendError,
    { challenge_id: string; anomaly_id: string; anomaly_type: string; last_snapshot_id: string; penultimate_snapshot_id: string; snooze_for_hours: number }
  >(
    ({ challenge_id, anomaly_id, anomaly_type, last_snapshot_id, penultimate_snapshot_id, snooze_for_hours }) =>
      patchAnomalySnooze(challenge_id, anomaly_id, {anomaly_type, last_snapshot_id, penultimate_snapshot_id, snooze_for_hours}),
    {
      onSuccess: () => {
        setIsSuccessSnackbarOpen(true);
        setSnackbarMessage("Anomaly snoozed successfully");
        invalidateChallengeNextAnomaly();
      },
    }
  );

  const handleAnomalyOptionSelect = (snooze_for_hours: number) => {
    snoozeAnomaly.mutate({
      challenge_id: challenge.id,
      anomaly_id: anomaly.anomaly_id,
      anomaly_type: anomaly.anomaly_type,
      last_snapshot_id: anomaly?.snapshot_id,
      penultimate_snapshot_id: anomaly?.penultimate_snapshot_id,
      snooze_for_hours: snooze_for_hours,
    });
  };

  const anomalySnoozeDropdownItems = [
    { node: "Snooze same anomaly for 1 hour", hours: 1 },
    { node: "Snooze same anomaly for 5 hours", hours: 5 },
    { node: "Snooze same anomaly for 1 day", hours: 24 },
    { node: "Snooze same anomaly for 1 week", hours: 168 },
  ];

  const handleSnoozeButtonClick = (isSnoozed: boolean) => {
    const checked = !isSnoozed;

    if (!updateChallengeSnooze.isLoading) {
      updateChallengeSnooze.mutate({ checked, challenge_id: challenge.id });
    }
  };


  return (
    <>
      {isModerator && anomaly?.anomaly_id && (
        <Box marginBottom={3}>
          <Alert
            icon={false}
            severity="error"
            sx={{
              flexDirection: "column",
            }}
          >
            <AlertTitle variant="h6">
              Anomaly "{anomaly.anomaly_type}" detected on{" "}
              {formatAnomalyDetectedDate(anomaly.date_created)}. Please
              investigate the snapshot differences at:
            </AlertTitle>

            <Box display={"flex"} flexDirection={"column"} marginTop={1}>
              <Box component="span">
                <Typography
                  variant="h6"
                  component={Link}
                  reloadDocument
                  to={`/challenges/${challenge.id}/?tab=${TTabID.SubmitCode}&runNumber=${anomaly.penultimate_snapshot_code_run_id}&userId=${anomaly.penultimate_snapshot_user_id}`}
                >
                  Anomaly triggering code run 1
                </Typography>
              </Box>
              <Box component="span">
                <Typography
                  variant="h6"
                  component={Link}
                  reloadDocument
                  to={`/challenges/${challenge.id}/?tab=${TTabID.SubmitCode}&runNumber=${anomaly.snapshot_code_run_id}&userId=${anomaly.snapshot_user_id}`}
                >
                  Anomaly triggering code run 2
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop={2}>
              <SplitButtonDropdown
                primaryButton={{
                  node: "Delete anomaly",
                  onClick: () => handleAnomalyOptionSelect(0),
                }}
                dropdownItems={anomalySnoozeDropdownItems.map((item) => ({
                  node: item.node,
                  onClick: () => handleAnomalyOptionSelect(item.hours),
                }))}
                variant="contained"
              />
            </Box>
          </Alert>
        </Box>
      )}
      <Box display={"flex"} alignItems={"center"} marginBottom={3}>
        <IconButton
          component={Link}
          to={`/projects/${project.id}/?tab=${DashboardTab.Dashboard}`}
          style={{ padding: 0, marginRight: "20px" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" flexGrow={1}>
          Back to project "{project.name}" dashboard
        </Typography>
        {!challenge.is_reserved && (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`reward`}
          >
            Reserve challenge
          </Button>
        )}
        {isModerator && (
          <Button
            variant="contained"
            color={challenge.snoozed_until ? "success" : "error"}
            sx={{ marginLeft: "10px" }}
            onClick={() => handleSnoozeButtonClick(isSnoozed)}
            disabled={updateChallengeSnooze.isLoading}
          >
            {challenge.snoozed_until
              ? "Unsnooze challenge"
              : "Snooze challenge"}
          </Button>
        )}
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {TABS.map((tabId) => (
              <Tab
                label={TAB_LABELS[tabId]}
                key={tabId}
                value={tabId}
                {...a11yProps(tabId)}
              />
            ))}
          </Tabs>
        </Box>
        {TABS.map((tab, index) => (
          <CustomTabPanel key={index} value={value} id={tab}>
            {tab === TTabID.ChallengeDescription && (
              <TabDescription
                challenge={challenge}
                SLA={SLA}
                project={project}
              />
            )}
            {tab === TTabID.DataFormat && <TabFormat challenge={challenge} />}
            {tab === TTabID.History && <TabHistory challenge={challenge} />}
            {tab === TTabID.CodeRuns && (
              <TabCodeRuns userId={userId} challenge={challenge} sla={SLA} />
            )}
            {tab === TTabID.LatestCodeVersion && (
              <TabLatestCodeVersion
                challenge={challenge}
                codeRunId={lastRunId}
                codeRunUserId={lastRunUserId}
                isEdited={isEdited}
                setIsEdited={setIsEdited}
                code={code}
                setCode={setCode}
                onSubmitCodeSuccess={submitHandler}
              />
            )}
            {tab === TTabID.Messages && <TabMessages challenge={challenge} />}
            {tab === TTabID.SubmitCode && (
              <TabSubmitCode
                challenge={challenge}
                codeRunId={presentCodeRunNumber}
                codeRunUserId={presentCodeRunUserId}
                isEdited={isEdited}
                setIsEdited={setIsEdited}
                code={code}
                setCode={setCode}
                onSubmitCodeSuccess={submitHandler}
              />
            )}
          </CustomTabPanel>
        ))}
        {!TABS.includes(tab) ? (
          <Typography textAlign="center">Tab not allowed</Typography>
        ) : null}
        <Snackbar
          open={isSuccessSnackbarOpen}
          autoHideDuration={3000}
          onClose={handleSuccessSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleSuccessSnackbarClose} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

const ChallengeDetailsInline = (props: ChallengeDetailsInlineProps) => {
  const location = useLocation();
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const qDetails = useQueryChallengeDetails(props.challengeId ?? "no-id");
  const qUser = useQueryCurrentUserDetails();
  const { data: qSLA, refetch: refetchSLA } = useChallengeSLA(
    props.challengeId ?? "no-id"
  );
  const qProject = useQueryProjectDetails(
    String(qDetails.data?.project_id ?? "no-id"),
    {
      enabled: !!qDetails.data?.project_id,
    }
  );
  const errorMessage = extractError(qDetails.error);

  useEffect(() => {
    if (location.state?.openSnackbar) {
      setIsSuccessSnackbarOpen(true);
    }
  }, [location.state?.openSnackbar]);

  const handleSuccessSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  if (qDetails.isLoading || qProject.isLoading) {
    return (
      <>
        <span>Loading details for Challenge#{props.challengeId}</span>
        <CircularProgress />
      </>
    );
  }

  if (!qDetails.isSuccess) {
    return <ErrorAlert value={errorMessage} />;
  }

  const user = qUser.data || ({} as User);
  const SLA = qSLA || ({} as SLA);

  const challenge = qDetails.data;
  const project = qProject.data || ({} as Project);
  return (
    <Box sx={{ marginTop: "60px" }}>
      <Snackbar
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSuccessSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Challenge created successfully!
        </Alert>
      </Snackbar>
      <BasicTabs
        challenge={challenge}
        SLA={SLA}
        user={user}
        project={project}
        refetchSLA={refetchSLA}
      />
    </Box>
  );
};

export default ChallengeDetailsInline;
