import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from "@mui/material";
import { useQuery } from "react-query";
import {
  AttributeUnpacked,
  SnapshotMinusTemplateUnpacked,
} from "../../../types/SnapshotMinusTemplate";
import { BackendError } from "../../../types/BackendError";
import { getSnapshotTemplatingUnpacked } from "../LocalOperations";
import ErrorAlert from "../../../components/ErrorAlert";
import { provideTheme } from "../../../utils/ProvideTheme";
import { SchemaLossyShow } from "./SchemaLossyShow";
import { AddLabel } from "./AddLabel";
import { TemplatingSaver } from "./TemplatingSaver";
import { TemplatingBulkActions } from "./TemplatingBulkActions";
import { TemplatingLabelsTable } from "./TemplatingLabelsTable";
import { TemplatingEditContext } from "../contexts/TemplatingEditContext";
import { useTemplating } from "../hooks/UseTemplating";
import { useEffect } from "react";
import { TextedCircularProgress } from "../../../components/TextedCircularProgress";
import { TemplatingImpact } from "./TemplatingImpact";

export const genSnapshotTemplatingUnpackedKey = (
  snapshotId?: string,
  templateId?: string
) => ["snapshotTemplatingUnpacked", String(snapshotId), String(templateId)];

type SnapshotTemplatingDialogProps = {
  onClose: () => void;
  open: boolean;
  snapshotId: string;
  challengeId: string;
  templateId: string;
};

export const SnapshotTemplatingDialog = ({
  onClose,
  open,
  snapshotId,
  challengeId,
  templateId,
}: SnapshotTemplatingDialogProps) => {
  const qTemplatingUnpacked = useQuery<
    SnapshotMinusTemplateUnpacked,
    BackendError
  >(
    genSnapshotTemplatingUnpackedKey(snapshotId, templateId),
    () => getSnapshotTemplatingUnpacked(snapshotId),
    {
      enabled: open,
      staleTime: 60 * 1000 * 5, // 5 m
    }
  );
  const templatingHandler = useTemplating({
    snapshotId,
    challengeId,
    templateId,
  });
  const templatingPendingHandler = useTemplating({
    snapshotId,
    challengeId,
    templateId,
  });

  const templating = qTemplatingUnpacked.data;
  const resetTo = templatingHandler.resetTo; // to reduce amounts of calls in useEffect
  const pendingResetTo = templatingPendingHandler.resetTo; // to reduce amounts of calls in useEffect

  useEffect(() => {
    if (templating) {
      const existsInSchema = (attr: AttributeUnpacked) => !attr.is_extra;
      const doesntExistInSchema = (attr: AttributeUnpacked) => attr.is_extra;
      resetTo(templating.attributes, {
        isOverwriteLabels: false,
        onSuccess: (newOriginals) => {
          pendingResetTo(newOriginals, {
            filter: doesntExistInSchema,
            isOverwriteLabels: true,
          });
        },
        filter: existsInSchema,
      });
    }
  }, [templating, resetTo, pendingResetTo]);

  const _onClose = () => {
    onClose();
    setTimeout(
      // avoid flickering close
      () =>
        resetTo([], {
          isOverwriteLabels: true,
          onSuccess: () => pendingResetTo([]),
        }),
      300
    );
  };

  return (
    <Dialog onClose={_onClose} open={open} fullWidth maxWidth={"md"}>
      <TemplatingEditContext.Provider value={templatingHandler}>
        <DialogTitle>Template tuning</DialogTitle>
        <DialogContent>
          {qTemplatingUnpacked.isLoading && (
            <TextedCircularProgress
              textPre={"Loading might take up to one minute ..."}
            />
          )}
          {qTemplatingUnpacked.isError && (
            <ErrorAlert value={qTemplatingUnpacked.error} />
          )}
          {templating &&
            (templating.error ? (
              <ErrorAlert value={templating.error} />
            ) : (
              <>
                <ThemeProvider theme={provideTheme}>
                  <TemplatingLabelsTable
                    warning={templating.warning}
                    headStyle={{ top: 35 }}
                    title="SCHEMA TO SAVE"
                    titleTooltip="These fields are in latest version to be saved"
                    actionDisplay="remove"
                    isTitleFullWidth
                    onActionSuccess={(attr) => {
                      // cross opposite action
                      if (
                        templatingPendingHandler.labelsOriginalMap.get(
                          attr.name
                        )
                      ) {
                        // adding to pending only generated labels, no user custom defined labels
                        templatingPendingHandler.addLabel(attr.name);
                      }
                    }}
                    isBottomPadded
                  />
                </ThemeProvider>
                <AddLabel onSuccess={templatingPendingHandler.removeLabel} />
                <TemplatingEditContext.Provider
                  value={templatingPendingHandler}
                >
                  <ThemeProvider theme={provideTheme}>
                    <TemplatingLabelsTable
                      headStyle={{ top: 35 }}
                      title={"OTHER FIELDS"}
                      titleTooltip="These fields will be not saved in schema"
                      editDisabled
                      isTitleFullWidth
                      actionDisplay="add"
                      onActionSuccess={(attr) => {
                        // cross opposite action
                        templatingHandler.addLabel(attr.name);
                      }}
                    />
                  </ThemeProvider>
                </TemplatingEditContext.Provider>
                <SchemaLossyShow
                  snapshotId={snapshotId}
                  templateId={templateId}
                />
                <TemplatingSaver
                  style={{ marginTop: "15px" }}
                  onSaveAsSuccess={() => {
                    _onClose();
                    alert("Template forked");
                  }}
                  onSaveOverwriteSuccess={() => {
                    _onClose();
                    alert("Schema overwritten");
                  }}
                />
                <TemplatingImpact />
              </>
            ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled>
            Save (not yet)
          </Button>
          <TemplatingBulkActions
            isDisabled={!templating}
            boxStyle={{ marginRight: "auto" }}
          />
          <Button onClick={_onClose} variant="contained">
            Discard changes and close
          </Button>
        </DialogActions>
      </TemplatingEditContext.Provider>
    </Dialog>
  );
};
