import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "react-query";
import { ProjectUser } from "../../../types/ProjectUsers";
import ProjectRoleEnum from "../../../types/ProjectRoleEnum";
import { postUpdateUserProjectRole } from "../LocalOperations";
import { BackendError } from "../../../types/BackendError";
import { useInvalidateProjectUsers } from "../../../hooks/UseQueryProjectUsers";
import ErrorAlert from "../../../components/ErrorAlert";

interface UpdateUserProjectRoleModalProps {
  open: boolean;
  onClose: () => void;
  user: ProjectUser;
  projectId: string;
  onUserProjectRoleUpdateSuccess?: () => void;
}

const UpdateUserProjectRoleModal: React.FC<UpdateUserProjectRoleModalProps> = ({
  open,
  onClose,
  user,
  projectId,
  onUserProjectRoleUpdateSuccess,
}) => {
  const [selectedRole, setSelectedRole] = useState<ProjectRoleEnum>(
    user.project_role as ProjectRoleEnum
  );

  useEffect(() => {
    setSelectedRole(user.project_role as ProjectRoleEnum);
  }, [user.project_role]);

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value as ProjectRoleEnum);
  };

  const handleClose = () => {
    onClose();
    setSelectedRole(user.project_role as ProjectRoleEnum);
  };

  const updateUserProjectRole = useMutation<
    void,
    BackendError,
    { projectId: string; userId: string; role: ProjectRoleEnum }
  >(postUpdateUserProjectRole);

  const invalidateProjectUsers = useInvalidateProjectUsers(projectId);

  const onUpdateUserProjectRole = () => {
    if (selectedRole && selectedRole !== user.project_role) {
      updateUserProjectRole.mutate(
        { projectId, userId: String(user.user_id), role: selectedRole },
        {
          onSuccess: () => {
            if (onUserProjectRoleUpdateSuccess)
              onUserProjectRoleUpdateSuccess();
            invalidateProjectUsers();
            handleClose();
          },
        }
      );
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" component="h2">
            Currently, {user.shown_name ?? user.email} holds the role of{" "}
            {user.project_role} in this project.
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <RadioGroup value={selectedRole} onChange={handleRoleChange}>
          <FormControlLabel
            value={ProjectRoleEnum.Contributor}
            control={<Radio />}
            label="Contributor"
          />
          <FormControlLabel
            value={ProjectRoleEnum.Moderator}
            control={<Radio />}
            label="Moderator"
          />
        </RadioGroup>
        {updateUserProjectRole.isError && (
          <ErrorAlert value={updateUserProjectRole.error} />
        )}
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disabled={
              updateUserProjectRole.isLoading ||
              selectedRole === user.project_role
            }
            onClick={onUpdateUserProjectRole}
          >
            {updateUserProjectRole.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Update user's role"
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateUserProjectRoleModal;
