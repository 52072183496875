import API from "../../API";
import axios from "axios";
import { SnapshotHead } from "../../types/SnapshotHead";
import { Validations } from "../../types/Validations";
import { SnapshotPreview } from "../../types/SnapshotPreview";
import { SnapshotExtra } from "../../types/SnapshotExtra";

export const getSnapshotHead = async (snapshotId: string) => {
  const { data } = await axios.get<SnapshotHead>(
    API.genGetSnapshotHead(snapshotId)
  );
  return data;
};

export const getSnapshotValidations = async (snapshotId: string) => {
  const { data } = await axios.get<Validations>(
    API.genGetSnapshotValidations(snapshotId)
  );
  return data;
};

export const getSnapshotPreview = async (snapshotId: string) => {
  const { data } = await axios.get<SnapshotPreview>(
    API.genGetSnapshotPreview(snapshotId)
  );
  return data;
};

export const getSnapshotExtra = async (snapshotId: string) => {
  const { data } = await axios.get<SnapshotExtra>(
    API.genGetSnapshotExtra(snapshotId)
  );
  return data;
};
