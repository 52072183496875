import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

interface CodeRunTableProps {
  isUserEmailShown?: boolean;
  children?: React.ReactNode;
}

const CodeRunTable = ({
  isUserEmailShown = false,
  children,
}: CodeRunTableProps) => {
  return (
    <TableContainer component={Paper} style={{ marginBottom: "40px" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell aria-label="Expand body" />
            <TableCell>Status</TableCell>
            <TableCell align="right">Run at datetime</TableCell>
            <TableCell align="right">Snapshot validation status</TableCell>
            {isUserEmailShown && (
              <TableCell align="right">User email</TableCell>
            )}
            <TableCell align="right" width="20%">
              Execution length (s)
            </TableCell>
            <TableCell aria-label="Contextual menu column" />
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default CodeRunTable;
