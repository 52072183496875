interface ExternalLinkProps {
  style?: React.CSSProperties;
  href: string;
  children: React.ReactNode;
}

const ExternalLink = ({ style, href, children }: ExternalLinkProps) => {
  const url = href.includes(":")
    ? href // like http://otodom.pl, mailto:hell@o.there, so no changes
    : `//${href}`; /* like otodom.pl, so to avoid relative.com/otodom.pl, we replace it with //otodom.pl
                    browser will auto-parse //otodom.pl into https://otodom.pl     */

  return (
    <a href={url} target="_blank" rel="noreferrer" style={style}>
      {children}
    </a>
  );
};

export default ExternalLink;
