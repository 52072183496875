import { DataTypeBackend } from "../types/EnumDataType";
import { Constraints, DataLabelDetailed } from "../types/DataLabelsDetailed";
import DataTypeConfigExpanded from "./DataTypeConfigExpanded";

interface DataTypeConfigProps {
  dataLabelDetailed: DataLabelDetailed;
  onOptionalChange?: (newValue: boolean) => void;
  onNullableChange?: (newValue: boolean) => void;
  onDataTypeChange?: (newValue: DataTypeBackend) => void;
  disabled?: boolean;
  constraints?: Constraints;
}

const DataTypeConfig = (props: DataTypeConfigProps) => {
  return (
    <DataTypeConfigExpanded
      label={props.dataLabelDetailed.label}
      dataType={props.dataLabelDetailed.data_type}
      onDataTypeChange={props.onDataTypeChange}
      optional={props.dataLabelDetailed.optional}
      nullable={props.dataLabelDetailed.nullable}
      onOptionalChange={props.onOptionalChange}
      onNullableChange={props.onNullableChange}
      disabled={props.disabled}
      wrapInRow
      constraints={props.constraints}
    />
  );
};

export default DataTypeConfig;
