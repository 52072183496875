import { Runtime } from "../types/Runtimes";

const createDefaultRuntime = (): Runtime => {
  return {
    id: "",
    full_name: "",
    language: "",
    version: "",
  };
};

export default createDefaultRuntime;
