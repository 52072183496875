import SelectUser from "./SelectUser";
import { useUserState } from "../hooks/UserStateProvider";
import { User } from "../types/Users";
import { useMutation } from "react-query";
import { UseredTokenPair } from "../types/TokenPair";
import { BackendError } from "../types/BackendError";
import { postImpersonate } from "../pages/maintenance/LocalOperations";
import { deleteTokenPair, setTokenPair } from "../utils/TokenManagement";
import createDefaultUser from "../utils/CreateDefaultUser";
import { CircularProgress } from "@mui/material";
import ErrorAlert from "./ErrorAlert";
import { useEffect } from "react";
import { setStorageUser } from "../utils/LoadUser";

const invalidDefaultUser = createDefaultUser();

const ChangeUser = () => {
  const [user, setUser] = useUserState();
  const changeIntoUser = (_user: User) => {
    if (_user.id === invalidDefaultUser.id) {
      deleteTokenPair();
    }
    setUser(_user);
    setStorageUser(_user);
  };

  const qImpersonate = useMutation<
    UseredTokenPair,
    BackendError,
    { from_email: string; to_email: string }
  >(
    ["impersonateFrom", user.id],
    (args) => postImpersonate(args.from_email, args.to_email),
    {
      onSuccess: (useredTokenPair: UseredTokenPair) => {
        setTokenPair(useredTokenPair);
        changeIntoUser({
          email: useredTokenPair.email,
          id: useredTokenPair.user_id,
        });
      },
    }
  );

  const onChange = (chosenUser?: User): void => {
    if (chosenUser === undefined) {
      return;
    }

    if (chosenUser.id === invalidDefaultUser.id) {
      // mock up for logout
      changeIntoUser(chosenUser);
    } else {
      qImpersonate.mutate({
        from_email: user.email,
        to_email: chosenUser.email,
      });
    }
  };

  useEffect(() => {
    // using useEffect hook to auto-remove error msg after successful login / logout
    // at other cases error msg is removed when user clicks close icon X
    if (
      qImpersonate.isError &&
      qImpersonate.variables?.from_email !== user.email
    ) {
      qImpersonate.reset();
    }
  }, [qImpersonate, user.email]);

  return (
    <>
      <SelectUser onChangeUser={onChange} prependDefaultUser userId={user.id} />
      {qImpersonate.isLoading ? (
        <CircularProgress />
      ) : qImpersonate.isError ? (
        <ErrorAlert
          prefix={""}
          value={qImpersonate.error}
          onClose={() => qImpersonate.reset()}
        />
      ) : undefined}
    </>
  );
};

export default ChangeUser;
