import Editor from "@monaco-editor/react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import SelectRuntime from "../../ChallengeViewSteps/components/SelectRuntime";
import { useState, useEffect, useContext } from "react";
import { Runtime } from "../../../types/Runtimes";
import useOnSubmitCode from "../../ChallengeViewSteps/hooks/UseOnSubmitCode";
import ErrorAlert from "../../../components/ErrorAlert";
import { CodeRunDetails } from "../../../types/CodeRunDetails";
import { useQuery } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { queryClient } from "../../../utils/QueryClient";
import { getCodeRunDetails } from "../../ChallengeViewSteps/LocalOperations";
import { postChallengeSendEmail } from "../../ChallengeViewSteps/LocalOperations";
import { GenerateCode } from "./GenerateCode";
import { LastCodeRefContext } from "../contexts/LastCodeRefContext";

/** wrapping queryClient, so we could trigger onSuccess even though we retrieved cached data */
const _getRunDetails = async (
  challengeId: string,
  runNumber: string,
  userId: string
) => {
  const data = await queryClient.fetchQuery<CodeRunDetails, BackendError>(
    ["codeRunDetails", String(challengeId), String(runNumber), String(userId)],
    () => getCodeRunDetails(challengeId, runNumber, userId),
    { staleTime: 30 * 60 * 1000 /* 30 min */ }
  );

  return data;
};

interface SolutionCodeEditorProps {
  upperWrapperClassName?: string;
  upperWrapperStyle?: React.CSSProperties;
  lowerWrapperClassName?: string;
  lowerWrapperStyle?: React.CSSProperties;
  disabled?: boolean;
  challengeId: string;
  sourceRunNumber?: string;
  userId: string;
  onSuccess?: (codeRunDetails: CodeRunDetails) => void;
  buttonTextOnSuccess?: string;
  runtime: Runtime;
  code: string;
  onRuntimeChange: (runtime: Runtime) => void;
  onCodeChange: (code: string) => void;
  isEdited: boolean;
  setIsEdited: (edited: boolean) => void;
}

const SolutionCodeEditor = ({
  upperWrapperClassName = "loose-column",
  upperWrapperStyle,
  lowerWrapperClassName,
  lowerWrapperStyle,
  disabled,
  challengeId,
  sourceRunNumber,
  userId,
  onSuccess,
  buttonTextOnSuccess = "Submitted, see side panel",
  runtime,
  onRuntimeChange,
  code,
  onCodeChange,
  isEdited,
  setIsEdited,
}: SolutionCodeEditorProps) => {
  const [isSourcing, setIsSourcing] = useState(!!sourceRunNumber);
  const [isCodeLoaded, setIsCodeLoaded] = useState(!sourceRunNumber);
  const lastCodeRef = useContext(LastCodeRefContext);
  const submission = useOnSubmitCode(challengeId, userId, {
    onSuccess: (codeRunDetails) => {
      setIsEdited(false);
      onSuccess?.(codeRunDetails); // if parent is listening for success, send success

      // Send email to moderator to notify about the code submission
      const code = codeRunDetails.code;
      const challenge_id = codeRunDetails.challenge_id;
      lastCodeRef?.setCodeRun(
        codeRunDetails.user_id,
        codeRunDetails.challenge_run_number
      );
      lastCodeRef?.setHealth({ isCodeCrashed: false, isCodeRunning: false });
      lastCodeRef?.setSnapValidation();
      if (code && challenge_id) {
        // Send email to user about the review
        return postChallengeSendEmail(
          challenge_id,
          "vilviltime@gmail.com",
          code.replaceAll("\n", "<br>")
        );
      }
    },
  });

  const { data: codeRun, isSuccess } = useQuery<CodeRunDetails, BackendError>(
    ["_codeRunDetails", challengeId, sourceRunNumber, userId],
    () => _getRunDetails(challengeId, sourceRunNumber ?? "", userId),
    {
      enabled: isSourcing,
      retry: 7,
    }
  );

  useEffect(() => {
    if (isSuccess && codeRun && isSourcing) {
      setIsSourcing(false);
      onRuntimeChange({
        language: codeRun.runtime,
        version: codeRun.runtime_version,
        id: codeRun.runtime_id,
        full_name: `${codeRun.runtime} ${codeRun.runtime_version}`,
      });
      setIsCodeLoaded(true);

      // If code is not edited and there is no code in editor, load the initial code
      if (!isEdited && !code) {
        onCodeChange(codeRun.code);

        lastCodeRef?.setCodeRun(codeRun.user_id, codeRun.challenge_run_number);
      }
    }
  }, [
    isSuccess,
    isEdited,
    codeRun,
    onCodeChange,
    onRuntimeChange,
    code,
    isSourcing,
    lastCodeRef,
  ]);

  return (
    <>
      <div
        className={upperWrapperClassName}
        style={{
          ...upperWrapperStyle,
        }}
      >
        <GenerateCode
          isCodeEdited={isEdited}
          onCodeChange={onCodeChange}
          challengeId={challengeId}
        />
        <Editor
          language={runtime.language || undefined}
          value={code}
          onChange={(newCode) => {
            if (isCodeLoaded) {
              onCodeChange(newCode ?? "");
              setIsEdited(true);
            }
          }}
          options={{ readOnly: disabled }}
          height="400px"
          className="code-editor"
        />
      </div>
      <Stack
        direction="row"
        className={lowerWrapperClassName}
        justifyContent="left"
        style={{ width: "100%" }}
      >
        {!isEdited && code && (
          <Typography variant="body1" color="red">
            No changes have been made to this solution
          </Typography>
        )}
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="right"
          className={lowerWrapperClassName}
          style={{ flex: 1, width: "100%", ...lowerWrapperStyle }}
        >
          {submission.isError && (
            <ErrorAlert value={submission.error} isClosable />
          )}
          <SelectRuntime
            idValue={runtime.id}
            onChange={(runtime) => {
              onRuntimeChange(runtime);
              if (code) {
                setIsEdited(true);
              }
            }}
            autoSelectFirst
            disabled={disabled || submission.isLoading}
          />
          <Button
            variant="contained"
            color="success"
            style={{
              height: "56px",
              marginTop: "8px",
              width: "150px",
            }}
            disabled={disabled || submission.isLoading || !code}
            onClick={() =>
              submission.onSubmitCode({ code, runtime_id: runtime.id })
            }
            endIcon={submission.isLoading ? <CircularProgress /> : undefined}
          >
            {submission.isSuccess && !isEdited ? buttonTextOnSuccess : "Submit"}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default SolutionCodeEditor;
