import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { Users } from "../types/Users";
import { BackendError } from "../types/BackendError";
import { getUsers } from "../pages/UserSteps/LocalOperations";

export const useQueryUsersBasic = (
  options?: Omit<
    UseQueryOptions<Users, BackendError, Users, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery<Users, BackendError>(["usersBasic"], getUsers, options);
};
