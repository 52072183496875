import { useEffect } from "react";
import { GPTModel } from "../../../types/GPTModel";
import { useQuery } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { getGPTModels } from "../LocalOperations";
import { ListSubheader, MenuItem, Select } from "@mui/material";
import { GPT_DEV_CHOICES_COUNT } from "../../../Const";

interface SelectGPTProps {
  model: null | GPTModel;
  setModel: React.Dispatch<React.SetStateAction<GPTModel | null>>;
}

export const SelectGPT = ({ model, setModel }: SelectGPTProps) => {
  const qModels = useQuery<GPTModel[], BackendError>(
    ["GPTModels"],
    getGPTModels,
    { staleTime: 30 * 60 * 1000 /* 30 min */ }
  );

  useEffect(() => {
    const _match = qModels.data?.find(
      (gptmodel) => gptmodel.name === "gpt-4o-mini"
    );
    if (!!_match) {
      setModel((prev) => (!!prev ? prev : _match));
    }
  }, [qModels.data, setModel]);
  return (
    <Select
      size="small"
      value={model?.name || ""}
      onChange={(e) =>
        setModel(
          (prev) =>
            qModels.data?.find(
              (gptmodel) => gptmodel.name === e.target.value
            ) || prev
        )
      }
      sx={{ maxWidth: "100%" }}
    >
      <ListSubheader>Developers choose</ListSubheader>
      {qModels.data?.slice(0, GPT_DEV_CHOICES_COUNT).map((model) => (
        <MenuItem key={model.name} value={model.name}>
          {model.name}
        </MenuItem>
      ))}
      <ListSubheader>Others</ListSubheader>
      {qModels.data?.slice(GPT_DEV_CHOICES_COUNT).map((model) => (
        <MenuItem key={model.name} value={model.name}>
          {model.name}
        </MenuItem>
      ))}
    </Select>
  );
};
