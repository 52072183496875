import React, { useEffect } from "react";
import { Grid, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FrequencySelectorDropdown } from "../FrequencySelector";
import { Frequency } from "../../../types/Frequencies";

interface SelectFrequencyProps {
  frequencyOption: string;
  frequency: string;
  onFrequencyOptionChange: (value: string) => void;
  onSelectedFrequencyChange: (frequency: string) => void;
}

const SelectFrequency: React.FC<SelectFrequencyProps> = ({
  frequencyOption,
  frequency,
  onFrequencyOptionChange,
  onSelectedFrequencyChange,
}) => {
  useEffect(() => {
    if (frequency.includes("day") || frequency.includes("week")) {
      onFrequencyOptionChange(frequency.includes("day") ? "daily" : "weekly");
      onSelectedFrequencyChange(frequency);
    } else {
      onFrequencyOptionChange(frequency);
    }
  }, [frequency]);

  const filterFrequency = (frequency: Frequency) => {
    if (frequencyOption === "daily") {
      return frequency.name.includes("day");
    }
    if (frequencyOption === "weekly") {
      return frequency.name.includes("week");
    }
    return true;
  };

  const handleFrequencyOptionChange = (value: string) => {
    onFrequencyOptionChange(value);
    if (value === "once" || value === "hour") {
      onSelectedFrequencyChange(value);
    }
  };

  const onSelectedFrequencyChangeHandler = (newFrequency: string) => {
    onSelectedFrequencyChange(newFrequency);
  };

  return (
    <RadioGroup
      aria-label="frequency-options"
      name="frequency-options"
      value={frequencyOption}
      onChange={(event) => handleFrequencyOptionChange(event.target.value)}
    >
      <Grid container item spacing={2}>
        <Grid item height={75}>
          <FormControlLabel value="once" control={<Radio />} label="Once" />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item height={75}>
          <FormControlLabel
            value="hour"
            control={<Radio />}
            label="Every hour"
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={1.75} height={75}>
          <FormControlLabel
            value="daily"
            control={<Radio />}
            label="Every day/s"
          />
        </Grid>
        {frequencyOption === "daily" && (
          <Grid item sm={2} height={75}>
            <FrequencySelectorDropdown
              onChange={onSelectedFrequencyChangeHandler}
              selectedFrequency={frequency}
              filter={filterFrequency}
            />
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={1.75} height={75}>
          <FormControlLabel
            value="weekly"
            control={<Radio />}
            label="Every week/s"
          />
        </Grid>
        {frequencyOption === "weekly" && (
          <Grid item sm={2}>
            <FrequencySelectorDropdown
              onChange={onSelectedFrequencyChangeHandler}
              selectedFrequency={frequency}
              filter={filterFrequency}
            />
          </Grid>
        )}
      </Grid>
    </RadioGroup>
  );
};

export default SelectFrequency;
