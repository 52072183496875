import React from "react";
import { UserProjectsContext } from "./UseUserProjectContext";
import { useQueryUserProjects, useInvalidateUserProjects } from "./useQueryProjects";

export const UserProjectsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data: userProjects = [] } = useQueryUserProjects();

  const invalidateUserProjects = useInvalidateUserProjects();

  return (
    <UserProjectsContext.Provider
      value={{ userProjects, refetchUserProjects: invalidateUserProjects }}
    >
      {children}
    </UserProjectsContext.Provider>
  );
};
