import { createContext } from "react";

/** ChallengeColumn Object, whose values are numbers from 0 to n */
export enum ChallengeColumn {
  NAME,
  FREQUENCY,
  SOLVER,
  STATUS,
  ACTION,
  DESCRIPTION,
  RESERVATION,
}

export interface ChallengesListDisplayType {
  columns: ChallengeColumn[];
}

export const ChallengesListDisplay = createContext<
  ChallengesListDisplayType | undefined
>(undefined);
