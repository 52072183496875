import NextButton from "../../components/NextButton";
import {
  Typography,
  Paper,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Box,
} from "@mui/material";

interface CreationIntroProps {
  onProceedSuccess: () => void;
}

const ChallengeCreationIntro = ({ onProceedSuccess }: CreationIntroProps) => {
  return (
    <Container className="cardGrid">
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" sx={{ marginBottom: 3 }}>
            How to create a new challenge
          </Typography>
        </Grid>
        <Paper elevation={0} sx={{ padding: 3, marginBottom: 3 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="body1">
                Our custom dataset service is designed with simplicity and
                efficiency in mind, and it's only 5 steps away:
              </Typography>
            </Grid>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
              }}
            >
              <ListItem>
                <Box display="flex">
                  <Link underline="none" sx={{ display: "list-item" }}>
                    Data selection:
                  </Link>
                  <Typography variant="body1" sx={{ ml: 0.5 }}>
                    Upload your own file (e.g., xls) or select from existing
                    formats.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box display="flex">
                  <Link underline="none" sx={{ display: "list-item" }}>
                    Data format:
                  </Link>
                  <Typography variant="body1" sx={{ ml: 0.5 }}>
                    Specify the format your data needs to be in.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box display="flex">
                  <Link underline="none" sx={{ display: "list-item" }}>
                    Source details:
                  </Link>
                  <Typography variant="body1" sx={{ ml: 0.5 }}>
                    Tell us more about your desired data or provide a link to
                    its source (optional).
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box display="flex">
                  <Link underline="none" sx={{ display: "list-item" }}>
                    Quality level:
                  </Link>
                  <Typography variant="body1" sx={{ ml: 0.5 }}>
                    Define the desired quality of your data (e.g., frequency).
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box display="flex">
                  <Link underline="none" sx={{ display: "list-item" }}>
                    Finalize query:
                  </Link>
                  <Typography variant="body1" sx={{ ml: 0.5 }}>
                    Confirm your selections and, if applicable, specify your
                    budget.
                  </Typography>
                </Box>
              </ListItem>
            </List>
            <Typography variant="body1">
              Once you complete the data entry, we will process your order
              payment and deliver the data within your chosen timeframe.
            </Typography>
          </Grid>
        </Paper>
        <Grid item xs={12} sm={12} md={12}>
          <NextButton onClick={onProceedSuccess} text="ADD DATA" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChallengeCreationIntro;
