import { useMemo } from "react";
import { useQueryUsersBasic } from "./UseQueryUsersBasic";

interface useIdMapEmailProps {
  isEnabled?: boolean;
}

/** mapping.get(id) --> email. returns empty mapping if disabled / request failed / no users exist. */
export const useUserIdMapEmail = ({ isEnabled = true }: useIdMapEmailProps) => {
  const qUsers = useQueryUsersBasic({
    staleTime: 30 * 60 * 1000 /** 30 min */,
    retry: 2,
    enabled: isEnabled,
  });
  const mapping = useMemo<Map<string, string>>(() => {
    const _mapping = new Map(
      qUsers.data?.map((user) => [String(user.id), user.email])
    );
    const _get = _mapping.get.bind(_mapping);
    _mapping.get = (key) => _get(String(key)); // make sure id 5 and "5" are understood in the same way
    return _mapping;
  }, [qUsers.data]);

  return mapping;
};
