import { Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import MultiCodeRun from "./components/MultiCodeRun";
import SingleCodeRun from "./components/SingleCodeRun";
import { usePerms } from "../../hooks/UsePerms";
import { Perm } from "../../Const";

type ChallengeCodeRunsParams = {
  userId?: string;
  challengeId?: string;
};

interface ChallengeCodeRunsProps {
  isViewingSingleCodeRun: boolean;
}

const ChallengeCodeRuns = (props: ChallengeCodeRunsProps) => {
  const { userId } = useParams<ChallengeCodeRunsParams>();
  const { challengeId } = useParams<ChallengeCodeRunsParams>();
  const perms = usePerms();

  return (
    <>
      <PageTitle
        header="Viewing code run status / result"
        subHeader="It might take some time for program (your code) to finish."
      />
      <Typography variant="subtitle1" textAlign="center">
        (Auto refresh frequency:{" "}
        <b>{props.isViewingSingleCodeRun ? "often" : "rare"}</b>)
      </Typography>
      <Link to="../">
        <Typography textAlign="center">Back to challenge details</Typography>
      </Link>
      {props.isViewingSingleCodeRun && (
        <>
          <Link to={`../code/all/user/${userId}`}>
            <Typography textAlign="center">See all submissions</Typography>
          </Link>
          {perms.has(Perm.VIEW_ANY_CODE) && (
            <Link to="../code/all/user/all">
              <Typography textAlign="center">
                See irrespective submissions
              </Typography>
            </Link>
          )}
        </>
      )}
      {props.isViewingSingleCodeRun ? <SingleCodeRun /> : <MultiCodeRun userId={userId ?? ""} challengeId={challengeId ?? ""}/>}
    </>
  );
};

export default ChallengeCodeRuns;
