import { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { User, UserContextType } from "../types/Users";
import createDefaultUser from "../utils/CreateDefaultUser";
import loadStorageUserOrInvalidUser from "../utils/LoadUser";
import { UNLOGGED_ID } from "../Const";
import { useQueryProjectUsers } from "./UseQueryProjectUsers";

const UserContext = createContext<UserContextType>([
  createDefaultUser(),
  () => {
    console.log(
      "user context is not initialised - you called this fn way too early"
    );
  },
]);

export function useUserState() {
  return useContext(UserContext);
}

interface UserStateProviderProps {
  children: React.ReactNode;
  isRedirectOnAnonymous?: boolean;
}

export const UserStateProvider = ({
  children,
  isRedirectOnAnonymous,
}: UserStateProviderProps) => {
  const [user, setUser] = useState<User>(() => loadStorageUserOrInvalidUser());
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId =
    searchParams.get("project_id") || location.pathname.split("/")[2];
  const { data: projectUsers } = useQueryProjectUsers(projectId, {
    enabled: !!projectId,
  });
  const path = location.pathname.toLowerCase();

  // Redirect to login if user is not logged in
  useEffect(() => {
    if (!isRedirectOnAnonymous || user.id !== UNLOGGED_ID) {
      return;
    }
    const keys = ["/login", "/users/new"];
    if (keys.find((key) => path.includes(key))) {
      return;
    }
    navigate("/login");
  }, [navigate, location, isRedirectOnAnonymous, user, path]);

  // Redirect to home if user is not a project user
  useEffect(() => {
    if (projectId && projectUsers) {
      const projectKeys = ["/projects"];
      if (projectKeys.find((key) => path.includes(key))) {
        const isProjectUser = projectUsers.some(
          (projectUser) => projectUser.user_id === Number(user.id)
        );
        if (!isProjectUser) {
          navigate("/");
        }
      }
    }
  }, [projectId, location, projectUsers, user, navigate, path]);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};
