enum TTabID {
  ChallengeCreationIntro = "creation_intro",
  DataSelection = "data_selection",
  ExistingDataFormat = "existing_data_format",
  NewDataFormat = "new_data_format",
  SourceDetails = "source_details",
  QualityLevels = "quality_levels",
  FinalizeQuery = "finalize_query",
  Finish = "finish",
}

export default TTabID;
