import { Grid, Typography, Container, Paper } from "@mui/material";
import React from "react";
import NextButton from "../../components/NextButton";
import SelectFrequency from "./components/SelectFrequency";

interface QualityIn {
  onDefineQualitySuccess: (frequency: string, frequencyOption: string) => void;
  frequency: string;
  frequencyOption: string;
  setFrequencyOption: (frequencyOption: string) => void;
  setSelectedFrequency: (frequency: string) => void;
}

const DefineQuality: React.FC<QualityIn> = ({
  onDefineQualitySuccess,
  frequency,
  frequencyOption,
  setFrequencyOption,
  setSelectedFrequency,
}) => {
  const onNext = () => {
    onDefineQualitySuccess(frequency, frequencyOption);
  };

  const canProceed = (): boolean => {
    if (frequencyOption === "once" || frequencyOption === "hour") {
      return true;
    } else if (frequencyOption === "daily" && !frequency.includes("day")) {
      return false;
    } else if (frequencyOption === "weekly" && !frequency.includes("week")) {
      return false;
    }
    return true;
  };

  return (
    <Container className="cardGrid">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5">Quality level</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 4, marginBottom: 3 }}>
            <Grid item xs={12} sm={12} md={12} marginBottom={2}>
              <Typography color="textSecondary">
                Choose your data update frequency
              </Typography>
            </Grid>
            <SelectFrequency
              frequencyOption={frequencyOption}
              frequency={frequency}
              onFrequencyOptionChange={setFrequencyOption}
              onSelectedFrequencyChange={setSelectedFrequency}
            />
          </Paper>
        </Grid>
      </Grid>
      <NextButton onClick={onNext} disabled={!canProceed()} />
    </Container>
  );
};

export default DefineQuality;
