import { Badge, ThemeProvider } from "@mui/material";
import useSelectedFiltersCount from "../hooks/UseSelectedFiltersCount";
import { themeV2 } from "../../../misc/theme";

const SelectedFiltersCounter = () => {
  const selectedFiltersCount = useSelectedFiltersCount();

  return (
    <ThemeProvider theme={themeV2}>
      <Badge badgeContent={selectedFiltersCount} sx={{ marginLeft: "20px" }} />
    </ThemeProvider>
  );
};

export default SelectedFiltersCounter;
