import { useContext } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import { DataLabelDetailed } from "../../../types/DataLabelsDetailed";
import DataTypeConfig from "../../../components/DataTypeConfig";
import { useQueryDataFormat } from "../../../hooks/UseQueryDataFormat";
import DataLabelsContext, {
  DataLabelsContextItems,
} from "../contexts/DataLabelsContext";
import { provideTheme } from "../../../utils/ProvideTheme";
import { DataTypeBackend } from "../../../types/EnumDataType";

interface DataLabelsTableProps {
  templateId?: string;
  size?: "small" | "medium";
}

const DataLabelsTable = ({
  templateId,
  size = "medium",
}: DataLabelsTableProps) => {
  const { dataLabels: contextDataLabels, setDataLabels } =
    useContext<DataLabelsContextItems>(DataLabelsContext);

  const qDataFormat = useQueryDataFormat(templateId || "");
  let dataLabels: DataLabelDetailed[] = [];

  if (templateId) {
    dataLabels = qDataFormat.data?.data_labels || [];
  } else {
    dataLabels = contextDataLabels || [];
  }

  const updateLabels = (newLabel: DataLabelDetailed) => {
    setDataLabels((old) =>
      old.map((dataLabel: DataLabelDetailed) =>
        dataLabel.label === newLabel.label ? newLabel : dataLabel
      )
    );
  };

  if (!dataLabels) {
    return <CircularProgress />;
  }

  return (
    <ThemeProvider theme={provideTheme}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Data type</TableCell>
            <TableCell>Optional</TableCell>
            <TableCell>Nullable</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataLabels.map((labelDetailed: DataLabelDetailed) => (
            <DataTypeConfig
              dataLabelDetailed={labelDetailed}
              key={labelDetailed.label}
              disabled={templateId != null}
              onOptionalChange={(newOptional: boolean) => {
                updateLabels({ ...labelDetailed, optional: newOptional });
              }}
              onDataTypeChange={(newDataType: DataTypeBackend) => {
                updateLabels({ ...labelDetailed, data_type: newDataType });
              }}
              onNullableChange={(newNullable: boolean) => {
                updateLabels({ ...labelDetailed, nullable: newNullable });
              }}
              constraints={labelDetailed.constraints}
            />
          ))}
        </TableBody>
      </Table>
    </ThemeProvider>
  );
};

export default DataLabelsTable;
