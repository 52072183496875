import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ErrorAlert from "../../../components/ErrorAlert";
import DataTypeConfigExpanded from "../../../components/DataTypeConfigExpanded";
import { ValueCompletenessBar } from "./ValueCompletenessBar";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import HelpIcon from "@mui/icons-material/Help";
import { useContext } from "react";
import { TemplatingEditContext } from "../contexts/TemplatingEditContext";
import { AttributeUnpacked } from "../../../types/SnapshotMinusTemplate";
import {
  PercentableItem,
  percentItems,
} from "../../../components/MultiColorBar";
import { DataTypeConfigBottomPadding } from "../../../components/DataTypeConfigBottomPadding";

interface TemplatingLabelsTableProps {
  warning?: string | null;
  headStyle?: React.CSSProperties;
  editDisabled?: boolean;
  actionDisplay: "add" | "remove";
  onActionSuccess?: (label: AttributeUnpacked) => void;
  title?: string;
  titleStyle?: React.CSSProperties;
  titleTooltip?: string;
  isTitleFullWidth?: boolean;
  isBottomPadded?: boolean;
}

const headers: PercentableItem[] = percentItems({
  items: [
    { name: "Name", value: 4, percent: 0 },
    { name: "Data type", value: 3, percent: 0 },
    { name: "Optional", value: 3, percent: 0 },
    { name: "Nullable", value: 3, percent: 0 },
    { name: "Counter", value: 3, percent: 0 },
    { name: "Action", value: 1, percent: 0 },
  ],
});

const _getIsChanged = (
  labelsMap: Map<string, AttributeUnpacked>,
  label: AttributeUnpacked,
  getter: (attr: AttributeUnpacked) => any
) => {
  const originalLabel = labelsMap.get(label.name);
  if (!originalLabel) {
    return false;
  }
  const value1 = getter(label);
  const value2 = getter(originalLabel);
  if (!!value1 && typeof value1 === "object") {
    // order sensitive comparison of arrays, objects
    return JSON.stringify(value1) !== JSON.stringify(value2);
  }
  return value1 !== value2;
};

const stickyStyle: React.CSSProperties = {
  position: "sticky",
  top: 0,
  paddingBottom: 3, // dont leave a see-through gap between header and title
  zIndex: 9,
  boxShadow: "0 1px 0px -1px lightgray",
};

export const TemplatingLabelsTable = ({
  warning,
  headStyle,
  editDisabled,
  actionDisplay,
  onActionSuccess,
  title,
  titleStyle,
  titleTooltip,
  isTitleFullWidth,
  isBottomPadded,
}: TemplatingLabelsTableProps) => {
  const templating = useContext(TemplatingEditContext);
  if (!templating) {
    return <ErrorAlert value="init error" />;
  }
  let titleComponent: React.ReactNode = !!title ? (
    <Typography
      variant="h5"
      textAlign="center"
      margin="auto"
      sx={{ backgroundColor: "background.paper" }}
      style={{
        ...stickyStyle,
        width: isTitleFullWidth ? "100%" : "fit-content",
        zIndex: 15, // above bulk actions
        ...titleStyle,
      }}
    >
      {title}
      <HelpIcon color="secondary" style={{ marginLeft: "10px" }} />
    </Typography>
  ) : null;
  titleComponent =
    !!titleTooltip && titleComponent ? (
      <Tooltip title={titleTooltip}>{titleComponent}</Tooltip>
    ) : (
      titleComponent
    );

  const getClassOnEdited = (
    attr: AttributeUnpacked,
    getter: (att: AttributeUnpacked) => any
  ) => {
    return _getIsChanged(templating.labelsOriginalMap, attr, getter)
      ? "edited-templating-cell"
      : undefined;
  };

  const paddingNode = isBottomPadded ? <DataTypeConfigBottomPadding /> : null;

  return (
    <Box>
      {titleComponent}
      <Table style={{ overflow: "visible" }}>
        <TableHead
          sx={{
            backgroundColor: "background.paper",
          }}
          style={{
            ...stickyStyle,
            ...headStyle,
          }}
        >
          <TableRow>
            {headers.map((headerPercentable) => (
              <TableCell
                key={headerPercentable.name}
                style={{ width: `${headerPercentable.percent}%` }}
              >
                <Typography variant="body2">
                  {headerPercentable.name}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!warning && (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <ErrorAlert value={warning} severity="warning" isClosable />
              </TableCell>
            </TableRow>
          )}
          {templating.labels.map((attr) => {
            return (
              <TableRow
                className={
                  attr.is_extra
                    ? "addition-background"
                    : attr.is_missing
                    ? "deletion-background"
                    : "fresh-templating"
                }
                key={attr.name}
              >
                <DataTypeConfigExpanded
                  onConstraintsChange={attr.onConstraintsChange}
                  allowEditConstraints={!editDisabled}
                  constraints={attr.constraints}
                  cellLabelClassName={getClassOnEdited(
                    attr,
                    (att) => att.constraints
                  )}
                  label={attr.name}
                  nullable={attr.is_nullable}
                  optional={attr.is_optional}
                  advisedOptional={
                    attr.is_missing || attr.is_conflicting_optional
                  } // if not delete, then at least offer to make field optional
                  advisedNotOptional={
                    !attr.is_missing && attr.is_unused_optional
                  }
                  advisedNull={attr.is_conflicting_null}
                  advisedNotNull={!attr.is_missing && attr.is_unused_null}
                  dataType={
                    attr.type_in_schema || attr.type_in_snapshot || undefined
                  }
                  advisedDataType={attr.type_in_snapshot || undefined}
                  wrapInRow={false}
                  onDataTypeChange={attr.onDataTypeChange}
                  onNullableChange={attr.onNullableChange}
                  onOptionalChange={attr.onOptionalChange}
                  allowAdvancedType
                  disabled={editDisabled}
                  cellDataTypeClassName={getClassOnEdited(
                    attr,
                    (att) => att.type_in_schema || att.type_in_snapshot
                  )}
                  cellOptionalClassName={getClassOnEdited(
                    attr,
                    (att) => att.is_optional
                  )}
                  cellNullClassName={getClassOnEdited(
                    attr,
                    (att) => att.is_nullable
                  )}
                  isBottomPadded={isBottomPadded}
                  advisedDelete={attr.is_missing}
                />
                <TableCell>
                  <ValueCompletenessBar
                    valueCount={attr.counter_val}
                    undefinedCount={attr.counter_undef}
                    nullCount={attr.counter_null}
                  />
                  {paddingNode}
                </TableCell>
                <TableCell>
                  <IconButton
                    sx={{
                      "&:hover": {
                        color:
                          actionDisplay === "remove"
                            ? "red"
                            : actionDisplay === "add"
                            ? "green"
                            : "yellow",
                        transition: "color",
                      },
                    }}
                    onClick={() => {
                      templating.removeLabel(attr.name);
                      onActionSuccess?.(attr);
                    }}
                  >
                    {actionDisplay === "remove" ? (
                      <DeleteIcon />
                    ) : actionDisplay === "add" ? (
                      <AddIcon />
                    ) : (
                      <CallToActionIcon />
                    )}
                  </IconButton>
                  {paddingNode}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
