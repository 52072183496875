import { checkboxClasses, createTheme, svgIconClasses } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export const themeV2 = createTheme({
  // dodgerblue
  // #263238 blueish
  // gray
  // (background:)
  // #efefef // whiteish
  // ghostwhite
  // white
  // (also background:)
  // whitesmoke ?
  palette: {
    primary: {
      main: "#1e90ff", // dodger blue
    },
    secondary: {
      main: "#263238",
    },
    error: {
      main: "#FC2727", // bright red
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          fontWeight: "bold",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckIcon />,
      },
      styleOverrides: {
        root: {
          // goal: gray checkbox + purple tick inside.
          // since checkbox works by placing a single icon, multi coloring does not sound feasible.
          // workaround: wrap icon inside gray border and then color the icon.
          // if empty checkbox is needed, make icon transparent (so only borders are visible).

          [`&.${checkboxClasses.root}`]: {
            // make checkbox icon invisible always (will be overridden for "selected")
            color: "rgba(255, 255, 255, 255)",
            [`.${svgIconClasses.root}`]: {
              // form a checkbox-like border around checkbox icon
              border: "1.5px solid #B6B6B6", //gray like
              borderRadius: "5px",
            },
          },
          [`&.${checkboxClasses.checked}`]: {
            // when checked, make the icon colored (visible)
            color: "#4D52C9", // blue + purple
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: "white",
          color: "#1e90ff",
        },
      },
    },
  },
  typography: {
    fontFamily: "Open Sans",
    fontSize: 14,
    h5: {
      fontSize: "24px",
      fontWeight: "bold",
    },
    h6: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    button: {
      textTransform: "none",
    },
  },
});
