import {
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "react-query";
import { ChallengesBrief } from "../../../types/ChallengesBrief";
import { BackendError } from "../../../types/BackendError";
import { getChallengesBrief } from "../LocalOperations";
import { useEffect } from "react";

interface UseLegacyInfQueryChallengesProps {
  isNextPageTriggered: boolean;
  challengeParams: any[];
  options?: Omit<
    UseInfiniteQueryOptions<
      ChallengesBrief,
      BackendError,
      ChallengesBrief,
      ChallengesBrief,
      QueryKey
    >,
    "queryKey" | "queryFn"
  >;
}

export const useLegacyInfQueryChallenges = ({
  isNextPageTriggered,
  challengeParams,
  options,
}: UseLegacyInfQueryChallengesProps) => {
  const infQuery = useInfiniteQuery<ChallengesBrief, BackendError>(
    ["challenges", ...challengeParams],
    (pageObjParam) => getChallengesBrief(pageObjParam, ...challengeParams),
    {
      getNextPageParam: (lastData, allData) =>
        // undefined is meant for react-query to signalize the end of pagination
        lastData.length > 0 ? allData.length : undefined,
      ...options,
    }
  );

  useEffect(() => {
    if (
      !infQuery.isFetchingNextPage &&
      infQuery.hasNextPage &&
      isNextPageTriggered
    ) {
      infQuery.fetchNextPage();
    }
  }, [isNextPageTriggered, infQuery]);

  return infQuery;
};
