import ExternalLink from "../../../components/ExternalLink";
import TitledCodeTextCard from "../../../components/TitledCodeTextCard";
import TabbedContentDialog, { DialogTabs } from "./TabbedContentDialog";
import { useQueryCodeRunDetails } from "../../../hooks/UseQueryCodeRunDetails";
import { useState } from "react";
import { DialogProps } from "@mui/material";

interface CodeOutputDialogProps extends DialogProps {
  onClose: () => void;
  challengeId: string;
  userId: string;
  codeRunNumber: string;
  contentStyle?: React.CSSProperties;
}

const CodeOutputDialog = (props: CodeOutputDialogProps) => {
  const {
    onClose,
    open,
    challengeId,
    userId,
    codeRunNumber,
    contentStyle,
    ...dialogProps
  } = props;
  const [tabs, setTabs] = useState<DialogTabs>(() => [
    { title: "Loading...", content: "Loading the code output..." },
  ]);
  const qCodeRunDetails = useQueryCodeRunDetails(
    challengeId,
    codeRunNumber,
    userId,
    {
      enabled: open,
      onSuccess: (codeRun) => {
        setTabs([
          {
            title: "Output",
            content: codeRun.output ? (
              <TitledCodeTextCard value={codeRun.output} wordWrap />
            ) : (
              "*empty*"
            ),
          },
          {
            title: "Stdout",
            content: codeRun.stdout ? (
              <TitledCodeTextCard value={codeRun.stdout} wordWrap />
            ) : (
              "*empty*"
            ),
          },
          {
            title: "Stderr",
            content: codeRun.stderr ? (
              <TitledCodeTextCard value={codeRun.stderr} wordWrap />
            ) : (
              "*empty*"
            ),
          },
        ]);
      },
      onError: () => {
        setTabs([{ title: "Whoops", content: "Could not find code run." }]);
      },
    }
  );

  const codeRun = qCodeRunDetails.data;

  return (
    <TabbedContentDialog
      open={open}
      onClose={onClose}
      tabs={tabs}
      title={"Details about code run result"}
      contentStyle={{
        width: "70vw",
        maxWidth: "70rem",
        minHeight: "30vh",
        ...contentStyle,
      }}
      suffixNode={
        codeRun?.file_url ? (
          <ExternalLink
            href={codeRun.file_url}
            style={{ textAlign: "right", display: "block" }}
          >
            Download as file
          </ExternalLink>
        ) : undefined
      }
      {...dialogProps}
    />
  );
};

export default CodeOutputDialog;
