import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { User } from "../types/Users";
import { BackendError } from "../types/BackendError";
import { getCurrentUser, getUser } from "../pages/ChallengeViewSteps/LocalOperations";

export const useQueryUserDetails = (
  userId: string,
  options?: Omit<
    UseQueryOptions<User, BackendError, User, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
    const _userId = String(userId)
  return useQuery<User, BackendError>(
    ["userDetails", _userId],
    () => getUser(_userId),
    options
  );
};


export const useQueryCurrentUserDetails = (
  options?: Omit<
    UseQueryOptions<User, BackendError, User, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery<User, BackendError>(
    ["userDetails"],
    () => getCurrentUser(),
    options
  );
};