import { useState, useEffect } from "react";
import { Typography, Box, Chip } from "@mui/material";
import { useQueryUsersBasic } from "../hooks/UseQueryUsersBasic";
import { useQueryChallengeProjectUsers } from "../hooks/UseQueryChallengeProjectUsers";
import { useQuery } from "react-query";
import UserSelector from "./UserSelector";
import { Solvings } from "../types/Solvings";
import { SLA } from "../types/SLA";
import { BackendError } from "../types/BackendError";
import { getChallengeSolvings, getChallengeSLA } from "../pages/ChallengeViewSteps/LocalOperations";

enum Preset {
  MAIN_CONTRIBUTOR = "main_contributor",
  ALL_TASK_CONTRIBUTORS = "all_task_contributors",
  ALL_CONTRIBUTORS = "all_contributors",
}

interface MultiSelectUserProps {
  selectedUserIds: string[];
  onChangeSelectedUsers: (selectedIds: string[]) => void;
  labelId?: string;
  challengeId: string;
}

const MultiSelectUser = ({
  selectedUserIds,
  onChangeSelectedUsers,
  labelId,
  challengeId,
}: MultiSelectUserProps) => {
  const queryAllUsers = useQueryUsersBasic();
  const queryChallengeProjectUsers = useQueryChallengeProjectUsers(challengeId);
  const qSolvings = useQuery<Solvings, BackendError>(["solvings"], () =>
    getChallengeSolvings(challengeId)
  );
  const qSLA = useQuery<SLA, BackendError>(["sla"], () =>
    getChallengeSLA(challengeId)
  );

  const [selectedPreset, setSelectedPreset] = useState<Preset | null>(null);
  const [presetUsers, setPresetUsers] = useState<string[]>([]);

  const handlePresetSelection = (preset: Preset) => {
    setSelectedPreset(preset === selectedPreset ? null : preset);
    setPresetUsersForPreset(preset);
  };

  const setPresetUsersForPreset = (preset: Preset) => {
    switch (preset) {
      case Preset.MAIN_CONTRIBUTOR:
        if (qSLA.data?.solver) {
          const solver = queryAllUsers.data?.find((user) => user.email === qSLA.data.solver);
          if (solver) {
            const solverId = String(solver.id);
            setPresetUsers([solverId]);
            onChangeSelectedUsers([solverId]);
          }
        }
        break;
      case Preset.ALL_TASK_CONTRIBUTORS:
        const solversIds = qSolvings.data?.map((solving) => String(solving.user_id)) || [];
        setPresetUsers(solversIds);
        onChangeSelectedUsers(solversIds);
        break;
      case Preset.ALL_CONTRIBUTORS:
        const allContributorsIds = queryChallengeProjectUsers.data?.map((user) => String(user.user_id)) || [];
        setPresetUsers(allContributorsIds);
        onChangeSelectedUsers(allContributorsIds);
        break;
    }
  };

  useEffect(() => {
    if (selectedPreset && presetUsers.length > 0) {
      const areSameUsers =
        selectedUserIds.length === presetUsers.length &&
        selectedUserIds.every((id) => presetUsers.includes(id));

      if (!areSameUsers) {
        setSelectedPreset(null);
      }
    }
  }, [selectedUserIds, presetUsers, selectedPreset]);

  return (
    <Box>
      <Box marginBottom={2}>
        <Typography>Select receiver</Typography>
        <Chip
          variant="outlined"
          onClick={() => handlePresetSelection(Preset.MAIN_CONTRIBUTOR)}
          sx={{ marginRight: 1 }}
          color={selectedPreset === Preset.MAIN_CONTRIBUTOR ? "primary" : undefined}
          label="Main contributor"
        />
        <Chip
          variant="outlined"
          onClick={() => handlePresetSelection(Preset.ALL_TASK_CONTRIBUTORS)}
          sx={{ marginRight: 1 }}
          color={selectedPreset === Preset.ALL_TASK_CONTRIBUTORS ? "primary" : undefined}
          label="All task contributors"
        />
        <Chip
          variant="outlined"
          onClick={() => handlePresetSelection(Preset.ALL_CONTRIBUTORS)}
          sx={{ marginRight: 1 }}
          color={selectedPreset === Preset.ALL_CONTRIBUTORS ? "primary" : undefined}
          label="All project contributors"
        />
      </Box>
      {queryAllUsers.isSuccess && queryChallengeProjectUsers.isSuccess && (
        <UserSelector
          selectedUserIds={selectedUserIds}
          onChange={onChangeSelectedUsers}
          users={queryChallengeProjectUsers.data?.map(projectUser => ({
            id: String(projectUser.user_id),
            email: projectUser.email,
          })) || []}
          label="Select receiver"
        />
      )}
    </Box>
  );
};

export default MultiSelectUser;
