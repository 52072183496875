import { CircularProgress } from "@mui/material";
import ErrorAlert from "../../../components/ErrorAlert";
import SnapshotStatisticsSchemaColumn from "./SnapshotStatisticsSchemaColumn";
import { DataLabelDetailed } from "../../../types/DataLabelsDetailed";
import { useQueryDataFormat } from "../../../hooks/UseQueryDataFormat";
import { useQuerySnapshotStatistics } from "../../../hooks/UseQuerySnapshotStatistics";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface SnapshotStatisticsSchemaProps {
  templateId: string;
  snapshotId: string;
}

const SnapshotStatisticsSchema = ({
  templateId,
  snapshotId,
}: SnapshotStatisticsSchemaProps) => {
  const qDataFormat = useQueryDataFormat(templateId);
  const qSnaphotStatistics = useQuerySnapshotStatistics(snapshotId);

  if (qDataFormat.isLoading) {
    return <CircularProgress />;
  }
  if (qDataFormat.isError) {
    return <ErrorAlert value={qDataFormat.error} />;
  }
  if (!qDataFormat.isSuccess) {
    return <ErrorAlert value={`Unkown error for data format ${templateId}`} />;
  }

  const dataLabels = qDataFormat.data.data_labels;

  if (dataLabels) {
    return (
      <>
        <Table aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Format</TableCell>
              <TableCell>Nullable</TableCell>
              <TableCell>Optional</TableCell>
              <TableCell>Valid value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLabels.map((dLabel: DataLabelDetailed) => (
              <SnapshotStatisticsSchemaColumn
                key={dLabel.label}
                dataLabelDetailed={dLabel}
                statistics={qSnaphotStatistics}
                disabled
              />
            ))}
          </TableBody>
        </Table>
      </>
    );
  }

  return <CircularProgress />;
};

export default SnapshotStatisticsSchema;
