import { useCallback, useContext, useMemo, useState } from "react";
import { GPTPrompt, GPTPromptRole, GPTPrompts } from "../../../types/GPTPrompt";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useQuery } from "react-query";
import { ChallengeContext } from "../../ChallengeViewSteps/contexts/ChallengeContext";
import { getDefaultGPTCodePrompt } from "../LocalOperations";
import { useUserState } from "../../../hooks/UserStateProvider";
import { ProxyType } from "../../../types/ProxyType";
import { Add, ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { LastCodeRefContext } from "../contexts/LastCodeRefContext";

const ROLE_NODES = Object.values(GPTPromptRole).map((role) => (
  <MenuItem value={role} key={role}>
    {role}
  </MenuItem>
));

interface RowProps {
  role: GPTPromptRole;
  content: string;
  line_i: number;
  onChange: (role: GPTPromptRole, content: string, i: number) => void;
  onDelete: (i: number) => void;
  onMove: (offset: number, i: number) => void;
}

const Row = ({
  role,
  content,
  line_i,
  onChange,
  onDelete,
  onMove,
}: RowProps) => {
  return (
    <Paper elevation={5} style={{ padding: "15px 5px", margin: "10px 0px" }}>
      <Stack direction="row">
        <Select
          value={role}
          onChange={(e) => {
            const value = e.target.value as GPTPromptRole;
            if (Object.values(GPTPromptRole).includes(value)) {
              onChange(value, content, line_i);
            }
          }}
          style={{ width: "20ch" }}
        >
          {ROLE_NODES}
        </Select>
        <TextField
          fullWidth
          multiline
          minRows={2}
          maxRows={Infinity}
          onChange={(e) => {
            onChange(role, e.target.value, line_i);
          }}
          value={content}
        />
        <Stack direction="column" justifyContent="center">
          <Tooltip title="up" placement="left">
            <IconButton onClick={() => onMove(-1, line_i)}>
              <ArrowUpward />
            </IconButton>
          </Tooltip>
          <Tooltip title="down" placement="left">
            <IconButton onClick={() => onMove(1, line_i)}>
              <ArrowDownward />
            </IconButton>
          </Tooltip>
        </Stack>
        <IconButton
          onClick={() => onDelete(line_i)}
          style={{ maxHeight: "3ch", margin: "auto" }}
        >
          <Delete color="error" />
        </IconButton>
      </Stack>
    </Paper>
  );
};

interface PromptSettingsDialogProps {
  open: boolean;
  onClose: (prompts: GPTPrompts) => void;
  proxyType: ProxyType | string;
  isUseFixMyCode?: boolean;
}

export const PromptSettingsDialog = ({
  open,
  onClose,
  proxyType,
  isUseFixMyCode,
}: PromptSettingsDialogProps) => {
  const [customPrompts, setCustomPrompts] = useState<GPTPrompts>([]);
  const challenge = useContext(ChallengeContext);
  const lastCodeRef = useContext(LastCodeRefContext);
  const [user] = useUserState();

  const keySuffix = isUseFixMyCode
    ? [
        "fix",
        lastCodeRef?.codeRun?.userId,
        lastCodeRef?.codeRun?.runId,
        lastCodeRef?.badValidationId,
      ]
    : ["default"];

  const qPrompts = useQuery<GPTPrompts>(
    [
      "CodePrompt",
      challenge?.id,
      proxyType,
      challenge?.template_id,
      ...keySuffix,
    ],
    () =>
      getDefaultGPTCodePrompt({
        challengeId: challenge?.id ?? "no-id",
        userEmail: user.email,
        proxyType,
        templateId: challenge?.template_id ?? "noid",
        codeUserId: isUseFixMyCode ? lastCodeRef?.codeRun?.userId : undefined,
        codeRunId: isUseFixMyCode ? lastCodeRef?.codeRun?.runId : undefined,
        validationId: isUseFixMyCode ? lastCodeRef?.badValidationId : undefined,
      }),
    { enabled: !!challenge }
  );

  const prompts = useMemo(
    () => (customPrompts.length > 0 ? customPrompts : qPrompts.data || []),
    [customPrompts, qPrompts.data]
  );

  const onChange = useCallback(
    (role: GPTPromptRole, content: string, i: number) => {
      if (!prompts[i]) {
        return;
      }
      const prompt = { role, content };
      const newPrompts = [...prompts];
      newPrompts[i] = prompt;
      setCustomPrompts(newPrompts);
    },
    [prompts, setCustomPrompts]
  );

  const onDelete = useCallback(
    (i: number) => {
      if (!prompts[i]) {
        return;
      }
      const newPrompts = [...prompts];
      newPrompts.splice(i, 1);
      setCustomPrompts(newPrompts);
    },
    [prompts, setCustomPrompts]
  );

  const onMove = useCallback(
    (offset: number, i: number) => {
      const current = prompts[i];
      const replacer = prompts[i + offset];
      if (!current || !replacer) {
        return;
      }
      const newPrompts = [...prompts];
      newPrompts[i] = replacer;
      newPrompts[i + offset] = current;
      setCustomPrompts(newPrompts);
    },
    [prompts, setCustomPrompts]
  );

  const closeHandler = () => {
    const prepare = (prompt: GPTPrompt) => ({
      role: prompt.role,
      content: prompt.content.trim(),
    });
    const _prompts = prompts.map(prepare);
    const _defaultPrompts = qPrompts.data?.map(prepare);
    const _isDefault =
      JSON.stringify(_prompts) === JSON.stringify(_defaultPrompts);
    const _endPrompts = _isDefault ? [] : _prompts;
    setCustomPrompts(_endPrompts);
    onClose(_endPrompts);
  };

  return (
    <Dialog open={open} onClose={closeHandler} maxWidth="xl" fullWidth>
      <DialogTitle>Editing Prompts</DialogTitle>
      <DialogContent>
        {prompts.map((prompt, i) => (
          <Row
            key={`${i}${prompt.role}`}
            role={prompt.role}
            content={prompt.content}
            line_i={i}
            onChange={onChange}
            onMove={onMove}
            onDelete={onDelete}
          />
        ))}
        {qPrompts.isLoading && <CircularProgress />}
        <Stack direction="row" justifyContent="center">
          <Button
            startIcon={<Add />}
            onClick={() =>
              setCustomPrompts([
                ...prompts,
                { role: GPTPromptRole.USER, content: "" },
              ])
            }
            variant="outlined"
          >
            Add Prompt line
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler}>Close</Button>
        <Button
          onClick={() => {
            setCustomPrompts([]);
            onClose([]);
          }}
        >
          Discard and Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
