import { useCallback, useEffect, useRef, useState } from "react";
import { ChallengeBrief } from "../../../types/ChallengesBrief";
import { makeFilterChallengeByName } from "../../../utils/MakeFilterChallenge";

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

export enum BackendFilterType {
  ProjectId = "project_id",
  CreatorId = "creator_user_id",
  SolverId = "solver_user_id",
  TemplateId = "template_id",
  IsReserved = "is_reserved",
  IsSolved = "is_solved",
  IsAutorun = "is_autorun_on",
  isCreationSlaOk = "is_creation_sla_ok",
  IsDeliverySlaOk = "is_delivery_sla_ok",
  IsGrabbable = "is_up_for_grab",
  IsRejected = "is_rejected",
  IsReviewed = "is_reviewed",
  IsMyGrant = "is_my_grant",
  ValidationStatuses = "validation_statuses",
  ReservationStatuses = "reservation_statuses",
  CoderunStatuses = "coderun_statuses",
  IsOnSnooze = "is_on_snooze",
  IsAnomaly = "is_anomaly",
}

var BOOL_FLAGS = [
  BackendFilterType.IsReviewed,
  BackendFilterType.IsAutorun,
  BackendFilterType.IsRejected,
  BackendFilterType.IsGrabbable,
  BackendFilterType.isCreationSlaOk,
  BackendFilterType.IsDeliverySlaOk,
  BackendFilterType.IsSolved,
  BackendFilterType.IsOnSnooze,
  BackendFilterType.IsAnomaly,
];

export enum FrontendFilterType {
  Name = "name",
}

type FilterType = BackendFilterType | FrontendFilterType;

export const BackendFilterTypes = Object.values(BackendFilterType);
export const FrontendFilterTypes = Object.values(FrontendFilterType);

type ValueFilter = {
  type: FilterType;
  value: any;
};

export type ChallengeFilter = ValueFilter & {
  filterFunction?: (challenge: ChallengeBrief) => boolean;
};

export interface UseChallengeFilterType {
  getFilter: (
    type: FilterType,
    _filters?: ChallengeFilter[]
  ) => ChallengeFilter | undefined;
  getFilterValue: (
    type: FilterType,
    fallbackValue?: any,
    _filters?: ChallengeFilter[]
  ) => any;
  filters: ChallengeFilter[];
  apply: (filters?: ChallengeFilter[]) => void;
  /** removes filter on value "", null, undefined */
  setFilter: (type: FilterType, value: any, navigate?: Function) => void;
  /** back to placeholder values */
  resetFilters: () => void;
  /** completely no filters */
  clearFilters: () => void;
  loadPreviousFilters: (values: any) => void;
  isLoading: boolean;
}

export interface UseChallengeFilterProps {
  placeholderFilters?: ChallengeFilter[];
  isApplyAuto?: boolean;
  isLoadPreviousFilters?: boolean;
  onApply?: (filters: ChallengeFilter[]) => void;
}

const STORAGE_KEY = "CHALLENGE_FILTERS";

export const useChallengeFilter = ({
  placeholderFilters = [],
  isApplyAuto = true,
  isLoadPreviousFilters = true,
  onApply,
}: UseChallengeFilterProps): UseChallengeFilterType => {
  const [filters, setFilters] = useState<ChallengeFilter[]>(placeholderFilters);
  const [isLoading, setIsLoading] = useState(isLoadPreviousFilters);
  const location = useLocation();
  const refIsFirstRender = useRef(true);

  const getFilter = useCallback(
    (type: FilterType, _filters: ChallengeFilter[] = filters) => {
      return _filters.find((filter) => filter.type === type);
    },
    [filters]
  );

  const getFilterValue = useCallback(
    (
      type: FilterType,
      fallbackValue: any,
      _filters: ChallengeFilter[] = filters
    ) => {
      return getFilter(type, _filters)?.value ?? fallbackValue;
    },
    [getFilter, filters]
  );

  const apply = useCallback(
    (_filters: ChallengeFilter[] = filters, navigate?: Function) => {
      setFilters(_filters);
      onApply?.(_filters);
      const _nameFilter = getFilter(FrontendFilterType.Name, _filters);
      if (!!_nameFilter) {
        _nameFilter.filterFunction = makeFilterChallengeByName(
          _nameFilter.value
        );
      }

      var rawFilters = _filters.map(({ type, value }: ChallengeFilter) => {
        return { type, value };
      });
      var serializedFilters = JSON.stringify(rawFilters);

      if (navigate) {
        var queryParams = {} as { [key: string]: any };
        rawFilters.forEach((_filter: any) => {
          queryParams[_filter.type] = _filter.value;
        });
        const queryStringified = queryString.stringify(queryParams);
        navigate(`?${queryStringified}`);
      }
      try {
        localStorage.setItem(STORAGE_KEY, serializedFilters);
      } catch (ex) {
        // one of values not serializable or local storage disabled
      }
    },
    [filters, getFilter, onApply]
  );

  const loadPreviousFilters = useCallback(
    (_filters: any) => {
      try {
        apply(_filters);
      } catch (ex) {}
    },
    [apply]
  );

  const clearFilters = () => {
    apply([]);
  };

  const resetFilters = useCallback(() => {
    apply(placeholderFilters);
  }, [apply, placeholderFilters]);

  const setFilter = (type: FilterType, value: any, navigate?: Function) => {
    const _existingFilter = getFilter(type);
    const isValueEmptyish =
      ["", undefined, null, 0].includes(value) ||
      (Array.isArray(value) && value.length === 0);
    let _filters = filters;
    // remove filter on value 0, "", null, undefined, []

    if (_existingFilter) {
      _filters = filters.filter(({ type }) => type !== _existingFilter.type);
      if (!isValueEmptyish) {
        // filter's value will be adjusted
        _filters.push({ ..._existingFilter, value });
      } else {
        // filter will be removed
      }
    } else if (!isValueEmptyish) {
      // add new
      _filters = [...filters, { type: type, value: value }];
    }

    if (isApplyAuto) {
      apply(_filters, navigate);
    } else {
      setFilters(_filters);
    }
  };

  useEffect(() => {
    if (!location.search && !refIsFirstRender.current) {
      resetFilters();
      refIsFirstRender.current = false;
    }
  }, [location.search, resetFilters, refIsFirstRender]);

  useEffect(() => {
    if (isLoading) {
      const queryParams = new URLSearchParams(location.search);

      var ff = [];
      var validationStatuses = [];
      var reservationStatuses = [];
      var coderunStatuses = [];
      for (var item of Array.from(queryParams.entries())) {
        var t: any = item[0];
        var v: any = item[1];
        var value = null;

        if (BOOL_FLAGS.includes(t)) {
          if (v === "true") {
            value = true;
          } else if (v === "false") {
            value = false;
          }
        } else if (t === BackendFilterType.SolverId) {
          value = parseInt(v);
        } else if (t === BackendFilterType.ValidationStatuses) {
          validationStatuses.push(v);
        } else if (t === BackendFilterType.ReservationStatuses) {
          reservationStatuses.push(v);
        } else if (t === BackendFilterType.CoderunStatuses) {
          coderunStatuses.push(v);
        } else if (t === BackendFilterType.ProjectId) {
          value = parseInt(v);
        } else if (t === BackendFilterType.TemplateId) {
          value = parseInt(v);
        } else if (t === BackendFilterType.CreatorId) {
          value = parseInt(v);
        }

        if (value != null) {
          ff.push({
            type: t,
            value: value,
          });
        }
      }

      if (validationStatuses.length > 0) {
        ff.push({
          type: "validation_statuses",
          value: validationStatuses,
        });
      }

      if (reservationStatuses.length > 0) {
        ff.push({
          type: "reservation_statuses",
          value: reservationStatuses,
        });
      }

      if (coderunStatuses.length > 0) {
        ff.push({
          type: "coderun_statuses",
          value: coderunStatuses,
        });
      }

      loadPreviousFilters(ff);
    }
    setIsLoading(false);
  }, [loadPreviousFilters, setIsLoading, isLoading, location.search]);

  return {
    getFilter,
    getFilterValue,
    filters,
    apply,
    setFilter,
    resetFilters,
    clearFilters,
    loadPreviousFilters,
    isLoading,
  };
};
