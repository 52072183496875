import { CircularProgress, Stack, Typography } from "@mui/material";

const typoStyle = {
  justifyContent: "center",
  marginLeft: "30px",
  marginRight: "30px",
  paddingTop: "7px",
};

interface TextedCircularProgressProps {
  textPre?: string;
  textPast?: string;
  isOneLine?: boolean;
}

export const TextedCircularProgress = ({
  textPre,
  textPast,
  isOneLine = true,
}: TextedCircularProgressProps) => {
  return (
    <Stack direction={isOneLine ? "row" : "column"} marginBottom={"15px"}>
      {!!textPre && <Typography {...typoStyle}>{textPre}</Typography>}
      <CircularProgress style={isOneLine ? undefined : { margin: "auto" }} />
      {!!textPast && <Typography {...typoStyle}>{textPast}</Typography>}
    </Stack>
  );
};
