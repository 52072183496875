import React from 'react';

export const FormatChallengeReservedDate: React.FC<{ dateString: string | null }> = ({ dateString }) => {
  if (!dateString) return <span>N/A</span>;
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return (
    <div>
      <div>{`${hours}:${minutes}:${seconds}`}</div>
      <div>{`${day}/${month}/${year}`}</div>
    </div>
  );
};