import {
  ChallengeColumn,
  ChallengesListDisplay,
} from "./ChallengesListDisplay";

interface ChallengesListDisplayProviderProps {
  children: React.ReactNode;
  columns?: ChallengeColumn[];
}

const ChallengesListDisplayProvider = ({
  children,
  columns = [
    ChallengeColumn.NAME,
   // ChallengeColumn.DESCRIPTION,
    ChallengeColumn.FREQUENCY,
    ChallengeColumn.SOLVER,
    ChallengeColumn.STATUS,
    ChallengeColumn.RESERVATION,
    ChallengeColumn.ACTION,
  ],
}: ChallengesListDisplayProviderProps) => {
  return (
    <ChallengesListDisplay.Provider
      value={{
        columns: columns,
      }}
    >
      {children}
    </ChallengesListDisplay.Provider>
  );
};

export default ChallengesListDisplayProvider;
