import { CircularProgress, Container } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import extractError from "../../utils/ErrorExtract";
import FormatTemplate from "./components/FormatTemplate";
import ErrorAlert from "../../components/ErrorAlert";
import { useQueryChallengeDetails } from "../../hooks/UseQueryChallengeDetails";

type ChallengeFormatParams = {
  challengeId?: string;
};

const ChallengeFormat = () => {
  const { challengeId } = useParams<ChallengeFormatParams>();

  const qDetails = useQueryChallengeDetails(challengeId ?? "no-id");

  const errorMessage = extractError(qDetails.error);
  const challenge = qDetails.data;

  return (
    <>
      {qDetails.isLoading && (
        <>
          <span>Loading details for Challenge#{challengeId}</span>
          <CircularProgress />
        </>
      )}
      {qDetails.isError && <ErrorAlert value={errorMessage} />}
      {qDetails.isSuccess && challenge && (
        <>
          <PageTitle
            header="#. Format challenge"
            subHeader={`name: ${challenge.name}, id: ${challenge.id}`}
          />
          <Link to="../" style={{ textAlign: "center", display: "block" }}>
            Back to challenge
          </Link>
          <Container className="gridMain" style={{ textAlign: "center" }}>
            <FormatTemplate templateId={challenge.template_id} />
          </Container>
        </>
      )}
    </>
  );
};

export default ChallengeFormat;
