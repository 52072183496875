import { FormControl, InputLabel } from "@mui/material";
import { User } from "../types/Users";
import SelectUser from "./SelectUser";

interface LabeledSelectUserProps {
  userId: string;
  onChangeUser: (user?: User) => void;
  nameForEmpty?: string;
  prependDefaultUser?: boolean;
  label: string;
}

const LabeledSelectUser = ({
  userId,
  onChangeUser,
  nameForEmpty,
  prependDefaultUser,
  label,
}: LabeledSelectUserProps) => {
  const labelId = `${label}-label`;
  return (
    <FormControl
      variant="filled"
      sx={{ m: 1, minWidth: label.length * 10 + 20 }}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <SelectUser
        onChangeUser={onChangeUser}
        nameForEmpty={nameForEmpty}
        userId={userId}
        prependDefaultUser={prependDefaultUser}
        labelId={labelId}
      />
    </FormControl>
  );
};

export default LabeledSelectUser;
