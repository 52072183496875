import { useState } from "react";
import { useMutation } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { postTemporaryPassChange } from "../LocalOperations";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Typography,
  Paper,
} from "@mui/material";
import PageTitle from "../../../components/PageTitle";
import PasswordInput from "./PasswordInput";
import ErrorAlert from "../../../components/ErrorAlert";
import {
  useInvalidateQueryIsPasswordTemp,
  useQueryIsPasswordTemp,
} from "../hooks/useQueryIsPasswordTemp";

const PasswordChange = () => {
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const qIsPassTemporary = useQueryIsPasswordTemp({
    staleTime: 5 * 60 * 1000, // 5 min
  });
  const invalidateIsPassTemporary = useInvalidateQueryIsPasswordTemp();
  const qChangeTempPass = useMutation<void, BackendError, string>(
    postTemporaryPassChange,
    {
      onSuccess: () => {
        invalidateIsPassTemporary();
        setIsShowSuccess(true);
      },
      retry: false,
    }
  );

  if (isShowSuccess) {
    return (
      <Alert severity="success" onClick={() => setIsShowSuccess(false)}>
        Password Changed Successfully
      </Alert>
    );
  }

  if (
    qIsPassTemporary.isLoading ||
    !qIsPassTemporary.isSuccess ||
    qIsPassTemporary.data.answer === false // password is not temporary, no need to display
  ) {
    return <></>; // silently loading or crashing or not temporary-pass
  }

  return (
    <Container
      className="containerMain"
      style={{
        width: "100%",
        maxWidth: "700px",
        border: "3px solid whitesmoke",
      }}
    >
      <Paper elevation={0}>
        <PageTitle header="Password change" />
        <Typography>
          Since your password was generated or predefined, it is advisable to
          change password into safe one.
        </Typography>
        <div className="br" />
        <div className="gapVerticalMini loose-column">
          <PasswordInput
            placeholder="New Password"
            className="text-field-medium"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button
            className="button-medium"
            variant="contained"
            endIcon={
              qChangeTempPass.isLoading ? (
                <CircularProgress size="25px" />
              ) : undefined
            }
            disabled={newPassword.length < 1 || qChangeTempPass.isLoading}
            onClick={() => qChangeTempPass.mutate(newPassword)}
          >
            Change Password
          </Button>
          {qChangeTempPass.isError && !qChangeTempPass.isLoading && (
            <ErrorAlert value={qChangeTempPass.error} />
          )}
        </div>
      </Paper>
    </Container>
  );
};

export default PasswordChange;
