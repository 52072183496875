import { Button, Card, Stack, Typography } from "@mui/material";
import { SnapshotMinusTemplatePacked } from "../../../types/SnapshotMinusTemplate";
import { usePerms } from "../../../hooks/UsePerms";
import {
  Perm,
  SNAPSHOT_TEMPLATE_DIFFERENCES_PER_PREVIEW,
} from "../../../Const";
import { useQuery } from "react-query";
import { getSnapshotTemplatingPacked } from "../LocalOperations";
import ErrorAlert from "../../../components/ErrorAlert";
import { BackendError } from "../../../types/BackendError";
import { useMemo } from "react";
import { useModal } from "react-hooks-use-modal";
import { SnapshotTemplatingDialog } from "./SnapshotTemplatingDialog";
import { TextedCircularProgress } from "../../../components/TextedCircularProgress";

export const genSnapshotTemplatingPackedKey = (
  snapshotId?: string,
  templateId?: string
) => ["snapshotTemplatingPacked", String(snapshotId), String(templateId)];

interface SnapshotTemplatingCardProps {
  style?: React.CSSProperties;
  snapshotId?: string;
  challengeId: string;
  templateId: string;
  isSnapshotUpcoming?: boolean;
}

const makeBriefDifference = (
  templating?: SnapshotMinusTemplatePacked,
  maxDiffs: number = SNAPSHOT_TEMPLATE_DIFFERENCES_PER_PREVIEW
): [React.ReactNode[], boolean] => {
  const _maxDiffs = maxDiffs === -1 ? 999999 : maxDiffs;
  let isOverflow = _maxDiffs <= 0;
  if (_maxDiffs < 0) return [[], isOverflow];
  if (!templating) return [[], isOverflow];
  const diffs: React.ReactNode[] = [];

  const _makeDiff = (attr: string, color: string, prefix: string) => (
    <Stack direction={"row"} key={prefix + "-" + attr}>
      <Typography
        color={color}
        fontWeight="bold"
        style={{ width: "8vw", maxWidth: "20rem" }}
      >
        {prefix}
      </Typography>
      <Typography>{attr}</Typography>
    </Stack>
  );

  const _filterCriticalMisses = (attr: string) =>
    !templating.unused_optionals.includes(attr);

  templating.misses
    .filter(_filterCriticalMisses)
    .forEach((attr) => diffs.push(_makeDiff(attr, "error", "-")));
  templating.conflicting_nulls.forEach((attr) =>
    diffs.push(_makeDiff(attr, "error", "→Null"))
  );
  templating.conflicting_optionals.forEach((attr) =>
    diffs.push(_makeDiff(attr, "error", "→Optional"))
  );
  templating.hard_type_conflicts.forEach((attr) =>
    diffs.push(_makeDiff(attr, "error", "~Type"))
  );
  templating.misses
    .filter((attr) => !_filterCriticalMisses(attr))
    .forEach((attr) => diffs.push(_makeDiff(attr, "success.main", "-")));
  templating.extras.forEach((attr) =>
    diffs.push(_makeDiff(attr, "success.main", "+"))
  );
  templating.unused_nulls.forEach((attr) =>
    diffs.push(_makeDiff(attr, "success.main", "→Not-Null"))
  );
  templating.unused_optionals.forEach((attr) =>
    diffs.push(_makeDiff(attr, "success.main", "→Not-Optional"))
  );
  templating.soft_type_conflicts.forEach((attr) =>
    diffs.push(_makeDiff(attr, "success.main", "~Type"))
  );

  isOverflow = diffs.length > _maxDiffs;
  return [diffs.slice(0, _maxDiffs), isOverflow];
};

export const SnapshotTemplatingCard = ({
  snapshotId,
  style,
  isSnapshotUpcoming,
  challengeId,
  templateId,
}: SnapshotTemplatingCardProps) => {
  const perms = usePerms();
  const qTemplatingPacked = useQuery<SnapshotMinusTemplatePacked, BackendError>(
    genSnapshotTemplatingPackedKey(snapshotId, templateId),
    () => getSnapshotTemplatingPacked(snapshotId ?? ""),
    {
      enabled: !!snapshotId,
      staleTime: 1000 * 60 * 5, // 5 min
    }
  );

  const [, openModal, closeModal, isOpenModal] = useModal();

  const waitingForSnapshot = (
    <Typography variant="body2">Waiting for snapshot...</Typography>
  );
  const noSnapshot = (
    <Typography variant="body2">No snapshot to analyze</Typography>
  );

  const templatingPacked = qTemplatingPacked.data;
  const [briefDiffs, isDiffOverflow] = useMemo(
    () => makeBriefDifference(templatingPacked),
    [templatingPacked]
  );

  const content = qTemplatingPacked.isLoading ? (
    <TextedCircularProgress
      textPre={"Loading might take up to one minute ..."}
      isOneLine={false}
    />
  ) : qTemplatingPacked.isError ? (
    <ErrorAlert value={qTemplatingPacked.error} isClosable />
  ) : !templatingPacked ? (
    <ErrorAlert value="unknown error" isClosable />
  ) : templatingPacked.error ? (
    <ErrorAlert value={templatingPacked.error} isClosable />
  ) : briefDiffs.length === 0 ? (
    "All good"
  ) : (
    briefDiffs
  );

  if (!perms.has(Perm.MANAGE_ANY_TEMPLATE)) return null;

  return (
    <Card style={style}>
      {isSnapshotUpcoming ? (
        waitingForSnapshot
      ) : !snapshotId ? (
        noSnapshot
      ) : (
        <>
          {briefDiffs.length > 0 && (
            <Typography variant="h6">Suggested changes to format</Typography>
          )}
          {content}
          {isDiffOverflow && <Typography variant="body2">...</Typography>}
          <Button onClick={openModal}>View full details</Button>
          <SnapshotTemplatingDialog
            onClose={closeModal}
            open={isOpenModal}
            snapshotId={snapshotId}
            challengeId={challengeId}
            templateId={templateId}
          />
        </>
      )}
    </Card>
  );
};
