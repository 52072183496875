import axios from "axios";
import API from "../../API";
import { axiosTokened } from "../../utils/AxiosInstances";

export const getUsers = async () => {
  const { data } = await axios.get(API.getUsersBasic);
  return data;
};

export const patchUserShownName = async (shownName: string) => {
  await axiosTokened.patch(API.patchUserDetails, {
    shown_name: shownName,
  });
};

export const patchUserPassword = async (currentPassword: string, newPassword: string) => {
  const { data} = await axiosTokened.patch(API.patchUserDetails, {
    current_password: currentPassword,
    new_password: newPassword,
  });

  return data;
};