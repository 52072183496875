import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { BackendError } from "../types/BackendError";
import { SnapshotHead } from "../types/SnapshotHead";
import { getSnapshotStatistics } from "../pages/solutionSubmission/LocalOperations";

export const useQuerySnapshotStatistics = (
  snapshotId: string | number,
  options?: Omit<
    UseQueryOptions<SnapshotHead, BackendError, SnapshotHead, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _snapshotId = String(snapshotId);
  return useQuery<any, BackendError>(
    ["snapshotStats", _snapshotId],
    () => getSnapshotStatistics(_snapshotId),
    options
  );
};
