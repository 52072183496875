import { useQueryClient } from "react-query";

export const useQCodeRunHeadsRefetch = (
  challengeId: string | number,
  userId: string | number
) => {
  const queryClient = useQueryClient();
  return () => {
    const _challengeId = String(challengeId);
    const _userId = String(userId);
    queryClient.refetchQueries(["codeRunHeads", _challengeId, _userId], {
      active: true,
      exact: true,
    });
  };
};
