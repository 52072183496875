import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CodeRunDetails } from "../../../types/CodeRunDetails";
import { localizeDatetimeUTC } from "../../../utils/Localize";

interface FailingAutorunProps {
  failingAutorun: CodeRunDetails;
}

const FailingAutorun = ({ failingAutorun }: FailingAutorunProps) => {
  return (
    <Stack direction="row" className="singleRow">
      <Typography>Challenge {failingAutorun.challenge_id}</Typography>
      <Typography>
        {localizeDatetimeUTC(failingAutorun.date_run_start)}
      </Typography>
      <Typography>{failingAutorun.status}</Typography>
      <Link
        to={`/challenges/${failingAutorun.challenge_id}/code/${failingAutorun.challenge_run_number}/user/${failingAutorun.user_id}`}
      >
        Go to details
      </Link>
    </Stack>
  );
};

export default FailingAutorun;
