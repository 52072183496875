import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "react-query";
import { CodeRunReview } from "../../../types/CodeRunReview";
import { BackendError } from "../../../types/BackendError";
import { getCodeReview } from "../LocalOperations";

export const useQueryCodeReview = (
  challengeId: string,
  codeRunNumber: string,
  userId: string,
  options?: Omit<
    UseQueryOptions<CodeRunReview, BackendError, CodeRunReview, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery<CodeRunReview, BackendError>(
    ["codeReview", challengeId, codeRunNumber, userId],
    () => getCodeReview(challengeId, codeRunNumber, userId),
    options
  );
};

export const useRefetchQueryCodeReview = (
  challengeId: string,
  codeRunNumber: string,
  userId: string
) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.refetchQueries(
      ["codeReview", challengeId, codeRunNumber, userId],
      { active: true, exact: true }
    );
  };
};
