import {
  UseChallengeFilterProps,
  useChallengeFilter,
} from "../../ChallengeViewSteps/hooks/UseChallengeFilter";
import { ChallengeFiltering } from "./ChallengeFiltering";

interface ChallengeFilteringProviderProps extends UseChallengeFilterProps {
  children: React.ReactNode;
}

const ChallengeFilteringProvider = (props: ChallengeFilteringProviderProps) => {
  const { children, ...hookProps } = props;
  const filtering = useChallengeFilter(hookProps);
  return (
    <ChallengeFiltering.Provider value={filtering}>
      {children}
    </ChallengeFiltering.Provider>
  );
};

export default ChallengeFilteringProvider;
