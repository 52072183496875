import NextButton from "../../components/NextButton";
import { Typography, Paper, Container, Grid } from "@mui/material";
import DataLabelsContext, {
  DataLabelsContextItems,
} from "./contexts/DataLabelsContext";
import { useContext } from "react";
import DataLabelsTable from "./components/DataLabelsTable";

interface ConfirmDataFormatProps {
  templateId?: string;
}

const ConfirmDataFormat = ({ templateId }: ConfirmDataFormatProps) => {
  const { onConfirmDataLabels } =
    useContext<DataLabelsContextItems>(DataLabelsContext);

  return (
    <Container className="cardGrid">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" sx={{ marginBottom: 3 }}>
            Data format
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={0} sx={{ border: "none" }}>
            <DataLabelsTable templateId={templateId} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} marginTop={1}>
          <NextButton onClick={onConfirmDataLabels} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConfirmDataFormat;
