import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmProps {
  title?: string;
  text?: string;
  onConfirm: () => void;
  onCancel: () => void;
  yesText?: string;
  noText?: string;
  open: boolean;
}

const Confirm = ({
  title = "Confirm action",
  text = "Are You sure?",
  onConfirm,
  onCancel,
  yesText = "Yes",
  noText = "No",
  open,
}: ConfirmProps) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} autoFocus>
          {noText}
        </Button>
        <Button onClick={onConfirm}>{yesText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
