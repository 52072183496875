import { DiffEditor } from "@monaco-editor/react";
import { CircularProgress, Typography } from "@mui/material";
import ErrorAlert from "../../../components/ErrorAlert";
import { CodeRunHead } from "../../../types/CodeRunHead";
import { useQueryCodeRunDetails } from "../../../hooks/UseQueryCodeRunDetails";

interface CodeRunBodyCardsProps {
  codeRunHead: CodeRunHead;
  olderCodeRunHead?: CodeRunHead;
}

const CodeRunBodyCards = ({
  codeRunHead,
  olderCodeRunHead
}: CodeRunBodyCardsProps) => {
  const {
    challenge_id: challengeId,
    challenge_run_number: runNumber,
    user_id: userId,
    runtime,
  } = codeRunHead;

  const qCodeRun = useQueryCodeRunDetails(challengeId, runNumber, userId);
  const qPreviousCodeRun = useQueryCodeRunDetails(
    olderCodeRunHead?.challenge_id ?? "",
    olderCodeRunHead?.challenge_run_number ?? "",
    olderCodeRunHead?.user_id ?? "",
    { enabled: !!olderCodeRunHead }
  );

  if (qCodeRun.isLoading) {
    return <CircularProgress />;
  }
  if (qCodeRun.isError) {
    return <ErrorAlert value={qCodeRun.error} />;
  }
  if (qCodeRun.data === undefined) {
    return (
      <ErrorAlert value="Unexpected error. No body for code run retrieved." />
    );
  }

  const {
    code,
    output,
    stderr,
    stdout,
    internal_error: internalError,
  } = qCodeRun.data;

  const previousCode = qPreviousCodeRun.data?.code;

  return (
    <>
      <Typography variant="h6">Code</Typography>
      <DiffEditor
        original={previousCode}
        modified={code}
        language="python"
        height={'40vh'}
        options={{ readOnly: true }}
      />
      </>
  );
};

export default CodeRunBodyCards;
