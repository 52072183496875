import { CircularProgress, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useQuery } from "react-query";
import ErrorAlert from "../../../components/ErrorAlert";
import { BackendError } from "../../../types/BackendError";
import { CodeRunDetails, CodeRunsDetails } from "../../../types/CodeRunDetails";
import { getFailingAutoruns } from "../LocalOperations";
import FailingAutorun from "./FailingAutorun";

interface FailingAutorunsProps {
  userId?: string;
}

const FailingAutoruns = ({ userId }: FailingAutorunsProps) => {
  const qFailings = useQuery<CodeRunsDetails, BackendError>(
    ["failingAutoruns", userId],
    () => getFailingAutoruns(userId),
    { staleTime: 2 * 60 * 1000 }
  );

  if (qFailings.isError) {
    return (
      <ErrorAlert
        prefix="Failed to get failing autoruns. "
        value={qFailings.error}
      />
    );
  }
  if (qFailings.isLoading) {
    return <CircularProgress />;
  }
  if (!qFailings.data) {
    return <ErrorAlert value="Bad data got. Unexpected." />;
  }
  if (qFailings.data.length < 1) {
    return (
      <Typography variant="subtitle1">No failing autorun exists</Typography>
    );
  }

  return (
    <Container className="containerMain gapVertical">
      {qFailings.data.map((failingAutorun: CodeRunDetails) => (
        <FailingAutorun
          failingAutorun={failingAutorun}
          key={[
            failingAutorun.challenge_id,
            failingAutorun.challenge_run_number,
            failingAutorun.user_id,
          ].join("-")}
        />
      ))}
    </Container>
  );
};

export default FailingAutoruns;
