import API from "../../API";
import axios from "axios";
import { Project, ProjectBase } from "../../types/Projects";
import { Subscriptions } from "../../types/Subscription";
import { axiosTokened } from "../../utils/AxiosInstances";
import ProjectRoleEnum from "../../types/ProjectRoleEnum";
import { ProjectMakeTemplateAnswer } from "../solutionSubmission/components/ProjectCollision";
import ProjectNew from "../../types/ProjectNew";

const getProject = async (projectId: string) => {
  const { data } = await axios.get<Project>(API.genGetProject(projectId));
  return data;
};

const getProjectUsers = async (projectId: string) => {
  const { data } = await axios.get(API.genGetProjectUsers(projectId));
  return data;
};

const postAddUserToProject = async ({
  projectId,
  users,
}: {
  projectId: string;
  users: { user_id: number; role: ProjectRoleEnum }[];
}) => {
  await axiosTokened.post(API.genPostAddUserToProject(projectId), {
    users: users,
  });
};

const postUpdateUserProjectRole = async ({
  projectId,
  userId,
  role,
}: {
  projectId: string;
  userId: string;
  role: ProjectRoleEnum;
}) => {
  await axiosTokened.post(
    API.genUpdateUserProjectRole(projectId, userId, role)
  );
};

export const getProjectOnboardingTemplateName = async () => {
  const { data } = await axios.get<ProjectBase>(
    API.getProjectOnboardingTemplateName
  );
  return data;
};

export const patchMakeProjectOnboardingTemplate = async ({
  projectId,
  isForce,
}: {
  projectId: string | number;
  isForce: boolean;
}) => {
  const { data } = await axiosTokened.patch<ProjectMakeTemplateAnswer>(
    API.genPatchMakeProjectOnboardingTemplate(projectId),
    undefined,
    { params: { is_force: isForce } }
  );
  return data;
};

export const postProjectDuplicate = async (projectDuplicate: ProjectNew) => {
  const { data } = await axiosTokened.post(API.postProjectDuplicate, projectDuplicate);
  return data;
};

export const getProjectSubscriptions = async (projectId: string) => {
  const { data } = await axiosTokened.get<Subscriptions>(
    API.genGetProjectSubscriptions(projectId)
  );
  return data;
};

export const updateProjectSubscription = async (
  notification: string,
  active: boolean,
  projectId?: string
) => {
  await axiosTokened.post(API.updateProjectSubscription, {
    notification,
    project_id: projectId,
    active,
  });
};

export const patchProjectName = async (
  projectId: string,
  projectName: string
) => {
  await axiosTokened.patch(API.genPatchProjectDetails(projectId), {
    name: projectName,
  });
};

export const patchProjectDescription = async (
  projectId: string,
  projectDescription: string
) => {
  await axiosTokened.patch(API.genPatchProjectDetails(projectId), {
    description: projectDescription,
  });
};

export {
  getProject,
  getProjectUsers,
  postAddUserToProject,
  postUpdateUserProjectRole,
};
