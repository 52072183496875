import React from "react";
import {
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  List,
  ListItem,
  Grid,
  Button,
} from "@mui/material";
import PlatformRoles from "../../types/PlatformRoles";

interface SelectRoleProps {
  onRoleSelectSuccess: (role: string) => void;
  selectedRole: string;
  setSelectedRole: (selectedRole: string) => void;
}

const SelectRole = ({
  onRoleSelectSuccess,
  selectedRole,
  setSelectedRole,
}: SelectRoleProps) => {
  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const role = (event.target as HTMLInputElement).value;
    setSelectedRole(role);
  };

  const getButtonLabel = () => {
    if (selectedRole === PlatformRoles.BUSINESS_PARTNER) {
      return "Apply as Business Partner";
    } else if (selectedRole === PlatformRoles.CONTRIBUTOR) {
      return "Apply as Contributor";
    } else {
      return "Create my account";
    }
  };

  return (
    <Grid container className="containerMain">
      <Grid item xs={12} marginBottom={2} marginTop={2}>
        <Typography variant="h4" gutterBottom>
          Join as business or contributor
        </Typography>
      </Grid>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="role"
              name="role"
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <List
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "32px",
                }}
              >
                {[
                  PlatformRoles.BUSINESS_PARTNER,
                  PlatformRoles.CONTRIBUTOR,
                ].map((role) => (
                  <ListItem
                    key={role}
                    sx={{
                      width: "266px",
                      height: "124px",
                      borderRadius: "8px",
                      border: `1px solid ${
                        selectedRole === role ? "#398CBF" : "#EFEFEF"
                      }`,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <Radio
                      value={role}
                      sx={{ color: "neutral" }}
                      style={{
                        position: "absolute",
                        left: "226px",
                        width: "24px",
                      }}
                    />
                    <Typography
                      variant="body1"
                      style={{
                        position: "absolute",
                        left: "16px",
                        top: "60px",
                        width: "234px",
                        lineHeight: "24px",
                        fontFamily: "Open Sans",
                      }}
                    >
                      {role === PlatformRoles.BUSINESS_PARTNER
                        ? "I’m a business, looking for help with data."
                        : "I’m a contributor, looking for work."}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item marginTop={2}>
          <Button
            variant="contained"
            className="button-medium"
            onClick={() => onRoleSelectSuccess(selectedRole)}
            disabled={!selectedRole}
          >
            {getButtonLabel()}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectRole;
