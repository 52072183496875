import { Container, Tab, Tabs } from "@mui/material";
import PageTitle from "../../../components/PageTitle";
import React, { useState } from "react";
import CodeIcon from "@mui/icons-material/Code";
import DescriptionIcon from "@mui/icons-material/Description";
import SolutionCodeEditor from "./SolutionCodeEditor";
import { CodeRunDetails } from "../../../types/CodeRunDetails";
import { Runtime } from "../../../types/Runtimes";

interface SolutionSubmissionProps {
  challengeId: string;
  sourceRunNumber?: string;
  userId: string;
  style?: React.CSSProperties;
  onSuccessfulCode?: (codeDetailed: CodeRunDetails) => void;
  header?: string;
  subHeader?: string;
  isEdited: boolean;
  setIsEdited: (isEdited: boolean) => void;
  code: string;
  setCode: (code: string) => void;
}

const enum TabValue {
  CODE = "code",
  DATA = "data",
}

const SolutionSubmission = ({
  challengeId,
  sourceRunNumber,
  userId,
  style,
  onSuccessfulCode,
  header,
  subHeader,
  isEdited,
  setIsEdited,
  code,
  setCode,
}: SolutionSubmissionProps) => {
  const [tabValue, setTabValue] = useState<TabValue>(TabValue.CODE);
  const squishedHeight: React.CSSProperties = {
    paddingTop: "0px",
    paddingBottom: "0px",
    minHeight: "40px",
  };
  const styleSelected = { backgroundColor: "gainsboro" };
  const styleTabCode = tabValue === TabValue.CODE ? styleSelected : {};
  const styleTabData = tabValue === TabValue.DATA ? styleSelected : {};
  const [runtime, setRuntime] = useState<Runtime>({
    full_name: "",
    id: "",
    language: "",
    version: "",
  });

  return (
    <>
      <Container
        className="containerMain"
        style={{ marginTop: "0px", marginBottom: "0px", ...style }}
      >
        <PageTitle
          header={header}
          childrenStyle={{ textAlign: "left" }}
          subHeader={subHeader}
        />
        <Tabs
          value={tabValue}
          onChange={(_event: React.SyntheticEvent, newValue: TabValue) =>
            setTabValue(newValue)
          }
          style={{ ...squishedHeight, marginLeft: "15px", marginTop: "2px" }}
        >
          <Tab
            icon={<CodeIcon />}
            iconPosition="end"
            label="Submit code"
            style={{ ...squishedHeight, ...styleTabCode }}
            value={TabValue.CODE}
          />
          <Tab
            icon={<DescriptionIcon />}
            iconPosition="start"
            label="Upload XLS"
            style={{ ...squishedHeight, ...styleTabData }}
            value={TabValue.DATA}
          />
        </Tabs>
        {tabValue === TabValue.CODE && (
          <SolutionCodeEditor
            challengeId={challengeId}
            sourceRunNumber={sourceRunNumber}
            userId={userId}
            onSuccess={onSuccessfulCode}
            code={code}
            onCodeChange={setCode}
            runtime={runtime}
            onRuntimeChange={setRuntime}
            isEdited={isEdited}
            setIsEdited={setIsEdited}
          />
        )}
        {tabValue === TabValue.DATA && (
          <div
            style={{
              height: "554px",
              width: "100%",
              backgroundColor: "ghostwhite",
            }}
          >
            Yet to be
          </div>
        )}
      </Container>
    </>
  );
};

export default SolutionSubmission;
