import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import BooleanAnswer from "../../../types/BooleanAnswer";
import { BackendError } from "../../../types/BackendError";
import { getIsPassTemporary } from "../LocalOperations";
import { useUserState } from "../../../hooks/UserStateProvider";
import { useCallback } from "react";

export const useQueryIsPasswordTemp = (
  options?: Omit<
    UseQueryOptions<BooleanAnswer, BackendError, BooleanAnswer, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const [user] = useUserState();
  return useQuery<BooleanAnswer, BackendError>(
    ["isPassTemp", String(user.id)],
    getIsPassTemporary,
    options
  );
};

export const useInvalidateQueryIsPasswordTemp = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["isPassTemp"] });
  }, [queryClient]);
};
