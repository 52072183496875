import API from "../../API";
import axios from "axios";
import { CodeRunsDetails } from "../../types/CodeRunDetails";
import { Validations } from "../../types/Validations";
import { axiosTokened } from "../../utils/AxiosInstances";
import { UseredTokenPair } from "../../types/TokenPair";

export const getFailingAutoruns = async (userId?: string) => {
  const { data } = await axios.get<CodeRunsDetails>(API.getFailingAutoruns, {
    params: { user_id: userId !== "" ? userId : undefined },
  });
  return data;
};

export const getFailingValidations = async (userId?: string) => {
  const { data } = await axios.get<Validations>(API.getFailingValidations, {
    params: { user_id: userId !== "" ? userId : undefined },
  });
  return data;
};

export const postImpersonate = async (from_email: string, to_email: string) => {
  const { data } = await axiosTokened.post<UseredTokenPair>(
    API.postImpersonation,
    undefined,
    { params: { from: from_email, target_email: to_email } }
  );
  return data;
};
