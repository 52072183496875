import {
  useQuery,
  UseQueryOptions,
  useQueryClient,
  QueryKey,
} from "react-query";
import {
  getProjects,
  getUserProjects,
} from "../pages/ChallengesNewSteps/LocalOperations";
import { useCallback } from "react";
import { Project } from "../types/Projects";
import { BackendError } from "../types/BackendError";

// Fetch all existing projects
export const useQueryProjects = (
  options?: Omit<
    UseQueryOptions<Project[], BackendError, Project[], QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery<Project[], BackendError>("projects", getProjects, {
    staleTime: 300000,
    ...options,
  });
};

export const useInvalidateProjects = () => {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries("projects"),
    [queryClient]
  );
};

// Fetch user's projects
export const useQueryUserProjects = (
  options?: Omit<
    UseQueryOptions<Project[], BackendError, Project[], QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery<Project[], BackendError>("userProjects", getUserProjects, {
    staleTime: 300000,
    ...options,
  });
};

export const useInvalidateUserProjects = () => {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries("userProjects"),
    [queryClient]
  );
};

export const useInvalidateProject = (projectId: string | number) => {
  const queryClient = useQueryClient();
  const _projectId = String(projectId);
  return useCallback(
    () => queryClient.invalidateQueries(["Project", _projectId]),
    [queryClient, _projectId]
  );
}
