export enum GPTPromptRole {
  SYSTEM = "system",
  USER = "user",
  ASSISTANT = "assistant",
}

export type GPTPrompt = {
  content: string;
  role: GPTPromptRole;
};

export type GPTPrompts = GPTPrompt[];
