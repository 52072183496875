import { useQuery } from "react-query";
import { BackendError } from "../types/BackendError";
import { getMyPerms } from "../utils/SharedOperations";
import { useUserState } from "./UserStateProvider";
import { Perm } from "../Const";

const getPermsSet = async () => {
  const wrapper = await getMyPerms();
  return new Set<Perm>(wrapper.perms);
};

const placeholderPerms = new Set<Perm>();

/** returns current user perms. To check for perm: usePerms().has(perm) */
export const usePerms = (): Set<Perm> => {
  const [user] = useUserState();
  const qPerms = useQuery<Set<Perm>, BackendError>(
    ["perms", user.id],
    getPermsSet,
    {
      staleTime: 1 * 60 * 60 * 1000, // 1 hour
    }
  );

  if (!qPerms.isSuccess) {
    return placeholderPerms;
  }
  return qPerms.data;
};
