import { MutateOptions, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { BackendError } from "../../../types/BackendError";
import { Code } from "../../../types/Code";
import { CodeRunDetails } from "../../../types/CodeRunDetails";
import createDefaultCodeRunRequest from "../../../utils/CreateDefaultCodeRunRequest";
import { postCode, PostCodeParams } from "../LocalOperations";

type HookReturnType = {
  isLoading: boolean;
  isSuccess: boolean;
  data?: CodeRunDetails;
  isError: boolean;
  error: BackendError | null;
  onSubmitCode: (code: Code) => void;
};

const useOnSubmitCode = (
  challengeId: string,
  userId: string,
  options?: MutateOptions<CodeRunDetails, BackendError, PostCodeParams, unknown>
): HookReturnType => {
  const navigate = useNavigate();
  const qSubmitCode = useMutation<CodeRunDetails, BackendError, PostCodeParams>(
    postCode
  );
  const onSuccess = options?.onSuccess;

  const onSubmitCode = (code: Code) => {
    const codeRunRequest = createDefaultCodeRunRequest();
    codeRunRequest.code = code.code;
    codeRunRequest.runtime_id = code.runtime_id;
    codeRunRequest.user_id = userId;
    qSubmitCode.mutate(
      {
        challengeId: challengeId,
        codeRunRequest: codeRunRequest,
      },
      {
        ...options,
        onSuccess: !!onSuccess
          ? onSuccess
          : (codeRunDetails: CodeRunDetails) => {
              // temporary it is a default behaviour. After design v2, default behaviour will be removed
              navigate(
                `code/${codeRunDetails.challenge_run_number}/user/${codeRunDetails.user_id}`
              );
            },
      }
    );
  };

  return {
    isLoading: qSubmitCode.isLoading,
    isError: qSubmitCode.isError,
    error: qSubmitCode.error,
    isSuccess: qSubmitCode.isSuccess,
    data: qSubmitCode.data,
    onSubmitCode,
  };
};

export default useOnSubmitCode;
