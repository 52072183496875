import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { Solving } from "../../../types/Solvings";
import { BackendError } from "../../../types/BackendError";
import { getSolving } from "../LocalOperations";

export const useQuerySolving = (
  challengeId: number | string,
  userId: number | string,
  options?: Omit<
    UseQueryOptions<Solving, BackendError, Solving, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _challengeId = String(challengeId);
  const _userId = String(userId);
  return useQuery<Solving, BackendError>(
    ["solving", _challengeId, _userId],
    () => getSolving(_challengeId, _userId),
    options
  );
};
