import TitledCard from "./TitledCard";

interface TitledCodeTextCardProps {
  title?: string;
  value?: string | null;
  wordWrap?: boolean;
}

const TitledCodeTextCard = ({
  title,
  value,
  wordWrap,
}: TitledCodeTextCardProps) => {
  return (
    <TitledCard title={title}>
      <pre
        style={
          wordWrap ? { wordWrap: "break-word", whiteSpace: "pre-wrap" } : {}
        }
      >
        {value}
      </pre>
    </TitledCard>
  );
};

export default TitledCodeTextCard;
