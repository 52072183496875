import Title from "./Title";
import BasicHeaders from "../types/BasicHeaders";

const ChapterTitle = (props: BasicHeaders) => {
  return (
    <div className="chapterTitle" style={props.style}>
      <Title
        header={props.header}
        subHeader={props.subHeader}
        headerVariant="body1"
        headerBold
        subHeaderVariant="body1"
      />
    </div>
  );
};

export default ChapterTitle;
