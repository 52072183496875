import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  TextField,
  Paper,
  Grid,
} from "@mui/material";
import { useQuery } from "react-query";
import ErrorAlert from "../../../components/ErrorAlert";
import { BackendError } from "../../../types/BackendError";
import { Template, Templates } from "../../../types/Template";
import { getNamedTemplates } from "../LocalOperations";

interface TemplateOption {
  label?: string; // `?` is needed for mui autocomplete component `options`.
  id: string;
}

interface SearchExistingFormatProps {
  label?: string;
  onChooseExisting: (templateId: string) => void;
  disabled?: boolean;
  canChooseNothing?: boolean;
  value?: string;
  size?: AutocompleteProps<string, undefined, undefined, undefined>["size"];
}

const SearchExistingFormat = ({
  label,
  onChooseExisting,
  disabled,
  canChooseNothing,
  value,
  size,
}: SearchExistingFormatProps) => {
  const qTemplates = useQuery<Templates, BackendError>(
    "namedTemplates",
    getNamedTemplates
  );

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: TemplateOption | null
  ) => {
    if (newValue) {
      onChooseExisting(newValue.id);
    } else {
      onChooseExisting("");
    }
  };

  // used for cases when we want to control value.
  // example: auto-choose value from previous search
  const matchedTemplate = // only used for <Autocomplete> controlled case
    qTemplates.data?.find((template: Template) => template.id === value);
  const labeledValue =
    value === undefined
      ? undefined // keep <Autocomplete> UNcontrolled
      : matchedTemplate === undefined
      ? null //keep <Autocomplete> controlled
      : { id: matchedTemplate.id, label: matchedTemplate.name };

  const options =
    qTemplates.data?.map((template: Template) => {
      return {
        label: template.name,
        id: template.id,
      };
    }) ?? [];

  return (
    <>
      <Grid item xs={12} sm={5} md={4}>
        <Paper elevation={0} sx={{ border: "none" }}>
          <Autocomplete
            options={options}
            renderInput={(params) => <TextField {...params} label={label} />}
            onChange={handleChange}
            loading={qTemplates.isLoading}
            loadingText={<CircularProgress />}
            autoHighlight
            isOptionEqualToValue={(
              option: TemplateOption,
              value: TemplateOption
            ) => option.id === value.id}
            value={labeledValue}
            size={size}
            disabled={disabled}
          />
        </Paper>
      </Grid>
      {qTemplates.isError && (
        <ErrorAlert
          prefix="Error while loading existing formats: "
          value={qTemplates.error}
        />
      )}
    </>
  );
};

export default SearchExistingFormat;
