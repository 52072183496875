import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import Confirm from "../../../components/Confirm";

interface ButtonsCancelCreateDeleteProps {
  onCreate: () => void;
  onCancel: () => void;
  onDelete: () => void;
  isEditing?: boolean;
  isCreating?: boolean;
  deletionDialogTitle?: string;
  deletionDialogText?: string;
}

const ButtonsCancelCreateDelete = ({
  onCreate,
  onCancel,
  onDelete,
  isEditing,
  isCreating,
  deletionDialogTitle,
  deletionDialogText,
}: ButtonsCancelCreateDeleteProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const cancelDeletion = () => {
    setIsDialogOpen(false);
  };

  const activateDeletion = () => {
    setIsDialogOpen(false);
    onDelete();
  };

  return (
    <>
      <IconButton
        size="large"
        color="primary"
        onClick={onCreate}
        disabled={isCreating || isEditing}
      >
        <AddIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        size="large"
        color="secondary"
        onClick={onCancel}
        disabled={!isCreating && !isEditing}
      >
        <CancelIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        size="large"
        color="error"
        onClick={() => setIsDialogOpen(true)}
        disabled={!isEditing}
      >
        <DeleteIcon fontSize="inherit" />
      </IconButton>
      <Confirm
        title={deletionDialogTitle}
        text={deletionDialogText}
        onConfirm={activateDeletion}
        onCancel={cancelDeletion}
        open={isDialogOpen}
      />
    </>
  );
};

export default ButtonsCancelCreateDelete;
