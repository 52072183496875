import TitledCodeTextCard from "../../../components/TitledCodeTextCard";
import TabbedContentDialog, { DialogTabs } from "./TabbedContentDialog";
import { Validation } from "../../../types/Validations";
import { DialogProps, Typography } from "@mui/material";
import { getStatusClassname } from "../../../components/ColoredStatus";

interface ValidationErrorDialogProps extends DialogProps {
  onClose: () => void;
  title?: string;
  text?: string;
  validation: Validation;
  contentStyle?: React.CSSProperties;
}

const _makeTabs = (validation: Validation): DialogTabs => {
  const tabs: DialogTabs = [];
  if (validation.error) {
    tabs.push({
      title: "Error",
      content: <TitledCodeTextCard value={validation.error} wordWrap />,
    });
  }
  if (validation.warning) {
    tabs.push({
      title: "Warning",
      content: <TitledCodeTextCard value={validation.warning} wordWrap />,
    });
  }
  if (getStatusClassname(validation.status) === "success-tag") {
    tabs.push({
      title: "Success",
      content: <Typography>All good, nothing to see here.</Typography>,
    });
  }
  if (tabs.length < 1) {
    tabs.push({
      title: "Info",
      content: (
        <Typography>Sorry, no additional information provided.</Typography>
      ),
    });
  }
  return tabs;
};

const ValidationErrorDialog = (props: ValidationErrorDialogProps) => {
  const {
    onClose,
    open,
    title,
    text,
    validation,
    contentStyle,
    ...dialogProps
  } = props;
  const tabs = open ? _makeTabs(validation) : [];

  return (
    <TabbedContentDialog
      open={open}
      onClose={onClose}
      prefix={
        text ?? "Providing the details about validation error or warning."
      }
      tabs={tabs}
      title={
        title ??
        `"${
          validation.validator_custom_name || validation.validator_name
        }" details`
      }
      contentStyle={contentStyle}
      {...dialogProps}
    />
  );
};

export default ValidationErrorDialog;
