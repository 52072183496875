import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useState } from "react";

export type DialogTab = {
  content: React.ReactNode;
  title: string;
};

export type DialogTabs = DialogTab[];

interface TabbedContentDialogProps extends DialogProps {
  onClose: () => void;
  title?: string;
  tabs: DialogTabs;
  isSoloTabHidden?: boolean;
  contentStyle?: React.CSSProperties;
  prefix?: string;
  suffixNode?: React.ReactNode;
  downloadLink?: React.ReactNode;
}

const TabbedContentDialog = (props: TabbedContentDialogProps) => {
  const {
    title,
    tabs,
    onClose,
    contentStyle,
    isSoloTabHidden,
    prefix,
    suffixNode,
    children,
    downloadLink,
    ...dialogProps
  } = props;
  const [_tabIndex, setTabIndex] = useState(0);
  const tabIndex = _tabIndex + 1 > tabs.length ? tabs.length - 1 : _tabIndex; // protect self from varying length tab-array

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      {!!title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent style={{ width: "100%", ...contentStyle }}>
        {!!prefix && <DialogContentText>{prefix}</DialogContentText>}
        {(tabs.length > 1 || !isSoloTabHidden) && (
          <Tabs value={tabIndex} onChange={(_, value) => setTabIndex(value)}>
            {tabs.map((tab, index) => (
              <Tab
                label={tab.title}
                value={index}
                key={`${index}`} // tab.title might be used, but maybe not all titles will be unique
              />
            ))}
          </Tabs>
        )}
        {tabs.length > 0 && tabIndex > -1 && (
          <div style={{ width: "100%" }}>{tabs[tabIndex].content}</div>
        )}
        {suffixNode}
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {downloadLink}
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TabbedContentDialog;
