import { createContext, useContext } from "react";
import { Projects } from "../types/Projects";

interface UserProjectsContextProps {
  userProjects: Projects;
  refetchUserProjects: () => void;
}

export const UserProjectsContext = createContext<UserProjectsContextProps | undefined>(
  undefined
);

export const useUserProjects = () => {
  const context = useContext(UserProjectsContext);
  if (!context) {
    throw new Error(
      "'useUserProjects' hook must be used within a UserProjectsProvider"
    );
  }
  return context;
};
