import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import React from "react";
import SplitButtonDropdown from "../../../components/SplitButtonDropdown";

export type BulkAction = {
  name: string;
  action?: () => void;
  disabled?: boolean;
  tooltip?: string;
};

interface TemplatingBulkActionsProps {
  isHidden?: boolean;
  style?: React.CSSProperties;
  boxStyle?: React.CSSProperties;
  boxSx?: SxProps;
  isSticky?: boolean;
  isDisabled?: boolean;
}

export const TemplatingBulkActions = ({
  style,
  boxStyle,
  boxSx,
  isSticky,
  isHidden,
  isDisabled,
}: TemplatingBulkActionsProps) => {
  const bulkActions: BulkAction[] = [
    {
      name: "Insert new labels",
      tooltip: "Insert all deleted used-labels (color: green or white)",
    },
    {
      name: "Remove unused labels",
      tooltip:
        "Remove all labels that are not used in the snapshot (color: red)",
    },
    {
      name: "Fix optionals",
      tooltip:
        "Uncheck optionals that were not used in snapshot (red color circle) ; check optionals that were used in snapshot but not in schema (green color circle)",
    },
    {
      name: "Fix nullables",
      tooltip:
        "Uncheck nullables that were not used in snapshot (red color circle) ; check nullables that were used in snapshot but not in schema (green color circle)",
    },
    {
      name: "Fix optionals + nullables",
      tooltip: 'Applies "Fix optionals" and "Fix nullables"',
    },
    {
      name: "Fix dataTypes",
      tooltip:
        "Change dataTypes to match snapshot. Uses the advised dataTypes if applicable",
    },
    {
      name: "Apply all suggestions",
      tooltip: "Inserts, removes, fixes, ... - applies all suggestions",
    },
  ];

  return isHidden ? null : (
    <Box
      style={
        isSticky
          ? {
              width: "100%",
              position: "sticky",
              top: 0,
              zIndex: 10,
              ...boxStyle,
            }
          : boxStyle
      }
      sx={isSticky ? { bgcolor: "background.paper", ...boxSx } : boxSx}
    >
      <SplitButtonDropdown
        primaryButton={{ node: "Bulk edit" }}
        variant="contained"
        color="info"
        isPrimaryButtonExpandsDropdown
        dropdownItems={bulkActions.map((actionItem) => {
          return {
            node: (
              <Tooltip key={actionItem.name} title={actionItem.tooltip}>
                <Typography>{actionItem.name}</Typography>
              </Tooltip>
            ),
            onClick: actionItem.disabled ? undefined : actionItem.action,
          };
        })}
        style={style}
        disabled={isDisabled}
      />
    </Box>
  );
};
