import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { CodeRunDetails } from "../types/CodeRunDetails";
import { BackendError } from "../types/BackendError";
import { getCodeRunDetails } from "../pages/ChallengeViewSteps/LocalOperations";

export const useQueryCodeRunDetails = (
  challengeId: string | number,
  runNumber: string | number,
  userId: string | number,
  options?: Omit<
    // type copy-pasted from type hints
    UseQueryOptions<CodeRunDetails, BackendError, CodeRunDetails, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _challengeId = String(challengeId);
  const _runNumber = String(runNumber);
  const _userId = String(userId);
  return useQuery<CodeRunDetails, BackendError>(
    ["codeRunDetails", _challengeId, _runNumber, _userId],
    () => getCodeRunDetails(_challengeId, _runNumber, _userId),
    options
  );
};
