import { Button } from "@mui/material";
import { useMemo, useState } from "react";
import { CHAR_LIMIT_FOR_TRUNCATABLE } from "../Const";
import TitledCard from "./TitledCard";

interface TitledTruncatableCardProps {
  title?: string;
  value?: string | null;
}

const TitledTruncatableCard = ({
  title,
  value,
}: TitledTruncatableCardProps) => {
  const isTruncationApplicable =
    value && value.length > CHAR_LIMIT_FOR_TRUNCATABLE;
  const [isHidingMore, setIsHidingMore] = useState(true);

  const valueSubstring = useMemo<undefined | string>(
    () => value?.substring(0, CHAR_LIMIT_FOR_TRUNCATABLE),
    [value]
  );
  const visibleValue = isHidingMore ? valueSubstring : value;
  const buttonText = isHidingMore ? "Show more" : "Show less";

  return (
    <TitledCard title={title}>
      <pre style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
        {visibleValue}
      </pre>
      {isTruncationApplicable && (
        <Button onClick={() => setIsHidingMore(!isHidingMore)} variant="text">
          {buttonText}
        </Button>
      )}
    </TitledCard>
  );
};

export default TitledTruncatableCard;
