import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Runtime, Runtimes } from "../../../types/Runtimes";
import { useQuery } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { getRuntimes } from "../LocalOperations";
import createDefaultRuntime from "../../../utils/CreateDefaultRuntime";

interface SelectRuntimeProps {
  idValue: string;
  onChange: (runtime: Runtime) => void;
  error?: boolean;
  disabled?: boolean;
  autoSelectFirst?: boolean;
}

const SelectRuntime = (props: SelectRuntimeProps) => {
  const qGetRuntimes = useQuery<Runtimes, BackendError>(
    "runtimes",
    getRuntimes,
    {
      onSuccess: (data: Runtimes) => {
        if (props.autoSelectFirst && props.idValue === "" && data[0]) {
          props.onChange(data[0]);
        }
      },
    }
  );

  const onChange = (e: SelectChangeEvent) => {
    let arg: Runtime = createDefaultRuntime();
    if (qGetRuntimes.data) {
      const matchedRuntimes = qGetRuntimes.data.filter(
        (runtime: Runtime) => runtime.id === e.target.value
      );
      const matched = matchedRuntimes[0];
      arg = matched ?? arg;
    }
    props.onChange(arg);
  };

  return (
    <FormControl required sx={{ m: 1, minWidth: 120 }} error={props.error}>
      <InputLabel id="select-runtime-label">Runtime</InputLabel>
      <Select
        labelId="select-runtime-label"
        id="select-runtime"
        // this label is not visible BUT select will reserve as much space
        // (for real label) as this label takes
        label="Runtime"
        value={qGetRuntimes.data ? props.idValue : ""}
        onChange={onChange}
        disabled={props.disabled}
      >
        {qGetRuntimes.isLoading ? (
          <MenuItem value="">
            <em>Loading ...</em>
          </MenuItem>
        ) : qGetRuntimes.data ? (
          qGetRuntimes.data.map((runtime: Runtime) => (
            <MenuItem value={runtime.id} key={runtime.id}>
              {runtime.full_name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="">No runtimes found</MenuItem>
        )}
      </Select>
      <FormHelperText>Required</FormHelperText>
    </FormControl>
  );
};

export default SelectRuntime;
