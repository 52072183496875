import { BodyError } from "../types/BackendError";
import { ErrorLike } from "../types/ErrorLike";

const isArrayBodyError = (
  detail: string | [BodyError]
): detail is [BodyError] => {
  return Array.isArray(detail);
};

const extractError = (error: ErrorLike): string | null => {
  if (error === null) {
    return null;
  }
  if (typeof error === "string") {
    // extract extracted? just return same error string
    return error;
  }
  if (error.response === undefined) {
    return (error as unknown as Error).message;
  }
  let detail = error.response.data.detail;
  if (isArrayBodyError(detail)) {
    return detail
      .map((bodyError: BodyError) => {
        return `Error type: (${bodyError.type}) message: (${bodyError.msg}) location: (${bodyError.loc}).`;
      })
      .join("\n");
  }
  return error.response.data.detail as string;
};

export default extractError;
