import { CircularProgress, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { CodeRunDetails } from "../../../types/CodeRunDetails";
import CodeRunTable from "./CodeRunTable";
import ErrorAlert from "../../../components/ErrorAlert";
import CodeRunTableRow from "./CodeRunTableRow";
import SnapshotsView from "../../SnapshotsView";
import { useQueryCodeRunDetails } from "../../../hooks/UseQueryCodeRunDetails";

type SingleCodeRunParams = {
  challengeId?: string;
  runNumber?: string;
  userId?: string;
};

const SingleCodeRun = () => {
  const {
    challengeId = "",
    runNumber = "",
    userId = "",
  } = useParams<SingleCodeRunParams>();
  const qCodeRunDetails = useQueryCodeRunDetails(
    challengeId,
    runNumber,
    userId,
    {
      refetchInterval: (codeRunDetails?: CodeRunDetails) => {
        return codeRunDetails === undefined || !codeRunDetails?.is_run_done
          ? 3 * 1000
          : 0;
      },
    }
  );
  const codeRunDetails = qCodeRunDetails.data;
  return (
    <>
      {qCodeRunDetails.isLoading && <CircularProgress />}
      {qCodeRunDetails.isError && (
        <ErrorAlert prefix="Error. " value={qCodeRunDetails.error} />
      )}
      {qCodeRunDetails.isSuccess && codeRunDetails && (
        <CodeRunTable>
          <CodeRunTableRow
            codeRunHead={codeRunDetails}
            openByDefault
            queuedUserId={userId}
          ></CodeRunTableRow>
        </CodeRunTable>
      )}
      <Typography textAlign="center" variant="subtitle1">
        Code run autorun feature is available when viewing all submissions
      </Typography>
      <div className="br" />
      {codeRunDetails && !!codeRunDetails.snapshot_id ? (
        <SnapshotsView snapshotId={codeRunDetails.snapshot_id} />
      ) : (
        <Typography textAlign="center" variant="subtitle1">
          No snapshot was generated for this code run
        </Typography>
      )}
      <div className="br-big" />
    </>
  );
};

export default SingleCodeRun;
