import axios, { AxiosError } from "axios";
import {
  deleteTokenPair,
  getAccessToken,
  getRefreshToken,
  refreshTokens,
} from "./TokenManagement";
import { setStorageUser } from "./LoadUser";
import createDefaultUser from "./CreateDefaultUser";

const axiosTokened = axios.create();

// auto append Bearer
axiosTokened.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      // Authorization header appended only when access token is set
      config.headers = {
        ...config.headers, // also handles cases where config.headers is null or undefined
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// auto refresh token
axiosTokened.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const config = error?.config || {};
    const refreshToken = getRefreshToken();

    if (
      // Property 'isRetried' does not exist on type 'AxiosRequestConfig<any>'
      // @ts-expect-error
      !!config.isRetried ||
      error?.response?.status !== 401 ||
      !refreshToken
    ) {
      // .isRetried - request tried after refreshing tokens?
      // status code 401 - unauthorized. Token expired or no token supplied.
      // !refreshToken - public user, unlogged, token refresh not applicable
      return Promise.reject(error);
    }

    try {
      await refreshTokens(refreshToken);
    } catch (ex) {
      // ex is of unknown type
      // @ts-expect-error
      if (!!ex.isAxiosError && !ex.response) {
        // https://github.com/axios/axios/issues/383#issuecomment-539544039
        // network error, so let us leave user logged-in for when he comes back into connection

        // error of original request, not of failed refresh-tokens.
        return Promise.reject(error);
      } else {
        // refreshing failed not because of network error. delete tokens
        deleteTokenPair();
        // also locally logout #63
        setStorageUser(createDefaultUser());
        // error of original request, not of failed refresh-tokens.
        return Promise.reject(error);
      }
    }
    const accessToken = getAccessToken();
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    // we mark upcoming request as retried to avoid infinite go-refresh-tokens loop
    // Property 'isRetried' does not exist on type 'AxiosRequestConfig<any>'
    // @ts-expect-error
    config.isRetried = true;

    return axiosTokened.request(config);
  }
);

export { axiosTokened };
