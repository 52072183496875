import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FormatChallengeReservedDate } from "../../../utils/FormatChallengeReservedDate";
import { ChallengeDetailed } from "../../../types/ChallengesDetailed";

interface ChallengeSolverDetailsProps {
  challenge: ChallengeDetailed;
  sla: any;
}

const ChallengeSolverDetails: React.FC<ChallengeSolverDetailsProps> = ({
  challenge,
  sla,
}) => {
  return (
    <Paper elevation={0} sx={{ padding: 2, marginBottom: 3 }}>
      <TableContainer>
        <Table>
          <TableHead>
            {!challenge.is_reserved ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No solver is currently assigned to this challenge.
                </TableCell>
              </TableRow>
            ) : (
              <>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell align="right">Reserved until</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{sla.solver}</TableCell>
                  <TableCell align="right">
                    <FormatChallengeReservedDate
                      dateString={challenge.reserved_through ?? null}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ChallengeSolverDetails;
