import React from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import TTabID from "../../../types/ChallengeCreationTabsEnum";

interface CreateTaskButtonProps {
  isExpanded: boolean;
  buttonSize?: "small" | "medium" | "large";
}

const CreateTaskButton: React.FC<CreateTaskButtonProps> = ({
  isExpanded,
  buttonSize,
}) => {
  return (
    <Link
      to={`/challenges/new/?tab=${TTabID.ChallengeCreationIntro}`}
      style={{ textDecoration: "none" }}
    >
      <Fab
        size={buttonSize || "medium"}
        color="primary"
        variant={isExpanded ? "extended" : "circular"}
        sx={{ whiteSpace: "normal", ...(isExpanded && { width: "200px" }) }}
      >
        <AddIcon sx={{ marginRight: isExpanded ? 1 : 0 }} />
        {isExpanded && "Create task"}
      </Fab>
    </Link>
  );
};

export default CreateTaskButton;
