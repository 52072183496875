import { Alert, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import ErrorAlert from "../../components/ErrorAlert";
import PageTitle from "../../components/PageTitle";

interface FinishLocationIn {
  data?: any;
  error: string | null;
}

const Finish = () => {
  const location = useLocation();

  const query = location.state as FinishLocationIn | undefined;

  if (query === undefined) {
    return (
      <h1>
        Ooops... Did you mean to go to the
        <Link to="/"> main page?</Link>
      </h1>
    );
  }

  const errorMessage = query.error;
  const isError: boolean = query.error !== null;
  const isSuccess: boolean = !isError;

  return (
    <>
      <PageTitle
        header="Results of submitting new challenge"
        subHeader="(Reloading the page will change nothing)"
      />
      <Grid container direction="column" className="gridMain">
        {isSuccess && <Alert severity="success">Submitted successfully.</Alert>}
        {isError && <ErrorAlert value={errorMessage} />}
      </Grid>
    </>
  );
};

export default Finish;
