import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import { useState } from "react";

interface PasswordInputProps extends OutlinedInputProps {
  /** defaults to "end" */
  eyeButtonPosition?: "start" | "end" | "none";
}

const PasswordInput = ({
  type,
  eyeButtonPosition = "end",
  endAdornment,
  startAdornment,
  ...inputProps
}: PasswordInputProps) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const eyeAdornment =
    eyeButtonPosition === "none" ? undefined : (
      <InputAdornment position={eyeButtonPosition}>
        <IconButton
          onClick={() => setIsShowPassword((prev) => !prev)}
          edge={eyeButtonPosition}
        >
          {isShowPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  const adornmentsAsProps = {
    // eyeAdornment > adornments via props
    endAdornment: eyeButtonPosition === "end" ? eyeAdornment : endAdornment,
    startAdornment:
      eyeButtonPosition === "start" ? eyeAdornment : startAdornment,
  };

  return (
    // also works with <Input /> but looks unnatural
    <OutlinedInput
      type={isShowPassword ? "text" : "password"}
      {...adornmentsAsProps}
      {...inputProps}
    />
  );
};

export default PasswordInput;
