import API from "../../API";
import { axiosTokened } from "../../utils/AxiosInstances";

export const patchForceAutorun = async (
  challengeId: string,
  challengeRunNumber: string,
  userId: string
) => {
  await axiosTokened.patch(
    API.genPatchForceAutorun(challengeId, challengeRunNumber, userId)
  );
};

export const getXLSBlob = async (
  challengeId: string,
  challengeRunNumber: string,
  userId: string
) => {
  return await axiosTokened.get(
    API.genDownloadXLS(challengeId, challengeRunNumber, userId, null),
    {responseType: 'blob'}
  );
};

export const getChallengeXLSBlob = async (
  challengeId: string,
) => {
  return await axiosTokened.get(
    API.genChallengeDownloadXLS(challengeId),
    {responseType: 'blob'}
  );
};

export const patchForceRun = async (
  challengeId: string,
  challengeRunNumber: string,
  userId: string
) => {
  await axiosTokened.patch(
    API.genPatchForceRun(challengeId, challengeRunNumber, userId)
  );
};

export const patchCancelRun = async (
  challengeId: string,
  challengeRunNumber: string,
  userId: string
) => {
  await axiosTokened.patch(
    API.genPatchCancelRun(challengeId, challengeRunNumber, userId)
  );
};

