import { useQuery } from "react-query";
import { DataFormat } from "../types/DataFormat";
import { BackendError } from "../types/BackendError";
import { getTemplateDataFormat } from "../pages/ChallengesNewSteps/LocalOperations";

export const useQueryDataFormat = (templateId: string) => {
  return useQuery<DataFormat, BackendError>(
    ["templateDataFormat", templateId],
    () => getTemplateDataFormat(templateId)
  );
};
