import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconProps,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { TemplatingEditContext } from "../contexts/TemplatingEditContext";
import { useMutation } from "react-query";
import {
  ReasoningName,
  TemplatingImpactSummary,
} from "../../../types/TemplatingImpactSummary";
import { BackendError } from "../../../types/BackendError";
import { postEvaluateTemplatingImpact } from "../LocalOperations";
import ErrorAlert from "../../../components/ErrorAlert";
import BuildIcon from "@mui/icons-material/Build";
import {
  ExpandLess,
  ExpandMore,
  PowerOff,
  ThumbUp,
  Warning,
} from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import Check from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { Link } from "react-router-dom";
import ChallengeTab from "../../../types/ChallengeViewTabsEnum";

interface TemplatingImpactProps {
  style?: React.CSSProperties;
}

const _debugCountings = (summary?: TemplatingImpactSummary) => {
  const outs: React.ReactNode[] = [];
  Object.values(ReasoningName).forEach((key) => {
    if (!summary?.reasoning) {
      return;
    }
    const attrToAffecteds = summary.reasoning[key];
    if (!attrToAffecteds) {
      return;
    }
    for (const attr in attrToAffecteds) {
      const list = attrToAffecteds[attr];
      if (!list) {
        continue;
      }
      // how is triggerrred wwritten?
      outs.push(
        <Stack direction="row" key={key + "-" + attr}>
          <Box flex={3}>Triggerred {list.length} Challenges</Box>
          <Box flex={3}>in &lt;{key}&gt;</Box>
          <Box flex={2}>field &lt;{attr}&gt;</Box>
        </Stack>
      );
    }
  });
  return outs;
};

const _debugUnknowns = (summary?: TemplatingImpactSummary) => {
  const outs: React.ReactNode[] = [];
  if (!summary?.reason_unknowns) {
    return outs;
  }

  for (const challenge_id in summary?.reason_unknowns) {
    const _challenge_id = Number(challenge_id);
    if (Number.isNaN(_challenge_id)) {
      continue;
    }
    outs.push(
      <Stack direction="row" key={"unknown-" + challenge_id}>
        <Box flex={3}>
          #{challenge_id} {summary.challenge_id_to_name?.[_challenge_id] || ""}{" "}
          got into unknowns:
        </Box>
        <Box flex={5}>{summary.reason_unknowns[_challenge_id]}</Box>
      </Stack>
    );
  }
  return outs;
};

const _debugCategories = (summary?: TemplatingImpactSummary) => {
  const outs: React.ReactNode[] = [];
  Object.values(ReasoningName).forEach((key) => {
    if (!summary?.reasoning) {
      return;
    }
    const attrToAffecteds = summary.reasoning[key];
    if (!attrToAffecteds) {
      return;
    }
    for (const attr in attrToAffecteds) {
      const list = attrToAffecteds[attr];
      outs.push(
        ...list.map((challenge_id) => (
          <Stack
            direction="row"
            key={String(challenge_id) + "-" + key + "-" + attr}
          >
            <Box flex={3}>
              {summary.challenge_id === challenge_id ? "(self) " : ""}#
              {challenge_id}{" "}
              {summary.challenge_id_to_name?.[challenge_id] || ""}
            </Box>
            <Box flex={3}>in &lt;{key}&gt;</Box>
            <Box flex={2}>field &lt;{attr}&gt;</Box>
          </Stack>
        ))
      );
    }
  });
  return outs;
};

const _debugWarnings = (summary?: TemplatingImpactSummary) => {
  const outs: React.ReactNode[] = [];
  if (!summary?.challenge_id_to_warning) {
    return outs;
  }

  for (const challenge_id in summary?.challenge_id_to_warning) {
    const _challenge_id = Number(challenge_id);
    if (Number.isNaN(_challenge_id)) {
      continue;
    }
    outs.push(
      <Stack direction="row" key={"warning-" + challenge_id}>
        <Box flex={3}>
          #{challenge_id} {summary.challenge_id_to_name?.[_challenge_id] || ""}{" "}
          warns:
        </Box>
        <Box flex={5}>{summary.challenge_id_to_warning[_challenge_id]}</Box>
      </Stack>
    );
  }
  return outs;
};

const makeDebug = (summary?: TemplatingImpactSummary): React.ReactNode[] => {
  const outs: React.ReactNode[] = [];
  const fallback: React.ReactNode[] = [
    <Box key="nodebug">No debug information</Box>,
  ];
  if (!summary?.reasoning) {
    return fallback;
  }

  outs.push(_debugCountings(summary));
  outs.push(_debugWarnings(summary));
  outs.push(_debugUnknowns(summary));
  outs.push(_debugCategories(summary));

  return outs.length > 0 ? outs : fallback;
};

const makeChallengeSection = (summary?: TemplatingImpactSummary) => {
  const outs: React.ReactNode[] = [];
  const fallback: React.ReactNode[] = [
    <Box key="nochallenge">Click Evaluate to populate this section</Box>,
  ];
  if (!summary?.reasoning) {
    return fallback;
  }

  const enum _State {
    UNKNOWN,
    SOFT_OK,
    HARD_OK,
    FAIL,
  }
  const _make = (
    challengeId: number,
    state: _State,
    isPreviouslyFail: boolean
  ) => {
    const name = summary.challenge_id_to_name?.[challengeId] || "";
    const warning = summary.challenge_id_to_warning?.[challengeId] || "";
    const endDescriptor: {
      tooltip: string;
      color: IconProps["color"];
      Icon: typeof ThumbUp;
    } =
      state === _State.HARD_OK
        ? {
            tooltip: "New schema perfectly fits the latest snapshot",
            color: "success",
            Icon: ThumbUp,
          }
        : state === _State.SOFT_OK
        ? {
            tooltip: "New schema will be sufficient for the latest snapshot",
            color: "success",
            Icon: Check,
          }
        : state === _State.FAIL
        ? {
            tooltip:
              "New schema will break the pipeline according to the latest snapshot",
            color: "error",
            Icon: ErrorIcon,
          }
        : state === _State.UNKNOWN
        ? {
            tooltip: `Can not evaluate new schema impact for this challenge: ${
              summary.reason_unknowns?.[challengeId] || "unknown reasons"
            }`,
            color: "disabled",
            Icon: PowerOff,
          }
        : {
            tooltip: `Unsupported state <${state}>`,
            color: "disabled",
            Icon: CircleIcon,
          };
    const endItem = (
      <Tooltip title={endDescriptor.tooltip}>
        <endDescriptor.Icon color={endDescriptor.color} fontSize="medium" />
      </Tooltip>
    );
    return (
      <Stack direction="row" key={challengeId}>
        <Link
          to={`/challenges/${challengeId}/?tab=${ChallengeTab.LatestCodeVersion}`}
          className="listish-hover-link"
          key={challengeId}
        >
          <Box flex={2}>
            {isPreviouslyFail && (
              <Typography color={"error"}>Previously failing →</Typography>
            )}
          </Box>
          <Box flex={3}>
            {summary?.challenge_id === challengeId && (
              <Typography fontWeight="bold" display="inline">
                (You are here){" "}
              </Typography>
            )}
            #{challengeId} {name}
            {!!warning && (
              <Tooltip title={warning}>
                <Warning color="warning" fontSize="small" />
              </Tooltip>
            )}
          </Box>
          <Box flex={1}>{endItem}</Box>
        </Link>
      </Stack>
    );
  };

  outs.push(
    ...summary.unknowns.map((challengeId) =>
      _make(challengeId, _State.UNKNOWN, false)
    )
  );
  outs.push(
    ...summary.failing_after.map((challengeId) =>
      _make(
        challengeId,
        _State.FAIL,
        summary.failing_before.includes(challengeId)
      )
    )
  );
  outs.push(
    ...summary.soft_success.map((challengeId) =>
      _make(
        challengeId,
        _State.SOFT_OK,
        summary.failing_before.includes(challengeId)
      )
    )
  );
  outs.push(
    ...summary.complete_success.map((challengeId) =>
      _make(
        challengeId,
        _State.HARD_OK,
        summary.failing_before.includes(challengeId)
      )
    )
  );

  return outs;
};

export const TemplatingImpact = ({ style }: TemplatingImpactProps) => {
  const templating = useContext(TemplatingEditContext);

  const qEvaluateImpact = useMutation<TemplatingImpactSummary, BackendError>(
    ["evalTemplatingImpact", templating?.snapshotId, templating?.templateId],
    () =>
      postEvaluateTemplatingImpact(
        String(templating?.snapshotId || 0),
        templating?.toDataLabels() || []
      ),
    { onSuccess: (data) => setData(data) }
  );

  const [isDebugOpen, setIsDebugOpen] = useState(false);
  const [isChallengeSectionOpen, setIsChallengeSectionOpen] = useState(true);
  const [data, setData] = useState<TemplatingImpactSummary | undefined>(
    undefined
  );

  const debugElements = useMemo(() => makeDebug(data), [data]);
  const sectionChallengeElements = useMemo(
    () => makeChallengeSection(data),
    [data]
  );

  const horizontalLayers = [
    {
      name: "Debug logs",
      isOpen: isDebugOpen,
      elements: debugElements,
      setter: setIsDebugOpen,
    },
    {
      name: "Challenges section",
      isOpen: isChallengeSectionOpen,
      elements: sectionChallengeElements,
      setter: setIsChallengeSectionOpen,
    },
  ];

  if (!templating) {
    return null;
  }
  return (
    <Paper style={{ marginTop: "15px", ...style }} elevation={2}>
      <Button
        onClick={() => qEvaluateImpact.mutate()}
        style={{ height: "55px" }}
        disabled={qEvaluateImpact.isLoading}
        fullWidth
        endIcon={
          qEvaluateImpact.isLoading ? <CircularProgress /> : <BuildIcon />
        }
      >
        {data ? "RE-" : ""}Evaluate Impact
      </Button>
      {qEvaluateImpact.isError && (
        <ErrorAlert value={qEvaluateImpact.error} isClosable />
      )}
      {!data ? (
        <Box
          width={"100%"}
          height="400px"
          textAlign={"center"}
          alignContent={"center"}
        >
          Will show how many challenges of the same template disagrees with the
          new schema
        </Box>
      ) : null}
      {horizontalLayers.map(({ name, isOpen, elements, setter }) => (
        <Paper key={name} style={{ margin: "0 10px 5px" }}>
          <Button
            onClick={() => setter(!isOpen)}
            fullWidth
            endIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
          >
            {name}
          </Button>
          <Divider />
          <Collapse in={isOpen}>
            {elements}
            <Divider />
          </Collapse>
        </Paper>
      ))}
    </Paper>
  );
};
