import { useContext } from "react";
import ChallengeTable from "./ChallengeTable";
import ChallengeRow from "./ChallengeRow";
import { useInView } from "react-intersection-observer";
import { Box, BoxProps, Typography } from "@mui/material";
import {
  ChallengeBrief,
  ChallengesBrief,
} from "../../../types/ChallengesBrief";
import { ChallengeFiltering } from "../contexts/ChallengeFiltering";
import ErrorAlert from "../../../components/ErrorAlert";
import { useInfQueryChallenges } from "../hooks/UseInfQueryChallenges";
import { FrontendFilterType } from "../../ChallengeViewSteps/hooks/UseChallengeFilter";

interface ChallengesInListProps {
  ChallengesBoxProps?: BoxProps;
  onShowChallengeDetails?: (challengeId: any) => void;
}

const ChallengesInTable = ({
  ChallengesBoxProps,
  onShowChallengeDetails,
}: ChallengesInListProps) => {
  const filtering = useContext(ChallengeFiltering);
  const tableBottomView = useInView();
  const infQuery = useInfQueryChallenges({
    isNextPageTriggered: tableBottomView.inView,
    filters: filtering?.filters ?? [],
    options: { enabled: !filtering?.isLoading },
  });

  if (filtering === undefined) {
    return <ErrorAlert value="Missing filtering-context" />;
  }

  // uncomment this assignment after name filtering field gets added
  const _filterByName =
    filtering.getFilter(FrontendFilterType.Name)?.filterFunction ??
    (() => true);

  return (
    <>
      <Box {...ChallengesBoxProps}>
        <ChallengeTable>
          {infQuery.data?.pages.map((challenges: ChallengesBrief) =>
            challenges
              .filter(_filterByName)
              .map((challenge: ChallengeBrief) => (
                <ChallengeRow
                  key={challenge.id}
                  challenge={challenge}
                  onShowDetails={onShowChallengeDetails}
                />
              ))
          )}
        </ChallengeTable>
        <Typography ref={tableBottomView.ref} justifyContent="center">
          {infQuery.isLoading && "Loading more"}
          {infQuery.hasNextPage && // hasn't started fetching yet since it is busy fetching sla statuses
            !infQuery.isLoading &&
            "Checking for more"}

          {infQuery.isSuccess &&
            infQuery.data.pages[0].length < 1 &&
            "No matches with specified filters"}
        </Typography>
      </Box>
    </>
  );
};

export default ChallengesInTable;
