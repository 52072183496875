import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Chip,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQueryUsersBasic } from "../hooks/UseQueryUsersBasic";
import { useMutation } from "react-query";
import { BackendError } from "../types/BackendError";
import { updateUserStatus } from "./ChallengeViewSteps/LocalOperations"; // Import updateUserStatus function
import { formatDate } from "../utils/FormatDate";
import { useModal } from "react-hooks-use-modal";
import { useNavigate } from "react-router-dom";
import { provideTheme } from "../utils/ProvideTheme";

const UsersView = () => {
  const { data: users, isLoading, isError, refetch } = useQueryUsersBasic();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [selectedUsername, setSelectedUsername] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [isUserDetailsTab, setIsUserDetailsTab] = useState<boolean>(true);
  const [isManagePermissionsTab, setIsManagePermissionsTab] =
    useState<boolean>(false);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] =
    useState<boolean>(false);
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] =
    useState<boolean>(false);

  const [Modal, openDeleteDialog, closeDeleteDialog, isDeleteDialogOpen] =
    useModal();

  const navigate = useNavigate();

  const qUpdateStatus = useMutation<
    void,
    BackendError,
    { userId: string; status: string }
  >(({ userId, status }) => updateUserStatus(userId, status), {
    onSuccess: () => {
      setSelectedUserId("");
      setSelectedStatus("");
      setAnchorEl(null);
      refetch();
      setIsSuccessSnackbarOpen(true);
    },
    onError: (error: BackendError) => {
      setSelectedUserId("");
      setSelectedStatus("");
      setAnchorEl(null);
      refetch();
      setIsErrorSnackbarOpen(true);
    },
  });

  const handleActivateButton = (userId: string) => {
    setSelectedUserId(userId);
    setSelectedStatus("active");
    qUpdateStatus.mutate({ userId, status: "active" });
  };

  const handleDeactivateButton = (userId: string) => {
    setSelectedUserId(userId);
    setSelectedStatus("inactive");
    qUpdateStatus.mutate({ userId, status: "inactive" });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSuccessSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  const handleErrorSnackbarClose = () => {
    setIsErrorSnackbarOpen(false);
  };

  if (isLoading) return <CircularProgress />;

  if (isError) return <p>Error fetching users</p>;

  if (!users || users.length === 0) return <p>There are no users</p>;

  const handleViewUserDetails = (userId: string) => {
    setSelectedUserId(userId);
    setIsUserDetailsTab(true);
    setIsManagePermissionsTab(false);
    navigate(`/users/user_details/${userId}?tab=details`);
  };

  const handleManagePermissions = (userId: string) => {
    setSelectedUserId(userId);
    navigate(`/users/user_details/${userId}?tab=permissions`);
  };

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    userId: string,
    shownName: string,
    status: string
  ) => {
    setSelectedUserId(userId);
    setSelectedUsername(shownName);
    setSelectedStatus(status);
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteButtonClick = (userId: string, username: string) => {
    setSelectedUserId(userId);
    setSelectedUsername(username);
    openDeleteDialog();
  };

  const handleDeleteUser = () => {
    closeDeleteDialog();
  };

  const setCellDesign = (field: string) => (params: any) => {
    const textColor =
      params.row.status === "inactive"
        ? provideTheme.palette.primary.light
        : provideTheme.palette.primary.main;
    if (field === "last_logged_in" || field === "created") {
      const formattedDate = formatDate(params.row[field]);
      return <span style={{ color: textColor }}>{formattedDate}</span>;
    } else {
      return (
        <span style={{ color: textColor }}>{params.row[field] || "none"}</span>
      );
    }
  };

  const renderStatusChip = (status: string) => {
    const textColor =
      status === "active"
        ? provideTheme.palette.success.main
        : provideTheme.palette.secondary.main;
    const backgroundColor =
      status === "active"
        ? provideTheme.palette.success.light
        : provideTheme.palette.secondary.light;
    const chipLabel = status.charAt(0).toUpperCase() + status.slice(1);

    return (
      <Chip
        label={chipLabel}
        style={{ color: textColor, backgroundColor: backgroundColor }}
      />
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 75,
    },
    {
      field: "shown_name",
      headerName: "Display name",
      width: 200,
      renderCell: setCellDesign("shown_name"),
    },
    {
      field: "email",
      headerName: "Email address",
      width: 250,
      renderCell: setCellDesign("email"),
    },
    {
      field: "role_name",
      headerName: "User type",
      width: 150,
      renderCell: setCellDesign("role_name"),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params: any) => renderStatusChip(params.value),
    },
    {
      field: "created",
      headerName: "Joined",
      width: 150,
      renderCell: setCellDesign("created"),
    },
    {
      field: "last_logged_in",
      headerName: "Last active",
      width: 150,
      renderCell: setCellDesign("last_logged_in"),
    },
    {
      field: "action",
      headerName: "Action",
      width: 75,
      renderCell: (params: any) => (
        <IconButton
          aria-controls="actions-menu"
          aria-haspopup="true"
          onClick={(event) =>
            handleActionClick(
              event,
              params.row.id,
              params.row.shown_name,
              params.row.status
            )
          }
        >
          ...
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Typography variant="h5" align="left" marginBottom={2}>
        Manage users
      </Typography>
      <Box sx={{ height: 650 }}>
        <Paper elevation={0} sx={{ marginBottom: 3, border: "none" }}>
          <DataGrid
            rows={users}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 15, 20]}
            disableRowSelectionOnClick
          />
          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleViewUserDetails(selectedUserId)}>
              View user details
            </MenuItem>
            <MenuItem onClick={() => handleManagePermissions(selectedUserId)}>
              Manage permissions
            </MenuItem>
            {selectedStatus === "active" ? (
              <MenuItem onClick={() => handleDeactivateButton(selectedUserId)}>
                Deactivate user
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleActivateButton(selectedUserId)}>
                Activate user
              </MenuItem>
            )}
            <MenuItem
              onClick={() =>
                handleDeleteButtonClick(selectedUserId, selectedUsername)
              }
            >
              Delete user
            </MenuItem>
          </Menu>
          <Dialog
            open={isDeleteDialogOpen}
            onClose={closeDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to delete user {selectedUsername}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deleting this user will permanently remove their account and all
                associated data.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={closeDeleteDialog}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleDeleteUser}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Snackbar
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSuccessSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          User status updated successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Error updating user status.
        </Alert>
      </Snackbar>
    </>
  );
};

export default UsersView;
