import { useLastCodeRef } from "../hooks/UseLastCodeRef";
import { LastCodeRefContext } from "./LastCodeRefContext";

interface BadCodeRefContextProviderProps {
  children: React.ReactNode;
  challengeId: string;
}

export const LastCodeRefContextProvider = ({
  challengeId,
  children,
}: BadCodeRefContextProviderProps) => {
  const badCode = useLastCodeRef({ challengeId });
  return (
    <LastCodeRefContext.Provider value={badCode}>
      {children}
    </LastCodeRefContext.Provider>
  );
};
