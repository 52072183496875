import { MenuItem, Select, Typography, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { Frequencies, Frequency } from "../../types/Frequencies";
import { getFrequencies } from "./LocalOperations";

import { useMutation } from "react-query";
import { ChallengeDetailed } from "../../types/ChallengesDetailed";
import { BackendError } from "../../types/BackendError";

import { CircularProgress } from "@mui/material";

import SelectFrequency from "./components/SelectFrequency";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";

import { updateChallengeFrequency } from "../ChallengeViewSteps/LocalOperations";

const SELECTOR_TEXT = "Data is updated every";
const SELECTOR_TEXT_ONCE = "Data created once";

interface FrequencySelectorProps {
  onChange: (frequency: string) => void;
  selectedFrequency?: string;
  filter?: (frequency: Frequency) => boolean;
}

const frequencyComparator = (x: Frequency, y: Frequency) => {
  if (x.number_of_days < y.number_of_days) {
    return -1;
  }
  if (x.number_of_days > y.number_of_days) {
    return 1;
  }
  return 0;
};

const FrequencySelectorDropdown = ({
  onChange,
  selectedFrequency = "",
  filter,
}: FrequencySelectorProps) => {
  const query = useQuery<Frequencies>("frequencies", getFrequencies, {
    onSuccess: (data) => {},
  });

  const frequenciesMenuItems = query.isSuccess
    ? query.data
        .filter((frequency) => (filter ? filter(frequency) : true))
        .sort(frequencyComparator)
        .map((frequency: Frequency) => (
          <MenuItem value={frequency.name} key={frequency.name}>
            {frequency.name}
          </MenuItem>
        ))
    : null;

  return (
    <>
      {!!query.isSuccess ? (
        <Select
          labelId="select-label"
          id="update-frequency-select"
          value={selectedFrequency}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          disabled={!query.isSuccess}
        >
          {frequenciesMenuItems}
        </Select>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

interface FrequencyReadModeProps {
  frequency: string;
}

function FrequencyLabel({ frequency }: FrequencyReadModeProps) {
  return (
    <Typography variant="body1">
      {frequency === "once"
        ? `${SELECTOR_TEXT_ONCE}`
        : `${SELECTOR_TEXT} ${frequency}`}
    </Typography>
  );
}

interface FrequencyLabelWithEditorProps {
  frequency: string;
  is_editable: boolean;
  onEdit?: () => void;
}

function FrequencyLabelWithEditor({
  frequency,
  is_editable,
  onEdit,
}: FrequencyLabelWithEditorProps) {
  return (
      <Grid container alignItems={"center"} spacing={2}>
        <Grid item>
          <Typography variant="body1" component="span">
            {!!is_editable && (
              <Typography variant="body1" component="span">
                <IconButton size="small" onClick={onEdit}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <FrequencyLabel frequency={frequency} />
        </Grid>
      </Grid>
  );
}

interface FrequencyEditorProps {
  is_editable: boolean;
  isDone?: boolean;
  selectedFrequency: string;
  onChange: (frequency: string) => void;
  onSave: (frequency: string) => void;
}

const FrequencyEditor: React.FC<FrequencyEditorProps> = ({
  selectedFrequency,
  onChange,
  onSave,
  is_editable = false,
  isDone = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [frequencyOption, setFrequencyOption] = useState(selectedFrequency);
  const [chosenFrequency, setSelectedFrequency] = useState(selectedFrequency);

  if (!isEditing) {
    return (
      <FrequencyLabelWithEditor
        frequency={selectedFrequency}
        is_editable={is_editable}
        onEdit={() => setIsEditing(true)}
      />
    );
  }

  const canProceed = (): boolean => {
    if (frequencyOption === "once" || frequencyOption === "hour") {
      return true;
    } else if (frequencyOption === "daily" && !chosenFrequency.includes("day")) {
      return false;
    } else if (frequencyOption === "weekly" && !chosenFrequency.includes("week")) {
      return false;
    }
    return true;
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={12}>
        <SelectFrequency
          frequencyOption={frequencyOption}
          frequency={chosenFrequency}
          onFrequencyOptionChange={(value) => {
            setFrequencyOption(value);
            if (value === "once" || value === "hour") {
              setSelectedFrequency(value);
              onChange(value);
            }
          }}
          onSelectedFrequencyChange={(newFrequency) => {
            setSelectedFrequency(newFrequency);
            onChange(newFrequency);
          }}
        />
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          disabled={!canProceed()}
          onClick={() => {
            onSave(chosenFrequency);
            setIsEditing(false);
          }}
        >
          <SaveIcon />
        </IconButton>
      </Grid>
      {!!isDone && !isEditing && (
        <Grid item>
          <DoneIcon style={{ marginBlock: "auto" }} color="primary" />
        </Grid>
      )}
    </Grid>
  );
};

interface FrequencyEditorForChallengeProps {
  challenge: ChallengeDetailed;
}

function FrequencyEditorForChallenge({
  challenge,
}: FrequencyEditorForChallengeProps) {
  const [isDone, setIsDone] = useState(false);
  const [frequency, setFrequency] = useState(challenge.frequency_name);

  const { mutate } = useMutation<void, BackendError, any>(
    updateChallengeFrequency,
    {}
  );

  return (
    <FrequencyEditor
      is_editable={challenge.is_editable}
      isDone={isDone}
      selectedFrequency={frequency}
      onChange={(frequency) => {
        setFrequency(frequency);
      }}
      onSave={(frequency) => {
        mutate({
          challengeId: challenge.id,
          frequency_name: frequency,
        });
        setIsDone(true);
      }}
    />
  );
}

export {
  FrequencySelectorDropdown,
  FrequencyEditor,
  FrequencyLabel,
  FrequencyEditorForChallenge,
};
