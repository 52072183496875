import { useCallback, useMemo, useState } from "react";

type typeSetCodeRun = (userId: null | string, runId: null | string) => void;

type typeSetSnapValidation = (
  snapshotId?: string,
  topValidationId?: string
) => void;

type typeSetHealth = (detailed: {
  isCodeRunning?: boolean;
  isCodeCrashed?: boolean;
  isTopValidationErrored?: boolean;
}) => void;

export type UseLastCodeRefType = {
  challengeId: string;
  codeRun: {
    userId: string;
    runId: string;
  } | null;
  badValidationId?: string;
  snapshotId?: string;
  setCodeRun: typeSetCodeRun;
  setSnapValidation: typeSetSnapValidation;
  setHealth: typeSetHealth;
  health: {
    isCodeRunning: boolean;
    isCodeCrashed: boolean;
    isTopValidationErrored: boolean;
  };
};

interface UseLastCodeRefProps {
  challengeId: string;
}

export const useLastCodeRef = ({
  challengeId,
}: UseLastCodeRefProps): UseLastCodeRefType => {
  const [userId, setUserId] = useState<string | null>(null);
  const [runId, setRunId] = useState<string | null>(null);
  const [validationId, setValidationId] = useState<string | null>(null);
  const [snapshotId, setSnapshotId] = useState<string | null>(null);
  const [isCodeCrashed, setIsCodeCrashed] = useState<boolean>(false);
  const [isCodeRunning, setIsCodeRunning] = useState<boolean>(false);
  const [isTopValidationErrored, setIsTopValidationErrored] =
    useState<boolean>(false);

  const setCodeRun: typeSetCodeRun = useCallback(
    (userId, runId) => {
      setUserId(userId);
      setRunId(runId);
    },
    [setUserId, setRunId]
  );

  const setSnapValidation: typeSetSnapValidation = useCallback(
    (snapshotId, validationId) => {
      setSnapshotId(snapshotId || null);
      setValidationId(validationId || null);
    },
    [setSnapshotId, setValidationId]
  );

  const setHealth: typeSetHealth = useCallback(
    (detailed) => {
      if (detailed.isCodeCrashed !== undefined) {
        setIsCodeCrashed(detailed.isCodeCrashed);
      }
      if (detailed.isCodeRunning !== undefined) {
        setIsCodeRunning(detailed.isCodeRunning);
      }
      if (detailed.isTopValidationErrored !== undefined) {
        setIsTopValidationErrored(detailed.isTopValidationErrored);
      }
    },
    [setIsCodeCrashed, setIsCodeRunning, setIsTopValidationErrored]
  );

  const toReturn = useMemo(() => {
    return {
      challengeId,
      codeRun: userId && runId ? { userId, runId } : null,
      badValidationId: validationId || undefined,
      snapshotId: snapshotId || undefined,
      health: {
        isCodeRunning,
        isCodeCrashed,
        isTopValidationErrored,
      },
      setCodeRun,
      setSnapValidation,
      setHealth,
    };
  }, [
    challengeId,
    validationId,
    snapshotId,
    userId,
    runId,
    isCodeCrashed,
    isCodeRunning,
    isTopValidationErrored,
    setCodeRun,
    setSnapValidation,
    setHealth,
  ]);

  return toReturn;
};
