import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { ValidatorJsonSchema } from "../../../types/ValidatorJsonSchema";
import {
  getJsonSchemaValidator,
  patchJsonSchemaValidator,
} from "../LocalOperations";
import EditableField from "./EditableField";

interface FormatValidatorJsonProps {
  templateId: string;
}

const FormatValidatorJson = ({ templateId }: FormatValidatorJsonProps) => {
  // editable
  // not: addable, removable
  const [jsonSchema, setJsonSchema] = useState("");
  const [customName, setCustomName] = useState("");

  const qSchema = useQuery<ValidatorJsonSchema, BackendError>(
    ["templateJSONSchema", templateId],
    () => getJsonSchemaValidator(templateId),
    {
      onSuccess: (data: ValidatorJsonSchema) => {
        let jsonSchemaStr = data.json_schema_str;
        try {
          const jsonSchemaObject = JSON.parse(jsonSchemaStr);
          jsonSchemaStr = JSON.stringify(jsonSchemaObject, null, 2);
        } catch (ex) {}
        setJsonSchema((prev: string) => (prev === "" ? jsonSchemaStr : prev));
        setCustomName((prev: string) =>
          prev === "" ? data.validator_custom_name ?? "" : prev
        );
      },
    }
  );

  const qEditSchema = useMutation<void, BackendError, void>(() =>
    patchJsonSchemaValidator(
      qSchema.data?.id ?? "no-id",
      jsonSchema,
      customName === "" ? undefined : customName
    )
  );

  return (
    <>
      <EditableField
        label="name for JSON schema"
        fieldValue={customName}
        isLoading={qSchema.isLoading || qEditSchema.isLoading}
        error={qSchema.error}
        onFieldChange={setCustomName}
      />
      <EditableField
        label="JSON schema"
        fieldValue={jsonSchema}
        isEditSuccess={qEditSchema.isSuccess}
        isLoading={qSchema.isLoading || qEditSchema.isLoading}
        onFieldChange={setJsonSchema}
        onSave={qEditSchema.mutate}
        error={qEditSchema.error}
        language="json"
      />
    </>
  );
};

export default FormatValidatorJson;
