import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { undeleteChallenge } from "../LocalOperations";
import ErrorAlert from "../../../components/ErrorAlert";
import { usePerms } from "../../../hooks/UsePerms";
import { Perm } from "../../../Const";
import { useNavigate } from "react-router-dom";
import { useModal } from "react-hooks-use-modal";
import RestoreIcon from "@mui/icons-material/Restore";
import TTabID from "../../../types/ChallengeViewTabsEnum";

interface UndeleteChallengeButtonProps {
  challengeId: string;
  challengeName?: string;
}

interface RestorationResponse {
  success: boolean;
  is_autoruned: boolean;
}

export const UndeleteChallengeButton: React.FC<
  UndeleteChallengeButtonProps
> = ({ challengeId, challengeName }) => {
  const navigate = useNavigate();
  const [_, openUndeleteDialog, closeUndeleteDialog, isUndeleteDialogOpen] =
    useModal();

  const { mutate, isError, isLoading, error } = useMutation<
    RestorationResponse,
    BackendError
  >(() => undeleteChallenge(challengeId), {
    onSuccess: (data) => {
      if (data.is_autoruned) {
        navigate(`?tab=${TTabID.CodeRuns}`);
      } else {
        navigate(0); // reload page
      }
      // ts doesnt allow navigate(cond? "path" : 0)
    },
  });

  const perms = usePerms();

  if (!perms.has(Perm.DELETE_CHALLENGE)) {
    return null;
  }

  return (
    <>
      {isError && <ErrorAlert value={error} />}
      <Button
        startIcon={<RestoreIcon />}
        color="error"
        disabled={isLoading}
        onClick={openUndeleteDialog}
      >
        UNDELETE CHALLENGE
      </Button>
      <Dialog
        open={isUndeleteDialogOpen}
        onClose={closeUndeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to undelete "{challengeName}" challenge?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Undeleting this challenge will restore any previous running autorun.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeUndeleteDialog}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={() => mutate()}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={24} />}
          >
            {isLoading ? "Undeleting..." : "UNDELETE"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
