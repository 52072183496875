import { TableRow, TableCell, IconButton, Box, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { ColoredStatus } from "../../../components/ColoredStatus";
import { Validation } from "../../../types/Validations";
import TitledTruncatableCard from "../../../components/TitledTruncatableCard";
import TitledCodeTextCard from "../../../components/TitledCodeTextCard";
import { localizeDatetimeUTC } from "../../../utils/Localize";

interface ValidationRowProps {
  validation: Validation;
}

const ValidationTableRow = ({ validation }: ValidationRowProps) => {
  const {
    status,
    date_run_start: dateValidationStart,
    length_secs: validationLength,
    error,
    internal_error: internalError,
    warning,
    validator_name: validator,
    validator_custom_name: name,
  } = validation;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setIsOpen((old: boolean) => !old)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {<ColoredStatus status={status}>{status}</ColoredStatus>}
        </TableCell>
        <TableCell align="right">
          {localizeDatetimeUTC(dateValidationStart)}
        </TableCell>
        <TableCell align="right">{validationLength}</TableCell>
        <TableCell align="right">{validator}</TableCell>
        <TableCell align="right">{name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box
              sx={{ margin: 1 }}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                columnGap: "50px",
                rowGap: "30px",
              }}
            >
              <TitledTruncatableCard title="Warning" value={warning} />
              <TitledCodeTextCard title="Error" value={error} />
              <TitledCodeTextCard
                title="Internal error"
                value={internalError}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ValidationTableRow;
