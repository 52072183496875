import { useQueryClient } from "react-query";

interface useQValidatorCodeRefetchProps {
  templateId: string;
}

export function useQValidatorCodeRefetch({
  templateId,
}: useQValidatorCodeRefetchProps) {
  const queryClient = useQueryClient();
  return () => {
    queryClient.refetchQueries(["templateCustomCodes", templateId], {
      active: true,
      exact: true,
    });
  };
}
