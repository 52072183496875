import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserState } from "../../../hooks/UserStateProvider";
import { ChallengeBannerProps } from "./ChallengeBanner";
import SplitButtonDropdown from "../../../components/SplitButtonDropdown";
import { useChallengeSLA } from "../../../hooks/UseChallengeSLA";
import { ActionType } from "../../../Const";
import { useMutation } from "react-query";
import { releaseChallenge, updateChallengeFrequency } from "../LocalOperations";
import { BackendError } from "../../../types/BackendError";
import TTabID from "../../../types/ChallengeViewTabsEnum";
import ChallengeMoveToProjectModal from "../../../components/ChallengeMoveToProjectModal";

interface BannerRightProps extends ChallengeBannerProps {
  onShowDetails?: (challengeId: string) => void;
}

const BannerRight = ({ linkPrefix, challenge }: BannerRightProps) => {
  const qChallengeSLA = useChallengeSLA(challenge.id, {
    enabled: false,
    staleTime: 5 * 60 * 1000, // 5 min
  });
  const [user] = useUserState();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: releaseChallengeMutation } = useMutation<
    void,
    BackendError,
    any
  >(releaseChallenge, {
    onSuccess: () => {
      navigate("/?is_up_for_grab=true");
    },
  });

  var { mutate } = useMutation<void, BackendError, any>(
    updateChallengeFrequency,
    {}
  );
  const updateChallengeFrequencyMutation = mutate;

  const slaData = qChallengeSLA.data;
  const ctas = slaData?.ctas || [];
  var choices: any[] = [];

  if (ctas.includes(ActionType.SHOW_REVIEW)) {
    choices.push({
      node: "Review solution",
      link: `${linkPrefix}${challenge.id}/?tab=${TTabID.SubmitCode}&runNumber=${slaData?.reviewed_coderun_id}&userId=${slaData?.solver_id}`,
    });
  }

  if (ctas.includes(ActionType.RESERVE)) {
    choices.push({
      node: "Reserve challenge",
      link: `${linkPrefix}${challenge.id}/?tab=${TTabID.ChallengeDescription}`,
    });
  }

  if (ctas.includes(ActionType.SHOW_LAST_RUN)) {
    choices.push({
      node: "Show last run",
      link: `${linkPrefix}${challenge.id}/?tab=${TTabID.LatestCodeVersion}&last_run_id=${slaData?.last_run_id}&last_run_user_id=${slaData?.last_run_user_id}`,
    });
  }

  if (ctas.includes(ActionType.SUBMIT)) {
    choices.push({
      node: "Submit solution ➤",
      link: `${linkPrefix}${challenge.id}/?tab=${TTabID.SubmitCode}`,
    });
  }

  if (ctas.includes(ActionType.SHOW_ALL_RUNS)) {
    choices.push({
      node: "Show irrespective runs",
      link: `${linkPrefix}${challenge.id}?tab=${TTabID.CodeRuns}`,
    });
  }

  if (ctas.includes(ActionType.SHOW_MY_RUNS)) {
    choices.push({
      node: "Show my code runs",
      link: `${linkPrefix}${challenge.id}?tab=${TTabID.CodeRuns}&user_id=${user.id}`,
    });
  }

  if (ctas.includes(ActionType.RELEASE)) {
    choices.push({
      node: "Release challenge",
      onClick: () => releaseChallengeMutation(challenge.id),
    });
  }

  if (ctas.includes(ActionType.RUN_HOURLY)) {
    choices.push({
      node: "Run hourly",
      onClick: () =>
        updateChallengeFrequencyMutation({
          challengeId: challenge.id,
          frequency_name: "hour",
        }),
    });
  }

  if (ctas.includes(ActionType.RUN_DAILY)) {
    choices.push({
      node: "Run daily",
      onClick: () =>
        updateChallengeFrequencyMutation({
          challengeId: challenge.id,
          frequency_name: "day",
        }),
    });
  }

  if (ctas.includes(ActionType.MOVE_TO_PROJECT)) {
    choices.push({
      node: "Move to project",
      onClick: () => setIsModalOpen(true),
    });
  }

  if (ctas.length > 0 && !slaData) {
    return <div></div>;
  }

  const dropdownItems = slaData ? choices : [{ node: "Loading..." }];

  return (
    <div style={{ margin: "auto 0px", paddingTop: "5px" }}>
      <SplitButtonDropdown
        primaryButton={{
          node: "Show details",
          link: `${linkPrefix}${challenge.id}/?tab=${TTabID.ChallengeDescription}`,
        }}
        dropdownItems={dropdownItems}
        variant="contained"
        color="success"
      />
      {isModalOpen && (
        <ChallengeMoveToProjectModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          challengeId={challenge.id}
        />
      )}
    </div>
  );
};

export default BannerRight;
