import { useQueryCodeRunHead } from "../../../hooks/UseQueryCodeRunHead";
import ChallengeDetailsCard from "./ChallengeDetailsCard";
import SubmissionValidationCard from "./SubmissionValidationCard";
import SubmissionViewCard from "./SubmissionViewCard";
import FormatCard from "./FormatCard";
import { SnapshotTemplatingCard } from "./SnapshotTemplatingCard";

interface SolutionDashboardProps {
  challengeId: string;
  runNumber?: string;
  userId: string;
  templateId: string;
  style?: React.CSSProperties;
  cardStyle?: React.CSSProperties;
}

const SolutionDashboard = ({
  challengeId,
  userId,
  runNumber,
  templateId,
  style,
  cardStyle,
}: SolutionDashboardProps) => {
  cardStyle = { width: "100%", padding: "17px", ...cardStyle };
  const qCodeRunHead = useQueryCodeRunHead(
    challengeId,
    runNumber ?? "",
    userId,
    {
      enabled: !!runNumber, // maybe the code has not been yet submitted by user
      refetchInterval(codeRunHead) {
        return codeRunHead === undefined || codeRunHead.is_snapshot_upcoming
          ? 3 * 1000 // 3 s
          : false; // stop refetching
      },
    }
  );
  const snapshotId = qCodeRunHead.data?.snapshot_id ?? "";

  const isCodeBad =
    !!runNumber &&
    !qCodeRunHead.data?.is_snapshot_upcoming &&
    !qCodeRunHead.data?.snapshot_id;

  return (
    <div className="loose-column gapVertical" style={{ ...style }}>
      <SnapshotTemplatingCard
        snapshotId={snapshotId}
        challengeId={challengeId}
        templateId={templateId}
        style={cardStyle}
        isSnapshotUpcoming={qCodeRunHead.data?.is_snapshot_upcoming}
      />
      <SubmissionValidationCard
        runNumber={runNumber ?? ""}
        snapshotId={snapshotId}
        isCodeRunning={!qCodeRunHead.data?.is_run_done}
        isCodeBad={isCodeBad}
        style={cardStyle}
      />
      {qCodeRunHead.isSuccess && (
        <SubmissionViewCard
          challengeId={challengeId}
          templateId={templateId}
          runNumber={qCodeRunHead.data.challenge_run_number}
          snapshotId={snapshotId}
          userId={userId}
          style={cardStyle}
        />
      )}
      <FormatCard templateId={templateId} style={cardStyle} />
      <ChallengeDetailsCard challengeId={challengeId} style={cardStyle} />
    </div>
  );
};

export default SolutionDashboard;
