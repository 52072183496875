import { User } from "../types/Users";
import createDefaultUser from "./CreateDefaultUser";

const loadStorageUserOrInvalidUser = (): User => {
  const userDumps = window.localStorage.getItem("USER");
  if (userDumps === null) {
    return createDefaultUser();
  }

  try {
    return JSON.parse(userDumps);
  } catch (ex) {
    return createDefaultUser();
  }
};

export const setStorageUser = (user: User) => {
  try {
    window.localStorage.setItem("USER", JSON.stringify(user));
  } catch (ex) {
    // the user has disabled storage for the site, so we cannot help it
  }
};

export default loadStorageUserOrInvalidUser;
