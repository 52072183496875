import { Stack, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { HashLink } from "react-router-hash-link";
import { BackendError } from "../../../types/BackendError";
import { SnapshotExtra } from "../../../types/SnapshotExtra";
import { Validation } from "../../../types/Validations";
import { getSnapshotExtra } from "../../SnapshotsViewSteps/LocalOperations";
import { localizeDatetimeUTC } from "../../../utils/Localize";

interface FailingValidationProps {
  failingValidation: Validation;
}

const FailingValidation = ({ failingValidation }: FailingValidationProps) => {
  const qExtra = useQuery<SnapshotExtra, BackendError>(
    ["snapshotExtra", failingValidation.snapshot_id],
    () => getSnapshotExtra(failingValidation.snapshot_id)
  );

  const extraValue = qExtra.isLoading ? "loading..." : "error";
  const extraPlaceholder: SnapshotExtra = {
    challenge_id: extraValue,
    challenge_name: extraValue,
    user_email: extraValue,
    user_id: extraValue,
    challenge_run_number: undefined,
  };
  const extraOfQuery =
    qExtra.isSuccess && !!qExtra.data ? qExtra.data : extraPlaceholder;

  const {
    challenge_id: challengeId,
    challenge_name: challengeName,
    user_id: userId,
    challenge_run_number: challengeRunNumber,
    user_email: userEmail,
  } = extraOfQuery;

  const statusAt =
    failingValidation.validator_custom_name ?? failingValidation.validator_name;

  const goToDetails =
    challengeRunNumber !== undefined && challengeRunNumber !== null ? (
      <HashLink
        smooth
        to={`/challenges/${challengeId}/code/${challengeRunNumber}/user/${userId}#validations-table`}
        style={{ flex: "1" }}
      >
        Go to details
      </HashLink>
    ) : (
      <Typography style={{ flex: "1" }}>
        "Sorry, go-to is not implemented for non-autorun"
      </Typography>
    );

  return (
    <Stack
      direction="row"
      className="singleRow"
      style={{ borderBottom: "0.2px solid khaki" }}
    >
      <Stack direction="column" style={{ textAlign: "left", flex: "2" }}>
        <Typography>Challenge: {challengeName}</Typography>
        <Typography>id: {challengeId}</Typography>
        <Typography>solver: {userEmail}</Typography>
      </Stack>
      <Typography style={{ flex: "1" }}>
        {localizeDatetimeUTC(failingValidation.date_created)}
      </Typography>
      <Typography style={{ flex: "1" }}>
        {failingValidation.status} at {statusAt}
      </Typography>
      {goToDetails}
    </Stack>
  );
};

export default FailingValidation;
