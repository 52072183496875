import { Box } from "@mui/material";
import SnapshotStatisticsSchema from "./SnapshotStatisticsSchema";

interface SnapshotStatisticsProps {
  templateId: string;
  snapshotId: string;
}

const SnapshotStatistics = ({ templateId, snapshotId }: SnapshotStatisticsProps) => {
  return (
    <Box
      width="max-content"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignContent="space-around"
      className="gapVerticalMini"
    >
      <SnapshotStatisticsSchema templateId={templateId} snapshotId={snapshotId} />
    </Box>
  );
};

export default SnapshotStatistics;
