import {
  QueryKey,
  UseQueryOptions,
  useQuery,
} from "react-query";
import { ProjectUsers } from "../types/ProjectUsers";
import { BackendError } from "../types/BackendError";
import { getChallengeProjectUsers } from "../pages/ChallengeViewSteps/LocalOperations";

export const useQueryChallengeProjectUsers = (
  challengeId: string | number,
  options?: Omit<
    UseQueryOptions<ProjectUsers, BackendError, ProjectUsers, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _challengeId = String(challengeId);
  return useQuery<ProjectUsers, BackendError>(
    ["challengeProjectUsers", _challengeId],
    () => getChallengeProjectUsers(_challengeId),
    options
  );
};
