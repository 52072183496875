import { Grid, IconButton, Typography, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useMutation } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";
import SearchProject from "./components/SearchProject";
import { BackendError } from "../../types/BackendError";
import { Project } from "../../types/Projects";
import { ChallengeDetailed } from "../../types/ChallengesDetailed";
import { updateChallengeProject } from "../ChallengeViewSteps/LocalOperations";
import { useQueryProjects } from "../../hooks/useQueryProjects";
import { useInvalidateChallengeDetails } from "../../hooks/UseQueryChallengeDetails";

interface ProjectSelectorProps {
  onChange: (projectId: number) => void;
  selectedProjectId: number;
  projects: Project[] | undefined;
}

const ProjectSelectorDropdown = ({
  onChange,
  selectedProjectId,
  projects,
}: ProjectSelectorProps) => {
  return (
    <>
      {!projects ? (
        <CircularProgress />
      ) : (
        <SearchProject
          label="Select Project"
          selectedProjectId={selectedProjectId}
          setSelectedProjectId={onChange}
          userProjects={true}
        />
      )}
    </>
  );
};

interface ProjectReadModeProps {
  projectName: string;
}

function ProjectLabel({ projectName }: ProjectReadModeProps) {
  return <Typography variant="body1">{projectName}</Typography>;
}

interface ProjectLabelWithEditorProps {
  projectName: string;
  isEditable?: boolean;
  onEdit?: () => void;
}

function ProjectLabelWithEditor({
  projectName,
  isEditable,
  onEdit,
}: ProjectLabelWithEditorProps) {
  return (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid item>
        {isEditable && (
          <IconButton size="small" onClick={onEdit}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Grid>
      <Grid item>
        <ProjectLabel projectName={projectName} />
      </Grid>
    </Grid>
  );
}

interface ProjectEditorProps {
  isEditable: boolean;
  isDone?: boolean;
  projectId: number;
  projectName: string;
  onChange: (projectId: number, projectName: string) => void;
  onSave: (projectId: number) => void;
  projects: Project[] | undefined;
}

const ProjectEditor: React.FC<ProjectEditorProps> = ({
  projectId,
  projectName,
  onChange,
  onSave,
  isEditable = false,
  isDone = false,
  projects,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProjectId, setProjectId] = useState(projectId);
  const [selectedProjectName, setProjectName] = useState(projectName);

  if (!isEditing) {
    return (
      <ProjectLabelWithEditor
        projectName={selectedProjectName}
        isEditable={isEditable}
        onEdit={() => setIsEditing(true)}
      />
    );
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={12}>
        <ProjectSelectorDropdown
          selectedProjectId={selectedProjectId}
          projects={projects}
          onChange={(id) => {
            const selectedProject = projects?.find((project: Project) => project.id === id);
            setProjectId(id);
            setProjectName(selectedProject?.name || "");
            onChange(id, "");
          }}
        />
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          onClick={() => {
            onSave(selectedProjectId);
            setIsEditing(false);
          }}
          disabled={!selectedProjectId}
        >
          <SaveIcon />
        </IconButton>
      </Grid>
      {!!isDone && !isEditing && (
        <Grid item>
          <DoneIcon style={{ marginBlock: "auto" }} color="primary" />
        </Grid>
      )}
    </Grid>
  );
};

interface ProjectEditorForChallengeProps {
  challenge: ChallengeDetailed;
  project: Project;
}

function ProjectEditorForChallenge({
  challenge,
  project
}: ProjectEditorForChallengeProps) {
  const [isDone, setIsDone] = useState(false);

  const [projectId, setSelectedProjectId] = useState<number>(Number(challenge.project_id) || 0);
  const [projectName, setSelectedProjectName] = useState(project.name);

  const { data: projects, isLoading } = useQueryProjects();
  const invalidateChallengeDetails = useInvalidateChallengeDetails(challenge.id);

  const { mutate } = useMutation<void, BackendError, any>(
    updateChallengeProject,
    {
      onSuccess: () => {
        invalidateChallengeDetails();
      }
    }
  );

  if (isLoading) return <CircularProgress />;

  return (
    <ProjectEditor
      isEditable={challenge.is_editable}
      isDone={isDone}
      projectId={projectId}
      projectName={projectName}
      projects={projects}
      onChange={(projectId, projectName) => {
        setSelectedProjectId(projectId);
        setSelectedProjectName(projectName);
      }}
      onSave={(projectId) => {
        mutate({
          challengeId: challenge.id,
          projectId: projectId,
        });
        setIsDone(true);
      }}
    />
  );
}

export {
  ProjectSelectorDropdown,
  ProjectEditor,
  ProjectLabel,
  ProjectEditorForChallenge,
};
