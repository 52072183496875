import {
  Button,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  Paper,
  Select,
  Stack,
  Switch,
  Typography,
  typographyClasses,
} from "@mui/material";
import React, { useContext, useState } from "react";
import SearchExistingFormat from "../../ChallengesNewSteps/components/SearchExistingFormat";
import SelectUser from "../../../components/SelectUser";
import { ChallengeFiltering } from "../contexts/ChallengeFiltering";
import ErrorAlert from "../../../components/ErrorAlert";
import { BackendFilterType, UseChallengeFilterType } from "../../ChallengeViewSteps/hooks/UseChallengeFilter";
import { useNavigate } from 'react-router-dom';
import SearchProject from "../../ChallengesNewSteps/components/SearchProject";


import { ValidationStatus, ReservationStatus, CoderunStatus, Perm } from "../../../Const";
import { usePerms } from "../../../hooks/UsePerms";

interface ChallengesVerticalFilterProps {
  style?: React.CSSProperties;
}

const _styleDefault: React.CSSProperties = {
  paddingTop: "30px",
  paddingLeft: "30px",
  paddingRight: "15px",
  paddingBottom: "20px",
  marginBottom: "111px",
};

const _switchLabelProps: Omit<FormControlLabelProps, "control" | "label"> = {
  labelPlacement: "start",
  style: {
    marginLeft: "0px",
  },
  sx: {
    // push text to the leftmost border while switch will be at the rightmost border
    [`& .${typographyClasses.root}`]: {
      width: "100%",
      textAlign: "left",
    },
  },
};


type TwoArgCallback = (arg1: boolean, arg2: Function) => void;

interface FilterComponentProps {
  checked?: boolean;
  label: string;
  onChange: TwoArgCallback;
}

const FormControlLabelWithNavigation = (props: FilterComponentProps) => {
  const navigate = useNavigate();

  return (
      <FormControlLabel
        control={<Switch />}
        label={props.label}
        {..._switchLabelProps}
        checked={props.checked}
        onChange={(_, newChecked) => {
          props.onChange(newChecked, navigate);
        }}      
    />
  );
}

interface SelectUserWithNavigationProps {
  onChangeUser: (user: any, navigate?: Function) => void;
  userId: string;
  projectId?: string;
}

const SelectUserWithNavigation = (props: SelectUserWithNavigationProps) => {
  const navigate = useNavigate();

  return (
    <SelectUser
      onChangeUser={(user) => {
        props.onChangeUser(user, navigate);
      }}      
      size="small"
      nameForEmpty="All"
      userId={props.userId}
      projectId={props.projectId}
    />
  );
  }
  
  interface SearchProjectWithNavigationProps {
    onChooseProject: (projectID: any, navigate?: Function) => void;
    value: string;
  }

  const SearchProjectWithNavigation = (props: SearchProjectWithNavigationProps) => {
    const navigate = useNavigate();
    const perms = usePerms();
    const isModerator = perms.has(Perm.VIEW_ANY_CHALLENGE);

    return (
      <SearchProject
        selectedProjectId={parseInt(props.value)}
        setSelectedProjectId={(projectId) => {
          props.onChooseProject(projectId, navigate);
        }}
        userProjects={!isModerator}
      />
    );
  };

  interface SearchExistingFormatWithNavigationProps {
    onChooseExisting: (templateId: any, navigate?: Function) => void;
    value: string;
  }
  
  const SearchExistingFormatWithNavigation = (props: SearchExistingFormatWithNavigationProps) => {
    const navigate = useNavigate();

    return (
      <SearchExistingFormat
        onChooseExisting={(templateId: string) => {
          props.onChooseExisting(templateId, navigate);
        }}
        size="small"
        value={props.value}
        canChooseNothing
      />
    );
  }


  interface CheckboxWithNavigationProps {
    filtering: UseChallengeFilterType,
    activeStatuses: [] | [any],
    status: any,
    filterType: BackendFilterType,
  }

const CheckboxWithNavigation = (props: CheckboxWithNavigationProps) => {
  const navigate = useNavigate();
    return (
      <Checkbox
        checked={props.activeStatuses.some((activeStatus) => activeStatus === props.status)}
        onChange={(_, newChecked) => {
          const _newStatuses = newChecked
            ? [...props.activeStatuses, props.status]
            : props.activeStatuses.filter((activeStatus) => activeStatus !== props.status);
          props.filtering.setFilter(props.filterType, _newStatuses, navigate);
       }}
      />
    );
};

const ChallengesVerticalFilter = ({ style }: ChallengesVerticalFilterProps) => {
  const filtering = useContext(ChallengeFiltering);

  if (filtering === undefined) {
    return <ErrorAlert value="Filtering context missing" />;
  }

  const _creatorId = filtering.getFilterValue(BackendFilterType.CreatorId, "");
  const _projectId = filtering.getFilterValue(BackendFilterType.ProjectId, "");
  const _solverId = filtering.getFilterValue(BackendFilterType.SolverId, "");
  const _templateId = filtering.getFilterValue(
    BackendFilterType.TemplateId,
    ""
  );
  const _isSolved = filtering.getFilterValue(BackendFilterType.IsSolved, null);
  const _isOnSnooze = filtering.getFilterValue(BackendFilterType.IsOnSnooze, null);
  const _isAnomaly = filtering.getFilterValue(BackendFilterType.IsAnomaly, null);
  const _isAutorun = filtering.getFilterValue(
    BackendFilterType.IsAutorun,
    null
  );
  const _isCreationSlaOk = filtering.getFilterValue(
    BackendFilterType.isCreationSlaOk,
    null
  );
  const _isDeliverySlaOK = filtering.getFilterValue(
    BackendFilterType.IsDeliverySlaOk,
    null
  );
  const _isGrabbable = filtering.getFilterValue(
    BackendFilterType.IsGrabbable,
    null
  );
  const _isRejected = filtering.getFilterValue(
    BackendFilterType.IsRejected,
    null
  );
  const _isReviewed = filtering.getFilterValue(
    BackendFilterType.IsReviewed,
    null
  );


  let _validationStatuses: [any] | [] = filtering.getFilterValue(
    BackendFilterType.ValidationStatuses,
    []
  );
  _validationStatuses = Array.isArray(_validationStatuses) ? _validationStatuses : [];


  let _reservationStatuses: [any] | [] = filtering.getFilterValue(
      BackendFilterType.ReservationStatuses,
      []
  );
  _reservationStatuses = Array.isArray(_reservationStatuses) ? _reservationStatuses : [];

  let _coderunStatuses: [any] | [] = filtering.getFilterValue(
    BackendFilterType.CoderunStatuses,
    []
);
  _coderunStatuses = Array.isArray(_coderunStatuses) ? _coderunStatuses : [];


  const hiddenStyle: React.CSSProperties = filtering.isLoading
    ? { opacity: "0.1" }
    : {};

  return (
    <Paper style={{ ..._styleDefault, ...style, ...hiddenStyle }}>
      <Stack direction="row">
        <Typography variant="h5" flex="6">
          Filter by
        </Typography>
        <Button
          variant="text"
          color="error"
          onClick={() => {
            filtering.resetFilters();
          }}
        >
          Clear all
        </Button>
      </Stack>
      <FormGroup>
        <FormControlLabelWithNavigation
            label="Has solution" 
            checked={!!_isSolved} 
            onChange={(newChecked, navigate) => {
              filtering.setFilter(
                BackendFilterType.IsSolved,
                newChecked || null,
                navigate
              )
            }}
          />
        <FormControlLabelWithNavigation
            label="With scheduled autorun" 
            checked={!!_isAutorun} 
            onChange={(newChecked, navigate) => {
              filtering.setFilter(
                BackendFilterType.IsAutorun,
                newChecked || null,
                navigate
              )
            }}
          />
        <FormControlLabelWithNavigation
            label="Exceeds creation SLA" 
            checked={_isCreationSlaOk === false} 
            onChange={(newChecked, navigate) => {
              filtering.setFilter(
                BackendFilterType.isCreationSlaOk,
                newChecked === true ? false : null,
                navigate
              )
            }}
          />
  
        <FormControlLabelWithNavigation
            label="Exceeds delivery SLA" 
            checked={_isDeliverySlaOK == false} 
            onChange={(newChecked, navigate) => {
              filtering.setFilter(
                BackendFilterType.IsDeliverySlaOk,
                newChecked === true ? false : null,
                navigate
              )
            }}
          />

        <FormControlLabelWithNavigation
            label="Up for grab" 
            checked={!!_isGrabbable} 
            onChange={(newChecked, navigate) => {
              filtering.setFilter(
                BackendFilterType.IsGrabbable,
                newChecked || null,
                navigate
              )
            }}
          />
        <FormControlLabelWithNavigation
            label="Needs fix" 
            checked={!!_isRejected} 
            onChange={(newChecked, navigate) => {
              filtering.setFilter(
                BackendFilterType.IsRejected,
                newChecked || null,
                navigate
              )
            }}
          />
         <FormControlLabelWithNavigation
            label="Needs review" 
            checked={_isReviewed === false} 
            onChange={(newChecked, navigate) => {
              filtering.setFilter(
                BackendFilterType.IsReviewed,
                newChecked === true ? false : null,
                navigate
              )
            }}
          />
           <FormControlLabelWithNavigation
            label="On snooze" 
            checked={!!_isOnSnooze} 
            onChange={(newChecked, navigate) => {
              filtering.setFilter(
                BackendFilterType.IsOnSnooze,
                newChecked || null,
                navigate
              )
            }}
          />
          <FormControlLabelWithNavigation
            label="Is anomaly" 
            checked={!!_isAnomaly} 
            onChange={(newChecked, navigate) => {
              filtering.setFilter(
                BackendFilterType.IsAnomaly,
                newChecked || null,
                navigate
              )
            }}
          />
      </FormGroup>

      
      <FormGroup>
        <Typography variant="h6">Reservation status</Typography>
        <FormControlLabel
          control={
            <CheckboxWithNavigation
                filtering={filtering}
                activeStatuses={_reservationStatuses}
                status={ReservationStatus.FREE}
                filterType={BackendFilterType.ReservationStatuses}
            />
          }
          label="Free"
        />
        <FormControlLabel
          control={
            <CheckboxWithNavigation
                filtering={filtering}
                activeStatuses={_reservationStatuses}
                status={ReservationStatus.RESERVED}
                filterType={BackendFilterType.ReservationStatuses}
            />
          }
          label="Reserved"
        />
      </FormGroup>

      <FormGroup>
        <Typography variant="h6">Validation status</Typography>
        <FormControlLabel
          control={
            <CheckboxWithNavigation
                filtering={filtering}
                activeStatuses={_validationStatuses}
                status={ValidationStatus.DATA_FAILURE}
                filterType={BackendFilterType.ValidationStatuses}
            />
          }
          label="Error"
        />
        <FormControlLabel
          control={
            <CheckboxWithNavigation
                filtering={filtering}
                activeStatuses={_validationStatuses}
                status={ValidationStatus.SUCCESS_WITH_WARNING}
                filterType={BackendFilterType.ValidationStatuses}
            />
          }
          label="Warning"
        />
       <FormControlLabel
          control={
            <CheckboxWithNavigation
                filtering={filtering}
                activeStatuses={_validationStatuses}
                status={ValidationStatus.SUCCESS}
                filterType={BackendFilterType.ValidationStatuses}
            />
          }
          label="Success"
        />
      </FormGroup>
      <FormGroup>
        <Typography variant="h6">Select schema</Typography>
        <SearchExistingFormatWithNavigation
          onChooseExisting={(templateId, navigate) =>
            filtering.setFilter(BackendFilterType.TemplateId, templateId, navigate)
          }
          value={_templateId}
        />
        <Typography variant="h6">Select moderator</Typography>
        <Select disabled value={""} />
        <Typography variant="h6">Select contributor</Typography>
        <SelectUserWithNavigation
          onChangeUser={(user, navigate) =>
            filtering.setFilter(BackendFilterType.SolverId, user?.id, navigate)
          }
          userId={_solverId}
          projectId={_projectId}
        />
        <Typography variant="h6">Select business</Typography>
        <SelectUserWithNavigation
          onChangeUser={(user, navigate) =>
            filtering.setFilter(BackendFilterType.CreatorId, user?.id, navigate)
          }
          userId={_creatorId}
        />
        <Typography variant="h6">Select project</Typography>
          <SearchProjectWithNavigation
             onChooseProject={(projectId, navigate) =>
              filtering.setFilter(BackendFilterType.ProjectId, projectId, navigate)
            }
            value={_projectId}
          />
      </FormGroup>

      <FormGroup>
        <Typography variant="h6">Last coderun status</Typography>
        <FormControlLabel
          control={
            <CheckboxWithNavigation
                filtering={filtering}
                activeStatuses={_coderunStatuses}
                status={CoderunStatus.SUCCESS}
                filterType={BackendFilterType.CoderunStatuses}
            />
          }
          label="Success"
        />
        <FormControlLabel
          control={
            <CheckboxWithNavigation
                filtering={filtering}
                activeStatuses={_coderunStatuses}
                status={CoderunStatus.CRASH}
                filterType={BackendFilterType.CoderunStatuses}
            />
          }
          label="Crash"
        />
        <FormControlLabel
          control={
            <CheckboxWithNavigation
                filtering={filtering}
                activeStatuses={_coderunStatuses}
                status={CoderunStatus.ERROR}
                filterType={BackendFilterType.CoderunStatuses}
            />
          }
          label="Code error"
        />
         <FormControlLabel
          control={
            <CheckboxWithNavigation
                filtering={filtering}
                activeStatuses={_coderunStatuses}
                status={CoderunStatus.TIMEOUT}
                filterType={BackendFilterType.CoderunStatuses}
            />
          }
          label="Timeout"
        />
      </FormGroup>
    

    </Paper>
  );
};

export default ChallengesVerticalFilter;
