import { useState, useEffect } from "react";
import { Box, CircularProgress, Alert } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PageTitle from "../components/PageTitle";
import { getAllUserSubscriptions } from "./ChallengeViewSteps/LocalOperations";
import { formatDate } from "../utils/FormatDate";

interface Subscription {
  id: number;
  alert_sent_at: string | null;
  created_at: string;
  challenge_id: number;
  notification: string;
}

const UserSubscriptionsView = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const data: Subscription[] = await getAllUserSubscriptions();
        setSubscriptions(
          data.map((subscription: Subscription) => ({
            ...subscription,
          }))
        );
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const defaultFormatter = (params: { value: any }) => params.value || "none";

  const COLUMNS = [
    {
      field: "challenge_name",
      headerName: "Challenge",
      width: 250,
      valueFormatter: defaultFormatter,
    },
    {
      field: "alert_sent_at",
      headerName: "Alert sent at",
      width: 350,
      valueFormatter: (params: { value: any }) => formatDate(params.value),
    },
    {
      field: "created_at",
      headerName: "Alert subscription date",
      width: 350,
      valueFormatter: (params: { value: any }) => formatDate(params.value),
    },
    {
      field: "notification",
      headerName: "Notification",
      width: 250,
      valueFormatter: defaultFormatter,
    },
  ];

  return (
    <>
      <PageTitle header="List of Subscriptions" />
      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Alert severity="error">Error fetching subscriptions</Alert>
      ) : subscriptions.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <span>You have not subscribed to any challenge</span>
        </Box>
      ) : (
        <Box>
          <DataGrid
            rows={subscriptions}
            columns={COLUMNS}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 15, 20]}
            disableRowSelectionOnClick
          />
        </Box>
      )}
    </>
  );
};

export default UserSubscriptionsView;
