import ErrorAlert from "../../../components/ErrorAlert";
import { DataLabelDetailed } from "../../../types/DataLabelsDetailed";
import { useQueryDataFormat } from "../../../hooks/UseQueryDataFormat";
import {
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  ThemeProvider,
} from "@mui/material";
import { provideTheme } from "../../../utils/ProvideTheme";
import { TooltipedLabel } from "../../../components/TooltipedLabel";
import { useQueryTemplate } from "../../../hooks/UseQueryTemplate";

interface FormatCardTableProps {
  templateId: string;
  size?: "small" | "medium";
  isTitled?: boolean;
}

interface FormatCardTableRowProps {
  dataLabelDetailed: DataLabelDetailed;
  disabled?: boolean;
  size: "small" | "medium";
}

const FormatCardTableRow = ({
  dataLabelDetailed,
  disabled,
  size = "medium",
}: FormatCardTableRowProps) => {
  return (
    <TableRow>
      <TableCell size={size}>
        <TooltipedLabel
          label={dataLabelDetailed.label}
          constraints={dataLabelDetailed.constraints}
          disabledEdit
        />
      </TableCell>
      <TableCell size={size}>
        <Typography style={{ color: "silver" }}>
          {dataLabelDetailed.data_type}
        </Typography>
      </TableCell>
      <TableCell size={size}>
        <Checkbox
          checked={dataLabelDetailed.optional}
          name="optional"
          disabled={disabled}
        />
      </TableCell>
      <TableCell size={size}>
        <Checkbox
          checked={dataLabelDetailed.nullable}
          name="nullable"
          disabled={disabled}
        />
      </TableCell>
    </TableRow>
  );
};

const FormatCardTable = ({
  templateId,
  size = "medium",
  isTitled,
}: FormatCardTableProps) => {
  const qDataFormat = useQueryDataFormat(templateId);
  const qTemplate = useQueryTemplate(templateId);

  if (qDataFormat.isLoading) {
    return <CircularProgress />;
  }
  if (qDataFormat.isError) {
    return <ErrorAlert value={qDataFormat.error} />;
  }
  if (!qDataFormat.isSuccess) {
    return <ErrorAlert value={`Unkown error for data format ${templateId}`} />;
  }

  const dataLabels = qDataFormat.data.data_labels;
  if (dataLabels) {
    return (
      <ThemeProvider theme={provideTheme}>
        {isTitled && !!qTemplate.data?.name && (
          <Typography variant="h6" textAlign="center">
            Schema "{qTemplate.data.name}"
          </Typography>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Data type</TableCell>
              <TableCell>Optional</TableCell>
              <TableCell>Nullable</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLabels.map((dLabel: DataLabelDetailed) => (
              <FormatCardTableRow
                key={dLabel.label}
                dataLabelDetailed={dLabel}
                size={size}
                disabled
              />
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    );
  }

  return <CircularProgress />;
};

export default FormatCardTable;
