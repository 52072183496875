import { User } from "../types/Users";
import { UNLOGGED_ID } from "../Const";

const createDefaultUser = (): User => {
  return {
    id: UNLOGGED_ID,
    email: "unknown",
  };
};

export default createDefaultUser;
