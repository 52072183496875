import ChallengeCodeRuns from "./pages/ChallengeViewSteps/ChallengeCodeRuns";

interface PageCodeRunsProps {
  isViewingSingleCodeRun: boolean;
}

const PageCodeRuns = ({ isViewingSingleCodeRun }: PageCodeRunsProps) => {
  return <ChallengeCodeRuns isViewingSingleCodeRun={isViewingSingleCodeRun} />;
};

export default PageCodeRuns;
