/** try assuming UTC on datetime string; returns Date instance */
export const tryDatetimeUTC = (datetimeUTC: string): Date => {
  // idea:
  //    * if suffixing datetime with Z does not invalidate it, go for it

  datetimeUTC = datetimeUTC.trim();

  // try detecting whether datetime is timezoned
  const lowerDatetime = datetimeUTC.toLowerCase();
  let isTimezoned = lowerDatetime.includes("utc");
  isTimezoned = isTimezoned || !!lowerDatetime.match(/[a-z][a-z]t/); //gmt pst est hst nzdt awst mdt ...
  isTimezoned = isTimezoned || !!lowerDatetime.match(/[+-]\d\d?:/); //+01:00 -4:55 +33: ...

  // parse datetime, forced timezone datetime
  const dateOriginal = new Date(datetimeUTC);
  const dateZero = new Date(`${datetimeUTC}Z`); // force Z - Zero timezone, offset by 0 from UTC

  // if Date() failed parsing modified datetime string, .getTime() will return NaN
  //    since invalid datetime cannot be converted into timestamp.
  // When adding z might invalidate date:
  //    datetime already has "gmt" / "utc" / "+10:00" / "Z" timezone thingies.
  //    (no guarantees though)
  // dateOriginal might be invalid too, but we are still returning it as it is
  const dateFinal =
    isTimezoned || isNaN(dateZero.getTime()) ? dateOriginal : dateZero;
  return dateFinal;
};

/** adjust datetime to locale timezone, print in locale format. (null | undefined) => undefined */
export const localizeDatetimeUTC = (datetimeUTC: string | null | undefined) => {
  if (!datetimeUTC) {
    return undefined;
  }

  return tryDatetimeUTC(datetimeUTC).toLocaleString();
};
