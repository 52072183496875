import { getStatusClassname } from "../components/ColoredStatus";
import { Validation, Validations } from "../types/Validations";

/** checks validations and returns `true` if there is any unresolved (running) validation*/
export const getIsAnyValidationRunning = (validations?: Validations) => {
  return (
    validations === undefined || // validations are not yet loaded
    validations.length < 1 || // maybe still being created internally
    validations.find(
      (validation: Validation) =>
        // queued, running, timeout (because timeout is not yet implemented)
        getStatusClassname(validation.status) === undefined
    ) !== undefined
  );
};
