import ChallengeNew from "../types/ChallengeNew";

const createDefaultChallengeNew = (): ChallengeNew => {
  return {
    frequency_name: "inexistant_name_!@#$%^&*()-",
    completeness: -987654,
    email: "not-email",
    budget_eur_per_month: -987654,
    budget_length_months: -987654,
    data_description: "",
    name: "",
    project_id: -987654,
  };
};

export default createDefaultChallengeNew;
