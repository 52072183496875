import { Typography } from "@mui/material";
import PageTitle from "../components/PageTitle";
import ChallengesView from "./ChallengesView";
import PasswordChange from "./LoginSteps/components/PasswordChange";
import Failings from "./maintenance/components/Failings";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <h1>Home</h1>

      <PasswordChange />
      <div className="br" />
      <Failings />
      <PageTitle header="#1 Taken and open challenges" />
      <ChallengesView headless showFilters linkPrefix="challenges/" />
    </>
  );
};

export default Home;
