import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { ChallengeBrief } from "../../../types/ChallengesBrief";

import DatasetDownloader from "./DatasetDownloader";
import { formatChallengeName } from "../../../utils/FormatChallengeName";

export interface DatasetBannerProps {
  challenge: ChallengeBrief;
  linkPrefix?: string;
}

const DatasetBanner = ({ challenge, linkPrefix = "" }: DatasetBannerProps) => {
  const centerElement = <Typography>{challenge.data_description}</Typography>;

  return (
    <Card>
      <Box
        sx={{
          backgroundColor: "NavajoWhite",
          "&:hover": {
            backgroundColor: "LightSalmon",
          },
        }}
      >
        <CardContent>
          <Stack direction="row" justifyContent="space-around">
            <Typography align="left">
              {formatChallengeName(challenge)}
            </Typography>
            {centerElement}
            <DatasetDownloader challenge={challenge} />
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
};

export default DatasetBanner;
