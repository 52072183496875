import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "react-query";
import { BackendError } from "../../../types/BackendError";
import { getSchemaCustomization } from "../LocalOperations";
import { SchemaCustomization } from "../../../types/SchemaCustomization";
import { useCallback } from "react";

export const useQuerySchemaLossy = (
  snapshotId?: string | number,
  templateId?: string | number,
  options?: Omit<
    UseQueryOptions<
      SchemaCustomization,
      BackendError,
      SchemaCustomization,
      QueryKey
    >,
    "queryKey" | "queryFn"
  >
) => {
  const _snapshotId = String(snapshotId);
  const _templateId = String(templateId);
  return useQuery<SchemaCustomization, BackendError>(
    ["snapSchemaLossy", _snapshotId, _templateId],
    () => getSchemaCustomization(_snapshotId),
    options
  );
};

export const useInvalidateSchemaLossy = (
  snapshotId?: string | number,
  templateId?: string | number
) => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries([
      "snapSchemaLossy",
      String(snapshotId),
      String(templateId),
    ]);
  }, [queryClient, snapshotId, templateId]);
};
