import Title from "./Title";
import BasicHeaders from "../types/BasicHeaders";
import { TypographyVariant } from "@mui/material";

interface PageTitleProps extends BasicHeaders {
  headerVariant?: TypographyVariant;
  subHeaderVariant?: TypographyVariant;
  childrenStyle?: React.CSSProperties;
}

const PageTitle = ({
  style,
  header,
  subHeader,
  headerVariant = "h3",
  subHeaderVariant = "h5",
  childrenStyle,
}: PageTitleProps) => {
  return (
    <div className="pageTitle" style={style}>
      <Title
        header={header}
        subHeader={subHeader}
        headerVariant={headerVariant}
        subHeaderVariant={subHeaderVariant}
        headerStyle={childrenStyle}
        subHeaderStyle={childrenStyle}
      />
    </div>
  );
};

export default PageTitle;
