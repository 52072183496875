
import { ChallengeBrief } from "../../../types/ChallengesBrief";
import { useChallengeSLA } from "../../../hooks/UseChallengeSLA";
import TTabID from "../../../types/ChallengeViewTabsEnum";

import { Alert } from "@mui/material";
import { Link } from "react-router-dom";

import {  CircularProgress } from "@mui/material";

interface BannerChallengeStatusProps {
  challenge: ChallengeBrief;
}

const ChallengeFixNotice = ({ challenge }: BannerChallengeStatusProps) => {
  const qChallengeSLA = useChallengeSLA(challenge.id);
  
  if (qChallengeSLA.isSuccess) {
    const sla = qChallengeSLA.data;
    const solutionLink = `/challenges/${challenge.id}/?tab=${TTabID.SubmitCode}&runNumber=${sla.last_run_id}&userId=${sla.solver_id}`;
    const historyLink = `/challenges/${challenge.id}/?tab=${TTabID.CodeRuns}`;
    if (!sla.last_run_id || !sla.solver_id) {
      return null;
    }

    return (
      <Alert severity="error">
        Please fix the last solution by{" "}
        <Link to={solutionLink}>{sla.solver}</Link> or check entire{" "}
        <Link to={historyLink}>submission history</Link>
      </Alert>
    ); 
  } else {
    return <CircularProgress />;
  }
};

export default ChallengeFixNotice;
