import { CircularProgress } from "@mui/material";
import ErrorAlert from "../../../components/ErrorAlert";
import { Validations, Validation } from "../../../types/Validations";
import ValidationTable from "./ValidationTable";
import ValidationTableRow from "./ValidationTableRow";
import { getIsAnyValidationRunning } from "../../../utils/ValidationChecker";
import { useQueryValidations } from "../../../hooks/UseQueryValidations";

interface ValidationsProps {
  snapshotId: string;
}

const SnapshotValidations = ({ snapshotId }: ValidationsProps) => {
  const qValidations = useQueryValidations(snapshotId, {
    refetchInterval: (validations?: Validations) => {
      return getIsAnyValidationRunning(validations) ? 5 * 1000 : 0;
    },
  });

  if (qValidations.isError) {
    return (
      <ErrorAlert
        prefix="Failed loading validations. "
        value={qValidations.error}
      />
    );
  }

  if (qValidations.isLoading) {
    return <CircularProgress />;
  }

  if (!qValidations.data) {
    return (
      <ErrorAlert value="Unexpected: blank format validations received from server." />
    );
  } else {
    // added else so TS would know that qValidations.data is defined
    return (
      <ValidationTable>
        {qValidations.data.map((validation: Validation) => (
          <ValidationTableRow validation={validation} key={validation.id} />
        ))}
      </ValidationTable>
    );
  }
};

export default SnapshotValidations;
