import { Button } from "@mui/material";

interface FinishIn {
  onClick: () => void;
  text?: string;
  disabled?: boolean;
}

const FinishButton = (props: FinishIn) => {
  return (
    <Button
      variant="contained"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text ?? "Confirm"}
    </Button>
  );
};

export default FinishButton;
