import {
  Select,
  MenuItem,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { User } from "../types/Users";
import { ProjectUser } from "../types/ProjectUsers";
import createDefaultUser from "../utils/CreateDefaultUser";
import ErrorAlert from "./ErrorAlert";
import { useQueryUsersBasic } from "../hooks/UseQueryUsersBasic";
import { useQueryProjectUsers } from "../hooks/UseQueryProjectUsers";

interface SelectUserProps {
  userId: string;
  onChangeUser: (user?: User) => void;
  nameForEmpty?: string;
  prependDefaultUser?: boolean;
  labelId?: string;
  size?: SelectProps["size"];
  projectId?: string;
}

const SelectUser = ({
  userId,
  onChangeUser,
  nameForEmpty,
  prependDefaultUser,
  labelId,
  size,
  projectId,
}: SelectUserProps) => {
  const usersQuery = useQueryUsersBasic();
  const projectUsersQuery = useQueryProjectUsers(projectId || "noid", {
    enabled: !!projectId,
  });

  const query = projectId ? projectUsersQuery : usersQuery;

  const defaultUser = createDefaultUser();
  const defaultUserMenuItem = (
    <MenuItem value={defaultUser.id} key={defaultUser.id}>
      Invalid user (or unlogged)
    </MenuItem>
  );

  const mapToUser = (user: User | ProjectUser): User => {
    if ("user_id" in user) {
      return {
        id: String(user.user_id),
        email: user.email,
      } as User;
    }
    return user as User;
  };

  const onChange = (e: SelectChangeEvent<string>): void => {
    if (query.isLoading) {
      return;
    }
    if (!query.data || query.isError) {
      if (nameForEmpty) {
        return onChangeUser(undefined);
      }
      if (prependDefaultUser) {
        return onChangeUser(defaultUser);
      }
      return;
    }

    const getIsMatchById = (user: User | ProjectUser) => {
      const mappedUser = mapToUser(user);
      return mappedUser.id === e.target.value;
    };

    if (nameForEmpty && e.target.value === "") {
      return onChangeUser(undefined);
    }

    let userMatch: User | undefined = undefined;

    if (projectId) {
      userMatch = (query.data as ProjectUser[]).find(getIsMatchById) as
        | User
        | undefined;
    } else {
      userMatch = (query.data as User[]).find(getIsMatchById);
    }

    const chosenUser = userMatch
      ? mapToUser(userMatch)
      : prependDefaultUser
      ? defaultUser
      : undefined;

    onChangeUser(chosenUser);
  };

  const prependedItems = [];
  if (nameForEmpty) {
    prependedItems.push(
      <MenuItem value="">
        <em>{nameForEmpty}</em>
      </MenuItem>
    );
  }
  if (prependDefaultUser) {
    prependedItems.push(defaultUserMenuItem);
  }

  return (
    <>
      <Select
        value={query.isLoading ? "loading" : userId}
        onChange={onChange}
        disabled={query.isLoading}
        labelId={labelId}
        size={size}
        placeholder={nameForEmpty}
      >
        {query.isLoading && (
          <MenuItem disabled value="loading">
            Loading ...
          </MenuItem>
        )}
        {query.isSuccess && [
          ...prependedItems,
          ...query.data.map((user: User | ProjectUser) => {
            const mappedUser = mapToUser(user);
            return (
              <MenuItem value={mappedUser.id} key={mappedUser.id}>
                {mappedUser.email}
              </MenuItem>
            );
          }),
        ]}
        {query.isError && prependDefaultUser && defaultUserMenuItem}
      </Select>
      {query.isError && (
        <ErrorAlert prefix="Could not fetch users. " value={query.error} />
      )}
    </>
  );
};

export default SelectUser;
