import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Chip, Box, Tooltip } from "@mui/material";
import ChapterTitle from "../../../components/ChapterTitle";
import InfoIcon from "@mui/icons-material/Info";
import {
  useQueryTemplateListensAnomaly,
  useInvalidateTemplateListensAnomaly,
} from "../../../hooks/UseQueryTemplateListensAnomaly";
import AnomalyTypeEnum from "../../../types/AnomalyTypeEnum";
import { useMutation } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { updateTemplateListensToAnomaly } from "../LocalOperations";
import ErrorAlert from "../../../components/ErrorAlert";

interface SelectAnomalyTypeForTemplateProps {
  templateId: string;
}

const anomalyLabels: Record<AnomalyTypeEnum, string> = {
  [AnomalyTypeEnum.RowIncrease]: "Row increase",
  [AnomalyTypeEnum.RowDecrease]: "Row decrease",
};

const SelectAnomalyTypeForTemplate = ({
  templateId,
}: SelectAnomalyTypeForTemplateProps) => {
  const { data: templateListensAnomalyData, isLoading } =
    useQueryTemplateListensAnomaly(templateId);

  const anomalyOptions = React.useMemo(
    () => Object.values(AnomalyTypeEnum),
    []
  );
  const invalidateTemplateListensAnomaly =
  useInvalidateTemplateListensAnomaly(templateId);
  
  const [selectedAnomalies, setSelectedAnomalies] = useState<AnomalyTypeEnum[]>(
    []
  );
  useEffect(() => {
    if (Array.isArray(templateListensAnomalyData)) {
      const initialSelectedAnomalies = templateListensAnomalyData
        .filter(
          (item) =>
            item.anomaly_type && anomalyOptions.includes(item.anomaly_type)
        )
        .map((item) => item.anomaly_type as AnomalyTypeEnum);
      setSelectedAnomalies(initialSelectedAnomalies);
    }
  }, [templateListensAnomalyData, anomalyOptions]);

  const updateAnomalyTypeMutation = useMutation<
    void,
    BackendError,
    { templateId: string; anomalyType: AnomalyTypeEnum; active: boolean }
  >(
    ({ templateId, anomalyType, active }) =>
      updateTemplateListensToAnomaly(templateId, anomalyType, active),
    {
      onSuccess: () => {
        invalidateTemplateListensAnomaly();
      },
    }
  );

  const handleAnomalySelectionChange = (
    changedAnomaly: AnomalyTypeEnum,
    isAnomalyTypeSelected: boolean
  ) => {
    updateAnomalyTypeMutation.mutate({
      templateId,
      anomalyType: changedAnomaly,
      active: isAnomalyTypeSelected,
    });
  };

  return (
    <Box>
      <Box mb={1} display="flex" alignItems="center" justifyContent="center">
        <ChapterTitle header="Anomaly type selector" />
        <Tooltip title="Selected anomaly types check will be applied for all challenges, that use current template">
          <InfoIcon fontSize="small" color="info" sx={{ marginLeft: 1 }} />
        </Tooltip>
      </Box>
      <Autocomplete
        multiple
        options={anomalyOptions}
        getOptionLabel={(option) => anomalyLabels[option]}
        value={selectedAnomalies}
        loading={isLoading}
        disabled={updateAnomalyTypeMutation.isLoading}
        onChange={(event, newSelectedAnomalies) => {
          const changedAnomaly =
            newSelectedAnomalies.find(
              (anomaly) => !selectedAnomalies.includes(anomaly)
            ) ||
            selectedAnomalies.find(
              (anomaly) => !newSelectedAnomalies.includes(anomaly)
            );

          if (changedAnomaly) {
            const isAnomalyTypeSelected =
              newSelectedAnomalies.includes(changedAnomaly);
            handleAnomalySelectionChange(changedAnomaly, isAnomalyTypeSelected);
          }
        }}
        renderTags={(value: AnomalyTypeEnum[], getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={anomalyLabels[option]}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Select anomaly type(s)"
            placeholder="Select anomaly type(s)"
          />
        )}
      />
      {
        updateAnomalyTypeMutation.isError && (
        <ErrorAlert value={updateAnomalyTypeMutation.error} />)
      }
    </Box>
  );
};

export default SelectAnomalyTypeForTemplate;
