import { Alert, Paper, Typography } from "@mui/material";
import { usePerms } from "../../../hooks/UsePerms";
import { useQueryCodeReview } from "../hooks/UseQueryCodeReview";
import { localizeDatetimeUTC } from "../../../utils/Localize";
import { Perm } from "../../../Const";
import CodeRunReviewActions from "./CodeRunReviewActions";
import { Link } from "react-router-dom";
import TTabID from "../../../types/ChallengeViewTabsEnum";

interface CodeRunReviewProps {
  challengeId: string;
  codeRunNumber: string;
  userId: string;
}

const CodeRunReview = ({
  challengeId,
  codeRunNumber,
  userId,
}: CodeRunReviewProps) => {
  const perms = usePerms();
  const qCodeReview = useQueryCodeReview(challengeId, codeRunNumber, userId, {
    staleTime: 5 * 1000, // 5 s
  });

  const isReviewed = !!qCodeReview.data?.date_reviewed;
  const isRejected = !!qCodeReview.data?.rejection_text;
  const isLatest =
    String(qCodeReview.data?.last_manual_run_number) === String(codeRunNumber);
  const review = qCodeReview.data;

  if (!review) {
    return null;
  }

  const _localDate = localizeDatetimeUTC(review.date_reviewed);
  const _bodyRejected = (
    <>
      <Typography>The submitted code was rejected on {_localDate}.</Typography>
      <Typography variant="caption">Feedback left:</Typography>
      <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
        "{review.rejection_text}"
      </Typography>
    </>
  );
  const _bodyAccepted = (
    <Typography>The submitted code was approved on {_localDate}.</Typography>
  );

  return (
    <>
      {!isLatest && !!review.last_manual_run_number && (
        <Alert severity="warning">
          You are viewing an outdated code. Go to the{" "}
          <Link
            to={`/challenges/${challengeId}/?tab=${TTabID.SubmitCode}&runNumber=${review.last_manual_run_number}&userId=${userId}`}
            reloadDocument
          >
            latest code
          </Link>
          .
        </Alert>
      )}

      {isReviewed ? (
        <Alert
          severity={isRejected ? "error" : "success"}
          variant="outlined"
          style={{ paddingBottom: "0px" }}
        >
          {isRejected ? _bodyRejected : _bodyAccepted}
        </Alert>
      ) : (
        perms.has(Perm.REVIEW_ANY_CODE) && (
          <Paper style={{ padding: "18px 10px" }}>
            <Typography variant="h6">Manage code review</Typography>
            <CodeRunReviewActions
              challengeId={challengeId}
              codeRunNumber={codeRunNumber}
              userId={userId}
            />
          </Paper>
        )
      )}
    </>
  );
};

export default CodeRunReview;
