import {
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  Container,
  Button,
} from "@mui/material";
import GridCard from "../../components/GridCard";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useRef } from "react";
import { useMutation } from "react-query";
import { DataLabels } from "../../types/DataLabels";
import { postSampleFile } from "./LocalOperations";
import { BackendError } from "../../types/BackendError";
import extractError from "../../utils/ErrorExtract";
import SearchExistingFormat from "./components/SearchExistingFormat";
import ErrorAlert from "../../components/ErrorAlert";

interface DefineDatasetIn {
  onDataLabelsSuccess: (a: DataLabels, fileName: string) => void;
  onChooseExistingFormat: (templateId: string) => void;
  selectedDataset: string;
  uploadedFileName: string;
  uploadedFileData: DataLabels;
  setSelectedDataset: (templateId: string) => void;
  setUploadedFileName: (fileName: string) => void;
  setUploadedFileData: (uploadedFileData: DataLabels) => void;
}

const DefineDataset = (props: DefineDatasetIn) => {
  const handleDatasetSelection = (templateId: string | null) => {
    props.setSelectedDataset(templateId || "");
    props.setUploadedFileName("");
    props.setUploadedFileData([]);
  };

  const handleNext = () => {
    if (props.selectedDataset) {
      props.onChooseExistingFormat(props.selectedDataset);
    } else if (props.uploadedFileData.length > 0) {
      props.onDataLabelsSuccess(props.uploadedFileData, props.uploadedFileName);
    }
  };

  const handleDeleteFile = () => {
    props.setUploadedFileName("");
    props.setUploadedFileData([]);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const mutation = useMutation<DataLabels, BackendError, any>(postSampleFile, {
    onSuccess: (data: DataLabels) => {
      props.setUploadedFileData(data);
    },
    onError: () => {
      if (formRef) {
        formRef.current?.reset();
        props.setUploadedFileName("");
      }
    },
  });
  const errorMessage = extractError(mutation.error);

  const filesRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const handleFilesChange = async () => {
    mutation.mutate(filesRef?.current?.files?.[0] ?? null);
    const fileName = filesRef?.current?.files?.[0]?.name;
    if (fileName) {
      props.setUploadedFileName(fileName);
    }
  };

  return (
    <Container className="cardGrid">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" sx={{ marginBottom: 3 }}>
            Data selection
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body1">Existing format</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ marginBottom: 1 }}
          >
            Choose one of existing formats or datasets:
          </Typography>
        </Grid>
        <SearchExistingFormat
          label="Select format or dataset"
          onChooseExisting={handleDatasetSelection}
          disabled={props.uploadedFileData.length > 0}
          value={props.selectedDataset}
        />
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ marginTop: 2, marginBottom: 2 }}
          >
            or
          </Typography>
        </Grid>
        <GridCard
          header="New format"
          subHeader="Upload sample data in XLS format."
        >
          <IconButton
            color="primary"
            component="label"
            size="large"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            disabled={mutation.isLoading || props.selectedDataset !== ""}
          >
            <form ref={formRef}>
              <input
                hidden
                accept=".csv,.xls,.xlsx"
                type="file"
                ref={filesRef}
                onChange={handleFilesChange}
              />
            </form>
            <FileUploadIcon fontSize="inherit" style={{ marginRight: 8 }} />
            <Typography
              variant="button"
              color={
                mutation.isLoading || props.selectedDataset !== ""
                  ? "default"
                  : "primary"
              }
            >
              ADD FILE
            </Typography>
          </IconButton>
          {props.uploadedFileName && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Typography
                variant="body1"
                align="center"
                style={{ marginRight: 8 }}
              >
                {props.uploadedFileName}
              </Typography>
              {mutation.isLoading ? (
                <CircularProgress />
              ) : (
                <IconButton
                  onClick={handleDeleteFile}
                  color="primary"
                  aria-label="delete file"
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </div>
          )}
          {mutation.error && (
            <ErrorAlert
              onClose={() => {
                mutation.reset();
              }}
              value={errorMessage}
            />
          )}
        </GridCard>
        <Grid item xs={12} sm={12} md={12} marginTop={1}>
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={
              props.selectedDataset === "" && props.uploadedFileData.length <= 0
            }
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DefineDataset;
