import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { TemplatingEditContext } from "../contexts/TemplatingEditContext";
import { useMutation } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { postTemplateSave, postTemplateSaveAs } from "../LocalOperations";
import ErrorAlert from "../../../components/ErrorAlert";
import { TemplateFork } from "../../../types/TemplateFork";
import { UseTemplatingType } from "../hooks/UseTemplating";
import { a11yProps } from "../../ChallengeViewSteps/components/CustomTabPanel";
import { useQueryTemplate } from "../../../hooks/UseQueryTemplate";

interface TemplatingSaverForkProps {
  onSaveAsSuccess?: () => void;
  templating: UseTemplatingType;
}

interface TemplatingSaverOverwriteProps {
  onSaveOverwriteSuccess?: () => void;
  templating: UseTemplatingType;
}

const TabSaveAs = ({ templating, ...props }: TemplatingSaverForkProps) => {
  const [name, setName] = useState("");
  const qSaveAs = useMutation<
    any,
    BackendError,
    { templateId: string; data: TemplateFork }
  >((packed) => postTemplateSaveAs(packed.templateId, packed.data), {
    onSuccess: () => {
      templating?.invalidate();
      props.onSaveAsSuccess?.();
    },
  });
  return (
    <>
      <Typography variant="h6">Save As</Typography>
      <Typography>
        New template will be forked for current challenge.
      </Typography>
      <Typography>
        Reusable template? Name it. Otherwise leave empty name to make the
        template private.
      </Typography>
      <Stack direction="row" style={{ paddingTop: "11px" }}>
        <TextField
          label="Save as name"
          style={{ marginRight: "11px" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          variant="outlined"
          onClick={() =>
            qSaveAs.mutate({
              templateId: templating.templateId,
              data: {
                data_labels: templating.toDataLabels(),
                new_name: name || undefined,
                transfer_challenges_id: [templating.challengeId],
              },
            })
          }
          disabled={qSaveAs.isLoading}
          endIcon={qSaveAs.isLoading ? <CircularProgress /> : undefined}
          style={{ height: "55px" }}
        >
          Save As
        </Button>
      </Stack>
      {!!qSaveAs.error && <ErrorAlert isClosable value={qSaveAs.error} />}
    </>
  );
};

const TabSave = ({ templating, ...props }: TemplatingSaverOverwriteProps) => {
  const qSave = useMutation<any, BackendError>(
    () =>
      postTemplateSave(templating.templateId, {
        data_labels: templating.toDataLabels(),
      }),
    {
      onSuccess: () => {
        templating?.invalidate();
        props.onSaveOverwriteSuccess?.();
      },
    }
  );
  const qTemplate = useQueryTemplate(templating.templateId);
  return (
    <>
      <Typography variant="h6">Save</Typography>
      <Typography>Schema of current template will be overwritten.</Typography>
      <Typography>
        Please be aware that it will affect all challenges that are using "
        {qTemplate.data?.name || "loading..."}" template.
      </Typography>
      <Button
        variant="outlined"
        onClick={() => qSave.mutate()}
        disabled={qSave.isLoading}
        endIcon={qSave.isLoading ? <CircularProgress /> : undefined}
        style={{ height: "55px", marginTop: "11px" }}
      >
        Save by Overwritting
      </Button>
      {!!qSave.error && <ErrorAlert isClosable value={qSave.error} />}
    </>
  );
};

interface TemplatingSaverProps
  extends TemplatingSaverForkProps,
    TemplatingSaverOverwriteProps {
  style?: React.CSSProperties;
}

enum TAB {
  SAVE = "save",
  SAVE_AS = "saveAs",
}

export const TemplatingSaver = ({
  style,
  ...props
}: Omit<TemplatingSaverProps, "templating">) => {
  const templating = useContext(TemplatingEditContext);
  const [tabValue, setTabValue] = useState(TAB.SAVE_AS);

  if (!templating) {
    return null;
  }

  return (
    <Card style={style} variant="outlined">
      <Tabs
        value={tabValue}
        onChange={(_e, val) => {
          setTabValue(val);
        }}
        aria-label="Templating tabs"
      >
        <Tab label="Forking" {...a11yProps(TAB.SAVE_AS)} value={TAB.SAVE_AS} />
        <Tab label="Overwritting" {...a11yProps(TAB.SAVE)} value={TAB.SAVE} />
      </Tabs>
      <CardContent
        style={{ display: tabValue === TAB.SAVE_AS ? undefined : "none" }}
      >
        <TabSaveAs {...props} templating={templating} />
      </CardContent>
      <CardContent
        style={{ display: tabValue === TAB.SAVE ? undefined : "none" }}
      >
        <TabSave {...props} templating={templating} />
      </CardContent>
    </Card>
  );
};
