import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useQueryProjectDetails } from "../hooks/useQueryProjectDetails";
import { Project } from "../../../types/Projects";
import { BackendError } from "../../../types/BackendError";
import EditableField from "../../ChallengeViewSteps/components/EditableField";
import { Grid, Typography, IconButton } from "@mui/material";
import { patchProjectName, patchProjectDescription } from "../LocalOperations";
import {
  useInvalidateUserProjects,
  useInvalidateProject,
} from "../../../hooks/useQueryProjects";
import EditIcon from "@mui/icons-material/Edit";

interface EditableProjectFeatureProps {
  projectId: string;
  getValue: (project: Project) => any;
  updateValue: any;
  readMode?: React.ReactNode | null;
}

const EditableProjectFeature = ({
  projectId,
  getValue,
  updateValue,
  readMode,
}: EditableProjectFeatureProps) => {
  const [value, setValue] = useState("");
  const [editor, setEditor] = useState(false);
  const invalidateUserProjects = useInvalidateUserProjects();
  const invalidateProject = useInvalidateProject(projectId);

  const {
    data: project,
    isLoading: isProjectLoading,
    isError,
    error: projectError,
  } = useQueryProjectDetails(projectId);

  const qEditValue = useMutation<void, BackendError, void>(() => {
    return updateValue(projectId, value).then(() => {
      invalidateProject();
      invalidateUserProjects();
    });
  });

  const handleCancel = () => {
    invalidateProject();
    setEditor(false);
  };

  useEffect(() => {
    if (project) {
      setValue(getValue(project));
    }
  }, [project, getValue]);

  if (!editor) {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <Typography>
            <IconButton size="small" onClick={() => setEditor(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs>
          {!!readMode ? (
            readMode
          ) : (
            <Typography
              variant="h6"
              sx={{ textDecoration: "none", fontWeight: "normal" }}
            >
              {value}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <EditableField
      label=""
      fieldValue={value}
      onFieldChange={setValue}
      onSave={qEditValue.mutate}
      onCancel={handleCancel}
      isLoading={isProjectLoading || qEditValue.isLoading}
      error={
        isError
          ? projectError
          : qEditValue.isError
          ? qEditValue.error
          : undefined
      }
      isEditSuccess={qEditValue.isSuccess}
      multiline={true}
      justify="flex-start"
    />
  );
};

interface EditableProjectFeatureImplementationProps {
  projectId: string;
  readMode?: React.ReactNode | null;
}

const EditableProjectName = ({
  projectId,
}: EditableProjectFeatureImplementationProps) => {
  return (
    <EditableProjectFeature
      projectId={projectId}
      getValue={(project: Project) => project.name ?? ""}
      updateValue={patchProjectName}
    />
  );
};

const EditableProjectDescription = ({
  projectId,
}: EditableProjectFeatureImplementationProps) => {
  return (
    <EditableProjectFeature
      projectId={projectId}
      getValue={(project: Project) => project.description ?? ""}
      updateValue={patchProjectDescription}
    />
  );
};

export { EditableProjectName, EditableProjectDescription };
