import React, { useContext } from "react";
import {
  ChallengeColumn,
  ChallengesListDisplay,
} from "../contexts/ChallengesListDisplay";
import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import ExternalLink from "../../../components/ExternalLink";
import { ChallengeBrief } from "../../../types/ChallengesBrief";
import { NavigateFunction, useNavigate } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import BannerChallengeStatus from "../../ChallengeViewSteps/components/BannerChallengeStatus";
import BannerChallengeSolver from "../../ChallengeViewSteps/components/BannerChallengeSolver";
import BannerRight from "../../ChallengeViewSteps/components/BannerRight";
import { formatChallengeName } from "../../../utils/FormatChallengeName";

const _makeCellContent = (
  columnType: ChallengeColumn,
  challenge: ChallengeBrief,
  navigate: NavigateFunction,
  onShowDetails?: (challengeId: string) => void
) => {
  const _lockStyle: React.CSSProperties = {
    // reservation lock icon
    marginBottom: "-5px",
  };
  switch (columnType) {
    case ChallengeColumn.NAME:
      return (
        <Stack direction="column">
          <Typography>{formatChallengeName(challenge)}</Typography>
          {!!challenge.target_url ? (
            <Typography variant="body2">
              <ExternalLink href={challenge.target_url}>
                {challenge.target_url}
              </ExternalLink>
            </Typography>
          ) : (
            <Typography>no link</Typography>
          )}
        </Stack>
      );
    case ChallengeColumn.FREQUENCY:
      return <Typography>{challenge.frequency_name}</Typography>;
    case ChallengeColumn.SOLVER:
      return <BannerChallengeSolver challenge={challenge} />;
    case ChallengeColumn.STATUS:
      return (
        <Typography>
          <BannerChallengeStatus challenge={challenge} />
        </Typography>
      );
    case ChallengeColumn.ACTION:
      return (
        <BannerRight
          challenge={challenge}
          variant={"monitor"}
          linkPrefix={"/challenges/"}
          onShowDetails={onShowDetails}
        />
      );
    case ChallengeColumn.DESCRIPTION:
      return <Typography>{challenge.data_description}</Typography>;
    case ChallengeColumn.RESERVATION:
      return (
        <Typography
          style={{
            backgroundColor: challenge.is_reserved
              ? "lemonchiffon"
              : "lightgreen",
            borderRadius: "11px",
          }}
          textAlign="center"
        >
          {challenge.is_reserved ? (
            <LockOutlinedIcon style={_lockStyle} />
          ) : (
            <LockOpenIcon style={_lockStyle} />
          )}
          {challenge.is_reserved ? "Reserved" : "Free"}
        </Typography>
      );
    default:
      return <Typography>_unknown</Typography>;
  }
};

interface ChallengeRowProps {
  challenge: ChallengeBrief;
  onShowDetails?: (challengeId: string) => void;
}

const ChallengeRow = ({ challenge, onShowDetails }: ChallengeRowProps) => {
  const options = useContext(ChallengesListDisplay);
  const navigate = useNavigate();
  if (options === undefined) {
    return <></>;
  }

  return (
    <TableRow>
      {options.columns.map((columnType) => (
        <TableCell key={columnType}>
          {_makeCellContent(columnType, challenge, navigate, onShowDetails)}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ChallengeRow;
