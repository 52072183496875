import API from "../../API";
import { CHALLENGES_PER_PAGE } from "../../Const";
import { axiosTokened } from "../../utils/AxiosInstances";
import { ChallengeFilter } from "../ChallengeViewSteps/hooks/UseChallengeFilter";
var qs = require('qs');

interface OnlyPageParam {
  pageParam?: number;
}

export const getChallengesBriefFiltered = async (
  page: OnlyPageParam,
  filters: ChallengeFilter[]
) => {
  const filtersAsParams = Object.assign(
    {},
    ...filters.map(({ value, type }) => {
      return { [`${type}`]: value };
    })
  );
  const { data } = await axiosTokened.get(API.getChallengesBrief, {
    params: {
      limit: CHALLENGES_PER_PAGE,
      skip: CHALLENGES_PER_PAGE * (page.pageParam ?? 0),
      ...filtersAsParams,
    },
    paramsSerializer: {
      'serialize': params => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },

  });
  return data;
};
