import { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useMutation } from "react-query";
import { postProjectNew } from "../pages/ChallengesNewSteps/LocalOperations";
import { useInvalidateProjects, useInvalidateUserProjects } from "../hooks/useQueryProjects";
import { BackendError } from "../types/BackendError";
import ProjectNew from "../types/ProjectNew";
import ErrorAlert from "./ErrorAlert";

const ProjectCreationForm = () => {
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const invalidateProjects = useInvalidateProjects();
  const invalidateUserProjects = useInvalidateUserProjects();

  const postProject = useMutation<void, BackendError, ProjectNew>(
    postProjectNew
  );

  const onCreateProject = () => {
    if (projectName.trim().length > 0 && projectDescription.trim().length > 0) {
      postProject.mutate(
        { name: projectName, description: projectDescription },
        {
          onSuccess: () => {
            setProjectName("");
            setProjectDescription("");
            setSnackbarMessage("Project created successfully!");
            setOpenSnackbar(true);
            invalidateProjects(); // refetch project list
            invalidateUserProjects(); // refetch user project list
          },
        }
      );
    }
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="project-name-input"
            label="Project Name"
            variant="outlined"
            fullWidth
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="project-description-input"
            label="Project Description"
            variant="outlined"
            fullWidth
            value={projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          {postProject.isError && (
            <ErrorAlert value={postProject.error} sx={{ marginBottom: 1 }} />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateProject}
            disabled={
              postProject.isLoading ||
              projectName.trim().length < 1 ||
              projectDescription.trim().length < 1
            }
          >
            {postProject.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Create Project"
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectCreationForm;
