import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "react-query";
import { patchMakeProjectOnboardingTemplate } from "../LocalOperations";
import { BackendError } from "../../../types/BackendError";
import { ProjectMakeTemplateAnswer } from "../../solutionSubmission/components/ProjectCollision";
import ErrorAlert from "../../../components/ErrorAlert";
import { useInvalidateProject, useInvalidateUserProjects } from "../../../hooks/useQueryProjects";

interface MakeProjectOnboardingTemplateModalProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
  onMakeProjectOnboardingTemplateSuccess?: () => void;
}

const MakeProjectOnboardingTemplateModal: React.FC<
  MakeProjectOnboardingTemplateModalProps
> = ({ open, onClose, projectId, onMakeProjectOnboardingTemplateSuccess }) => {

  const invalidateUserProjects = useInvalidateUserProjects();
  const invalidateProject = useInvalidateProject(projectId);

  const setProjectAsOnboardingTemplate = useMutation<
    ProjectMakeTemplateAnswer,
    BackendError,
    { projectId: string | number; isForce: boolean }
  >(
    ({ projectId, isForce }) =>
      patchMakeProjectOnboardingTemplate({ projectId, isForce }),
    {
      onSuccess: () => {
        if (onMakeProjectOnboardingTemplateSuccess) {
          onMakeProjectOnboardingTemplateSuccess();
        }
        invalidateProject();
        invalidateUserProjects();
        onClose();
      },
      onError: () => {},
    }
  );

  const handleMakeProjectOnboardingTemplate = () => {
    setProjectAsOnboardingTemplate.mutate({
      projectId: projectId || "",
      isForce: true,
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box display="flex" justifyContent="center" marginBottom={2}>
          <Typography variant="h4">
            Make project as onboarding template
          </Typography>
        </Box>
        <Typography variant="body1" align="center">
          Are you sure you want to make this project an onboarding template?
          Doing so will appoint new users on registration to a clone of current
          project with cloned challenges. The owner of such onboarding template
          will be the same as project of current project.
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleMakeProjectOnboardingTemplate}
            disabled={setProjectAsOnboardingTemplate.isLoading}
          >
            {setProjectAsOnboardingTemplate.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Yes"
            )}
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={onClose}
            disabled={setProjectAsOnboardingTemplate.isLoading}
          >
            No
          </Button>
        </Box>
        {setProjectAsOnboardingTemplate.isError && (
          <ErrorAlert
            value={setProjectAsOnboardingTemplate.error}
            sx={{ marginTop: 1 }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default MakeProjectOnboardingTemplateModal;
