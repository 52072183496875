import axios from "axios";
import API from "../API";
import { SnapshotValidationSummary } from "../types/SnapshotValidationSummary";
import { axiosTokened } from "./AxiosInstances";
import { PermsWrapper } from "../types/PermsWrapper";

export const getValidationsSummary = async (snapshotId: string) => {
  const { data } = await axios.get<SnapshotValidationSummary>(
    API.genGetValidationsSummary(snapshotId)
  );
  return data;
};

export const getMyPerms = async () => {
  const { data } = await axiosTokened.get<PermsWrapper>(API.getMyPerms);
  return data;
};

export type APIKeyWrapper = {
  api_key: string;
};

export const getMyAPIKey = async () => {
  const { data } = await axiosTokened.get<APIKeyWrapper>(API.getMyAPIKey);
  return data;
};