import { useQuery } from "react-query";
import { Counter } from "../../../types/counter";
import { BackendError } from "../../../types/BackendError";
import { User } from "../../../types/Users";
import {
  getAllProjectChallengesCount,
  getFreeProjectChallengesCount,
  getSolverProjectChallengesCount,
  getProjectSnoozedChallengesCount,
  getProjectChallengesWithErrorsCount,
  getProjectChallengesWithWarningsCount,
  getProjectChallengesNeedFixCount,
  getProjectChallengesWaitingForReviewCount,
  getProjectChallengesCreationSLABreachedCount,
  getProjectChallengesDeliverySLABreachedCount,
  getProjectAnomalyChallengesCount,
} from "../../ChallengeViewSteps/LocalOperations";

interface ChallengeCounts {
  qAllChallengesCount: ReturnType<typeof useQuery<Counter, BackendError>>;
  qFreeChallengesCount: ReturnType<typeof useQuery<Counter, BackendError>>;
  qSolverChallengesCount: ReturnType<typeof useQuery<Counter, BackendError>>;
  qSnoozedChallengesCount: ReturnType<typeof useQuery<Counter, BackendError>>;
  qAnomalyChallengesCount: ReturnType<typeof useQuery<Counter, BackendError>>;
  qChallengesWithErrorsCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
  qChallengesWithWarningsCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
  qChallengesNeedFixCount: ReturnType<typeof useQuery<Counter, BackendError>>;
  qChallengesWaitingForReviewCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
  qChallengesCreationSLABreachedCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
  qChallengesDeliverySLABreachedCount: ReturnType<
    typeof useQuery<Counter, BackendError>
  >;
}

export const useChallengesCount = (
  user: User,
  projectId: string,
  showMySolvings: boolean = false
): ChallengeCounts => {
  const enabled = !!user.id;

  const countOperations = {
    getAll: () => getAllProjectChallengesCount(projectId),
    getFree: () => getFreeProjectChallengesCount(projectId),
    getSolver: () => getSolverProjectChallengesCount(projectId),
    getSnoozed: () => getProjectSnoozedChallengesCount(projectId),
    getAnomalies: () => getProjectAnomalyChallengesCount(projectId),
    getErrors: () =>
      getProjectChallengesWithErrorsCount(projectId, showMySolvings),
    getWarnings: () =>
      getProjectChallengesWithWarningsCount(projectId, showMySolvings),
    getNeedFix: () =>
      getProjectChallengesNeedFixCount(projectId, showMySolvings),
    getWaitingForReview: () =>
      getProjectChallengesWaitingForReviewCount(projectId, showMySolvings),
    getCreationSLABreach: () =>
      getProjectChallengesCreationSLABreachedCount(projectId, showMySolvings),
    getDeliverySLABreach: () =>
      getProjectChallengesDeliverySLABreachedCount(projectId, showMySolvings),
  };

  const qAllChallengesCount = useQuery<Counter, BackendError>(
    ["allChallengesCount", user.id, projectId],
    countOperations.getAll,
    { enabled }
  );

  const qFreeChallengesCount = useQuery<Counter, BackendError>(
    ["freeChallengesCount", user.id, projectId],
    countOperations.getFree,
    { enabled }
  );

  const qSolverChallengesCount = useQuery<Counter, BackendError>(
    ["solverChallengesCount", user.id, projectId],
    countOperations.getSolver,
    { enabled }
  );

  const qSnoozedChallengesCount = useQuery<Counter, BackendError>(
    ["snoozedChallengesCount", user.id, projectId],
    countOperations.getSnoozed,
    { enabled }
  );

  const qAnomalyChallengesCount = useQuery<Counter, BackendError>(
    ["anomalyChallengesCount", user.id, projectId],
    countOperations.getAnomalies,
    { enabled }
  );

  const qChallengesWithErrorsCount = useQuery<Counter, BackendError>(
    ["challengesWithErrorsCount", user.id, projectId, showMySolvings],
    countOperations.getErrors,
    { enabled }
  );

  const qChallengesWithWarningsCount = useQuery<Counter, BackendError>(
    ["challengesWithWarningsCount", user.id, projectId, showMySolvings],
    countOperations.getWarnings,
    { enabled }
  );

  const qChallengesNeedFixCount = useQuery<Counter, BackendError>(
    ["challengesNeedFixCount", user.id, projectId, showMySolvings],
    countOperations.getNeedFix,
    { enabled }
  );

  const qChallengesWaitingForReviewCount = useQuery<Counter, BackendError>(
    ["challengesWaitingForReviewCount", user.id, projectId, showMySolvings],
    countOperations.getWaitingForReview,
    { enabled }
  );

  const qChallengesCreationSLABreachedCount = useQuery<Counter, BackendError>(
    ["challengesCreationSLABreachedCount", user.id, projectId, showMySolvings],
    countOperations.getCreationSLABreach,
    { enabled }
  );

  const qChallengesDeliverySLABreachedCount = useQuery<Counter, BackendError>(
    ["challengesDeliverySLABreachedCount", user.id, projectId, showMySolvings],
    countOperations.getDeliverySLABreach,
    { enabled }
  );

  return {
    qAllChallengesCount,
    qFreeChallengesCount,
    qSolverChallengesCount,
    qSnoozedChallengesCount,
    qAnomalyChallengesCount,
    qChallengesWithErrorsCount,
    qChallengesWithWarningsCount,
    qChallengesNeedFixCount,
    qChallengesWaitingForReviewCount,
    qChallengesCreationSLABreachedCount,
    qChallengesDeliverySLABreachedCount,
  };
};
