import { Alert, AlertProps } from "@mui/material";
import { ErrorLike } from "../types/ErrorLike";
import extractError from "../utils/ErrorExtract";
import { useState } from "react";

interface ErrorAlertProps extends AlertProps {
  prefix?: string;
  value: ErrorLike;
  suffix?: string;
  isClosable?: boolean;
}

/**
 * default prefix="An error has occurred. "
 *  props.value is passed to `extractError`
 */
const ErrorAlert = (props: ErrorAlertProps) => {
  const {
    prefix,
    value,
    suffix,
    children,
    severity,
    isClosable,
    onClose,
    ...alertProps
  } = props;
  const [isOpen, setIsOpen] = useState(true);

  if (isClosable && !isOpen) {
    return null; // undefined is not a valid JSX element
  }

  const _onClose =
    !!onClose || isClosable
      ? (event: React.SyntheticEvent<Element, Event>) => {
          setIsOpen(false);
          onClose?.(event);
        }
      : undefined;

  return (
    <Alert severity={severity ?? "error"} {...alertProps} onClose={_onClose}>
      {prefix ?? "An error has occurred. "}
      {extractError(value)}
      {suffix}
      {children}
    </Alert>
  );
};

export default ErrorAlert;
