import { useContext, useMemo } from "react";
import { ChallengeFiltering } from "../contexts/ChallengeFiltering";
import { FrontendFilterType } from "../../ChallengeViewSteps/hooks/UseChallengeFilter";

const useSelectedFiltersCount = () => {
  const filtering = useContext(ChallengeFiltering);

  const countSelectedFilters = useMemo(() => {
    if (!filtering) return 0;

    const frontendFilterTypes: Array<string> =
      Object.values(FrontendFilterType);
    const backendFilters = filtering.filters.filter(
      (filter) => !frontendFilterTypes.includes(filter.type)
    );
    return backendFilters.length;
  }, [filtering]);

  return countSelectedFilters;
};

export default useSelectedFiltersCount;
