import * as React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Fab,
  Box,
  Divider,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import AddIcon from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import { useUserState } from "./hooks/UserStateProvider";
import { usePerms } from "./hooks/UsePerms";
import { Perm } from "./Const";
import { useLocation, Link } from "react-router-dom";
import { provideTheme } from "./utils/ProvideTheme";
import { useUserProjects } from "./hooks/UseUserProjectContext";
import ProjectCreationModal from "./components/ProjectCreationModal";
import { default as DashboardTTabID } from "./types/DashboardTabsEnum";
import CreateTaskButton from "./pages/ChallengesNewSteps/components/CreateTaskButton";

interface MenuProps {
  isExpanded: boolean;
}

const NavMenu: React.FC<MenuProps> = ({ isExpanded }) => {
  const [user] = useUserState();
  const perms = usePerms();
  const isModerator = perms.has(Perm.DELETE_CHALLENGE);
  const location = useLocation();
  const { userProjects, refetchUserProjects } = useUserProjects();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const projectItems = React.useMemo(
    () =>
      userProjects.map((userProject) => ({
        name: userProject.name,
        href: `/projects/${userProject.id}/?tab=${DashboardTTabID.Dashboard}`,
        icon: <FolderIcon />,
      })),
    [userProjects]
  );

  const menuItems = [];

  if (isModerator) {
    menuItems.push(
      {
        name: "Manage users",
        href: "/users",
        icon: <GroupIcon />,
      },
      {
        name: "My subscriptions",
        href: "/my_subscriptions",
        icon: <LoyaltyIcon />,
      }
    );
  }

  if (user.email !== "unknown") {
    menuItems.push({
      name: "Settings",
      href: "/my_account",
      icon: <SettingsIcon />,
    });
    menuItems.push({
      name: "Download dataset",
      href: "/datasets",
      icon: <DownloadIcon />,
    });
  }

  React.useEffect(() => {
    refetchUserProjects();
  }, [user, refetchUserProjects]);

  return (
    <List>
      <Box
        sx={{
          display: "flex",
          justifyContent: isExpanded ? "start" : "center",
          marginLeft: isExpanded ? 2 : 0,
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <CreateTaskButton isExpanded={isExpanded} />
      </Box>

      <Divider sx={{ marginLeft: 2, marginRight: 2, marginTop: 2 }} />
      {projectItems.length > 0
        ? projectItems.map((item) => {
            const projectPathMatch = location.pathname.startsWith(
              `/projects/${item.href.split("/")[2]}`
            );

            return (
              <ListItem
                key={item.name}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isExpanded ? "initial" : "center",
                    px: 2.5,
                    backgroundColor: projectPathMatch
                      ? provideTheme.palette.secondary.light
                      : "transparent",
                    "&:hover": {
                      backgroundColor: provideTheme.palette.secondary.light,
                    },
                  }}
                  component={Link}
                  to={item.href}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isExpanded ? 3 : 0,
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {isExpanded && (
                    <ListItemText
                      primary={item.name}
                      sx={{ opacity: isExpanded ? 1 : 0 }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            );
          })
        : isExpanded && (
            <ListItem>
              <ListItemText
                primary="You are not assigned to any project. You can contact us for help."
                sx={{
                  textAlign: "left",
                  whiteSpace: "normal",
                }}
              />
            </ListItem>
          )}
      <Box
        sx={{
          display: "flex",
          justifyContent: isExpanded ? "start" : "center",
          marginLeft: isExpanded ? 2 : 0,
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <Fab
          size="medium"
          color="primary"
          variant={isExpanded ? "extended" : "circular"}
          onClick={() => setIsModalOpen(true)}
          sx={{
            whiteSpace: "normal",
            ...(isExpanded && { width: "200px", height: "50px" }),
          }}
        >
          <AddIcon sx={{ marginRight: isExpanded ? 1 : 0 }} />{" "}
          {isExpanded
            ? userProjects.length === 0
              ? "Create your first project"
              : "Create Project"
            : null}
        </Fab>
        <ProjectCreationModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </Box>
      <Divider sx={{ marginLeft: 2, marginRight: 2, marginTop: 2 }} />
      {menuItems.map((item) => {
        const isCurrentPage = location.pathname + location.search === item.href;

        return (
          <ListItem key={item.name} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isExpanded ? "initial" : "center",
                px: 2.5,
                backgroundColor: isCurrentPage
                  ? provideTheme.palette.secondary.light
                  : "transparent",
                "&:hover": {
                  backgroundColor: provideTheme.palette.secondary.light,
                },
              }}
              component="a"
              href={item.href}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isExpanded ? 3 : 0,
                  justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>
              {isExpanded && (
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: isExpanded ? 1 : 0 }}
                />
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default NavMenu;
