import { Typography, TypographyVariant } from "@mui/material";

interface Headers {
  header?: string;
  headerVariant?: TypographyVariant;
  subHeader?: string;
  subHeaderVariant?: TypographyVariant;
  headerBold?: boolean;
  subHeaderBold?: boolean;
  headerStyle?: React.CSSProperties;
  subHeaderStyle?: React.CSSProperties;
}

const Title = ({
  header,
  headerVariant = "h3",
  subHeader,
  subHeaderVariant = "h5",
  subHeaderBold,
  headerStyle,
  subHeaderStyle,
}: Headers) => {
  const subHeaderStyleBase = subHeaderBold ? { fontWeight: "bold" } : {};

  const headerEl = !!header && (
    <Typography
      variant={headerVariant}
      textAlign="center"
      style={{ ...headerStyle }}
    >
      {header}
    </Typography>
  );

  const subHeaderEl = !!subHeader && (
    <Typography
      variant={subHeaderVariant}
      textAlign="center"
      color="textSecondary"
      style={{ ...subHeaderStyleBase, ...subHeaderStyle }}
    >
      {subHeader}
    </Typography>
  );

  return (
    <>
      {headerEl}
      {subHeaderEl}
    </>
  );
};

export default Title;
