import { useNavigate } from "react-router-dom";
import { useUserState } from "../../../hooks/UserStateProvider";
import  {DatasetBannerProps } from "./DatasetBanner";
import SplitButtonDropdown, {
  SplitButtonDropdownChoice,
} from "../../../components/SplitButtonDropdown";
import { Typography } from "@mui/material";
import { usePerms } from "../../../hooks/UsePerms";
import { Perm } from "../../../Const";

import {
  getChallengeXLSBlob,
} from "../../codeRunsV2/LocalOperations"

interface DatasetDownloaderProps extends DatasetBannerProps {}

const downloadDatasetFile = async (
  challengeId: string,
) => {
  try {
    const response = await getChallengeXLSBlob(challengeId)
    const blob = new Blob([response.data], { 
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
    });
    const blobURL = window.URL.createObjectURL(blob);
    const newWindow = window.open();
    if (newWindow) {
      newWindow.location.href = blobURL;
    } else {
      // Handle case where the new window could not be opened
    }

  } catch (error) {
    // Handle the error appropriately
    console.error("Error downloading file:", error);
  }
};

const DatasetDownloader = ({ challenge }: DatasetDownloaderProps) => {
  const [user] = useUserState();
  const navigate = useNavigate();
  const perms = usePerms();

  const primaryChoice: SplitButtonDropdownChoice = {
    node: "Download Latest XLS",
    onClick: () => {
      downloadDatasetFile(challenge.id);
    },
  };

  const dropdownItems: SplitButtonDropdownChoice[] = [
    perms.has(Perm.DOWNLOAD_ANY_EXPORT) && {
      node: "TBD",
        onClick: () => {
      },
    },
  ];

  return (
    <div style={{ margin: "auto 0px", paddingTop: "5px" }}>
      <SplitButtonDropdown
        primaryButton={primaryChoice}
        dropdownItems={dropdownItems}
        variant="contained"
        color="success"
      />
    </div>
  );
};

export default DatasetDownloader;
