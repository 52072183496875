import {
  Box,
  Button,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import { objectToPrettyJSON } from "./JSONData";
import { useMemo } from "react";
import { Constraints } from "../types/DataLabelsDetailed";
import { useModal } from "react-hooks-use-modal";
import { ConstraintsDialog } from "../pages/solutionSubmission/components/ConstraintsDialog";

interface TooltipedLabelProps {
  label: string;
  constraints?: Constraints;
  Icon?: typeof FlagCircleIcon;
  onConstraintsChange?: (newValue: Constraints) => void;
  disabledEdit?: boolean;
}

const constraintsToText = (constraints?: Constraints) => {
  const jsoned = objectToPrettyJSON(constraints);
  return jsoned === "{}" || !constraints ? null : jsoned;
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

export const TooltipedLabel = ({
  label,
  constraints,
  Icon = FlagCircleIcon,
  onConstraintsChange = () => {},
  disabledEdit,
}: TooltipedLabelProps) => {
  const constraintsText = useMemo(
    () => constraintsToText(constraints),
    [constraints]
  );

  const tooltip = useMemo(() => {
    return constraintsText ? (
      <pre>
        This item has the following {"\n"}JSON-Schema constraints:
        {`\n${constraintsText}`}
      </pre>
    ) : (
      ""
    );
  }, [constraintsText]);
  const [, openDialog, closeDialog, isOpenDialog] = useModal();

  const iconNode = !!tooltip ? (
    <Icon color={disabledEdit ? "disabled" : "secondary"} />
  ) : null;

  const labelNode = (
    <Typography variant="body1">
      {label}
      {iconNode}
    </Typography>
  );

  const wrappedLabel = disabledEdit ? (
    <Box>{labelNode}</Box>
  ) : (
    <Button
      variant="text"
      sx={{
        textTransform: "none",
        padding: "0",
      }}
      onClick={openDialog}
    >
      {labelNode}
    </Button>
  );

  const dialogNode =
    disabledEdit || !isOpenDialog ? null : (
      <ConstraintsDialog
        isOpen={isOpenDialog}
        onClose={closeDialog}
        constraintsJSON={constraintsText || "{}"}
        onConstraintsChange={onConstraintsChange}
        labelName={label}
      />
    );

  if (!tooltip) {
    return (
      <>
        {wrappedLabel}
        {dialogNode}
      </>
    );
  }

  return (
    <>
      <NoMaxWidthTooltip title={tooltip} placement="left">
        {wrappedLabel}
      </NoMaxWidthTooltip>
      {dialogNode}
    </>
  );
};
