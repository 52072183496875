import { CodeRunRequest } from "../types/CodeRunRequest";

const createDefaultCodeRunRequest = (): CodeRunRequest => {
  return {
    user_id: "not a number",
    runtime_id: "inexistant runtime",
    code: "",
  };
};

export default createDefaultCodeRunRequest;
