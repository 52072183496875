import { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Snackbar,
  Modal,
  Alert,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "react-query";
import { BackendError } from "../../../types/BackendError";
import { Project } from "../../../types/Projects";
import { ProjectUsers } from "../../../types/ProjectUsers";
import ProjectRoleEnum from "../../../types/ProjectRoleEnum";
import { useQueryUsersBasic } from "../../../hooks/UseQueryUsersBasic";
import ProjectNew from "../../../types/ProjectNew";
import { postProjectDuplicate } from "../LocalOperations";
import { useInvalidateUserProjects } from "../../../hooks/useQueryProjects";
import ErrorAlert from "../../../components/ErrorAlert";

interface DuplicateProjectModalProps {
  open: boolean;
  onClose: () => void;
  duplicateFromProject: Project;
  projectUsers: ProjectUsers;
  onProjectDuplicateSuccess: () => void;
}

const DuplicateProjectModal: React.FC<DuplicateProjectModalProps> = ({
  open,
  onClose,
  duplicateFromProject,
  projectUsers,
  onProjectDuplicateSuccess,
}) => {
  const [projectName, setProjectName] = useState(duplicateFromProject.name);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>(
    projectUsers.map((user) => user.user_id)
  );
  const [projectDescription, setProjectDescription] = useState(
    duplicateFromProject.description
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const query = useQueryUsersBasic();
  const invalidateUserProjects = useInvalidateUserProjects();
  const [userRoles, setUserRoles] = useState<Record<string, ProjectRoleEnum>>(
    {}
  );

  const handleRoleChange = (userId: string, role: ProjectRoleEnum) => {
    setUserRoles((prevRoles) => ({
      ...prevRoles,
      [userId]: role,
    }));
  };

  const handleRemoveUser = (userId: number) => {
    setSelectedUserIds((prevIds) => prevIds.filter((id) => id !== userId));
  };

  const postProject = useMutation<void, BackendError, ProjectNew>(
    postProjectDuplicate
  );

  const onCreateProjectDuplicate = () => {
    if (projectName.trim().length > 0 && projectDescription.trim().length > 0) {
      postProject.mutate(
        {
          id: duplicateFromProject.id,
          name: projectName,
          description: projectDescription,
          project_users: projectUsers
            .filter((user) => selectedUserIds.includes(user.user_id))
            .map((user) => ({
              user_id: user.user_id,
              role: userRoles[user.user_id] || user.project_role,
            })),
        },
        {
          onSuccess: () => {
            if (onProjectDuplicateSuccess) {
              onProjectDuplicateSuccess();
            }
            onClose();
            invalidateUserProjects();
          },
        }
      );
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box display="flex" justifyContent="center" mb={2}>
            <Typography variant="h5">Duplicate Project</Typography>
          </Box>
          <Box display="flex" gap={2} mb={2}>
            <TextField
              id="project-name-input"
              label="Duplicated project name"
              variant="outlined"
              fullWidth
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
            <TextField
              id="project-description-input"
              label="Duplicated project description"
              variant="outlined"
              fullWidth
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
            />
          </Box>
          <Box display="flex" justifyContent="center" mb={2}>
            <Typography variant="h5">
              Also assign these users to duplicate project
            </Typography>
          </Box>
          {selectedUserIds.length > 0 && query.isSuccess && (
            <Box mt={2} sx={{ maxHeight: "55vh", overflowY: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedUserIds.map((userId) => {
                    const user = query.data.find(
                      (user) => Number(user.id) === userId
                    );

                    const projectUser = projectUsers.find(
                      (pu) => pu.user_id === Number(userId)
                    );

                    const projectRole =
                      userRoles[userId] ||
                      projectUser?.project_role ||
                      ProjectRoleEnum.Contributor;

                    const isOwner = projectRole === ProjectRoleEnum.Owner;

                    return (
                      <TableRow
                        key={userId}
                        sx={{
                          backgroundColor: isOwner ? "#f5f5f5" : "transparent",
                          pointerEvents: isOwner ? "none" : "auto",
                        }}
                      >
                        <TableCell>{user?.email || "Unknown"}</TableCell>
                        <TableCell sx={{ paddingRight: 12 }}>
                          <Select
                            value={projectRole}
                            onChange={(e) =>
                              handleRoleChange(
                                String(userId),
                                e.target.value as ProjectRoleEnum
                              )
                            }
                            displayEmpty
                            fullWidth
                            disabled={isOwner}
                          >
                            {Object.values(ProjectRoleEnum).map((role) => (
                              <MenuItem
                                key={role}
                                value={role}
                                disabled={role === ProjectRoleEnum.Owner}
                              >
                                {role}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleRemoveUser(userId)} disabled={isOwner}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={onCreateProjectDuplicate}
              disabled={postProject.isLoading || projectName.trim().length === 0 || projectDescription.trim().length === 0} 
            >
              Duplicate project
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={onClose}
            >
              Close
            </Button>
          </Box>

          {postProject.isError && (
            <Box mt={2}>
              <ErrorAlert value={postProject.error} sx={{ marginTop: 1 }} />
            </Box>
          )}
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DuplicateProjectModal;
