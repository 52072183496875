import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import ErrorAlert from "../../../components/ErrorAlert";
import { BackendError } from "../../../types/BackendError";
import { ValidatorCustomCode } from "../../../types/ValidatorsCustomCode";
import {
  deleteValidatorInstance,
  patchCustomCodeValidator,
  postCustomCodeValidator,
} from "../LocalOperations";
import EditableField from "./EditableField";
import SelectRuntime from "./SelectRuntime";
import { Runtime } from "../../../types/Runtimes";
import createDefaultCustomCodeValidator from "../../../utils/CreateDefaultValidator";
import ButtonsCancelCreateDelete from "./ButtonsCancelCreateDelete";
import SelectValidatorCode from "./SelectValidatorCode";
import { useQValidatorCodeRefetch } from "../hooks/UseQValidatorCodeRefetch";

interface FormatValidatorsCodeProps {
  templateId: string;
}

const FormatValidatorsCode = ({ templateId }: FormatValidatorsCodeProps) => {
  // editable, addible, removable
  const [selectedValidatorId, setSelectedValidatorId] = useState("");
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [customName, setCustomName] = useState("");
  const [runtimeId, setRuntimeId] = useState("");
  const [language, setLanguage] = useState("");
  const [code, setCode] = useState("");
  const refetchValidators = useQValidatorCodeRefetch({
    templateId: templateId,
  });
  const [actionText, setActionText] = useState(
    "*Choose an action / validator*"
  );

  const qNewCode = useMutation<void, BackendError, void>(
    () => postCustomCodeValidator(templateId, runtimeId, code, customName),
    {
      onSuccess: () => {
        setIsAddingNew(false);
        setActionText("New validator created.");
        onValidatorChange(createDefaultCustomCodeValidator());
        refetchValidators(); // select-list will have a new item
      },
    }
  );
  const qEditCode = useMutation<void, BackendError, void>(
    () =>
      patchCustomCodeValidator(
        selectedValidatorId,
        runtimeId,
        code,
        customName
      ),
    {
      onSuccess: () => {
        refetchValidators(); // maybe the name has changed
      },
    }
  );
  const qDeleteCode = useMutation<void, BackendError, void>(
    () => deleteValidatorInstance(selectedValidatorId),
    {
      onSuccess: () => {
        setActionText(`Deleted validator (id: ${selectedValidatorId}).`);
        onValidatorChange(createDefaultCustomCodeValidator());
        refetchValidators(); // select-list will have one less item
      },
    }
  );

  const onValidatorChange = (validator: ValidatorCustomCode) => {
    setSelectedValidatorId(validator.id);
    setCode(validator.code);
    setCustomName(validator.validator_custom_name ?? "");
    setLanguage(validator.runtime);
    setRuntimeId(validator.runtime_id);
    setIsAddingNew(false);
  };

  const isEditing = selectedValidatorId !== "";
  const isShowFields = isAddingNew || isEditing;
  const qSave = isAddingNew ? qNewCode : qEditCode;

  return (
    <>
      <Stack direction="row" justifyContent="center">
        <SelectValidatorCode
          templateId={templateId}
          idValue={selectedValidatorId}
          onChange={onValidatorChange}
        />
        <ButtonsCancelCreateDelete
          onCreate={() => setIsAddingNew(true)}
          onCancel={() => {
            setIsAddingNew(false);
            setActionText("Creation or changes discarded without saving.");
            onValidatorChange(createDefaultCustomCodeValidator());
          }}
          onDelete={() => qDeleteCode.mutate()}
          isCreating={isAddingNew}
          isEditing={isEditing}
          deletionDialogTitle="Delete current Custom Code Validator?"
          deletionDialogText="Deletion is irreversible. Existing validations will show *validator deleted*. Validator might be in use by several challenges. Delete?"
        />
      </Stack>
      {qDeleteCode.isError && (
        <ErrorAlert
          prefix="Failed deleting validator. "
          value={qDeleteCode.error}
          onClose={() => {}}
        />
      )}
      {isShowFields ? (
        <>
          <Stack direction="row" justifyContent="space-around">
            <SelectRuntime
              idValue={runtimeId}
              autoSelectFirst
              onChange={(newRuntime: Runtime) => {
                setRuntimeId(newRuntime.id);
                setLanguage(newRuntime.language);
              }}
              error={runtimeId === ""}
            />
            <EditableField
              label="Custom name"
              fieldValue={customName}
              onFieldChange={setCustomName}
              isLoading={qSave.isLoading}
            />
          </Stack>

          <EditableField
            label="Custom code"
            fieldValue={code}
            onFieldChange={setCode}
            isLoading={qSave.isLoading}
            onSave={qSave.mutate}
            language={language}
            error={qSave.error}
            multiline
          />
        </>
      ) : (
        <Typography
          style={{
            textAlign: "center",
            padding: "190px 0px",
          }}
        >
          {actionText}
        </Typography>
      )}
    </>
  );
};

export default FormatValidatorsCode;
