import { Grid, TextField, Typography, Container, Paper } from "@mui/material";
import NextButton from "../../components/NextButton";

interface DescribeIn {
  onDescribeSuccess: (url: string, description: string) => void;
  url: string;
  description: string;
  setUrl: (url: string) => void;
  setDescription: (description: string) => void;
}

const DescribeDataSource = ({onDescribeSuccess, url, description, setUrl, setDescription,}: DescribeIn) => {
  // const [name, setName] = useState("");

  const onTargetUrlChange = (newTargetUrl: string) => {
    setUrl(newTargetUrl);
  };

  const onDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
  }

  return (
    <Container className="cardGrid">
      <Grid container marginBottom={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5">Source details</Typography>
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ border: "none", padding: 3, marginBottom: 3 }}>
        {/* Target field is not needed for now but might be used later */}
        {/* <Grid container marginBottom={2}>
          <Grid item xs={5} sm={5} md={5}>
            <TextField
              id="Target-input"
              label="Target"
              variant="outlined"
              fullWidth
              helperText="Required"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        </Grid> */}
        <Grid container rowSpacing={3}>
          <Grid item xs={5} sm={5} md={5}>
            <TextField
              label="Target URL"
              variant="outlined"
              id="Target_URL-input"
              fullWidth
              value={url}
              onChange={(e) => onTargetUrlChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              id="details-textarea"
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={description}
              onChange={(e) => onDescriptionChange(e.target.value)}
            />
          </Grid>
        </Grid>
      </Paper>
      <NextButton onClick={() => onDescribeSuccess(url, description)} />
    </Container>
  );
};

export default DescribeDataSource;
