import MultiColorBar from "../../../components/MultiColorBar";

export enum valueCompletenessBarClasses {
  root = "value-completeness-bar-root",
  defined = "value-completeness-bar-defined",
  null = "value-completeness-bar-null",
  undefined = "value-completeness-bar-undefined",
}

interface ValueCompletenessBarProps {
  valueCount: number;
  nullCount: number;
  undefinedCount: number;
}

export const ValueCompletenessBar = ({
  valueCount,
  nullCount,
  undefinedCount,
}: ValueCompletenessBarProps) => {
  return (
    <MultiColorBar
      className={valueCompletenessBarClasses.root}
      items={[
        {
          name: "val",
          value: valueCount,
          className: valueCompletenessBarClasses.defined,
        },
        {
          name: "undef",
          value: undefinedCount,
          className: valueCompletenessBarClasses.undefined,
        },
        {
          name: "null",
          value: nullCount,
          className: valueCompletenessBarClasses.null,
        },
      ]}
    />
  );
};
