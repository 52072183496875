import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

interface ValidationTableProps {
  children?: React.ReactNode;
}

const ValidationTable = (props: ValidationTableProps) => {
  return (
    <TableContainer
      component={Paper}
      style={{ marginBottom: "40px" }}
      id="validations-table"
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Status</TableCell>
            <TableCell align="right">Validate at datetime</TableCell>
            <TableCell align="right">Validation length (s)</TableCell>
            <TableCell align="right">Validator</TableCell>
            <TableCell align="right">Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{props.children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ValidationTable;
