import { Typography } from "@mui/material";
import { ChallengeBrief } from "../../../types/ChallengesBrief";
import { useInView } from "react-intersection-observer";
import { SLA } from "../../../types/SLA";
import { getStatusClassname } from "../../../components/ColoredStatus";
import { useChallengeSLA } from "../../../hooks/UseChallengeSLA";

interface BannerChallengeSolverProps {
  challenge: ChallengeBrief;
}

const BannerChallengeSolver = ({ challenge }: BannerChallengeSolverProps) => {
  const qChallengeSLA = useChallengeSLA(challenge.id, {
    enabled: false,
    staleTime: 5 * 60 * 1000, // 5 min
  });
  const { ref } = useInView({
    triggerOnce: true,
    onChange: (isVisible: boolean) =>
      isVisible && qChallengeSLA.isIdle ? qChallengeSLA.refetch() : undefined,
  });

  const sla: any =
    qChallengeSLA.isSuccess && !!qChallengeSLA.data
      ? qChallengeSLA.data
      : qChallengeSLA.isIdle
      ? { solver: "unknown" }
      : qChallengeSLA.isLoading
      ? {solver: "loading..." }
      : {solver: "error" };

  return (
    <div style={{ textAlign: "left" }} ref={ref}>
        <Typography>{sla.solver}</Typography>
    </div>
  );
};

export default BannerChallengeSolver;
