import Title from "./Title";
import BasicHeaders from "../types/BasicHeaders";

const CardTitle = (props: BasicHeaders) => {
  return (
    <div className="cardTitle" style={props.style}>
      <Title
        header={props.header}
        subHeader={props.subHeader}
        headerVariant="body1"
        headerBold
        subHeaderVariant="body1"
      />
    </div>
  );
};

export default CardTitle;
