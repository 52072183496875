import { ValidatorCustomCode } from "../types/ValidatorsCustomCode";

const createDefaultCustomCodeValidator = (): ValidatorCustomCode => {
  return {
    code: "",
    id: "",
    runtime: "",
    runtime_version: "",
    runtime_id: "",
    validator_name: "",
    validator_custom_name: "",
  };
};

export default createDefaultCustomCodeValidator;
