import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useContext } from "react";
import {
  ChallengeColumn,
  ChallengesListDisplay,
} from "../contexts/ChallengesListDisplay";
import BannerTable from "../../../components/BannerTable";

interface ChallengeHeader {
  column: ChallengeColumn;
  title: String;
}
const _makeHeader = (
  column: ChallengeColumn,
  title: String
): ChallengeHeader => {
  return {
    column,
    title,
  };
};

const _headers: ChallengeHeader[] = [
  _makeHeader(ChallengeColumn.NAME, "Challenge"),
  _makeHeader(ChallengeColumn.FREQUENCY, "Frequency"),
  _makeHeader(ChallengeColumn.SOLVER, "Solver"),
  _makeHeader(ChallengeColumn.STATUS, "Status"),
  _makeHeader(ChallengeColumn.ACTION, ""),
  _makeHeader(ChallengeColumn.DESCRIPTION, "Description"),
  _makeHeader(ChallengeColumn.RESERVATION, "Reservation status"),
];
// make 0th ChallengeColumn enum map into 0th header
// header.column is enum
_headers.sort((a, b) => a.column - b.column);

interface ChallengeTableProps {
  children?: React.ReactNode;
}

const ChallengeTable = ({ children }: ChallengeTableProps) => {
  const options = useContext(ChallengesListDisplay);
  if (options === undefined) {
    return null;
  }

  return (
    <BannerTable>
      <TableHead>
        <TableRow>
          {options.columns.map((columnType) => (
            <TableCell key={columnType}>{_headers[columnType].title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </BannerTable>
  );
};

export default ChallengeTable;
