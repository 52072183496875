import Login from "./LoginSteps/components/Login";

const LoginView = () => {
  return (
    <>
      <Login />
      {/*
          to be conditional rendering for register, login, change pass, etc.
        */}
    </>
  );
};

export default LoginView;
