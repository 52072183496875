import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { useMemo } from "react";
import JSONData from "../../../components/JSONData";

interface JSONDataDialogProps extends DialogProps {
  isJSONDisplayedInitially?: boolean;
  JSONString?: string | null;
  title?: string;
  text?: string;
  onClose?: () => void;
  contentStyle?: React.CSSProperties;
}

const JSONDataDialog = (props: JSONDataDialogProps) => {
  const {
    isJSONDisplayedInitially = false,
    JSONString,
    text,
    title = "JSON Data",
    onClose,
    maxWidth = "lg",
    contentStyle,
    ...dialogProps
  } = props;
  const JSONObject = useMemo(() => {
    try {
      return JSON.parse(JSONString ?? "");
    } catch {
      return {};
    }
  }, [JSONString]);

  return (
    <Dialog maxWidth={maxWidth} onClose={onClose} {...dialogProps}>
      {!!title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent style={{ minWidth: "66vw", ...contentStyle }}>
        {!!text && <DialogContentText>{text}</DialogContentText>}
        <JSONData
          JSONObject={JSONObject}
          isInitiallyOpen={isJSONDisplayedInitially}
          textFieldStyle={{ height: "60vh" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JSONDataDialog;
