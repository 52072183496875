import { useMemo, useState } from "react";
import TitledCodeEditorCard from "./TitledCodeEditorCard";

interface SnapshotDataProps {
  JSONObject?: any;
  textWhenShown?: string;
  textWhenHidden?: string;
  isInitiallyOpen?: boolean;
  textFieldStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

export const objectToPrettyJSON = (JSONObject: any) => {
  try {
    return JSON.stringify(JSONObject, null, 2);
  } catch (ex) {
    return "{}";
  }
};

const JSONData = ({
  JSONObject,
  textWhenShown,
  textWhenHidden,
  isInitiallyOpen = false,
  textFieldStyle,
  wrapperStyle,
}: SnapshotDataProps) => {
  const [isShowJSON, setIsShowJSON] = useState(isInitiallyOpen);

  const JSONPrettyString = useMemo<string>(
    () => objectToPrettyJSON(JSONObject),
    [JSONObject]
  );

  return (
    <>
      {isShowJSON && (
        <TitledCodeEditorCard
          language="json"
          value={JSONPrettyString}
          wordWrap
          style={textFieldStyle}
          wrapperStyle={wrapperStyle}
        />
      )}
    </>
  );
};

export default JSONData;
