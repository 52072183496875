import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TitledCodeEditorCard from "../../../components/TitledCodeEditorCard";
import { useState } from "react";
import ErrorAlert from "../../../components/ErrorAlert";
import { Constraints } from "../../../types/DataLabelsDetailed";
import { objectToPrettyJSON } from "../../../components/JSONData";

interface ConstraintsDialogProps {
  isOpen?: boolean;
  onClose: () => void;
  constraintsJSON: string;
  labelName: string;
  onConstraintsChange: (newValue: Constraints) => void;
}

/** expects JSON as string and tries to parse a pure object out of it. Returns [parsedObj, error] */
const makeConstraints = (
  constraintsJSON: string
): [Constraints | null, string | null] => {
  const noConstraints = {};
  // no value
  if (constraintsJSON.trim() === "") {
    return [noConstraints, null];
  }
  try {
    const result = JSON.parse(constraintsJSON);
    if (
      typeof result === "object" &&
      !Array.isArray(result) &&
      result !== null
    ) {
      return [result, null];
    }

    // aliases for empty constraints
    // []
    if (Array.isArray(result) && result.length === 0) {
      return [noConstraints, null];
      // null undefined
    } else if (result === null || result === undefined) {
      return [noConstraints, null];
    }
    // yes value but it is just an empty string ""
    else if (typeof result === "string" && result.trim() === "") {
      return [noConstraints, null];
    }
    // 0 false
    else if (result === 0 || result === false) {
      return [noConstraints, null];
    }

    // most likely will be triggered by not empty arrays, numbers, true, not empty strings, etc.
    const prefix = "Expected constraints to be an object {} type, got ";
    const error =
      typeof result !== "object"
        ? `${prefix}<${typeof result}>`
        : Array.isArray(result)
        ? `${prefix}<array> as object`
        : result === null
        ? `${prefix}<null> as object. Save constraints as ${objectToPrettyJSON(
            noConstraints
          )} to remove any constraints.`
        : "Unknown error. ";
    return [null, error];
  } catch (e) {
    return [null, "Supplied constraints are not parseable as JSON"];
  }
};

export const ConstraintsDialog = ({
  isOpen = false,
  onClose,
  constraintsJSON,
  labelName,
  onConstraintsChange,
}: ConstraintsDialogProps) => {
  const [value, setValue] = useState(constraintsJSON);
  const isValueSame =
    constraintsJSON.replaceAll("\r", "") === value.replaceAll("\r", "");
  // after loading monaco editor, the editor on second render changes value from "1\n2" to "1\r\n2" to localize linefeeds to current user OS (I am on Windows), thus the replaceAll
  const [error, setError] = useState<string | null>(null);

  const onSave = () => {
    const [parsed, error] = makeConstraints(value);
    if (error) {
      setError(error);
      return;
    }
    onConstraintsChange(parsed);
    setValue(objectToPrettyJSON(parsed)); // otherwise: after reopening dialog,
    // the value is not pretty formatted, while the dump of constraints is,
    // therefore the RESET button and SAVE buttons would appear enabled
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Editing constraints for field {`<${labelName}>`} (JSON-Schema)
      </DialogTitle>
      <DialogContent>
        <TitledCodeEditorCard
          language="json"
          wordWrap
          value={value}
          onChange={(val) => {
            setValue(val ?? "");
            setError(null);
          }}
          wrapperStyle={{ height: "60vh", maxHeight: "60rem" }}
        />
        {!!error && <ErrorAlert value={error} isClosable />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onSave} variant="outlined" disabled={isValueSame}>
          Save
        </Button>
        <Button
          onClick={() => setValue(constraintsJSON)}
          variant="outlined"
          disabled={isValueSame}
        >
          Reset
        </Button>
        <Button onClick={onClose} variant="contained">
          Close {isValueSame ? "" : " without saving"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
