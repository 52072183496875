import {
  Checkbox,
  MenuItem,
  Select,
  Typography,
  TableRow,
  TableCell,
  FormHelperText,
  FormHelperTextProps,
} from "@mui/material";
import DataType, {
  DataTypeAllVals,
  DataTypeBackend,
  DataTypeToBackendArg,
} from "../types/EnumDataType";
import React from "react";
import { TooltipedLabel } from "./TooltipedLabel";
import { Constraints } from "../types/DataLabelsDetailed";
import { DataTypeConfigBottomPadding } from "./DataTypeConfigBottomPadding";

interface DataTypeConfigExpandedProps {
  dataType?: DataTypeBackend;
  advisedDataType?: DataTypeBackend;
  allowAdvancedType?: boolean;
  allowEditConstraints?: boolean;
  label: string;
  optional?: boolean;
  nullable?: boolean;
  advisedNull?: boolean;
  advisedNotNull?: boolean;
  advisedOptional?: boolean;
  advisedNotOptional?: boolean;
  onOptionalChange?: (newValue: boolean) => void;
  onNullableChange?: (newValue: boolean) => void;
  onDataTypeChange?: (newValue: DataTypeBackend) => void;
  onConstraintsChange?: (newValue: Constraints) => void;
  disabled?: boolean;
  styleRow?: React.CSSProperties;
  wrapInRow?: boolean;
  cellNullClassName?: string;
  cellOptionalClassName?: string;
  cellDataTypeClassName?: string;
  cellLabelClassName?: string;
  constraints?: Constraints;
  isBottomPadded?: boolean;
  advisedDelete?: boolean;
}

const dataTypesMenuItems = Object.values(DataType).map((val) => (
  <MenuItem value={DataTypeToBackendArg[val]} key={val}>
    {val}
  </MenuItem>
));

const dataTypesAllMenuItems = Object.values(DataTypeAllVals).map((val) => (
  <MenuItem value={DataTypeToBackendArg[val]} key={val}>
    {val}
  </MenuItem>
));

type Advisable = {
  helperProps: FormHelperTextProps;
  text: string;
};

const adviseOnType = (
  fallback: Advisable,
  isDisabled?: boolean,
  typeSchema?: DataTypeBackend,
  typeSnapshot?: DataTypeBackend
): Advisable => {
  const _advicedDataType = typeSnapshot
    ? DataTypeAllVals[typeSnapshot] || typeSnapshot
    : typeSchema;
  if (isDisabled) {
    return fallback;
  } else if (!typeSchema || !typeSnapshot) {
    return fallback;
  } else if (typeSchema === typeSnapshot) {
    return fallback;
  } else if (
    typeSchema === DataTypeToBackendArg.FLOAT &&
    typeSnapshot === DataTypeToBackendArg.INT
  ) {
    return { helperProps: {}, text: `can be ${_advicedDataType}` };
  } else {
    return {
      helperProps: { error: true },
      text: `advised: ${_advicedDataType}`,
    };
  }
};

const adviceOnOptional = (
  fallback: Advisable,
  isDisabled?: boolean,
  isOptional?: boolean,
  isAdvisedOptional?: boolean,
  isAdvisedNotOptional?: boolean,
  isAdvisedDelete?: boolean
): Advisable => {
  if (isDisabled) {
    return fallback;
  } else if (isAdvisedDelete && !isOptional && isAdvisedOptional) {
    return { helperProps: { error: true }, text: "or make it optional" };
  } else if (isAdvisedOptional && !isOptional) {
    return { helperProps: { error: true }, text: "advised: optional" };
  } else if (isAdvisedNotOptional && isOptional) {
    return { helperProps: {}, text: "can be not optional" };
  } else {
    return fallback;
  }
};

const adviseOnNullable = (
  fallback: Advisable,
  isDisabled?: boolean,
  isNullable?: boolean,
  isAdvisedNullable?: boolean,
  isAdvisedNotNullable?: boolean
): Advisable => {
  if (isDisabled) {
    return fallback;
  } else if (isAdvisedNotNullable && isNullable) {
    return { helperProps: {}, text: "can be not nullable" };
  } else if (isAdvisedNullable && !isNullable) {
    return { helperProps: { error: true }, text: "advised: nullable" };
  } else {
    return fallback;
  }
};

/** style object that is enough width to fit max-length datatype from all types (advanced included)  */
const styleWidthSelect = {
  width: `${
    Object.values(DataTypeAllVals).reduce(
      (acc, displayName) => Math.max(acc, displayName.length),
      0
    ) + 7 // at <= 5 Select transforms "object" to "obje..."
  }ch`, // 1ch = 1 character width
};

const creatableDataTypes = new Set<string>(Object.keys(DataType));
const creatableDataTypesAll = new Set<string>(Object.keys(DataTypeAllVals));

const DataTypeConfigExpanded = ({
  wrapInRow = true,
  cellNullClassName,
  cellOptionalClassName,
  cellDataTypeClassName,
  cellLabelClassName,
  constraints,
  isBottomPadded,
  ...props
}: DataTypeConfigExpandedProps) => {
  const _menuItems = props.allowAdvancedType
    ? dataTypesAllMenuItems
    : dataTypesMenuItems;
  const _creatableDataTypes = props.allowAdvancedType
    ? creatableDataTypesAll
    : creatableDataTypes;
  const Wrapper = wrapInRow
    ? ({ children }: { children?: React.ReactNode }) => (
        <TableRow style={props.styleRow}>{children}</TableRow>
      )
    : React.Fragment;

  const _dataTypeTexted = props.dataType ?? "Not defined";
  const paddingNode = isBottomPadded ? <DataTypeConfigBottomPadding /> : null;
  const noAdvice = isBottomPadded ? " " : "";
  const emptyAdvisable: Advisable = { helperProps: {}, text: noAdvice };
  const typeAdvice = adviseOnType(
    emptyAdvisable,
    props.disabled,
    props.dataType,
    props.advisedDataType
  );
  const optionalAdvice = adviceOnOptional(
    emptyAdvisable,
    props.disabled,
    props.optional,
    props.advisedOptional,
    props.advisedNotOptional,
    props.advisedDelete
  );
  const nullableAdvice = adviseOnNullable(
    emptyAdvisable,
    props.disabled,
    props.nullable,
    props.advisedNull,
    props.advisedNotNull
  );

  return (
    <Wrapper>
      <TableCell className={cellLabelClassName}>
        <TooltipedLabel
          label={props.label}
          constraints={constraints}
          onConstraintsChange={props.onConstraintsChange}
          disabledEdit={props.disabled || !props.allowEditConstraints}
        />
        {paddingNode}
      </TableCell>
      <TableCell className={cellDataTypeClassName}>
        {props.disabled ? (
          <Typography style={{ color: "silver" }}>{_dataTypeTexted}</Typography>
        ) : _creatableDataTypes.has(props.dataType ?? "") || !props.dataType ? (
          <Select
            labelId={`select-label-${props.label}`}
            id={`datatype-select-${props.label}`}
            value={props.dataType ?? ""}
            onChange={(e) =>
              props.onDataTypeChange?.(e.target.value as DataTypeBackend)
            }
            disabled={props.disabled}
            size="small" // height
            style={styleWidthSelect}
          >
            {_menuItems}
          </Select>
        ) : (
          <Typography
            style={{
              border: "1.5px solid silver",
              borderRadius: "4px",
              padding: "10px 8px",
              textTransform: "uppercase",
              color: "silver",
            }}
          >
            {_dataTypeTexted}
          </Typography>
        )}
        <FormHelperText {...typeAdvice.helperProps}>
          {typeAdvice.text}
        </FormHelperText>
        {!props.disabled && !props.dataType && (
          <FormHelperText>will fallback to {DataType.str}</FormHelperText>
        )}
      </TableCell>
      <TableCell className={cellOptionalClassName}>
        <Checkbox
          checked={props.optional}
          onChange={(e) => props.onOptionalChange?.(e.target.checked)}
          name="optional"
          disabled={props.disabled}
        />
        <FormHelperText {...optionalAdvice.helperProps}>
          {optionalAdvice.text}
        </FormHelperText>
      </TableCell>
      <TableCell className={cellNullClassName}>
        <Checkbox
          checked={props.nullable}
          onChange={(e) => props.onNullableChange?.(e.target.checked)}
          name="nullable"
          disabled={props.disabled}
        />
        <FormHelperText {...nullableAdvice.helperProps}>
          {nullableAdvice.text}
        </FormHelperText>
      </TableCell>
    </Wrapper>
  );
};

export default DataTypeConfigExpanded;
