import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { SLA } from "../types/SLA";
import { BackendError } from "../types/BackendError";
import { getChallengeSLA } from "../pages/ChallengeViewSteps/LocalOperations";

export const useChallengeSLA = (
  challengeId: number | string,
  options?: Omit<
    UseQueryOptions<SLA, BackendError, SLA, QueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  const _challengeId = String(challengeId);
  return useQuery<SLA, BackendError>(
    ["challengeSLA", _challengeId],
    () => getChallengeSLA(_challengeId),
    options
  );
};
