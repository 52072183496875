import { Grid, Paper } from "@mui/material";
import BasicHeaders from "../types/BasicHeaders";
import CardTitle from "./CardTitle";

interface GridCardIn extends BasicHeaders {
  children?: any;
  onClick?: () => void;
  selected?: boolean;
}

const GridCard = (props: GridCardIn) => {
  return (
    <Grid item xs={12} sm={5} md={4} onClick={props.onClick}>
      <Paper elevation={0} sx={{ border: "none", paddingTop: 2, paddingBottom: 1 }}>
        <CardTitle header={props.header} subHeader={props.subHeader} />
        {props.children}
      </Paper>
    </Grid>
  );
};

export default GridCard;
