import { useQuery } from "react-query";
import { Solvings } from "../../../types/Solvings";
import { BackendError } from "../../../types/BackendError";
import { getChallengeSolvings } from "../LocalOperations";
import { CircularProgress, Typography } from "@mui/material";
import ErrorAlert from "../../../components/ErrorAlert";
import { localizeDatetimeUTC, tryDatetimeUTC } from "../../../utils/Localize";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface ChallengeSolvingsProps {
  challengeId: string;
}

interface ChallengeSolvingTableRowProps {
  solving: any;
  challengeId: string;
}

const ChallengeSolvingTableRow = ({
  solving,
  challengeId,
}: ChallengeSolvingTableRowProps) => {
  const dateNow = new Date();
  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="body1">{solving.user_email}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            {tryDatetimeUTC(solving.reserved_through) > dateNow ? (
              "Reserved till"
            ) : (
              <i>Expired at</i>
            )}{" "}
            {localizeDatetimeUTC(solving.reserved_through)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            {solving.scheduled_code_run_number ? "Yes" : undefined}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            <Link
              to={`/challenges/${challengeId}/code/all/user/${solving.user_id}`}
            >
              View
            </Link>
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

const ChallengeSolvings = ({ challengeId }: ChallengeSolvingsProps) => {
  const qSolvings = useQuery<Solvings, BackendError>(
    ["challengeSolvings", challengeId],
    () => getChallengeSolvings(challengeId)
  );
  if (qSolvings.isLoading) {
    return <CircularProgress />;
  }
  if (!qSolvings.isSuccess) {
    return <ErrorAlert value={qSolvings.error} />;
  }
  return (
    <Table aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Expiration</TableCell>
          <TableCell>Has autorun</TableCell>
          <TableCell>View</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography variant="body1">Everyone</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">-</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">-</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              <Link to={`/challenges/${challengeId}/code/all/user/all`}>
                View
              </Link>
            </Typography>
          </TableCell>
        </TableRow>

        {qSolvings.data
          .sort((solving1, solving2) =>
            solving1.reserved_through < solving2.reserved_through ? -1 : 1
          )
          .map((solving) => (
            <ChallengeSolvingTableRow
              solving={solving}
              challengeId={challengeId}
              key={solving.user_id}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default ChallengeSolvings;
