import { useState } from "react";
import LabeledSelectUser from "../../../components/LabeledSelectUser";
import { User } from "../../../types/Users";
import FailingAutoruns from "./FailingAutoruns";
import FailingValidations from "./FailingValidations";

interface FailingsProps {
  /** if provided, failings will be filtered to this user; filter will become uneditable */
  userId?: string;
}

const Failings = ({ userId: givenUserId }: FailingsProps) => {
  const [userId, setUserId] = useState(givenUserId ?? "");
  return (
    <div
      style={{
        border: "0.5px solid gray",
        margin: "0px 50px",
        textAlign: "center",
        backgroundColor: "whitesmoke",
      }}
    >
      {givenUserId === undefined && (
        <LabeledSelectUser
          onChangeUser={(user?: User) => setUserId(user?.id ?? "")}
          nameForEmpty="Any"
          userId={userId}
          label="Failings by user"
        />
      )}
      <FailingAutoruns userId={userId} />
      <FailingValidations userId={userId} />
    </div>
  );
};

export default Failings;
