import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import { useQueryProjects, useQueryUserProjects } from "../../../hooks/useQueryProjects";

interface SearchProjectProps {
  label?: string;
  selectedProjectId: number;
  setSelectedProjectId: (projectId: number) => void;
  userProjects?: boolean;
}

const SearchProject = ({
  label,
  selectedProjectId,
  setSelectedProjectId,
  userProjects = false,
}: SearchProjectProps) => {
  
  const { data: allProjects, isLoading: isAllProjectsLoading } = useQueryProjects();
  const { data: userProjectsData, isLoading: isUserProjectsLoading } = useQueryUserProjects();

  const projects = userProjects ? userProjectsData : allProjects;
  const isProjectsLoading = userProjects ? isUserProjectsLoading : isAllProjectsLoading;

  const matchedProject = projects?.find(
    (project) => Number(project.id) === selectedProjectId
  );
  const labeledProject = matchedProject
    ? { label: matchedProject.name, id: Number(matchedProject.id) }
    : null;

  const options =
    projects?.map((project) => ({
      label: project.name,
      id: Number(project.id),
    })) ?? [];

  return (
    <Grid item xs={5} sm={5} md={5}>
      <Autocomplete
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            helperText={
              isProjectsLoading
                ? "Loading projects..."
                : label
                ? "Required"
                : ""
            }
          />
        )}
        onChange={(event, newValue) => {
          setSelectedProjectId(newValue?.id || 0);
        }}
        value={labeledProject}
        loading={isProjectsLoading}
        loadingText={<CircularProgress />}
        autoHighlight
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Grid>
  );
};

export default SearchProject;
