import {
  Paper,
  Table,
  TableContainer,
  TableProps,
  tableBodyClasses,
  tableCellClasses,
  tableHeadClasses,
} from "@mui/material";

const _sxDefault = {
  // do not show divider on each line;
  // make cells filled (for header bgcolor will be overridden)
  [`& .${tableCellClasses.root}`]: {
    border: 0,
    backgroundColor: "white",
  },

  // make table header "background-less";
  // text "disabled"
  [`& .${tableHeadClasses.root} .${tableCellClasses.root}`]: {
    minWidth: "10px",
    backgroundColor: "inherit",
    color: "gray", // disabled-like
    paddingBottom: "5px", // little closer to first body row
    fontWeight: "normal", // no need for bold on header row
  },

  // make each row banner-like:
  //   * rounded corners
  //   * gaps in-between

  // rounded corners left
  [`& .${tableBodyClasses.root} .${tableCellClasses.root}:first-of-type`]: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },

  // rounded corners right
  [`& .${tableBodyClasses.root} .${tableCellClasses.root}:last-of-type`]: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
};
const _styleDefault: React.CSSProperties = {
  // make gaps between rows
  borderCollapse: "separate",
  borderSpacing: "0 10px",
};

interface BannerTableProps extends TableProps {}

const BannerTable = (props: BannerTableProps) => {
  const { stickyHeader = true, sx, style, children, ...tableProps } = props;

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        backgroundColor: "whitesmoke",
      }}
      elevation={0}
    >
      <TableContainer>
        <Table
          stickyHeader={stickyHeader}
          sx={{
            ..._sxDefault,
            ...sx,
          }}
          style={{ ..._styleDefault, ...style }}
          {...tableProps}
        >
          {children}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BannerTable;
