
import { useState } from "react";
import { useMutation } from "react-query";
import { useQueryChallengeDetails } from "../../../hooks/UseQueryChallengeDetails";
import { ChallengeDetailed } from "../../../types/ChallengesDetailed";
import { BackendError } from "../../../types/BackendError";
import {
  patchChallengeDescription,
  patchChallengeName,
  patchChallengeTargetURL,
  patchChallengeBudget,
  patchChallengeRunTime
} from "../LocalOperations";

import EditableField from "./EditableField";
import { Grid,  Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";


interface EditableChallengeFeatureProps {
  challengeId: string;
  getValue: (challenge: ChallengeDetailed) => any;
  updateValue: any; //(challengeId: string, value: any) => void;
  readMode?: React.ReactNode | null;
}

const EditableChallengeFeature = ({challengeId, getValue, updateValue, readMode}: EditableChallengeFeatureProps) => {
  const [value, setValue] = useState("");
  const [editor, setEditor] = useState(false);

  const qChallenge = useQueryChallengeDetails(challengeId, {
    onSuccess: (challenge: ChallengeDetailed) => setValue(getValue(challenge)),
  });

  const qEditValue = useMutation<void, BackendError, void>(() =>
    updateValue(challengeId, value)
  );

  if (!editor) {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="body1">
            <IconButton size="small" onClick={() => setEditor(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item>
          {!!readMode ? (
            readMode
          ) : (
            <Typography variant="body1">{value}</Typography>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <EditableField
      label=""
      fieldValue={value}
      onFieldChange={setValue}
      onSave={qEditValue.mutate}
      isLoading={qChallenge.isLoading || qEditValue.isLoading}
      error={
        qChallenge.isError
          ? qChallenge.error
          : qEditValue.isError
          ? qEditValue.error
          : undefined
      }
      isEditSuccess={qEditValue.isSuccess}
      justify="flex-start"
    />
  );
}

interface EditableChallengeFeatureImplementationProps {
  challengeId: string;
  readMode?: React.ReactNode | null;
}

const EditableChallengeDescription = ({challengeId}: EditableChallengeFeatureImplementationProps) => {
  return (
    <EditableChallengeFeature 
      challengeId={challengeId} 
      getValue={(challenge: ChallengeDetailed) => (challenge.data_description ?? "")}
      updateValue={patchChallengeDescription}
    />
  )
}

const EditableChallengeName = ({challengeId}: EditableChallengeFeatureImplementationProps) => {
  return (
    <EditableChallengeFeature 
      challengeId={challengeId} 
      getValue={(challenge: ChallengeDetailed) => (challenge.name ?? "")}
      updateValue={patchChallengeName}
    />
  )
}

const EditableChallengeRunTime = ({challengeId, readMode}: EditableChallengeFeatureImplementationProps) => {
  return (
    <EditableChallengeFeature 
      challengeId={challengeId} 
      getValue={(challenge: ChallengeDetailed) => (challenge.run_time_limit_s ?? "")}
      updateValue={patchChallengeRunTime}
      readMode={readMode}
    />
  )
}

const EditableChallengeTargetURL = ({challengeId, readMode}: EditableChallengeFeatureImplementationProps) => {
  return (
    <EditableChallengeFeature 
      challengeId={challengeId} 
      getValue={(challenge: ChallengeDetailed) => (challenge.target_url ?? "")}
      updateValue={patchChallengeTargetURL}
      readMode={readMode}
    />
  )
}

const EditableChallengeBudget = ({challengeId}: EditableChallengeFeatureImplementationProps) => {
  return (
    <EditableChallengeFeature 
      challengeId={challengeId} 
      getValue={(challenge: ChallengeDetailed) => (challenge.budget_eur_per_month ?? "")}
      updateValue={patchChallengeBudget}
    />
  )
}

export {
  EditableChallengeDescription,
  EditableChallengeTargetURL,
  EditableChallengeName,
  EditableChallengeBudget,
  EditableChallengeRunTime
}
