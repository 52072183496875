import { Checkbox, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { DataLabelDetailed } from "../../../types/DataLabelsDetailed";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useState } from "react";
import React from "react";

interface SnapshotStatisticsSchemaColumnProps {
  dataLabelDetailed: DataLabelDetailed;
  disabled?: boolean;
  statistics: any;
}

interface ProgressBarProps {
  bgcolor: string;
  progress?: number;
  height: string;
}

interface ValueDistributionChartPoint {
  value: number;
  label: any;
}

interface ValueDistributionChartProps {
  data: ValueDistributionChartPoint[];
}

const ValueDistributionChart = (props: ValueDistributionChartProps) => {
  const chartData = props.data.map((item) => {
    return {
      value: item.value,
      label: item.label,
    };
  });
  return (
    <BarChart
      width={600}
      height={300}
      data={chartData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 100,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="label" angle={90}  orientation="bottom"  tickMargin={20} />
      <YAxis />
      <Bar dataKey="value" fill="#cccccc" />
    </BarChart>
  );
};

interface ValueDistributionChartContainerProps {
  statistics: any;
  label: string;
}

const ValueDistributionChartContainer = (
  props: ValueDistributionChartContainerProps
) => {
  if (props.statistics.isLoading) {
    return <CircularProgress />;
  }

  if (props.statistics.isSuccess) {
    const columnStatistics = props.statistics.data[props.label];

    if (!columnStatistics) {
      return null;
    }

    const histogram = columnStatistics.histogram;
    if (!histogram) {
      return null;
    }

    return <ValueDistributionChart data={histogram} />;
  }

  return null;
};

const ProgressBar = (props: ProgressBarProps) => {
  const Parentdiv = {
    height: props.height,
    width: "100%",
    backgroundColor: "whitesmoke",
    borderRadius: "4px",
  };

  const Childdiv = {
    height: "100%",
    width: `${props.progress}%`,
    backgroundColor: props.bgcolor,
    borderRadius: "4px",
    padding: "4px",
  };

  const progresstext = {
    color: "black",
    fontWeight: 900,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>{`${props.progress}%`}</span>
      </div>
    </div>
  );
};

interface SnapshotStatisticsColumnNullsProps {
  statistics: any;
  label: string;
}

const SnapshotStatisticsColumnNulls = (
  props: SnapshotStatisticsColumnNullsProps
) => {
  if (props.statistics.isLoading) {
    return <CircularProgress />;
  }

  if (props.statistics.isSuccess) {
    const columnStatistics = props.statistics.data[props.label];

    if (!columnStatistics) {
      return null;
    }

    const value = columnStatistics.nulls;
    if (!value) {
      return null;
    }

    const missing = value.missing;
    const provided = value.provided;
    const nulled = value.nulled;
    const total = missing + provided + nulled;
    const progress = Math.round((provided / total) * 100);
    return (
      <Box width={200}>
        <ProgressBar bgcolor="orange" progress={progress} height={"24px"} />
      </Box>
    );
  }

  return null;
};

interface SnapshotStatisticsColumnSummaryProps {
  statistics: any;
  label: string;
}

interface SnapshotStatisticsElementProps {
  label: string;
  value: any;
}

const SnapshotStatisticsElement = (props: SnapshotStatisticsElementProps) => {
  return (
    <Grid item xs>
      <Typography
        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        align="center"
      >
        {props.label}
      </Typography>
      <Typography align="center">
        {isNaN(props.value) ? props.value : Math.round(props.value * 100) / 100}
      </Typography>
    </Grid>
  );
};

interface ColumnStatsContainerProps {
  statistics: any;
  label: string;
}

const ColumnStatsContainer = (props: ColumnStatsContainerProps) => {
  if (props.statistics.isLoading) {
    return <CircularProgress />;
  }

  if (!props.statistics.isSuccess) {
    return null;
  }

  const columnStatistics = props.statistics.data[props.label];
  if (!columnStatistics) {
    return null;
  }

  const stats = columnStatistics.stats;
  if (!stats) {
    return null;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      {stats.map((item: any) => {
        return (
          <SnapshotStatisticsElement label={item.label} value={item.value} />
        );
      })}
    </Grid>
  );
};

const SnapshotStatisticsColumnSummary = (
  props: SnapshotStatisticsColumnSummaryProps
) => {
  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{ padding: "12px", backgroundColor: "#efefef" }}
    >
      <ColumnStatsContainer statistics={props.statistics} label={props.label} />
      <ValueDistributionChartContainer
        statistics={props.statistics}
        label={props.label}
      />
    </Box>
  );
};

const SnapshotStatisticsSchemaColumn = (
  props: SnapshotStatisticsSchemaColumnProps
) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            {props.dataLabelDetailed.label}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography style={{ color: "silver" }}>
            {props.dataLabelDetailed.data_type}
          </Typography>
        </TableCell>
        <TableCell>
          <Checkbox
            checked={props.dataLabelDetailed.optional}
            name="optional"
            disabled={props.disabled}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={props.dataLabelDetailed.nullable}
            name="nullable"
            disabled={props.disabled}
          />
        </TableCell>
        <TableCell>
          <SnapshotStatisticsColumnNulls
            label={props.dataLabelDetailed.label}
            statistics={props.statistics}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SnapshotStatisticsColumnSummary
              label={props.dataLabelDetailed.label}
              statistics={props.statistics}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SnapshotStatisticsSchemaColumn;
