import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQueryProjectDetails } from "../pages/DashboardView/hooks/useQueryProjectDetails";
import { useQueryChallengeDetails } from "../hooks/UseQueryChallengeDetails";
import { useMutation } from "react-query";
import SearchProject from "../pages/ChallengesNewSteps/components/SearchProject";
import { updateChallengeProject, UpdateChallengeProjectParams } from "../pages/ChallengeViewSteps/LocalOperations";
import { useInvalidateChallenges } from "../pages/challengesListV2/hooks/UseInfQueryChallenges";
import { BackendError } from "../types/BackendError";

interface ChallengeMoveToProjectProps {
  open: boolean;
  onClose: () => void;
  challengeId: string;
}

const ChallengeMoveToProjectModal: React.FC<ChallengeMoveToProjectProps> = ({
  open,
  onClose,
  challengeId,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState<number>(0);
  const [shouldInvalidate, setShouldInvalidate] = useState(false);

  const invalidateChallenges = useInvalidateChallenges();

  const {
    data: challengeDetails,
    isLoading: isChallengeLoading,
    refetch,
  } = useQueryChallengeDetails(challengeId, {
    enabled: open,
    onSuccess: (data) => {
      if (data?.project_id) {
        setSelectedProjectId(data.project_id);
      }
    },
  });

  const { data: currentProject, isLoading: isProjectLoading } =
    useQueryProjectDetails((challengeDetails?.project_id), {
      staleTime: 5 * 60 * 1000,
      enabled: !!challengeDetails?.project_id && open,
    });

  useEffect(() => {
    if (challengeDetails?.project_id) {
      setSelectedProjectId(challengeDetails?.project_id);
    }
  }, [challengeDetails, setSelectedProjectId]);

   const updateChallenge = useMutation<void, BackendError, UpdateChallengeProjectParams>(updateChallengeProject, {
    onSuccess: () => {
      setSnackbarMessage(
        "Challenge was successfully assigned to the new project"
      );
      setShouldInvalidate(true);
      setOpenSnackbar(true);
      refetch();
    },
  });

  const handleClose = () => {
    if (shouldInvalidate) {
      invalidateChallenges();
      setShouldInvalidate(false);
    }
    onClose();
  };

  const onSaveChallenge = () => {
    if (
      selectedProjectId &&
      selectedProjectId !== challengeDetails?.project_id
    ) {
      updateChallenge.mutate({ challengeId, projectId: String(selectedProjectId) });
    }
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          {isChallengeLoading || isProjectLoading ? (
            <CircularProgress />
          ) : (
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h5" component="h1">
                  Challenge "{challengeDetails?.name}" is currently assigned to
                  "{currentProject?.name}" project
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SearchProject
                    selectedProjectId={selectedProjectId}
                    setSelectedProjectId={setSelectedProjectId}
                    userProjects={true}
                  />
                </Grid>
                <Grid item xs={12} mt={2} container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSaveChallenge}
                    size="large"
                    disabled={
                      updateChallenge.isLoading ||
                      !selectedProjectId ||
                      selectedProjectId === challengeDetails?.project_id
                    }
                  >
                    {updateChallenge.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Save changes"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default React.memo(ChallengeMoveToProjectModal);
