import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Paper,
  Popover,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useRef, useState } from "react";
import { LinkForButton } from "./LinkForButton";

type _SplitButtonDropdownChoice = {
  /** text, number, \<Element />. False and nullish does not get rendered. */
  node: React.ReactNode;
  link?: string;
  /** to make dropdown choice unclickable, do not provide a method. Does not apply to primary button. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: React.CSSProperties;
};

export type SplitButtonDropdownChoice =
  | _SplitButtonDropdownChoice
  | undefined
  | null
  | false;

interface SplitButtonDropdownProps extends Omit<ButtonGroupProps, "ref"> {
  primaryButton: SplitButtonDropdownChoice & ButtonProps;
  dropdownItems?: SplitButtonDropdownChoice[];
  isPrimaryButtonExpandsDropdown?: boolean;
}

const SplitButtonDropdown = ({
  primaryButton,
  dropdownItems = [],
  isPrimaryButtonExpandsDropdown,
  ...buttonGroupProps
}: SplitButtonDropdownProps) => {
  const primarySource = !!primaryButton ? primaryButton : { node: null };
  const {
    node: primaryElement = "",
    link: primaryLink,
    onClick: _primaryOnClick,
    ...buttonProps
  } = primarySource;
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const isListUnrenderable = !dropdownItems.some((choice) => !!choice);
  const primaryOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (_primaryOnClick) _primaryOnClick(e);
    if (isPrimaryButtonExpandsDropdown) {
      setIsOpen(true);
    }
  };
  return (
    <>
      <ButtonGroup ref={ref} {...buttonGroupProps}>
        {primaryLink ? (
          <Button
            {...buttonProps}
            onClick={primaryOnClick}
            style={{ ...buttonProps.style }}
          >
            <LinkForButton to={primaryLink}>{primaryElement}</LinkForButton>
          </Button>
        ) : (
          <Button {...buttonProps} onClick={primaryOnClick}>
            {primaryElement}
          </Button>
        )}
        <Button
          onClick={() => setIsOpen(true)}
          size="small"
          disabled={isListUnrenderable}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popover
        anchorEl={ref.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper style={{ minWidth: "100px" }}>
          {dropdownItems.map((choice, index) => {
            const isDisabled = !!choice && choice?.onClick === undefined;

            if (!choice || !choice.node) return <React.Fragment key={index} />;
            const { link, onClick, node, style } = choice;
            const handleItemClick = (
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              if (onClick) onClick(e);
              setIsOpen(false);
            };
            return link ? (
              <LinkForButton
                to={link}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="text"
                  style={{
                    textTransform: "none",
                    display: "block",
                    width: "100%",
                    textAlign: "left",
                    color: "black",
                    ...buttonProps.style,
                    ...style,
                  }}
                  onClick={handleItemClick}
                >
                  {node}
                </Button>
              </LinkForButton>
            ) : (
              <Button
                onClick={handleItemClick}
                disabled={isDisabled}
                variant="text"
                key={index}
                style={{
                  textTransform: "none",
                  display: "block",
                  width: "100%",
                  textAlign: "left",
                  color: isDisabled ? "gray" : "black",
                  ...choice.style,
                }}
              >
                {node}
              </Button>
            );
          })}
        </Paper>
      </Popover>
    </>
  );
};

export default SplitButtonDropdown;
