import React from "react";
import { FormControl, Checkbox, Autocomplete, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { User } from "../types/Users";
import { ProjectUsers } from "../types/ProjectUsers";

interface UserSelectorProps {
  selectedUserIds: string[];
  onChange: (selectedIds: string[]) => void;
  users: User[];
  label: string;
  projectUsers?: ProjectUsers;
}

const UserSelector: React.FC<UserSelectorProps> = ({
  selectedUserIds,
  onChange,
  users,
  label,
  projectUsers = []
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
  const checkedIcon = <CheckBoxIcon fontSize="medium" />;

  const handleUserSelectionChange = (
    event: React.ChangeEvent<{}>,
    value: User[] | null
  ) => {
    onChange(value ? value.map((user) => user.id) : []);
  };

  const getSelectedUsers = (users: User[], selectedIds: string[]) => {
    return users.filter((user) => selectedIds.includes(user.id));
  };

  const availableUsers = users.filter(
    (user) => !projectUsers.some((projectUser) => projectUser.user_id === Number(user.id))
  );


  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={availableUsers}
        getOptionLabel={(option: User) => option.email}
        value={getSelectedUsers(users, selectedUserIds)}
        onChange={handleUserSelectionChange}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.email}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </FormControl>
  );
};

export default UserSelector;
