import Editor from "@monaco-editor/react";
import TitledCard from "./TitledCard";

interface TitledCodeEditorCardProps {
  language?: string;
  onChange?: (value?: string) => void;
  title?: string;
  value?: string | null;
  wordWrap?: boolean;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

const TitledCodeEditorCard = ({
  onChange,
  language,
  title,
  value,
  wordWrap,
  style,
  wrapperStyle,
}: TitledCodeEditorCardProps) => {
  return (
    <TitledCard
      title={title}
      style={{ height: "500px", ...style }}
      wrapperStyle={wrapperStyle}
    >
      <Editor
        onChange={onChange}
        options={{
          readOnly: onChange === undefined,
          wordWrap: wordWrap ? "on" : "off",
        }}
        language={language}
        value={value === null ? undefined : value}
      ></Editor>
    </TitledCard>
  );
};

export default TitledCodeEditorCard;
