import { Button, Card, Stack } from "@mui/material";
import { useState } from "react";
import CodeOutputDialog from "./CodeOutputDialog";
import SnapshotPreviewDialog from "./SnapshotPreviewDialog";
import { HashLink } from "react-router-hash-link";

interface SubmissionViewCardProps {
  style?: React.CSSProperties;
  challengeId: string;
  runNumber: string;
  userId: string;
  snapshotId: string;
  templateId: string;
}

const SubmissionViewCard = ({
  style,
  challengeId,
  runNumber,
  userId,
  snapshotId,
  templateId
}: SubmissionViewCardProps) => {
  const [isCodeOutputOpen, setIsCodeOutputOpen] = useState(false);
  const [isSnapshotViewOpen, setIsSnapshotViewOpen] = useState(false);

  return (
    <Card style={style}>
      <Stack direction="column">
        <Button onClick={() => setIsCodeOutputOpen(true)}>
          View Code Run Output
        </Button>
        <CodeOutputDialog
          challengeId={challengeId}
          userId={userId}
          codeRunNumber={runNumber ?? ""}
          open={isCodeOutputOpen}
          onClose={() => setIsCodeOutputOpen(false)}
          maxWidth="lg"
          style={{ minWidth: "30vw" }}
        />
        {!!snapshotId && (
          <>
            <Button
              onClick={() => {
                setIsSnapshotViewOpen(true);
              }}
              variant="contained"
            >
              View Snapshot Preview
            </Button>
            <SnapshotPreviewDialog
              open={isSnapshotViewOpen}
              onClose={() => setIsSnapshotViewOpen(false)}
              snapshotId={snapshotId}
              templateId={templateId}
              text={""}
              isDownloadShown
            />
          </>
        )}
      </Stack>
    </Card>
  );
};

export default SubmissionViewCard;
